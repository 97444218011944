import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import { useToast } from 'components/Toast';
import { useProductCategoryCreateMutation } from 'hooks/use-product-categories';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CategoryForm from './CategoryForm';
import useAuth from 'hooks/use-auth';
import useConf from 'hooks/use-conf';
import isSuccessResponse from 'lib/isSuccessResponse';

const CategoryNew = () => {
	const navigate = useNavigate();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { config } = useConf();
	const { locale, setLocale, logOut } = useAuth();

	useEffect(() => {
		// To create new category has to be default locale (Could not be called directly, React error)
		setLocale(config.default_locale);
	}, [config.default_locale]);

	const [createCategory, { loading: isSubmitting }] = useProductCategoryCreateMutation({
		onCompleted: ({ ProductCategoryCreate }) => {
			if (
				isSuccessResponse(
					ProductCategoryCreate,
					['ProductCategory'],
					logOut,
					addErrorMessage,
					'Crear categoría'
				)
			) {
				addSuccessMessage('Categoría', 'creada correctamente');
				navigate(`/dashboard/catalogo/categorias/edit/${ProductCategoryCreate.id}`);
			}
		},
	});

	const handleSubmit = values => {
		createCategory({ variables: { locale, input: values } });
	};

	return (
		<>
			<LanguageTabs disabled={true} />
			<Card>
				<LayoutHeader>Crear Categoría</LayoutHeader>
				<CategoryForm newCategory onSubmit={handleSubmit} isSubmitting={isSubmitting} />
			</Card>
		</>
	);
};

export default CategoryNew;
