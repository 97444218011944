import React from 'react';
import ConditionalWrapper from './ConditionalWrapper';
import Tooltip from './Tooltip';

const Button = ({
	children,
	as: Component = 'button',
	className = 'shadow-md',
	bg = 'bg-coral-500',
	font = 'font-bold',
	hover = 'hover:bg-coral-700',
	focus = 'focus:shadow focus:shadow-outline focus:outline-none',
	disabled = false,
	tooltip = null,
	padding = 'py-2 px-3',
	...props
}) => {
	return (
		<ConditionalWrapper condition={tooltip} wrapper={children => <Tooltip tooltip={tooltip}>{children}</Tooltip>}>
			<Component
				className={`${
					disabled ? 'cursor-not-allowed opacity-50' : ''
				} text-sm text-white ${font} block rounded-md transition-all duration-200 ${focus} ${className} ${bg} ${hover} ${padding}`}
				disabled={disabled}
				{...props}
			>
				{children}
			</Component>
		</ConditionalWrapper>
	);
};

export default Button;

export const Minibutton = ({ children, ...props }) => (
	<button className='text-xs text-gray-700' {...props}>
		{children}
	</button>
);

export const EditButton = props => (
	<Button {...props}>
		<svg viewBox='0 0 20 20' fill='currentColor' className='pencil-alt h-4 w-4'>
			<path d='M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z'></path>
			<path
				fillRule='evenodd'
				d='M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z'
				clipRule='evenodd'
			></path>
		</svg>
	</Button>
);

export const DeleteButton = props => (
	<Button className='ml-2' {...props}>
		<svg viewBox='0 0 20 20' fill='currentColor' className='trash h-4 w-4'>
			<path
				fillRule='evenodd'
				d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
				clipRule='evenodd'
			></path>
		</svg>
	</Button>
);
