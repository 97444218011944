import Input from 'components/Input';
import Modal from 'components/Modal';

export const TransferModal = ({
	merchantPayment,
	paymentValue,
	setPaymentValue,
	transferMerchantPayment,
	openModalTransfer,
	setOpenModalTransfer,
}) => {
	const checkInvalid = () => {
		if (paymentValue > getMaxValue() || paymentValue < getMinValue()) {
			return true;
		}
		return false;
	};

	const getMaxValue = () => {
		return merchantPayment?.MerchantPaymentTotals?.maxTransfer >= 0
			? merchantPayment.MerchantPaymentTotals.maxTransfer
			: merchantPayment?.netTotal;
	};

	const getMinValue = () => {
		return merchantPayment?.MerchantPaymentTotals?.maxTransfer >= 0
			? merchantPayment.netTotal
			: merchantPayment?.MerchantPaymentTotals?.maxTransfer;
	};

	const onTransferSubmit = () => {
		transferMerchantPayment();
		setOpenModalTransfer(false);
	};

	const modalObject = {
		title: getMaxValue() > 0 ? 'Transferencia en Stripe' : 'Confirmar la devolución',
		description: (
			<div className='flex flex-col items-center'>
				<p className='text-lg leading-5 text-gray-500'>
					{getMaxValue() > 0
						? `Al confirmar generará la transferencia de fondos a ${merchantPayment?.Merchant?.commercialName} y no se podrá anular.`
						: `Al confirmar se confirmará la recepción de fondos en nuestra cuenta de parte de ${merchantPayment?.Merchant?.commercialName}`}
				</p>
				<div className={`flex flex-col items-center mt-6 ${!checkInvalid() && 'mb-5'}`}>
					<Input
						name='payment'
						className='text-lg p-4'
						invalid={checkInvalid()}
						value={paymentValue}
						type='number'
						step='.01'
						onChange={e => setPaymentValue(e.target.value)}
					/>
					{paymentValue >= getMaxValue() && (
						<p className='text-coral-500 w-full text-base'>
							*Superior al máximo permitido ({getMaxValue()} €)
						</p>
					)}
					{paymentValue <= getMinValue() && (
						<p className='text-coral-500 w-full text-base'>
							*Inferior al mínimo permitido ({getMinValue()} €)
						</p>
					)}
				</div>
			</div>
		),
		btnText: 'Confirmar',
		cancelBtnText: 'Cancelar',
	};

	return (
		<Modal
			modalObject={modalObject}
			openModal={openModalTransfer}
			setOpenModal={setOpenModalTransfer}
			handleSubmit={onTransferSubmit}
			disabled={checkInvalid()}
		/>
	);
};

export default TransferModal;
