import microsoftTranslator from './MicrosoftTranslator';

const translatePlainField = async (
	text,
	fromLocale,
	setTranslatorModal,
	setValue,
	name,
	addErrorMessage,
	locale,
	config,
	setIsTranslated = () => {}
) => {
	let success = false;
	try {
		const translations = await microsoftTranslator(fromLocale, locale, [{ text }], config, setTranslatorModal);
		if (translations) {
			setValue(name, translations[0].translations[0].text);
			setIsTranslated(true);
			success = true;
		} else {
			addErrorMessage('Traducción', translations.message);
		}
	} catch (error) {
		setTranslatorModal(false);
		addErrorMessage('Traducción', error.message);
	}
	return success;
};

export default translatePlainField;
