import React, { useState } from 'react';

const Specifications = ({ specifications }) => {
	const [specificationsModal, setSpecificationsModal] = useState(false);

	if (!specifications) return null;

	return (
		<div onMouseEnter={() => setSpecificationsModal(true)} onMouseLeave={() => setSpecificationsModal(false)}>
			<img className='w-4 ml-2 mt-3' src='/images/help/circle-info-solid.svg' alt='icono información' />
			{specificationsModal && (
				<div className='absolute w-1/2 z-40 bg-gray-200 border-2 rounded-md border-coral-400 text-black font-medium p-2'>
					{specifications}
				</div>
			)}
		</div>
	);
};

export default Specifications;
