import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import { DeleteButton } from 'components/Button';
import Card from 'components/Card';
import ContentTable from 'components/ContentTable';
import Currency from 'components/Currency';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import Input from 'components/Input';
import SelectMerchantInput from 'components/SelectMerchantInput';
import { useToast } from 'components/Toast';
import TooltipMenu from 'components/TooltipMenu';
import TransferModal from 'components/TransferModal';
import columnTitleElement from 'lib/columnTitleElement';
import formatDate from 'lib/formatDate';
import translasteStatus from 'lib/translasteStatus';
import useAuth from 'hooks/use-auth';
import { useLazyMerchantPayments, useTransferMerchantPayment } from 'hooks/use-merchant-payments';
import isSuccessResponse from 'lib/isSuccessResponse';
import Pagination from 'components/Pagination';

const MerchantPayments = ({ paginationLimit = 50 }) => {
	const { addErrorMessage, addSuccessMessage } = useToast();
	const { locale, logOut } = useAuth();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [scope, setScope] = useState('all');
	const [orderScope, setOrderScope] = useState('created_asc');
	const [merchantId, setMerchantId] = useState(null);
	const [offset, setOffset] = useState(0);
	const [openModalTransfer, setOpenModalTransfer] = useState(false);
	const [merchantPayment, setMerchantPayment] = useState(null);
	const [MerchantPaymentsResponse, setMerchantPaymentsResponse] = useState(null);
	const [paymentValue, setPaymentValue] = useState(0);
	const [getLazyMerchantPayments, { loading }] = useLazyMerchantPayments({
		variables: {
			scope,
			order_scope: orderScope,
			startDate,
			endDate,
			MerchantId: merchantId,
			limit: paginationLimit,
			offset,
			locale,
		},
		onCompleted: ({ MerchantPayments }) => {
			if (isSuccessResponse(MerchantPayments, ['FAQ'], logOut, addErrorMessage, 'Facturación')) {
				setMerchantPaymentsResponse(MerchantPayments);
			}
		},
	});

	// Excecute once at page load
	useEffect(() => {
		getLazyMerchantPayments();
	}, [getLazyMerchantPayments]);

	const handleSelectStartDate = e => {
		if (e.target.value > endDate) {
			addErrorMessage('Fecha Inicial', 'No puede ser mayor que la fecha final');
		} else if (new Date(e.target.value) > new Date()) {
			addErrorMessage('Fecha Inicial', 'No puede ser mayor al día de hoy');
		} else {
			setStartDate(e.target.value);
		}
	};

	const handleSelectEndDate = e => {
		if (e.target.value < startDate) {
			addErrorMessage('Fecha Final', 'No puede ser menor que la fecha inicial');
		} else if (new Date(e.target.value) > new Date()) {
			addErrorMessage('Fecha Final', 'No puede ser mayor al día de hoy');
		} else {
			setEndDate(e.target.value);
		}
	};

	const handleSelectMerchant = e => setMerchantId(e.target.value);

	const handleClearDate = selector => {
		if (selector === 'startDate') {
			setStartDate(null);
		} else if (selector === 'endDate') {
			setEndDate(null);
		}
	};

	const filterStatusList = () =>
		[
			{ label: 'Todas', scope: 'all' },
			{ label: 'Creado', scope: 'created' },
			{ label: 'Pagado', scope: 'paid' },
		]?.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setScope(scope);
				setOffset(0);
				getLazyMerchantPayments();
			},
		}));

	const orderByDate = () =>
		[
			{ label: 'Ascendente', scope: 'created_asc' },
			{ label: 'Descendente', scope: 'created_desc' },
		].map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyMerchantPayments();
			},
		}));

	const [transferMerchantPayment] = useTransferMerchantPayment({
		variables: {
			MerchantPaymentId: merchantPayment?.id,
			quantity: Number.parseFloat(paymentValue),
			locale,
		},
		onCompleted: ({ TransferMerchantPayment }) => {
			if (isSuccessResponse(TransferMerchantPayment, ['Success'], logOut, addErrorMessage, 'Transferencia')) {
				addSuccessMessage('Transferencia', 'Realizada correctamente');
				getLazyMerchantPayments();
			}
		},
	});

	return (
		<>
			<Card className='mb-20'>
				<LayoutHeader>Facturación</LayoutHeader>
				<Input
					label='Fecha inicial'
					className='my-2'
					onChange={handleSelectStartDate}
					type='date'
					value={startDate}
				>
					<DeleteButton onClick={() => handleClearDate('startDate')} className='ml-2 h-10' />
				</Input>
				<Input label='Fecha final' className='my-2' onChange={handleSelectEndDate} type='date' value={endDate}>
					<DeleteButton onClick={() => handleClearDate('endDate')} className='ml-2 h-10' />
				</Input>
				<AuthorizationBoundary for={['admin']}>
					{/* En SelectMerchantInput se hace un use-merchant que muestra los merchants y permite cambiar el MerchantId */}
					<SelectMerchantInput
						emptyValue={{ id: '', commercialName: 'Todos los asociados' }}
						onChange={handleSelectMerchant}
						width='w-1/3'
						label='Selecciona un asociado'
					/>
				</AuthorizationBoundary>
				<LoadingView loading={loading} whileLoading='Buscando...'>
					<p className='mt-8 mb-4'>
						<span className='mb-2 font-semibold text-coral-500 text-lg'>Nota: </span>
						<span>
							Las facturas incluyen todos los pedidos posteriores a la fecha de la anterior factura.
						</span>
					</p>
					<Pagination
						offset={offset}
						setOffset={setOffset}
						count={MerchantPaymentsResponse?.count}
						hasMore={MerchantPaymentsResponse?.hasMore}
						limit={paginationLimit}
					/>
					<ContentTable
						key={22}
						columns={[
							columnTitleElement('Fecha', orderByDate),
							columnTitleElement('Asociado'),
							columnTitleElement('Estado', filterStatusList),
							columnTitleElement('Nº Pedidos'),
							columnTitleElement('Total'),
						]}
						content={MerchantPaymentsResponse?.List}
						render={(item, index) => (
							<tr key={index} className='border-t border-dashed border-coral-300 text-gray-700'>
								<td className='px-4 py-2 w-1/5'>{formatDate(item.endDate)}</td>
								<td className='px-4 text-left'>
									<Link
										to={`/dashboard/facturacion/${item.id}`}
										className='border-b border-dashed border-coral-300 hover:text-coral-500 tranisiton-color duration-100'
									>
										{item.Merchant?.commercialName}
									</Link>
								</td>
								<td
									className={`px-4 py-2 ${
										item.status === 'paid' ? 'text-green-400 font-semibold' : ''
									}`}
								>
									{translasteStatus(item.status)}
								</td>
								<td className='px-4 py-2'>{item.orderCount}</td>
								<td className='px-4 py-2'>
									<Currency>{item.transferredTotal || item.grossTotal}</Currency>
								</td>
								{item.status === 'created' && (
									<AuthorizationBoundary for={['admin']}>
										<td className='px-6'>
											<div className='h-6'>
												<TooltipMenu
													menuList={[
														{
															title: 'Transferir',
															onClick: () => {
																setMerchantPayment(item);
																setPaymentValue(Number.parseFloat(item.grossTotal));
																setOpenModalTransfer(true);
															},
														},
													]}
													mt=''
												/>
											</div>
										</td>
									</AuthorizationBoundary>
								)}
							</tr>
						)}
						empty={
							<p className='my-5 flex h-24 w-full items-center justify-center rounded-md border border-gray-400 text-gray-700 shadow-md'>
								No hay elementos
							</p>
						}
					/>
				</LoadingView>
			</Card>
			<TransferModal
				merchantPayment={merchantPayment}
				paymentValue={paymentValue}
				setPaymentValue={setPaymentValue}
				transferMerchantPayment={transferMerchantPayment}
				openModalTransfer={openModalTransfer}
				setOpenModalTransfer={setOpenModalTransfer}
			/>
		</>
	);
};

export default MerchantPayments;
