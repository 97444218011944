const semiStringifyJSON = (content, contentOfTypeEditor) => {
	// First Parse the contentOfTypeEditor keys (as they are already stringified)
	for (var [key, value] of Object.entries(content)) {
		if (contentOfTypeEditor.has(key) && typeof value === 'string') content[key] = JSON.parse(value);
	}

	// Now stringify the whole object in one go
	return JSON.stringify(content);
};

export default semiStringifyJSON;
