import React, { useState, useEffect } from 'react';

import useAuth from 'hooks/use-auth';
import { useLazyMerchant } from 'hooks/use-merchants';

import CommercialForm from 'pages/Merchants/CommercialForm';
import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import isSuccessResponse from 'lib/isSuccessResponse';
import { useToast } from 'components/Toast';

const MyCommercialForm = () => {
	const { user, logOut, locale } = useAuth();
	const [dirty, setDirty] = useState(false);
	const [merchantData, setMerchantData] = useState(null);

	const [getLazyMerchant] = useLazyMerchant();
	const { addErrorMessage, addSuccessMessage } = useToast();

	// Excecute once at page load
	useEffect(() => {
		// query Product fields with specific locale
		getLazyMerchant({
			variables: { id: user.MerchantId, forceLocale: true, locale },
			onCompleted: ({ Merchant }) => {
				if (isSuccessResponse(Merchant, ['Merchant'], logOut, addErrorMessage, 'Editar Asociado')) {
					setMerchantData(Merchant);
				}
			},
		});
	}, [locale, user.MerchantId]);

	const handleCopyAnotherLanguage = () => {
		getLazyMerchant({
			variables: { id: user.MerchantId, forceLocale: false, locale },
			onCompleted: ({ Merchant }) => {
				if (isSuccessResponse(Merchant, ['Merchant'], logOut, addErrorMessage, 'Copiar datos')) {
					setMerchantData(Merchant);
					addSuccessMessage('Copiar datos', 'Campos copiados correctamente, recuerda guardar los cambios');
				}
			},
		});
	};

	return (
		<>
			<LanguageTabs dirty={dirty} />
			<Card>
				{merchantData ? (
					<CommercialForm
						merchantData={merchantData}
						className='shadow-none'
						adminLayout={false}
						dirty={dirty}
						setDirty={setDirty}
						handleCopyAnotherLanguage={handleCopyAnotherLanguage}
						getLazyMerchant={getLazyMerchant}
					/>
				) : null}
			</Card>
		</>
	);
};

export default MyCommercialForm;
