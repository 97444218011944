import React, { useEffect, useState } from 'react';
import semverSort from 'semver-sort';
import Card from 'components/Card';
import LayoutHeader from 'components/LayoutHeader';
import ChangelogElement from 'components/ChangelogElement';
import useConf from 'hooks/use-conf';

async function fetchChangelog(changelogUrl) {
	const response = await fetch(changelogUrl);
	const releases = await response.json();
	return releases;
}

const Versions = () => {
	const { config } = useConf();
	const [releases, setReleases] = useState(<p>Cargando</p>);

	// Title & URL's must follow the same number & order
	const [changelogURLS] = useState([
		config.statics + '/changelog/api.json',
		config.statics + '/changelog/admin.json',
		config.statics + '/changelog/shop.json',
		config.statics + '/changelog/shop_common.json',
	]);

	const [changelogTitles] = useState(['API', 'Admin', 'Tienda', 'Tienda común']);

	// useEffect without dependencies, used to execute the function only once (empty dependencies array)
	useEffect(() => {
		Promise.all(changelogURLS.map(URL => fetchChangelog(URL))).then(releasesInfoArray => {
			const changelogs = {};
			// Adding API changelog information to changelogs object as {"#.#.#": {"API": {<API release #.#.# info>}}}
			// eslint-disable-next-line unicorn/no-array-for-each
			releasesInfoArray.forEach(({ releases }, index) => {
				// eslint-disable-next-line unicorn/no-array-for-each
				Object.entries(releases).forEach(([releaseStr, releaseInfo]) => {
					changelogs[releaseStr] = changelogs[releaseStr] || {};
					changelogs[releaseStr][changelogTitles[index]] = releaseInfo;
				});
			});
			setReleases(
				semverSort
					.desc(Object.entries(changelogs))
					.map(([releaseStr, releaseInfo]) => (
						<ChangelogElement key={releaseStr} releaseStr={releaseStr} release={releaseInfo} />
					))
			);
		});
	}, []);

	return (
		<Card>
			<LayoutHeader>Historial de versiones</LayoutHeader>
			{releases}
		</Card>
	);
};

export default Versions;
