import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default async (config, urls) =>
	new Promise((resolve, reject) => {
		axios({
			baseURL: config.shop_url,
			url: '/api/webhook',
			method: 'post',
			data: {
				secret: process.env.REACT_APP_REVALIDATE_WEBHOOK_SECRET,
				urls,
			},
			responseType: 'json',
		})
			.then(data => {
				if (data) {
					resolve(data);
				}
			})
			.catch(error => {
				reject(error);
			});
	});
