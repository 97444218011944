import RefundList from 'components/RefundList';
import React from 'react';

const Refunds = () => {
	const scopeList = [
		{ label: 'Todas', scope: 'refundAll' },
		{ label: 'Pendientes', scope: 'refundPending' },
		{ label: 'Solicitadas', scope: 'refundRequestedQuantity' },
		{ label: 'Recogidas', scope: 'refundPicked' },
		{ label: 'Completadas', scope: 'refundCompleted' },
	];
	return <RefundList title='Devoluciones' scopeList={scopeList} />;
};

export default Refunds;
