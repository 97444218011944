import React from 'react';

import Button from 'components/Button';

const DottedElement = ({ title = '', description = '', date = '', link, className = '', ...props }) => (
	<div
		{...props}
		className={`pb-4 pt-2 px-2 border-t-2 ${className} border-dotted border-red-400 grid grid-flow-col gap-4`}
	>
		<div>
			<p className='mb-2 font-medium'>{title}</p>
			{description.length > 0 && (
				<>
					<p>{description}</p>
					<br />
				</>
			)}
		</div>
		<div className='flex h-full'>
			<p className='w-full text-right'>{date}</p>
			<a href={link} target='_blank' rel='noreferrer'>
				<Button className='m-auto'>Ver Más</Button>
			</a>
		</div>
	</div>
);

export default DottedElement;
