import React, { useEffect, useState } from 'react';
import Card from 'components/Card';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import LayoutHeader from 'components/LayoutHeader';
import LayoutTitle from 'components/LayoutTitle';
import SalesStatisticsSummary from 'components/SalesStatisticsSummary';
import SelectMerchantInput from 'components/SelectMerchantInput';
import Input from 'components/Input';
import { useToast } from 'components/Toast';
import { useLazyStatistics } from 'hooks/use-statistics';
import OrderCountChart from './OrderCountChart';
import OrderAmountChart from './OrderAmountChart';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';

const Statistics = () => {
	const { logOut } = useAuth();
	const { addErrorMessage } = useToast();

	const [graphYear, setGraphYear] = useState(new Date().getFullYear());
	const [graphData, setGraphData] = useState(null);
	const [merchantId, setMerchantId] = useState(null);
	const [statisticsTotals, setStatisticsTotals] = useState(null);
	const [getLazyStatistics, { data }] = useLazyStatistics({ graphYear, MerchantId: merchantId });

	useEffect(() => {
		getLazyStatistics({ graphYear, MerchantId: merchantId });
	}, [getLazyStatistics]);

	const handleSelectGraphYear = e => setGraphYear(Number.parseInt(e.target.value));
	// graphData update States
	useEffect(() => {
		if (data && isSuccessResponse(data.Statistics, ['StatisticsValues'], logOut, addErrorMessage, 'Estadísticas')) {
			const filledStatisticsList = [...data.Statistics.salesList];
			filledStatisticsList.sort((a, b) => a.month - b.month);
			// Fill List with empty data
			if (filledStatisticsList.length < 12) {
				for (let month = 0; month < 12; month++) {
					if (filledStatisticsList[month]?.month !== month + 1) {
						filledStatisticsList.splice(month, 0, {
							month: month + 1 < 10 ? '0' + (month + 1) : month + 1,
							orderAmountPerMonth: 0,
							orderCountPerMonth: 0,
						});
					}
				}
			}
			setGraphData(filledStatisticsList);
			setStatisticsTotals(data.Statistics.totals);
		}
	}, [data, addErrorMessage]);

	const handleSelectMerchant = e => setMerchantId(e.target.value);

	return (
		<div className='flex flex-col flex-col-reverse lg:flex-row'>
			<Card className='mr-4 lg:w-2/3'>
				<LayoutHeader>Estadísticas</LayoutHeader>
				<Input
					label='Año'
					className='my-2'
					onChange={handleSelectGraphYear}
					type='number'
					min='2021'
					max='2099'
					step='1'
					value={graphYear}
				/>
				<AuthorizationBoundary for={['admin']}>
					{/* En SelectMerchantInput se hace un use-merchant que muestra los merchants y permite cambiar el MerchantId */}
					<SelectMerchantInput
						emptyValue={{ id: '', commercialName: 'Todos los asociados' }}
						onChange={handleSelectMerchant}
						width='w-1/3'
						label='Selecciona un asociado'
					/>
				</AuthorizationBoundary>
				{graphData && (
					<>
						<LayoutTitle>Nº de ventas mensuales</LayoutTitle>
						<OrderCountChart
							graphData={graphData.map(monthData => ({
								month: monthData.month,
								total: monthData.orderCountPerMonth,
							}))}
						/>
						<span className='mt-20'>
							<LayoutTitle>Total de ventas mensuales</LayoutTitle>
						</span>
						<OrderAmountChart
							graphData={graphData.map(monthData => ({
								month: monthData.month,
								total: monthData.orderAmountPerMonth,
							}))}
						/>
					</>
				)}
			</Card>
			{statisticsTotals && <SalesStatisticsSummary statistics={statisticsTotals} />}
		</div>
	);
};

export default Statistics;
