import { useState, useEffect } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useNavigate } from 'react-router-dom';

import useConf from 'hooks/use-conf';

import Currency from './Currency';
import LayoutHeader from './LayoutHeader';
import Input from './Input';
import Message from './Message';
import Button from './Button';

import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';
import { usePaymentFeeCreate, usePaymentFeeDone } from 'hooks/use-checkout';
import { useSendEmailToNotifications } from 'hooks/use-emails';
import PulseLoader from './PulseLoader';
import isSuccessResponse from 'lib/isSuccessResponse';

const CARD_OPTIONS = {
	iconStyle: 'solid',
	style: {
		base: {
			iconColor: '#646464',
			color: '#646464',
			fontWeight: '500',
			fontFamily: 'sans-serif',
			fontSize: '16px',
			fontSmoothing: 'antialiased',
			':-webkit-autofill': {
				color: '#fce883',
			},
			'::placeholder': {
				color: '#646464',
			},
		},
		invalid: {
			iconColor: '#ef2961',
			color: '#ef2961',
		},
	},
};

const StripeCheckout = ({ onSuccess = () => {}, administrativeBusinessName }) => {
	const { config } = useConf();
	const [stripePromise] = useState(loadStripe(config.stripe_publishable_key));
	const { addErrorMessage, addSuccessMessage } = useToast();
	const { isLoggedIn, loggedInStatus, user, logOut } = useAuth();
	const navigate = useNavigate();

	// Deny access to checkout if not merchant and not loged in
	useEffect(() => {
		if (user?.type !== 'merchant') {
			navigate('/dashboard');
			addErrorMessage('Autenticación', 'Opción de pago solo para Asociados');
		}
		if (isLoggedIn === loggedInStatus.no) {
			navigate('/login');
			addErrorMessage('Autenticación', 'Debe iniciar sesión');
		}
	}, [navigate, user]);

	return (
		<Elements options={{ locale: 'es' }} stripe={stripePromise}>
			<Checkout
				config={config}
				onSuccess={onSuccess}
				addErrorMessage={addErrorMessage}
				addSuccessMessage={addSuccessMessage}
				administrativeBusinessName={administrativeBusinessName}
				logOut={logOut}
			/>
		</Elements>
	);
};

const Checkout = ({ config, onSuccess, addErrorMessage, addSuccessMessage, administrativeBusinessName, logOut }) => {
	const stripe = useStripe();
	const elements = useElements();

	const [cardholderName, setCardholderName] = useState('');
	const [paymentStatus, setPaymentStatus] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');
	const [paymentCreateResponse, setPaymentCreateResponse] = useState('');
	const [paymentButtonLabel, setPaymentButtonLabel] = useState('Realizar pago');
	const [notifyButtonLabel, setNotifyButtonLabel] = useState('Notificar transferencia realizada');
	const [paymentFeeCreate] = usePaymentFeeCreate();
	const [sendEmail] = useSendEmailToNotifications({
		onCompleted: ({ SendEmailToNotifications }) => {
			if (isSuccessResponse(SendEmailToNotifications, ['Success'], logOut, addErrorMessage, 'Email')) {
				onSuccess('transfer');
				setNotifyButtonLabel('Email enviado');
			}
		},
	});
	const [paymentFeeDone] = usePaymentFeeDone();

	useEffect(() => {
		paymentFeeCreate().then(({ data }) => {
			if (isSuccessResponse(data?.PaymentFeeCreate, ['PaymentIntent'], logOut, addErrorMessage, 'Stripe')) {
				setPaymentCreateResponse(data.PaymentFeeCreate);
			}
		});
	}, []);

	const handleSubmit = async event => {
		// Block native form submission.
		event.preventDefault();
		setPaymentStatus(null);
		setErrorMessage(null);
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement);
		setPaymentButtonLabel(
			<div className='flex justify-center items-center w-full h-full'>
				<p>Procesando pago</p>
				<PulseLoader />
			</div>
		);
		// Use your card Element with other Stripe.js APIs
		const { error, paymentIntent } = await stripe.confirmCardPayment(paymentCreateResponse.client_secret, {
			payment_method: {
				card: cardElement,
				billing_details: { name: cardholderName },
			},
		});

		if (error) {
			setPaymentButtonLabel('Reintentar pago');
			setPaymentStatus(error);
			return;
		}
		setPaymentStatus(paymentIntent);

		paymentFeeDone().then(({ data }) => {
			if (isSuccessResponse(data?.PaymentFeeDone, ['Success', 'MailFault'], logOut, addErrorMessage, 'Pago')) {
				addSuccessMessage(
					'Pago',
					'Procesado correctamente, una vez verificado el pago se enviará al correo de tu perfil la factura de mismo'
				);
				onSuccess('stripe');
			}
		});
	};

	const handleCardholderName = e => setCardholderName(e.target.value);

	const handleSendEmail = () => {
		setNotifyButtonLabel(
			<div className='flex justify-center items-center w-full h-full'>
				<p>Enviando email</p>
				<PulseLoader />
			</div>
		);
		sendEmail({
			variables: {
				subject: `${administrativeBusinessName} transferencia de adhesión`,
				text: `El Asociado ${administrativeBusinessName} ha notificado la transferencia por un importe de: ${
					paymentCreateResponse.amount / 100
				} euros\nComprobar en el banco la recepción de la transferencia y luego marcar la opción de "Cuota pagada" en la Ficha Administrativa del Asociado`,
			},
		});
	};

	return (
		<>
			<div className='accordion w-full mt-4' id='accordionStripe'>
				<div className='accordion-item bg-white border border-coral-500'>
					<h2 className='accordion-header mb-0' id='transfer'>
						<button
							className='accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-coral-500 text-left bg-white border-0 rounded-none transition focus:outline-none'
							type='button'
							data-bs-toggle='collapse'
							data-bs-target='#collapseTransfer'
							aria-expanded='false'
							aria-controls='collapseTransfer'
						>
							<LayoutHeader>Transferencia</LayoutHeader>
						</button>
					</h2>
					<div
						id='collapseTransfer'
						className='accordion-collapse collapse'
						aria-labelledby='transfer'
						data-bs-parent='#accordionStripe'
					>
						<div className='accordion-body py-4 px-5'>
							<span className='text-start ml-10 mr-10 mt-4'>
								<p className='mb-4'>{`Puedes realizar una transferencia con los siguientes datos y enviar comprobante al administrador <${config.contact_email}>:`}</p>
								<div className='p-2 border rounded-lg bg-gray-200 font-medium'>
									<p>IBAN: {config.fiscal_data?.iban}</p>
									<p>SWIFT: {config.fiscal_data?.swift}</p>
									<p>{`Concepto: Cuota adhesion de ${administrativeBusinessName}`}</p>
									<p>
										Importe: <Currency>{paymentCreateResponse.amount / 100}</Currency>
									</p>
								</div>
								<Button onClick={handleSendEmail} className='mt-4 w-full'>
									{notifyButtonLabel}
								</Button>
								<p className='mt-4'>O si prefieres, puedes pagar con tarjeta</p>
							</span>
						</div>
					</div>
				</div>
				<div className='accordion-item bg-white border border-coral-500'>
					<h2 className='accordion-header mb-0' id='cardPayment'>
						<button
							className='accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-coral-500 text-left bg-white border-0 rounded-none transition focus:outline-none'
							type='button'
							data-bs-toggle='collapse'
							data-bs-target='#collapseCard'
							aria-expanded='false'
							aria-controls='collapseCard'
						>
							<LayoutHeader>Tarjeta</LayoutHeader>
						</button>
					</h2>
					<div
						id='collapseCard'
						className='accordion-collapse collapse'
						aria-labelledby='cardPayment'
						data-bs-parent='#accordionStripe'
					>
						<div className='accordion-body py-4 px-5'>
							<form onSubmit={handleSubmit}>
								{config.stripe_publishable_key.includes('pk_test') && (
									<p className='my-8 bg shadow-md p-4 text-white text-center font-bold'>
										Esto es una web de demostración. No se cargará nada en tu cuenta
									</p>
								)}
								<div className='mt-4 mb-4 shadow-md overflow-hidden p-8 border border-black rounded-tl-xl rounded-br-xl '>
									<LayoutHeader className='uppercase'>Datos de la tarjeta</LayoutHeader>
									<div className='w-full mt-4'>
										<Input
											required
											width='w-full'
											placeholder='Titular de la tarjeta'
											className='w-full bg-white p-2'
											placeholderstyle='placeholder-gray-700'
											onChange={handleCardholderName}
											value={cardholderName}
										/>
										<div className='w-full border rounded-md border-coral-300 h-10 bg-white p-2'>
											<CardElement options={CARD_OPTIONS} />
										</div>
										{paymentCreateResponse?.metadata && (
											<>
												<div className='mt-4 border-t border-dashed border-coral-500 px-4 flex justify-between py-2 items-center'>
													<p className='text-xl'>Base</p>
													<Currency className='text-xl'>
														{paymentCreateResponse.metadata.base / 100}
													</Currency>
												</div>
												<div className='border-t border-dashed border-coral-500 px-4 flex justify-between py-2 items-center'>
													<p className='text-xl'>IVA</p>
													<Currency className='text-xl'>
														{paymentCreateResponse.metadata.tax / 100}
													</Currency>
												</div>
											</>
										)}
										{paymentCreateResponse?.amount && (
											<div className='border-b border-t border-dashed border-coral-500 px-4 flex justify-between py-2 items-center'>
												<p className='text-xl'>Total a pagar</p>
												<Currency className='text-xl font-bold'>
													{paymentCreateResponse.amount / 100}
												</Currency>
											</div>
										)}

										<Button
											disabled={!stripe || !elements}
											type='submit'
											className='mt-4 w-full'
											id='pay'
										>
											{paymentButtonLabel}
										</Button>
										<Message error>{showPaymentStatus(paymentStatus, errorMessage)}</Message>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<p className='mt-2'>Una vez verificado el pago se enviará al correo de tu perfil la factura de mismo</p>
			</div>
		</>
	);
};

const showPaymentStatus = (paymentStatus, otherError) => {
	if (otherError) {
		return otherError;
	}

	if (paymentStatus?.type === 'validation_error') {
		switch (paymentStatus?.code) {
			case 'incomplete_number':
				return 'Número de tarjeta está incompleto';

			case 'invalid_expiry_year_past':
				return 'El año de vencimiento de su tarjeta está en el pasado';

			case 'incomplete_expiry':
				return 'La fecha de vencimiento de su tarjeta está incompleta';

			case 'invalid_number':
				return 'Número de tarjeta no es válido';

			case 'incomplete_cvc':
				return 'El código de seguridad de su tarjeta está incompleto';

			case 'incomplete_zip':
				return 'Código postal está incompleto';
		}
	}

	if (
		paymentStatus?.type === 'invalid_request_error' &&
		paymentStatus?.code === 'payment_intent_authentication_failure'
	) {
		return 'No podemos autenticar su método de pago. Elija un método de pago diferente y vuelva a intentarlo';
	}

	return null;
};

export default StripeCheckout;
