import LoginForm from 'components/LoginForm';
import RestorePasswordForm from 'components/RestorePasswordForm';
import useAuth from 'hooks/use-auth';
import BaseLayout from 'layouts/BaseLayout';
import MyAccountForm from 'pages/Account/MyAccountForm';
import MyAdministrativeForm from 'pages/Account/MyAdministrativeForm';
import MyCommercialForm from 'pages/Account/MyCommercialForm';
import Categories from 'pages/Categories';
import CategoryEdit from 'pages/Categories/CategoryEdit';
import CategoryNew from 'pages/Categories/CategoryNew';
import Customization from 'pages/Customization';
import CRM from 'pages/CRM';
import Customers from 'pages/Customers';
import Translators from 'pages/Translators';
import CustomerDetails from 'pages/Customers/CustomerDetails';
import Dashboard from 'pages/Dashboard';
import FAQCreate from 'pages/FAQ/FAQCreate';
import FAQEdit from 'pages/FAQ/FAQEdit';
import FAQ from 'pages/FAQ/FAQ';
import Help from 'pages/Help/Help';
import HelpDetail from 'pages/Help/HelpDetail';
import Merchants from 'pages/Merchants';
import MerchantEdit from 'pages/Merchants/MerchantEdit';
import MerchantNew from 'pages/Merchants/MerchantNew';
import Orders from 'pages/Orders';
import Refunds from 'pages/Refunds';
import OrderDetail from 'pages/Orders/OrderDetail';
import RefundDetail from 'pages/Refunds/RefundDetail';
import Products from 'pages/Products';
import ProductEdit from 'pages/Products/ProductEdit';
import ProductNew from 'pages/Products/ProductNew';
import RevisionEvents from 'pages/Revision/RevisionEvents';
import RevisionMerchants from 'pages/Revision/RevisionMerchants';
import RevisionProducts from 'pages/Revision/RevisionProducts';
import Statistics from 'pages/Statistics';
import MerchantPayments from 'pages/MerchantPayments';
import MerchantPaymentDetail from 'pages/MerchantPayments/MerchantPaymentDetail';
import Versions from 'pages/Versions/Versions';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import SpecialFeatures from 'pages/Products/SpecialFeatures';
import LoginLayout from 'layouts/Loginlayout';
import useConf from 'hooks/use-conf';
import SortProductsInHome from 'pages/Products/SortProductsInHome';
import Maintenance from 'components/Maintenance';

const App = () => {
	const { user } = useAuth();
	const { config, maintenance } = useConf();

	return maintenance ? (
		<Maintenance />
	) : (
		<BrowserRouter>
			<Routes>
				<Route
					path='/login'
					element={
						<LoginLayout>
							<div className='flex flex-col items-center justify-center absolute h-screen w-full'>
								<img
									src={`${config.statics}/home/artesania-hecha-a-mano-producto-de-aqui.jpg`}
									alt=''
									className='flex flex-col h-screen w-full object-cover'
								/>
								<div className='bg-gray-225 flex flex-col items-center justify-center rounded-bl-10px absolute rounded-br-10px rounded-tl-10px rounded-tr-10px  mx-1/50 sm:w-618px'>
									<LoginForm className='text-lg w-full sm:w-128 overflow-hidden mx-auto p-8 text-center' />
								</div>
							</div>
						</LoginLayout>
					}
				/>
				<Route
					path='/restore-password'
					element={
						<LoginLayout>
							<div className='flex flex-col items-center justify-center absolute h-screen w-full'>
								<img
									src={`${config.statics}/home/artesania-hecha-a-mano-producto-de-aqui.jpg`}
									alt=''
									className='flex flex-col h-screen w-full object-cover'
								/>
								<div className='bg-gray-225 flex flex-col items-center justify-center rounded-bl-10px absolute rounded-br-10px rounded-tl-10px rounded-tr-10px  mx-1/50 sm:w-618px'>
									<RestorePasswordForm className='text-lg w-full sm:w-128 overflow-hidden mx-auto p-8 text-center' />
								</div>
							</div>
						</LoginLayout>
					}
				/>
				<Route
					path='/dashboard'
					element={
						<ProtectedRoute userType={user?.type} allowedUserTypeList={['admin', 'merchant', 'translator']}>
							<BaseLayout>
								<Outlet />
							</BaseLayout>
						</ProtectedRoute>
					}
				>
					{/* All users allowed: 'admin', 'merchant', 'translator' */}
					<Route path='asociados/edit/:merchantId' element={<MerchantEdit />} />
					<Route path='account' element={<MyAccountForm />} />
					<Route path='help' element={<Help />} />
					<Route path='help/:helpId' element={<HelpDetail />} />
					<Route path='versions' element={<Versions />} />

					{/* Allowed: 'admin', 'translator'. 'Merchant' only if active' */}
					<Route
						element={
							<ProtectedRoute
								userType={user?.type}
								userStatus={user?.status}
								allowedUserTypeList={['admin', 'merchant', 'translator']}
							/>
						}
					>
						<Route path='catalogo/productos/edit/:productId' element={<ProductEdit />} />
						<Route path='catalogo/productos' element={<Products />} />
						<Route path='edit/commercial' element={<MyCommercialForm />} />
					</Route>

					{/* Allowed: 'admin' */}
					<Route element={<ProtectedRoute userType={user?.type} allowedUserTypeList={['admin']} />}>
						<Route path='catalogo/productos/special-features' element={<SpecialFeatures />} />
						<Route path='catalogo/productos/orden-destacados' element={<SortProductsInHome />} />
						<Route path='catalogo/categorias' element={<Categories />} />
						<Route path='catalogo/categorias/new' element={<CategoryNew />} />
						<Route path='catalogo/categorias/edit/:categoryId' element={<CategoryEdit />} />
						<Route path='asociados/new' element={<MerchantNew />} />
						<Route path='revision/eventos' element={<RevisionEvents />} />
						<Route path='personalizar-tienda' element={<Customization />} />
						<Route path='clientes' element={<Customers />} />
						<Route path='clientes/:customerId' element={<CustomerDetails />} />
						<Route path='crm' element={<CRM />} />
						<Route path='traductores' element={<Translators />} />
					</Route>

					{/* Allowed: 'admin'. 'Merchant' only if active */}
					<Route
						element={
							<ProtectedRoute
								userType={user?.type}
								userStatus={user?.status}
								allowedUserTypeList={['admin', 'merchant']}
							/>
						}
					>
						<Route path='catalogo/productos/new' element={<ProductNew />} />
						<Route path='pedido/:orderId' element={<OrderDetail />} />
						<Route path='pedidos' element={<Orders />} />
						<Route path='devolucion/:refundId' element={<RefundDetail />} />
						<Route path='devoluciones' element={<Refunds />} />
						<Route path='estadisticas' element={<Statistics />} />
						<Route path='facturacion' element={<MerchantPayments />} />
						<Route path='facturacion/:merchantpaymentId' element={<MerchantPaymentDetail />} />
						<Route path='edit/administrative' element={<MyAdministrativeForm />} />
					</Route>

					{/* Allowed: 'admin' and 'translator' */}
					<Route
						element={<ProtectedRoute userType={user?.type} allowedUserTypeList={['admin', 'translator']} />}
					>
						<Route path='faq/new' element={<FAQCreate />} />
						<Route path='faq/edit/:faqId' element={<FAQEdit />} />
						<Route path='faq' element={<FAQ />} />
						<Route path='revision/productos' element={<RevisionProducts />} />
						<Route path='revision/asociados' element={<RevisionMerchants />} />
						<Route path='asociados/list/:status' element={<Merchants />} />
					</Route>

					<Route index element={<Dashboard />} />
					{/* <Route path='clientes/new'  element={
                        <CustomerNew />
                        }/> */}
				</Route>
				<Route
					path='/'
					element={
						<Navigate
							to={
								user
									? user.type === 'translator'
										? '/dashboard/asociados/list/all'
										: '/dashboard'
									: '/login'
							}
							replace
						/>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
};

// https://www.robinwieruch.de/react-router-private-routes/
const ProtectedRoute = ({ userType, userStatus, allowedUserTypeList, redirectPath = '/dashboard', children }) => {
	if (!userType) {
		const location = useLocation();
		return <Navigate to={'/login'} state={{ redirectFrom: location.pathname }} replace />;
	}

	if (!allowedUserTypeList.includes(userType) || userStatus == 'pending' || userStatus == 'revision') {
		return <Navigate to={redirectPath} replace />;
	}

	return children ? children : <Outlet />;
};

export default App;
