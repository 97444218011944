import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

const PRODUCTINFO_FRAGMENT = gql`
	fragment ProductInfo on Product {
		id
		inHome
		merchantHighlight
		isDistilled
		locale
		promote
		status
		substatus
		title
		createdAt
		updatedAt
		Categories {
			id
			value: id
			locale
			name
		}
		DefaultImage {
			id
			locale
			src
			title
			alt
		}
		DataSheet {
			id
			src
		}
		Merchant {
			id
			locale
			commercialName
		}
		SpecialFeatures {
			id
			name
		}
		Variants {
			price
		}
	}
`;

const SORT_PRODUCT_INFO_FRAGMENT = gql`
	fragment SortProductInfo on Product {
		id
		inHome
		locale
		title
		Categories {
			id
			value: id
			locale
			name
		}
		DefaultImage {
			id
			locale
			src
			title
			alt
		}
	}
`;

const FULLPRODUCTINFO_FRAGMENT = gql`
	fragment FullProductInfo on Product {
		id
		locale
		brand
		contactInstructions
		deliveryObservations
		deliveryType
		description
		handle
		inHome
		instructions
		isDistilled
		nutritionalInformation
		otherFeatures
		preparationTime
		promote
		seoDescription
		seoTitle
		status
		substatus
		subtitle
		title
		type
		unit
		vat
		video
		localeHandles {
			locale
			text
		}
		CategoryId
		Category {
			id
			locale
			localeSlugs {
				locale
				text
			}
			Parent {
				id
				locale
				localeSlugs {
					locale
					text
				}
				Parent {
					id
					locale
					localeSlugs {
						locale
						text
					}
					Parent {
						id
						locale
						localeSlugs {
							locale
							text
						}
						Parent {
							id
							locale
							localeSlugs {
								locale
								text
							}
						}
					}
				}
			}
		}
		Variants {
			id
			barcode
			discountedPrice
			format
			isAvailable
			outOfSeasson
			limitedProduction
			price
			title
			sku
			position
		}
		DefaultImage {
			id
			locale
			filename
			src
			title
			alt
		}
		DataSheet {
			id
			filename
			src
		}
		Images {
			id
			locale
			filename
			src
			title
			alt
		}
		Merchant {
			id
			locale
			administrativeEmail
			commercialName
			slug
		}
		SpecialFeatures {
			id
			name
		}
		createdAt
		updatedAt
		Events {
			id
			action
			category
			details
			message
			success
			createdAt
		}
	}
`;

const EXPORT_FULL_PRODUCT_INFO_FRAGMENT = gql`
	fragment ExportFullProductInfo on Product {
		id
		title
		promote
		inHome
		isDistilled
		locale
		status
		substatus
		handle
		Categories {
			id
			locale
			name
		}
		Variants {
			barcode
			discountedPrice
			isAvailable
			price
			title
			sku
		}
		unit
		brand
		type
		vat
		createdAt
		updatedAt
	}
`;

const PRODUCTS_QUERY = gql`
	query Products(
		$search: String
		$scopes: [String]
		$substatus: String
		$order_scope: String
		$isInHome: Boolean
		$promote: Boolean
		$CategoryId: ID
		$MerchantId: ID
		$limit: Int
		$offset: Int
		$locale: String
	) {
		Products(
			search: $search
			scopes: $scopes
			substatus: $substatus
			order_scope: $order_scope
			isInHome: $isInHome
			promote: $promote
			CategoryId: $CategoryId
			MerchantId: $MerchantId
			limit: $limit
			offset: $offset
			locale: $locale
		) {
			... on ProductList {
				count
				hasMore
				List {
					...ProductInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${PRODUCTINFO_FRAGMENT}
`;

const SORT_PRODUCTS_QUERY = gql`
	query Products($isInHome: Boolean, $order_scope: String, $limit: Int, $offset: Int, $locale: String) {
		Products(isInHome: $isInHome, order_scope: $order_scope, limit: $limit, offset: $offset, locale: $locale) {
			... on ProductList {
				count
				hasMore
				List {
					...SortProductInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${SORT_PRODUCT_INFO_FRAGMENT}
`;

const EXPORT_PRODUCTS_QUERY = gql`
	query Products(
		$search: String
		$scopes: [String]
		$order_scope: String
		$promote: Boolean
		$inHome: Boolean
		$CategoryId: ID
		$MerchantId: ID
		$limit: Int = 0
		$offset: Int = 0
		$locale: String
	) {
		Products(
			search: $search
			scopes: $scopes
			order_scope: $order_scope
			promote: $promote
			inHome: $inHome
			CategoryId: $CategoryId
			MerchantId: $MerchantId
			limit: $limit
			offset: $offset
			locale: $locale
		) {
			... on ProductList {
				List {
					...ExportFullProductInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${EXPORT_FULL_PRODUCT_INFO_FRAGMENT}
`;

const FULL_PRODUCTS_QUERY = gql`
	query Products(
		$search: String
		$scopes: [String]
		$order_scope: String
		$CategoryId: ID
		$MerchantId: ID
		$locale: String
	) {
		Products(
			search: $search
			scopes: $scopes
			order_scope: $order_scope
			CategoryId: $CategoryId
			MerchantId: $MerchantId
			locale: $locale
		) {
			... on ProductList {
				List {
					...FullProductInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${FULLPRODUCTINFO_FRAGMENT}
`;

const PRODUCTCREATE_MUTATION = gql`
	mutation ProductCreateMutation($input: ProductInput!, $locale: String) {
		ProductCreate(input: $input, locale: $locale) {
			... on Product {
				...ProductInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${PRODUCTINFO_FRAGMENT}
`;

const PRODUCT_DUPLICATE_MUTATION = gql`
	mutation ProductDuplicateMutation($productId: ID!) {
		ProductDuplicate(productId: $productId) {
			... on Product {
				...ProductInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${PRODUCTINFO_FRAGMENT}
`;

const PRODUCTUPDATE_MUTATION = gql`
	mutation ProductUpdateMutation($id: ID!, $input: ProductInput!, $locale: String) {
		ProductUpdate(id: $id, input: $input, locale: $locale) {
			... on Product {
				...FullProductInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${FULLPRODUCTINFO_FRAGMENT}
`;

const PRODUCT_DELETE_MUTATION = gql`
	mutation ProductDelete($id: ID!) {
		ProductDelete(id: $id) {
			... on Success {
				success
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
`;

const PRODUCTS_UPDATE_STATUS_MUTATION = gql`
	mutation ProductsUpdateStatus($productsId: [ID!], $input: ProductStatusInput!) {
		ProductsUpdateStatus(productsId: $productsId, input: $input) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
`;

const SINGLEPRODUCT_QUERY = gql`
	query Product($id: ID!, $forceLocale: Boolean, $locale: String!) {
		Product(id: $id, forceLocale: $forceLocale, locale: $locale) {
			...FullProductInfo
		}
	}
	${FULLPRODUCTINFO_FRAGMENT}
`;

const PRODUCT_INCIDENT_MUTATION = gql`
	mutation ProductIncidentMutation($input: ProductIncidentInput!) {
		ProductIncident(input: $input) {
			... on Success {
				success
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
`;

const PRODUCT_INHOME_MUTATION = gql`
	mutation ProductInHome($id: ID!, $isInHome: Boolean!, $locale: String) {
		ProductInHome(id: $id, isInHome: $isInHome, locale: $locale) {
			... on Product {
				...FullProductInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${FULLPRODUCTINFO_FRAGMENT}
`;

const SORT_PRODUCT_INHOME_MUTATION = gql`
	mutation SortProductInHome($id: ID!, $position: Int!, $locale: String) {
		SortProductInHome(id: $id, position: $position, locale: $locale) {
			... on Success {
				success
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
`;

const SORT_MERCHANT_PRODUCTS_HIGHLIGHT_MUTATION = gql`
	mutation SortMerchantProductsHighlight($products: [MerchantProductHighlightInput]!, $merchantId: ID!) {
		SortMerchantProductsHighlight(products: $products, merchantId: $merchantId) {
			... on MerchantHighlightResponse {
				slug
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
`;

export const useProduct = variables => useQuery(SINGLEPRODUCT_QUERY, { variables, fetchPolicy: 'cache-and-network' });
export const useLazyProduct = variables =>
	useLazyQuery(SINGLEPRODUCT_QUERY, { variables, fetchPolicy: 'cache-and-network' });
export const useLazyProducts = variables =>
	useLazyQuery(PRODUCTS_QUERY, {
		variables,
		fetchPolicy: 'cache-and-network',
	});
export const useLazySortProducts = variables =>
	useLazyQuery(SORT_PRODUCTS_QUERY, {
		variables,
		fetchPolicy: 'cache-and-network',
	});
export const useLazyExportProducts = variables =>
	useLazyQuery(EXPORT_PRODUCTS_QUERY, {
		variables,
		fetchPolicy: 'no-cache',
	});
export const useLazyFullProducts = variables =>
	useLazyQuery(FULL_PRODUCTS_QUERY, { variables, fetchPolicy: 'cache-and-network' });
export const useCreateProduct = props => useMutation(PRODUCTCREATE_MUTATION, props);
export const useDuplicateProduct = props => useMutation(PRODUCT_DUPLICATE_MUTATION, props);
export const useUpdateProduct = props => useMutation(PRODUCTUPDATE_MUTATION, props);
export const useDeleteProduct = props => useMutation(PRODUCT_DELETE_MUTATION, props);
export const useProductsUpdateStatus = props => useMutation(PRODUCTS_UPDATE_STATUS_MUTATION, props);
export const useProductIncident = props => useMutation(PRODUCT_INCIDENT_MUTATION, props);
export const useProductInHome = props => useMutation(PRODUCT_INHOME_MUTATION, props);
export const useSortMerchantProductsHighlight = props => useMutation(SORT_MERCHANT_PRODUCTS_HIGHLIGHT_MUTATION, props);
export const useSortProductInHome = props => useMutation(SORT_PRODUCT_INHOME_MUTATION, props);
