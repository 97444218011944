import Button from 'components/Button';
import CheckForm from 'components/CheckForm';
import ContentTable from 'components/ContentTable';
import Currency from 'components/Currency';
import InputForm from 'components/InputForm';
import Modal from 'components/Modal';
import TextareaForm from 'components/TextareaForm';
import { useToast } from 'components/Toast';
import {
	useConfirmMerchantShipping,
	usePrepareMerchantShipping,
	useMergeDeliveries,
	useCompleteMerchantShipping,
} from 'hooks/use-orders';
import useToggle from 'hooks/use-toggle';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import translasteStatus from 'lib/translasteStatus';
import { PDFDownloadLink } from '@react-pdf/renderer';
import OrderPdf from 'components/OrderPdf';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';
import { deliveryTypes } from 'lib/constants/productConstants';

const MerchantShippingDetails = ({
	orderId,
	orderData,
	delivery,
	merchantShipping,
	setTotalAmount,
	merchantShippingIndex,
	getLazyOrder,
	shippingNotes,
}) => {
	const [openModal, setOpenModal] = useToggle();
	const [subtotalAmount, setSubtotalAmount] = useState(0);
	const [isFormFilled, setIsFormFilled] = useState(false);
	const [orderItemFields] = useState([
		{
			id: 0,
			grossDiscountedPrice: '',
			grossPrice: '',
			productTitle: '',
			boughtQuantity: '',
			sent: false,
			substituted: false,
			sentQuantity: null,
			Variant: {
				title: '',
				price: '',
				discountedPrice: '',
			},
		},
	]);
	const [disabled, setDisabled] = useState(
		!(merchantShipping?.status === 'new' || merchantShipping?.status === 'processing')
	);
	const [confirmMerchantShipping] = useConfirmMerchantShipping();
	const [prepareMerchantShipping] = usePrepareMerchantShipping();
	const [mergeDeliveries] = useMergeDeliveries();
	const [completeMerchantShipping] = useCompleteMerchantShipping();

	const {
		register,
		trigger,
		handleSubmit,
		control,
		watch,
		setValue,
		getValues,
		formState: { isValid, errors },
		resetField,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			merchantShippingForm: {
				orderItems: orderItemFields,
				note: '',
				packages: '',
				weight: '',
				noteMerged: '',
				packagesMerged: '',
				weightMerged: '',
				status: '',
			},
		},
	});

	useEffect(() => {
		if (merchantShipping) {
			setValue('merchantShippingForm', {
				orderItems: merchantShipping.OrderItems.map(orderItem => ({
					...orderItem,
					sentQuantity: orderItem.sentQuantity ?? orderItem.boughtQuantity,
					oldSentQuantity: orderItem.sentQuantity ?? orderItem.boughtQuantity,
					grossDiscountedPrice:
						typeof orderItem.grossDiscountedPrice === 'number' ? orderItem.grossDiscountedPrice : '',
				})),
				note: merchantShipping.note || merchantShipping.Merchant?.logisticsObservations || null,
				packages: merchantShipping.packages || null,
				weight: merchantShipping.weight || null,
				noteMerged: null,
				packagesMerged: null,
				weightMerged: null,
				status: merchantShipping.status,
			});

			setSubtotalAmount(
				merchantShipping.OrderItems.reduce(
					(sum, { grossDiscountedPrice, grossPrice, sentQuantity, boughtQuantity }) =>
						sum +
						(grossDiscountedPrice || grossPrice) * (sentQuantity === null ? boughtQuantity : sentQuantity),
					0
				)
			);
			setIsFormFilled(true);
		}
	}, [merchantShipping, setValue, setSubtotalAmount]);

	return (
		isFormFilled && (
			<div>
				<div className={`${disabled && 'bg-gray-200'} mb-5 border border-dashed border-coral-300`}>
					<h2 className='mt-2 text-center text-lg font-medium'>
						{merchantShipping?.Merchant.commercialName}
					</h2>
					<p className='mt-2 text-center text-lg'>
						<span className='border border-coral-500 py-1 px-2 text-center font-medium text-coral-500'>
							{translasteStatus(getValues('merchantShippingForm.status'))}
						</span>
					</p>
					<ContentTable
						columns={[
							'Artículo',
							'Coste',
							'Cantidad',
							'Cantidad enviada',
							'Estado envio',
							'Producto Sustituido',
							'Total Compra',
						]}
						content={merchantShipping.OrderItems}
						render={(orderItem, index) => (
							<tr key={index} className='border-t border-dashed border-coral-300 text-sm text-gray-700'>
								<td className='px-4 py-2'>
									{orderItem.Variant && (
										<Link to={`/dashboard/catalogo/productos/edit/${orderItem.Variant.Product.id}`}>
											{orderItem.Variant.Product.title}
										</Link>
									)}
									<div className='text-xs'>{orderItem.Variant?.title}</div>
									<div className='text-xs'>
										{orderItem.Variant?.barcode ? `EAN: ${orderItem.Variant?.barcode}` : ''}
										{orderItem.Variant?.barcode && orderItem.Variant?.sku && ' - '}
										{orderItem.Variant?.sku ? `SKU: ${orderItem.Variant?.sku}` : ''}
									</div>
								</td>
								<td className='px-4 py-2'>
									<Currency>{orderItem.grossDiscountedPrice || orderItem.grossPrice}</Currency>
								</td>
								<td className='px-4 py-2'>{orderItem.boughtQuantity}</td>
								<TdOrderItem
									setSubtotalAmount={setSubtotalAmount}
									setTotalAmount={setTotalAmount}
									orderItem={orderItem}
									orderItemIndex={index}
									control={control}
									register={register}
									setValue={setValue}
									getValues={getValues}
									watch={watch}
									authorizeProductSubstitution={orderData?.authorizeProductSubstitution}
									disabled={disabled}
								/>
							</tr>
						)}
						empty={null}
					/>
					<div className='ml-auto mr-12 w-48'>
						<p className='flex'>
							<span className='flex-1 text-right'>Subtotal:</span>
							<Currency className='flex-1 text-right'>{subtotalAmount}</Currency>
						</p>
					</div>
					<div className='mt-2 flex w-full flex-col border-t border-dashed border-coral-300 pt-2'>
						<MerchantShippingElement
							setDisabled={setDisabled}
							control={control}
							register={register}
							handleSubmit={handleSubmit}
							merchantShipping={merchantShipping}
							orderId={orderId}
							openModal={openModal}
							setOpenModal={setOpenModal}
							watch={watch}
							setValue={setValue}
							trigger={trigger}
							isValid={isValid}
							errors={errors}
							resetField={resetField}
							disabled={disabled}
							confirmMerchantShipping={confirmMerchantShipping}
							prepareMerchantShipping={prepareMerchantShipping}
							mergeDeliveries={mergeDeliveries}
							completeMerchantShipping={completeMerchantShipping}
							orderData={orderData}
							delivery={delivery}
							merchantShippingForm={getValues('merchantShippingForm')}
							merchantShippingIndex={merchantShippingIndex}
							getLazyOrder={getLazyOrder}
							shippingNotes={shippingNotes}
						/>
					</div>
				</div>
			</div>
		)
	);
};

const TdOrderItem = ({
	setTotalAmount,
	setSubtotalAmount,
	orderItem,
	orderItemIndex,
	control,
	register,
	setValue,
	getValues,
	watch,
	authorizeProductSubstitution,
	disabled,
}) => {
	const grossPrice = orderItem.grossDiscountedPrice || orderItem.grossPrice;
	const [orderItemAmount, setOrderItemAmount] = useState(
		grossPrice * (orderItem.sentQuantity ?? orderItem.boughtQuantity)
	);
	const [disableSubsituted, setDisableSubsituted] = useState(false);

	const returnQuantityStatus = _orderItem => {
		const _sentQuantity = getValues(`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`);
		// Changes the status
		if (Number.parseInt(_sentQuantity) === _orderItem.boughtQuantity) {
			return 'Enviado completo';
		} else if (
			Number.parseInt(_sentQuantity) === 0 ||
			_sentQuantity === '' ||
			_sentQuantity === undefined ||
			_sentQuantity === null
		) {
			return 'No disponible';
		} else if (_sentQuantity < _orderItem.boughtQuantity) {
			return 'Enviado parcial';
		}
	};

	useEffect(() => {
		// While sentQuantity changes
		const subscription = watch(({ merchantShippingForm }, { name }) => {
			if (name === `merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`) {
				const oldSentQuantity = Number.parseInt(
					merchantShippingForm.orderItems[orderItemIndex].oldSentQuantity
				);
				const newSentQuantity =
					Number.parseInt(merchantShippingForm.orderItems[orderItemIndex].sentQuantity) || 0;

				// Sets sentQuantity field to the requested quantity
				// whenever it is more than the requested quantity
				if (newSentQuantity > orderItem.boughtQuantity) {
					setValue(
						`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`,
						orderItem.boughtQuantity
					);
				}
				// Changes Subsituted value to false whenever `newSentQuantity` is zero
				if (newSentQuantity === 0) {
					setValue(`merchantShippingForm.orderItems.${orderItemIndex}.substituted`, false);
				}
				if (merchantShippingForm.orderItems[orderItemIndex].sentQuantity === '') {
					setValue(`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`, 0);
				}
				setDisableSubsituted(newSentQuantity === 0);

				// Changes the orderItemAmount
				setOrderItemAmount(grossPrice * newSentQuantity);

				// Changes the total and subTotal Amount
				setSubtotalAmount(prev => prev + (newSentQuantity - oldSentQuantity) * grossPrice);
				setTotalAmount(prev => prev + (newSentQuantity - oldSentQuantity) * grossPrice);

				setValue(`merchantShippingForm.orderItems.${orderItemIndex}.oldSentQuantity`, newSentQuantity);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, orderItem.boughtQuantity, orderItemIndex, grossPrice, setSubtotalAmount, setTotalAmount, setValue]);

	return (
		<>
			<td className='bg-coral-100 px-4 py-2'>
				<InputForm
					name={`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`}
					control={control}
					register={register}
					type='number'
					max={orderItem.boughtQuantity}
					min='0'
					width='1'
					disabled={
						getValues('merchantShippingForm.status') !== 'new' &&
						getValues('merchantShippingForm.status') !== 'processing'
					}
				/>
			</td>
			<td className='bg-coral-100 px-4 py-2'>{returnQuantityStatus(orderItem)}</td>
			<td className='bg-coral-100 px-4 py-2 text-center'>
				<CheckForm
					name={`merchantShippingForm.orderItems.${orderItemIndex}.substituted`}
					control={control}
					className='flex justify-center'
					disabled={disabled || disableSubsituted || !authorizeProductSubstitution}
				/>
			</td>
			<td className='px-4 py-2 text-center'>
				<Currency>{orderItemAmount}</Currency>
			</td>
		</>
	);
};

const situations = Object.freeze({
	// Standard Delivery, processing MerchantShipping
	STANDARD_PROCESSING: 'standardProcessing',
	// Deferred Delivery, processing MerchantShipping
	DEFERRED_PROCESSING: 'deferredProcessing',
	// Direct Delivery, processing MerchantShipping
	DIRECT_PROCESSING: 'directProcessing',
	// Deferred Delivery, accepted MerchantShipping. Only one product to send, therefore only one deferred Delivery
	DEFERRED_SINGLE: 'deferredSingle',
	// Deferred Delivery, accepted MerchantShipping. Multiple products to send, therefore multiple deferred Deliveries, must decide to join them
	DEFERRED_MULTIPLE: 'deferredMultiple',
	// Deferred Delivery, accepted MerchantShipping. Multiple deferred Deliveries, sent one separately
	DEFERRED_MULTIPLE_SEPARATE: 'deferredMultipleSeparate',
	// Deferred Delivery, accepted MerchantShipping. Multiple deferred Deliveries, sent all together, enter resulting MerchantShipping data
	DEFERRED_MULTIPLE_JOINT_INPUT: 'deferredMultipleJointInput',
	// Deferred Delivery, accepted MerchantShipping. Multiple deferred Deliveries, sent all together
	DEFERRED_MULTIPLE_JOINT: 'deferredMultipleJoint',
	// Direct Delivery, accepted MerchantShipping. Mark as completed once finished
	DIRECT_ACCEPTED: 'directAccepted',
	// Any Delivery type, processing MerchantShipping. Shipment canceled as nothing was sent
	EMPTY_SHIPPING: 'emptyShipping',
	// Any Delivery type, canceled MerchantShipping
	CANCELED: 'canceled',
	// Any situation that does not require the action of the merchant
	NONE: 'none',
});

const MerchantShippingElement = ({
	control,
	register,
	merchantShipping,
	watch,
	orderId,
	openModal,
	setOpenModal,
	setValue,
	trigger,
	isValid,
	errors,
	resetField,
	confirmMerchantShipping,
	prepareMerchantShipping,
	mergeDeliveries,
	completeMerchantShipping,
	orderData,
	delivery,
	merchantShippingForm,
	merchantShippingIndex,
	getLazyOrder,
	shippingNotes,
}) => {
	const { logOut } = useAuth();
	const [currentSituation, setCurrentSituation] = useState(situations.NONE);
	const [updating, setUpdating] = useState(false);
	const { addErrorMessage, addSuccessMessage } = useToast();

	useEffect(() => {
		const subscription = watch(({ merchantShippingForm }, { name }) => {
			if (name.includes('orderItems')) {
				if (
					merchantShippingForm.orderItems?.every(
						item => Number.parseInt(item.sentQuantity) === 0 || item.sentQuantity === ''
					)
				) {
					setCurrentSituation(situations.EMPTY_SHIPPING);
				} else {
					refreshCurrentSituation();
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	useEffect(() => {
		refreshCurrentSituation();
	}, []);

	useEffect(() => {
		if (currentSituation === situations.EMPTY_SHIPPING) {
			setValue('merchantShippingForm.packages', 0);
			setValue('merchantShippingForm.weight', 0);
		}
	}, [currentSituation]);

	const refreshCurrentSituation = () => {
		if (merchantShipping.status === 'canceled') {
			setCurrentSituation(situations.CANCELED);
		} else if (
			merchantShippingForm.orderItems?.every(
				item => Number.parseInt(item.sentQuantity) === 0 || item.sentQuantity === ''
			) &&
			merchantShipping.status === 'processing'
		) {
			setCurrentSituation(situations.EMPTY_SHIPPING);
		} else {
			[('new', 'processing', 'accepted')].includes(merchantShipping.status)
				? setCurrentSituation(situationsTable[delivery.deliveryType][merchantShipping.status])
				: setCurrentSituation(situations.NONE);
		}
	};

	const situationsTable = {
		standard: {
			new: situations.STANDARD_PROCESSING,
			processing: situations.STANDARD_PROCESSING,
			accepted: situations.NONE,
		},
		deferred: {
			new: situations.DEFERRED_PROCESSING,
			processing: situations.DEFERRED_PROCESSING,
			accepted:
				orderData?.Deliveries?.filter(
					delivery => delivery.deliveryType === deliveryTypes.DEFERRED && delivery.status === 'new'
				).length === 1
					? situations.DEFERRED_SINGLE
					: situations.DEFERRED_MULTIPLE,
		},
		merchant: {
			new: situations.DIRECT_PROCESSING,
			processing: situations.DIRECT_PROCESSING,
			accepted: situations.DIRECT_ACCEPTED,
		},
	};

	const getSituationData = () => {
		switch (currentSituation) {
			case situations.STANDARD_PROCESSING:
				return {
					btnText: 'Envío listo para recoger',
					modalTitle: 'Confirmación',
					modalDescription:
						'Al "confirmar" se notificará a Logística que recoja este envío lo antes posible, y no se podrá anular.',
					modalShowShippingNotes: true,
					modalShowShippingInput: false,
					modalAcceptButtonText: 'Confirmar',
					modalCancelButtonText: 'Cancelar',
					onAcceptModal: () => handleConfirmMerchantShipping(),
					onCancelModal: () => setOpenModal(false),
					requiredFields: true,
				};
			case situations.DEFERRED_PROCESSING:
				return {
					btnText: 'Aceptar envío',
					modalTitle: 'Confirmación',
					modalDescription:
						'Al confirmar se aceptará la orden y no se podrá anular. Cuando los artículos estén listos deberás marcar el envío como preparado para ser recogido por Logística.',
					modalShowShippingNotes: false,
					modalShowShippingInput: false,
					modalAcceptButtonText: 'Confirmar',
					modalCancelButtonText: 'Cancelar',
					onAcceptModal: () => handleConfirmMerchantShipping(),
					onCancelModal: () => setOpenModal(false),
					requiredFields: false,
				};
			case situations.DIRECT_PROCESSING:
				return {
					btnText: 'Aceptar envío',
					modalTitle: 'Confirmación',
					modalDescription:
						'Al confirmar se aceptará la orden y no se podrá anular. Cuando hayas entregado los artículos deberás marcar el envío como completado.',
					modalShowShippingNotes: true,
					modalShowShippingInput: false,
					modalAcceptButtonText: 'Confirmar',
					modalCancelButtonText: 'Cancelar',
					onAcceptModal: () => handleConfirmMerchantShipping(),
					onCancelModal: () => setOpenModal(false),
					requiredFields: false,
				};
			case situations.DEFERRED_SINGLE:
				return {
					btnText: 'Envío listo para recoger',
					modalTitle: 'Confirmación',
					modalDescription:
						'Al "confirmar" se notificará a Logística que recoja este envío lo antes posible, y no se podrá anular.',
					modalShowShippingNotes: true,
					modalShowShippingInput: false,
					modalAcceptButtonText: 'Confirmar',
					modalCancelButtonText: 'Cancelar',
					onAcceptModal: () => handlePrepareMerchantShipping(),
					onCancelModal: () => setOpenModal(false),
					requiredFields: true,
				};
			case situations.DEFERRED_MULTIPLE:
				return {
					btnText: 'Envío listo para recoger',
					modalTitle: 'Confirmación',
					modalDescription:
						'Recuerda que el costo logistico de cada envio diferido es soportado por ti. Este pedido consta de varios envios. ¿Quieres juntarlos todos en una única expedicion?',
					modalShowShippingNotes: false,
					modalShowShippingInput: false,
					modalAcceptButtonText: 'Sí',
					modalCancelButtonText: 'No',
					onAcceptModal: () => setCurrentSituation(situations.DEFERRED_MULTIPLE_JOINT_INPUT),
					onCancelModal: () => setCurrentSituation(situations.DEFERRED_MULTIPLE_SEPARATE),
					requiredFields: true,
				};
			case situations.DEFERRED_MULTIPLE_SEPARATE:
				return {
					btnText: 'Envío listo para recoger',
					modalTitle: 'Confirmación',
					modalDescription:
						'Al "confirmar" se notificará a Logística que recoja tan solo este envío lo antes posible, y no se podrá anular.',
					modalShowShippingNotes: true,
					modalShowShippingInput: false,
					modalAcceptButtonText: 'Confirmar',
					modalCancelButtonText: 'Cancelar',
					onAcceptModal: () => handlePrepareMerchantShipping(),
					onCancelModal: () => {
						setOpenModal(false);
						setCurrentSituation(situations.DEFERRED_MULTIPLE);
					},
					requiredFields: true,
				};
			case situations.DEFERRED_MULTIPLE_JOINT_INPUT:
				return {
					btnText: 'Envío listo para recoger',
					modalTitle: 'Envío conjunto',
					modalDescription: '',
					modalShowShippingNotes: false,
					modalShowShippingInput: true,
					modalAcceptButtonText: 'Confirmar',
					modalCancelButtonText: 'Cancelar',
					onAcceptModal: () => checkValidFrom(() => setCurrentSituation(situations.DEFERRED_MULTIPLE_JOINT)),
					onCancelModal: () => {
						resetField('merchantShippingForm.packages');
						resetField('merchantShippingForm.weight');
						resetField('merchantShippingForm.packagesMerged');
						resetField('merchantShippingForm.weightMerged');
						setOpenModal(false);
						setCurrentSituation(situations.DEFERRED_MULTIPLE);
					},
					requiredFields: true,
				};
			case situations.DEFERRED_MULTIPLE_JOINT:
				return {
					btnText: 'Envío listo para recoger',
					modalTitle: 'Confirmación',
					modalDescription:
						'Al "confirmar" se notificará a Logística que recoja este envío conjunto lo antes posible, y no se podrá anular.',
					modalShowShippingNotes: true,
					modalShowShippingInput: false,
					modalAcceptButtonText: 'Confirmar',
					modalCancelButtonText: 'Cancelar',
					onAcceptModal: () => handleMergeDeliveries(),
					onCancelModal: () => {
						setOpenModal(false);
						setCurrentSituation(situations.DEFERRED_MULTIPLE);
					},
					requiredFields: true,
				};
			case situations.DIRECT_ACCEPTED:
				return {
					btnText: 'Envío completado',
					modalTitle: 'Confirmación',
					modalDescription: 'Al "confirmar" se notificará que el pedido ya ha sido entregado.',
					modalShowShippingNotes: false,
					modalShowShippingInput: false,
					modalAcceptButtonText: 'Confirmar',
					modalCancelButtonText: 'Cancelar',
					onAcceptModal: () => handleCompleteMerchantShipping(),
					onCancelModal: () => setOpenModal(false),
					requiredFields: false,
				};
			case situations.EMPTY_SHIPPING:
				return {
					btnText: 'Confirma anulación pedido',
					modalTitle: 'Confirmación',
					modalDescription: 'Al confirmar el pedido quedará anulado y no se podrá revertir.',
					modalShowShippingNotes: false,
					modalShowShippingInput: false,
					modalAcceptButtonText: 'Confirmar',
					modalCancelButtonText: 'Cancelar',
					onAcceptModal: () => handleConfirmMerchantShipping(),
					onCancelModal: () => setOpenModal(false),
					requiredFields: false,
				};
			case situations.CANCELED:
				return {
					btnText: 'Pedido rechazado',
					modalTitle: '',
					modalDescription: '',
					modalShowShippingNotes: false,
					modalShowShippingInput: false,
					modalAcceptButtonText: '',
					modalCancelButtonText: '',
					onAcceptModal: () => {},
					onCancelModal: () => {},
					requiredFields: false,
				};
			case situations.NONE:
				return {
					btnText: '',
					modalTitle: '',
					modalDescription: '',
					modalShowShippingNotes: false,
					modalShowShippingInput: false,
					modalAcceptButtonText: '',
					modalCancelButtonText: '',
					onAcceptModal: () => {},
					onCancelModal: () => {},
					requiredFields: false,
				};
		}
	};

	const checkValidFrom = async (onSuccess = () => {}) => {
		if (!isValid && getSituationData().requiredFields) {
			// Trigger forces a validation and fills errors
			await trigger(undefined, { shouldFocus: true });
			// We display the required fields
			const requiredFields = errors?.merchantShippingForm
				? Object.keys(errors.merchantShippingForm).join(', ')
				: '';
			addErrorMessage('Pedido', `Por favor rellene los campos requeridos: ${requiredFields}`);
		} else {
			onSuccess();
		}
	};

	const handleConfirmMerchantShipping = async () => {
		const emptyShipping = currentSituation === situations.EMPTY_SHIPPING;
		merchantShippingForm.orderItems = merchantShippingForm?.orderItems.map(orderItem => ({
			id: orderItem.id,
			substituted: orderItem.substituted,
			sentQuantity: orderItem.sentQuantity,
		}));
		confirmMerchantShipping({
			variables: {
				OrderId: orderId,
				MerchantId: merchantShipping?.Merchant.id,
				DeliveryNumber: delivery.number,
				orderItems: merchantShippingForm.orderItems,
				emptyShipping,
			},
		}).then(async ({ data }) => {
			setOpenModal(false);
			setUpdating(false);
			if (
				isSuccessResponse(
					data.ConfirmMerchantShipping,
					['Success'],
					logOut,
					addErrorMessage,
					'Confirmar pedido'
				)
			) {
				if (delivery.deliveryType === deliveryTypes.STANDARD && !emptyShipping) {
					handlePrepareMerchantShipping();
				} else {
					await getLazyOrder();
					setValue('merchantShippingForm.status', 'accepted');
					addSuccessMessage('Pedido', emptyShipping ? 'Rechazado correctamente' : 'Aceptado correctamente');
					refreshCurrentSituation();
					setOpenModal(false);
				}
			}
		});
	};

	const handlePrepareMerchantShipping = async (
		MerchantShippingNumber = merchantShipping.number,
		note = merchantShippingForm.note,
		weight = merchantShippingForm.weight,
		packages = merchantShippingForm.packages
	) => {
		const merchantShippingInput = { note, weight, packages };
		prepareMerchantShipping({
			variables: {
				MerchantShippingNumber: MerchantShippingNumber,
				merchantShippingInput: merchantShippingInput,
			},
		}).then(async ({ data }) => {
			setOpenModal(false);
			setUpdating(false);
			if (
				isSuccessResponse(data.PrepareMerchantShipping, ['Success'], logOut, addErrorMessage, 'Preparar pedido')
			) {
				await getLazyOrder();
				setValue('merchantShippingForm.status', 'prepared');
				addSuccessMessage('Pedido', 'Preparado correctamente');
				setCurrentSituation(situations.NONE);
				setOpenModal(false);
			}
		});
	};

	const handleMergeDeliveries = async () => {
		const deliveries = orderData.Deliveries.filter(
			delivery => delivery.deliveryType === deliveryTypes.DEFERRED && delivery.status === 'new'
		);
		const merchantShippings = deliveries.flatMap(delivery => delivery.MerchantShippings);
		let orderItems = merchantShippings.flatMap(merchantShipping => merchantShipping.OrderItems);
		orderItems = orderItems.map(orderItem => ({
			id: orderItem.id,
			substituted: orderItem.substituted,
			sentQuantity: orderItem.sentQuantity,
		}));
		mergeDeliveries({
			variables: {
				orderItems: orderItems,
			},
		}).then(({ data }) => {
			if (isSuccessResponse(data.MergeDeliveries, ['Success'], logOut, addErrorMessage, 'Unir envíos')) {
				handlePrepareMerchantShipping(
					data.MergeDeliveries.merchantShippingNumber,
					merchantShippingForm.noteMerged,
					merchantShippingForm.weightMerged,
					merchantShippingForm.packagesMerged
				);
			}
		});
	};

	const handleCompleteMerchantShipping = () => {
		completeMerchantShipping({ variables: { MerchantShippingNumber: merchantShipping.number } }).then(
			async ({ data }) => {
				if (
					isSuccessResponse(
						data.CompleteMerchantShipping,
						['Success'],
						logOut,
						addErrorMessage,
						'Completar envío'
					)
				) {
					await getLazyOrder();
					setValue('merchantShippingForm.status', 'completed');
					addSuccessMessage('Pedido', 'Completado correctamente');
					setCurrentSituation(situations.NONE);
					setOpenModal(false);
				}
			}
		);
	};

	return (
		<div className='p-6'>
			<div className='flex w-full flex-col lg:flex-row'>
				<div className='w-full'>
					<p className='w-full italic text-gray-400'>*Observaciones para logística</p>
					<TextareaForm
						name='merchantShippingForm.note'
						control={control}
						cols='80'
						className='mt-1 rounded-md border border-coral-300 p-4 text-lg outline-none focus:outline-none'
						placeholder='Escribir aquí posibles incidencias'
						disabled={!getSituationData().requiredFields}
						simple
					/>
				</div>

				<div className='flex w-1/3 flex-col justify-center'>
					<p className='w-full text-left italic text-gray-400'>
						*Número total de bultos que componen la expedición. {getSituationData().btnText}
					</p>
					<InputForm
						name='merchantShippingForm.packages'
						control={control}
						register={register}
						type='number'
						label='Bultos'
						placeholder='Número de bultos'
						width='w-full'
						labelclass='w-1/4'
						min={currentSituation === situations.EMPTY_SHIPPING ? '0' : 1}
						required
						disabled={!getSituationData().requiredFields}
						forcePositiveInteger
					/>
					<p className='w-full text-left italic text-gray-400'>
						*Peso total de la expedición en Kilos sin decimales.
					</p>
					<InputForm
						name='merchantShippingForm.weight'
						control={control}
						register={register}
						type='number'
						label='Peso'
						width='w-full'
						labelclass='w-1/4'
						placeholder='Peso'
						min={currentSituation === situations.EMPTY_SHIPPING ? '0' : 1}
						required
						disabled={!getSituationData().requiredFields}
						forcePositiveInteger
					/>
				</div>
			</div>

			<div className='flex justify-end self-end'>
				{currentSituation !== situations.NONE ? (
					<Button
						className='w-40'
						disabled={updating || merchantShipping.status === 'canceled'}
						onClick={() => checkValidFrom(() => setOpenModal(value => !value))}
					>
						{getSituationData().btnText}
					</Button>
				) : (
					<PDFDownloadLink
						document={
							<OrderPdf
								merchantShippingIndex={merchantShippingIndex}
								orderData={orderData}
								delivery={delivery}
								merchantShippingData={merchantShippingForm}
							/>
						}
						fileName={`Pedido ${merchantShipping?.number}`}
					>
						{({ loading }) =>
							loading ? (
								'Cargando documento...'
							) : (
								<div className='mt-4 block w-40 rounded-md bg-coral-500 py-2 px-3 text-center text-sm font-bold text-white shadow-md transition-all duration-200 hover:bg-coral-700 focus:shadow focus:shadow-outline focus:outline-none'>
									Descargar PDF: {merchantShipping?.number}
								</div>
							)
						}
					</PDFDownloadLink>
				)}
			</div>
			<div className='flex justify-end self-end'></div>

			<Modal
				modalObject={{
					title: getSituationData().modalTitle,
					description: (
						<span className='text-lg leading-5 text-gray-500'>{getSituationData().modalDescription}</span>
					),
					btnText: getSituationData().modalAcceptButtonText,
					cancelBtnText: getSituationData().modalCancelButtonText,
				}}
				openModal={openModal}
				setOpenModal={setOpenModal}
				onCancel={getSituationData().onCancelModal}
				handleSubmit={getSituationData().onAcceptModal}
			>
				{getSituationData().modalShowShippingNotes && shippingNotes ? (
					<div className='flex flex-col w-full gap-2'>
						<h2 className='text-xl bold'>Atención, ten en cuenta la nota del cliente</h2>
						<p className='border mt-1 p-4 text-lg rounded-md border-coral-300 outline-none focus:outline-none h-32 w-full'>
							{shippingNotes}
						</p>
					</div>
				) : null}

				{/* Input to fill in the merged delivery data */}
				{getSituationData().modalShowShippingInput ? (
					<div className='flex w-full flex-col'>
						<div className='w-full'>
							<p className='w-full italic text-gray-400'>*Observaciones para logística</p>
							<TextareaForm
								name='merchantShippingForm.noteMerged'
								control={control}
								cols='80'
								className='mt-1 rounded-md border border-coral-300 p-4 text-lg outline-none focus:outline-none'
								placeholder='Escribir aquí posibles incidencias'
								simple
							/>
						</div>

						<div className='flex w-1/2 flex-col justify-center'>
							<p className='w-full text-left italic text-gray-400'>
								*Número total de bultos que componen la expedición.
							</p>
							<InputForm
								name='merchantShippingForm.packagesMerged'
								control={control}
								register={register}
								type='number'
								label='Bultos'
								placeholder='Número de bultos'
								width='w-full'
								labelclass='w-1/3'
								min={1}
								required
								forcePositiveInteger
							/>
							<p className='w-full text-left italic text-gray-400'>
								*Peso total de la expedición en Kilos sin decimales.
							</p>
							<InputForm
								name='merchantShippingForm.weightMerged'
								control={control}
								register={register}
								type='number'
								label='Peso'
								width='w-full'
								labelclass='w-1/3'
								placeholder='Peso'
								min={1}
								required
								forcePositiveInteger
							/>
						</div>
					</div>
				) : null}
			</Modal>
		</div>
	);
};

export default MerchantShippingDetails;
