import Button from 'components/Button';
import DropImageArea from 'components/DropImageArea';
import { useToast } from 'components/Toast';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateCustomization } from 'hooks/use-costumizations';
import isSuccessResponse from 'lib/isSuccessResponse';
import useAuth from 'hooks/use-auth';

const CustomizationImageInput = ({ setDirty = () => {}, imageData, locale }) => {
	const { logOut } = useAuth();
	const [updateCustomization, { loading }] = useUpdateCustomization();
	const [image, setImage] = useState();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [imagesUploading, setImagesUploading] = useState(false);

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { isDirty },
	} = useForm({
		defaultValues: {
			customizationForm: {
				description: '',
				key: '',
				valueType: '',
				ValueCustomizationImageId: '',
			},
		},
	});

	// Load Edit data
	useEffect(() => {
		if (imageData) {
			setValue('customizationForm', {
				description: imageData.description || '',
				key: imageData.key || '',
				valueType: imageData.valueType || '',
				ValueCustomizationImageId: imageData.ValueCustomizationImage?.id || null,
			});
			// Creates a copy of each image element so it can be modified inside the DropfileImage
			setImage({ ...imageData.ValueCustomizationImage });
			setDirty(false);
		}
	}, [imageData, setValue, setDirty]);

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (isDirty) {
			setDirty(isDirty);
		}
	}, [setDirty, isDirty]);

	const handleImage = useCallback(
		([newImage]) => {
			setImage(newImage);
			setValue('customizationForm.ValueCustomizationImageId', newImage.id);
		},
		[setImage, setValue]
	);

	return (
		<form
			onSubmit={handleSubmit(({ customizationForm }) => {
				updateCustomization({
					variables: {
						customizationId: imageData.id,
						input: customizationForm,
						locale,
					},
				}).then(({ data: { CustomizationUpdate } }) => {
					if (
						isSuccessResponse(
							CustomizationUpdate,
							['Customization'],
							logOut,
							addErrorMessage,
							'Configuración'
						)
					) {
						addSuccessMessage('Configuración', 'actualizado correctamente');
					}
				});
			})}
		>
			<DropImageArea
				path={getValues('customizationForm.valuePlainText')}
				label={getValues('customizationForm.description')}
				Images={[image]}
				onChange={handleImage}
				whileIdleText='Pincha o Suelta tu Imágen aquí'
				onDelete={() => {
					setValue('customizationForm.ValueCustomizationImageId', null);
					setImage(null);
				}}
				setUploading={setImagesUploading}
				specifications={
					<ul className='list-disc pl-4'>
						<li>Tamaño máximo 500kb</li>
					</ul>
				}
			/>
			<Button className='mt-5' type='submit' disabled={loading || imagesUploading}>
				Guardar imagen
			</Button>
		</form>
	);
};

export default CustomizationImageInput;
