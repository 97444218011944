import { gql, useLazyQuery, useMutation } from '@apollo/client';

const CUSTOMIZATION_INFO_FRAGMENT = gql`
	fragment CustomizationInfo on Customization {
		id
		description
		enumValues
		key
		valueType
		valuePlainText
		valueTranslatableText
		ValueCustomizationImage {
			id
			locale
			src
			title
			alt
		}
	}
`;

const CUSTOMIZATIONS_QUERY = gql`
	query Customizations($searchByDescription: String, $locale: String) {
		Customizations(searchByDescription: $searchByDescription, locale: $locale) {
			... on Customization {
				...CustomizationInfo
			}
		}
	}
	${CUSTOMIZATION_INFO_FRAGMENT}
`;

const UPDATE_CUSTOMIZATION_MUTATION = gql`
	mutation CustomizationUpdate($customizationId: ID!, $input: CustomizationUpdateInput!, $locale: String) {
		CustomizationUpdate(customizationId: $customizationId, input: $input, locale: $locale) {
			... on Customization {
				...CustomizationInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${CUSTOMIZATION_INFO_FRAGMENT}
`;

export const useLazyCustomizations = props =>
	useLazyQuery(CUSTOMIZATIONS_QUERY, {
		...props,
		fetchPolicy: 'cache-and-network',
	});

export const useUpdateCustomization = props => useMutation(UPDATE_CUSTOMIZATION_MUTATION, props);
