/**
 * Generate a random password
 * @param {Number} length Number of characters of the password
 * @returns {String} Random password
 */
const generateRandomPassword = (length = 10) => {
	const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	let password = '';
	for (let i = 0, n = charset.length; i < length; ++i) {
		password += charset.charAt(Math.floor(Math.random() * n));
	}
	return password;
};

export default generateRandomPassword;
