import React from 'react';

const CloseButton = ({ onClick, className }) => {
	return (
		<button onMouseDown={onClick} className={`absolute rounded-full right-0 top-0 m-1 ${className}`}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				fill='none'
				viewBox='0 0 24 24'
				stroke='white'
				width='15'
				height='15'
			>
				<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
			</svg>
		</button>
	);
};

export default CloseButton;
