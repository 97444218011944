import microsoftTranslator from './MicrosoftTranslator';
import transformEditorJsonToString from './transformEditorJsonToString';
import transformTranslatedEditorText from './transformTranslatedEditorText';

const translateProductForm = async (
	product,
	fromLocale,
	locale,
	config,
	setTranslatorModal,
	setValue,
	setRefreshCounterNumber,
	getLazyProduct,
	setDefaultImage,
	setImages,
	addSuccessMessage,
	addErrorMessage
) => {
	let isSuccess = false;
	const { data } = await getLazyProduct({ variables: { id: product.id, forceLocale: true, locale: fromLocale } });

	if (!data?.Product) return isSuccess;
	const productToTranslate = data?.Product;

	// Define custom set methods to update state with translated text
	const currentImages = product.Images.map(image => ({ ...image }));
	const setImageGalleryTitle = (position, text) => {
		currentImages[position].title = text;
	};
	const setImageGalleryAlt = (position, text) => {
		currentImages[position].alt = text;
	};

	const setDefaultImageAttribute = (name, text) =>
		setDefaultImage(prev => ({
			...prev,
			[name]: text,
		}));

	const setEditorValue = (name, text) => {
		const translationToSet = transformTranslatedEditorText(text);
		setValue(name, translationToSet);
	};

	// List of elements with text to translate and custom properties for each field
	// [{ text: text to translate,
	//  <name>: name of the field in the form | image position,
	//	set: custom method to update state
	// }, ...]
	const listOfTextToTranslate = [];

	// Nombre del Producto
	if (!product.title && productToTranslate.title) {
		listOfTextToTranslate.push({ text: productToTranslate.title, name: 'productForm.title', set: setValue });
	}
	// Subtítulo
	if (!product.subtitle && productToTranslate.subtitle) {
		listOfTextToTranslate.push({ text: productToTranslate.subtitle, name: 'productForm.subtitle', set: setValue });
	}
	// Información del producto
	// Transform "description" formated estructure to a string and validate that the field contains text to translate
	if (productToTranslate.description) {
		try {
			// Validate current description is empty to translate
			const productDescription = transformEditorJsonToString(product.description);
			if (!productDescription || productDescription.length === 0) {
				const descriptionContent = transformEditorJsonToString(
					JSON.parse(productToTranslate.description),
					'\n'
				);
				if (descriptionContent) {
					listOfTextToTranslate.push({
						text: descriptionContent,
						name: 'productForm.description',
						set: setEditorValue,
					});
				}
			}
		} catch {
			addErrorMessage('Error al transformar "Información del producto"');
		}
	}
	// Otras características
	if (productToTranslate.otherFeatures) {
		try {
			const rawOtherFeatures = JSON.parse(productToTranslate.otherFeatures);
			for (let i = 0; i <= rawOtherFeatures.length - 1; i++) {
				if (rawOtherFeatures[i].title) {
					listOfTextToTranslate.push({
						text: rawOtherFeatures[i].title,
						name: `productForm.otherFeatures.${i}.title`,
						set: setValue,
					});
				}
				if (rawOtherFeatures[i].value) {
					listOfTextToTranslate.push({
						text: rawOtherFeatures[i].value,
						name: `productForm.otherFeatures.${i}.value`,
						set: setValue,
					});
				}
			}
		} catch {
			addErrorMessage('Error al transformar "Otras características"');
		}
	}
	// Información útil
	// Transform "instructions" formated estructure to a string and validate that the field contains text to translate
	if (productToTranslate.instructions) {
		try {
			// Validate current instructions is empty to translate
			const productInstructions = transformEditorJsonToString(product.instructions);
			if (!productInstructions || productInstructions.length === 0) {
				const instructionsContent = transformEditorJsonToString(
					JSON.parse(productToTranslate.instructions),
					'\n'
				);
				if (instructionsContent) {
					listOfTextToTranslate.push({
						text: instructionsContent,
						name: 'productForm.instructions',
						set: setEditorValue,
					});
				}
			}
		} catch {
			addErrorMessage('Error al transformar "Información útil"');
		}
	}
	// Nombre de las varianciones
	if (productToTranslate.Variants.length > 0) {
		for (const [index, variant] of productToTranslate.Variants.entries()) {
			if (variant.title) {
				listOfTextToTranslate.push({
					text: variant.title,
					name: `productForm.Variants.${index}.title`,
					set: setValue,
				});
			}
		}
	}
	// Título SEO
	if (!product.seoTitle && productToTranslate.seoTitle) {
		listOfTextToTranslate.push({ text: productToTranslate.seoTitle, name: 'productForm.seoTitle', set: setValue });
	}
	// Descripción SEO
	if (!product.seoDescription && productToTranslate.seoDescription) {
		listOfTextToTranslate.push({
			text: productToTranslate.seoDescription,
			name: 'productForm.seoDescription',
			set: setValue,
		});
	}

	// Imagen destacada
	if (productToTranslate.DefaultImage?.title && !product.DefaultImage?.title) {
		listOfTextToTranslate.push({
			text: productToTranslate.DefaultImage?.title,
			set: setDefaultImageAttribute,
			name: 'title',
		});
	}
	if (productToTranslate.DefaultImage?.alt && !product.DefaultImage?.alt) {
		listOfTextToTranslate.push({
			text: productToTranslate.DefaultImage?.alt,
			set: setDefaultImageAttribute,
			name: 'alt',
		});
	}
	// Galería de la empresa
	if (productToTranslate.Images?.length > 0) {
		const imagesToTraslate = [...productToTranslate.Images];
		const currentImages = [...product.Images];
		for (const [index, imageToTranslate] of imagesToTraslate.entries()) {
			const currentImage = currentImages[index];
			if (imageToTranslate.title && !currentImage.title) {
				listOfTextToTranslate.push({
					text: imageToTranslate.title,
					name: index,
					set: setImageGalleryTitle,
				});
			}
			if (imageToTranslate.alt && !currentImage.alt) {
				listOfTextToTranslate.push({
					text: imageToTranslate.alt,
					name: index,
					set: setImageGalleryAlt,
				});
			}
		}
	}

	if (listOfTextToTranslate.length > 0) {
		try {
			const translations = await microsoftTranslator(
				fromLocale,
				locale,
				listOfTextToTranslate.map(ele => ({ text: ele.text })),
				config,
				setTranslatorModal
			);
			if (translations) {
				for (const { name, set } of listOfTextToTranslate) {
					set(name, translations.shift().translations[0].text);
				}

				setImages(currentImages);
				setRefreshCounterNumber(v => v + 1); // Just increase the refreshCounterNumber
				addSuccessMessage('Traducción', 'campos traducidos correctamente');
				isSuccess = true;
			} else {
				addErrorMessage('Traducción', translations.message);
			}
		} catch (error) {
			setTranslatorModal(false);
			addErrorMessage('Traducción', error.message);
		}
	} else {
		setTranslatorModal(false);
		addErrorMessage('Traducción', 'no hay nada para traducir');
	}
	return isSuccess;
};

export default translateProductForm;
