import { gql, useMutation } from '@apollo/client';

const PAYMENT_FEE_CREATE_MUTATION = gql`
	mutation PaymentFeeCreate {
		PaymentFeeCreate {
			... on PaymentIntent {
				id
				status
				client_secret
				amount
				metadata {
					base
					tax
				}
			}
			... on DatabaseFault {
				message
			}
			... on AuthorizationFault {
				user
				message
			}
			... on MailFault {
				message
				emailRecipient
			}
		}
	}
`;

const PAYMENT_FEE_DONE_MUTATION = gql`
	mutation PaymentFeeDone {
		PaymentFeeDone {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
			}
			... on AuthorizationFault {
				user
				message
			}
			... on MailFault {
				message
				emailRecipient
			}
		}
	}
`;

export const usePaymentFeeCreate = () => useMutation(PAYMENT_FEE_CREATE_MUTATION);
export const usePaymentFeeDone = () => useMutation(PAYMENT_FEE_DONE_MUTATION);
