/**
 * Update state after image is translated
 * @param {Function} set Method to update state
 * @param {Object} translatedImage values to update
 * @param {Number} index Position of the image when using multiple
 */
const handleTranslatedImage = (set, translatedImage, index) => {
	if (index !== undefined) {
		set(prev => {
			const newImages = [...prev];
			const newImage = { ...newImages[index], ...translatedImage };
			newImages[index] = newImage;
			return newImages;
		});
		return;
	}
	set(prev => ({ ...prev, ...translatedImage }));
};

export default handleTranslatedImage;
