import React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const client = new ApolloClient({
	link: createUploadLink({
		uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
		credentials: 'include',
	}),
	cache: new InMemoryCache({
		// Since Merchant, Product and ProductCategory are translated, we need to make sure the data is saved in cache for each locale
		// For example, the Product data in 'es' is different from 'en'
		typePolicies: {
			ProductCategory: {
				keyFields: ['id', 'locale'],
			},
			Product: {
				keyFields: ['id', 'locale'],
			},
			Merchant: {
				keyFields: ['id', 'locale'],
			},
			Media: {
				keyFields: ['id', 'locale'],
			},
		},
	}),
});

const Apollo = ({ children }) => <ApolloProvider client={client}>{children}</ApolloProvider>;

export const clearCache = () => client.clearStore();

export default Apollo;
