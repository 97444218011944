import { useToast } from 'components/Toast';
import webhook from 'lib/webhook.js';

// Get full slug, including parents (parent_slug/category_slug)
const getCategoryFullSlug = (c, index) =>
	c === null || typeof c === 'undefined'
		? ''
		: getCategoryFullSlug(c.Parent, index) + '/' + c.localeSlugs[index]?.text;

// Get category and its parents pages, all must be revalidated
const getAllSlugs = (c, slugs) => {
	if (c != null && typeof c != 'undefined') {
		slugs = [
			...slugs,
			...c.localeSlugs.map((localeSlug, index) => `/${localeSlug.locale}${getCategoryFullSlug(c, index)}`),
		];
		return getAllSlugs(c.Parent, slugs);
	} else {
		return slugs;
	}
};

export default function useRevalidateCategory() {
	const { addSuccessMessage, addErrorMessage } = useToast();
	const revalidateCategory = (config, category, showSuccessMessage) => {
		webhook(config, getAllSlugs(category, []))
			.then(() => {
				showSuccessMessage && addSuccessMessage('Categoría', 'Categoría actualizada correctamente');
			})
			.catch(error => {
				addErrorMessage(
					'Categoría',
					`Categoría actualizada correctamente, pero no se pudo revalidar en la tienda: "${error.message}"`
				);
			});
	};
	return revalidateCategory;
}
