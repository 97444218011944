import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/json';

import { v4 } from 'uuid';

/**
 * MicrosoftTranslator
 * @param {String} fromLocale Source locale
 * @param {String} toLocale Output locale
 * @param {Object} textToTranslate List of objects with text to translate eg: [{text: "text to translate"}, ...]
 * @param {Object} config The config object
 * @param {Function} setLoading Set of loader
 * @returns {Object} List of objects with translated text
 */
export default async (fromLocale, toLocale, textToTranslate, config, setLoading = () => {}) =>
	new Promise((resolve, reject) => {
		setLoading(true);
		axios({
			baseURL: config.translatorEndpoint,
			url: '/translate',
			method: 'post',
			headers: {
				'Ocp-Apim-Subscription-Key': config.translatorKey,
				'Ocp-Apim-Subscription-Region': config.translatorLocation,
				'X-ClientTraceId': v4().toString(),
			},
			params: {
				'api-version': '3.0',
				from: fromLocale,
				// The attribute "to" can be an array of locales (e.g: ['en', 'es']) or a string with a single locale
				// In our case we translate only 1 locale at a time
				to: toLocale,
			},
			data: textToTranslate,
			responseType: 'json',
		})
			.then(({ data }) => {
				setLoading(false);
				// When attribute "params.to" cointains more than 1 language, the array in "translations" may contain more than 1 index
				// The response.data from axios is structured as:
				// [
				//  {
				//   "translations":[
				//    {
				//     "text":"Halo, rafiki! Ulifanya nini leo?",
				//     "to":"sw"
				//    }
				//   ]
				// 	{
				// ]
				if (data?.length === textToTranslate.length) {
					resolve(data);
				}
				resolve({ message: `Error al traducir de "${fromLocale}" a "${toLocale}"` });
			})
			.catch(error => {
				setLoading(false);
				reject({
					message: `Error al traducir de "${fromLocale}" a "${toLocale}", detalle: ${error.response.data.error.message}`,
				});
			});
	});
