import React from 'react';

import Button from 'components/Button';
import Card from 'components/Card';
import LayoutHeader from 'components/LayoutHeader';
import DottedElement from 'components/DottedElement';
import useConf from 'hooks/use-conf';

const Help = () => {
	const { config } = useConf();

	return (
		<>
			<Card>
				<LayoutHeader>Ayuda</LayoutHeader>
				<div
					style={{ backgroundImage: 'url("/images/help/first-steps-background.svg")' }}
					className='mb-10 flex w-full justify-center border bg-2000 bg-center p-10 shadow'
				>
					<Button className='uppercase'>Primeros pasos gestión plataforma</Button>
				</div>
				<DottedElement
					title='Datos de perfil'
					description='Cómo añadir los datos personales de acceso a la cuenta de la empresa. 
					Estado de la cuenta. Nombre, email. teléfono, imagen de la cuenta. Cambio de contraseña.'
					link={`${config.statics}/help/datos-de-perfil.pdf`}
				/>
				<DottedElement
					title='Ficha Administrativa'
					description='Cómo añadir los datos administrativos y de facturación de la empresa. 
					Email, categoría principal, razón social, CIF, dirección administrativa de facturación, dirección logística, registro sanitario. Comisión, Cuota, Stripe.'
					link={`${config.statics}/help/ficha-administrativa.pdf`}
				/>
				<DottedElement
					title='Ficha Comercial'
					description='Cómo añadir los datos comerciales de la empresa que serán visibles en Google. Nombre, logo, descripción breve, historia de la empresa, elaboración de los productos, características de los productos. 
					Contacto, Google Maps, imagen destacada, galería de imágenes, vídeo de YouTube.'
					className='hidden'
				/>
			</Card>
		</>
	);
};

export default Help;
