import React, { useState } from 'react';
import Select from 'components/Select';

const ComboBox = ({ options, width, ...props }) => {
	const [filter, setFilter] = useState('');

	const filteredOptions = options.filter(
		option => option.name && option.name.toLowerCase().includes(filter.toLowerCase())
	);
	return (
		<div>
			<input
				type='text'
				value={filter}
				onChange={e => setFilter(e.target.value)}
				placeholder='Filtrar productos'
				className={`border box-border ${width} border-coral-300 rounded-md mt-1 p-2 outline-none bg-white`}
			/>
			<Select options={filteredOptions} width={width} {...props} />
		</div>
	);
};

export default ComboBox;
