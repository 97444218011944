import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Card from 'components/Card';
import ContentTable from './ContentTable';
import Currency from './Currency';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import Pagination from 'components/Pagination';
import { useToast } from 'components/Toast';

import printUserName from 'lib/printUserName';
import columnTitleElement from 'lib/columnTitleElement';
import formatDate from 'lib/formatDate';

import useAuth from 'hooks/use-auth';
import { useLazyRefunds } from 'hooks/use-orders';
import isSuccessResponse from 'lib/isSuccessResponse';

const getStatus = items => {
	let isRequested = false;
	let isPicked = false;
	let isCompleted = false;

	items.map(item => {
		if (item.refundStatus === 'requested' && isRequested == false) {
			isRequested = true;
		}
		if (item.refundStatus === 'picked' && isPicked == false) {
			isPicked = true;
		}
		if (item.refundStatus === 'completed' && isCompleted == false) {
			isCompleted = true;
		}
	});

	return `${isRequested ? 'Solicitado' : ''} ${isPicked ? 'Recogido' : ''} ${isCompleted ? 'Completado' : ''}`;
};

const getPrice = items => {
	let total = 0;
	items.map(item => {
		total += item.refundRequestedQuantity * (item.grossDiscountedPrice || item.grossPrice);
	});
	return total;
};

const RefundList = ({ title, scopeList, paginationLimit = 50 }) => {
	const { addErrorMessage } = useToast();
	const { user, logOut } = useAuth();
	const [scope, setScope] = useState('refundPending');
	const [orderScope, setOrderScope] = useState('created_asc');
	const [offset, setOffset] = useState(0);
	const [refunds, setRefunds] = useState(null);

	const [getLazyRefunds, { data, loading }] = useLazyRefunds({
		scope: scope,
		order_scope: orderScope,
		limit: paginationLimit,
		offset,
	});

	useEffect(() => {
		getLazyRefunds();
	}, [getLazyRefunds]);

	useEffect(() => {
		if (data && isSuccessResponse(data.MerchantOrders, ['OrdersList'], logOut, addErrorMessage, 'Devoluciones')) {
			setRefunds(data.MerchantOrders);
		}
	}, [data, addErrorMessage]);

	const filterStatusList = () => {
		return scopeList?.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setScope(scope);
				setOffset(0);
			},
		}));
	};

	const orderByDate = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'created_asc' },
			{ label: 'Descendente', scope: 'created_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
			},
		}));
	};

	const orderByCustomer = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'email_asc' },
			{ label: 'Descendente', scope: 'email_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
			},
		}));
	};

	return (
		<Card className='mb-20'>
			<LayoutHeader>{title}</LayoutHeader>
			<Pagination
				offset={offset}
				setOffset={setOffset}
				count={refunds?.count}
				hasMore={refunds?.hasMore}
				limit={paginationLimit}
			/>
			<LoadingView loading={loading} whileLoading='Buscando devoluciones...'>
				<ContentTable
					columns={
						user.type === 'admin'
							? [
									columnTitleElement('Nº Pedido'),
									columnTitleElement('Cliente', orderByCustomer),
									columnTitleElement('Proveedor'),
									columnTitleElement('Fecha', orderByDate),
									columnTitleElement('Estado', filterStatusList),
									columnTitleElement('Total'),
									'',
							  ]
							: [
									columnTitleElement('Nº Pedido'),
									columnTitleElement('Cliente', orderByCustomer),
									columnTitleElement('Fecha', orderByDate),
									columnTitleElement('Estado', filterStatusList),
									columnTitleElement('Total'),
									'',
							  ]
					}
					content={refunds?.List}
					render={(refund, index) =>
						refund && (
							<tr key={index} className='text-gray-700 border-t border-dashed border-coral-300'>
								<td className='px-4 py-2'>
									<Link
										className='border-b border-dashed border-coral-300 hover:text-coral-500 tranisiton-color duration-100'
										to={`/dashboard/devolucion/${refund.id}`}
										data-testid='refund'
									>
										{refund.id}
									</Link>
								</td>
								<td className='px-4 py-2'>
									<Link
										className='border-b border-dashed border-coral-300 hover:text-coral-500 tranisiton-color duration-100'
										to={`/dashboard/clientes/${refund.User?.id}`}
									>
										{refund.email || printUserName(refund.User)}
									</Link>
								</td>
								{user.type === 'admin' && (
									<td className='px-4 py-2'>
										<span className='flex flex-col'>
											{refund.Merchants.map((merchant, index) => (
												<p key={index}>{merchant.commercialName}</p>
											))}
										</span>
									</td>
								)}
								<td className='px-4 py-2'>{formatDate(refund.orderDate)}</td>
								<td className='px-4 py-2'>{getStatus(refund.RefundItems)}</td>
								<td className='px-4 py-2'>
									<Currency>{getPrice(refund.RefundItems)}</Currency>
								</td>
								<td className='px-4 py-2'></td>
							</tr>
						)
					}
					empty={<p>No hay devoluciones pendientes</p>}
				/>
			</LoadingView>
		</Card>
	);
};

export default RefundList;
