import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import InputForm from 'components/InputForm';
import { useToast } from 'components/Toast';
import { useForm } from 'react-hook-form';
import { useUpdateCustomization } from 'hooks/use-costumizations';
import useConf from 'hooks/use-conf';
import Editor from 'components/Editor';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';

const CustomizationTextInput = ({ setDirty = () => {}, textData, locale }) => {
	const { logOut } = useAuth();
	const { config } = useConf();

	const [updateCustomization, { loading }] = useUpdateCustomization();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [isFormFilled, setIsFormFilled] = useState(false);

	const {
		handleSubmit,
		control,
		register,
		setValue,
		formState: { isDirty },
	} = useForm({
		defaultValues: {
			customizationForm: {
				description: '',
				key: '',
				valueType: '',
				valuePlainText: null,
				valueTranslatableText: null,
			},
		},
	});

	// Load Edit data
	useEffect(() => {
		if (textData) {
			switch (textData.valueType) {
				case 'plainText':
					setValue('customizationForm', {
						description: textData.description || '',
						key: textData.key || '',
						valueType: textData.valueType || '',
						valuePlainText: textData.valuePlainText || null,
					});
					break;
				case 'translatableText':
				case 'translatableTextEditor':
					setValue('customizationForm', {
						description: textData.description || '',
						key: textData.key || '',
						valueType: textData.valueType || '',
						valueTranslatableText:
							textData.valueType === 'translatableText'
								? textData.valueTranslatableText || null
								: textData.valueTranslatableText || '[{"type":"paragraph","children":[{"text":""}]}]',
					});
					break;

				default:
					break;
			}
			setDirty(false);
			setIsFormFilled(true);
		}
	}, [textData, setValue, setDirty]);

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (isDirty) {
			setDirty(isDirty);
		}
	}, [setDirty, isDirty]);

	return (
		isFormFilled && (
			<form
				onSubmit={handleSubmit(({ customizationForm }) => {
					updateCustomization({
						variables: {
							customizationId: textData.id,
							input: customizationForm,
							locale,
						},
					}).then(({ data: { CustomizationUpdate } }) => {
						if (
							isSuccessResponse(
								CustomizationUpdate,
								['Customization'],
								logOut,
								addErrorMessage,
								'Configuración'
							)
						) {
							addSuccessMessage('Configuración', 'actualizado correctamente');
						}
					});
				})}
			>
				{textData.valueType === 'translatableText' && (
					<InputForm
						name='customizationForm.valueTranslatableText'
						control={control}
						register={register}
						label={textData.description}
						placeholder={textData.description}
					/>
				)}
				{textData.valueType === 'translatableTextEditor' && (
					<Editor
						name='customizationForm.valueTranslatableText'
						control={control}
						label={textData.description}
					/>
				)}
				{textData.valueType === 'plainText' && (
					<InputForm
						name='customizationForm.valuePlainText'
						control={control}
						register={register}
						disabled={locale !== config.default_locale}
						label={textData.description}
						placeholder={textData.description}
					/>
				)}
				<Button className='mt-5' type='submit' disabled={loading}>
					{`Guardar texto${textData.valueType === 'translatableText' ? ' traducible' : ''}`}
				</Button>
			</form>
		)
	);
};

export default CustomizationTextInput;
