const Maintenance = () => {
	return (
		<div className='flex flex-col gap-6 items-center mt-20 px-4'>
			<p className='text-5xl font-medium'>Página en mantenimiento</p>
			<div className='text-xl'>
				<p>Esta web se encuentra actualmente bajo labores de mantenimiento.</p>
				<p>Volverá a estar disponible pronto.</p>
			</div>
		</div>
	);
};

export default Maintenance;
