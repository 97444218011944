import ConditionalWrapper from 'components/ConditionalWrapper';
import Field from 'components/Field';
import { useToast } from 'components/Toast';
import transformEditorJsonToString from 'lib/transformEditorJsonToString';
import React from 'react';
import { useController } from 'react-hook-form';

import SlateEditor from './Editor';

const Editor = ({
	name,
	control,
	minLength,
	maxLength,
	refreshContentNumber,
	editorHeigth,
	showRequiredIcon = false,
	children,
	missingTranslations = false,
	...props
}) => {
	const { addErrorMessage } = useToast();
	const {
		field: { ref, value, onChange },
	} = useController({
		name,
		control,
		// This somehow limits the Length but gives no error, just blocks the form submit.
		// TODO: Investigate how to focus and show an error (tried playing with ref, but nothing worked)
		// update: maxLength cannot be used since the editorValue is an object, it adds the structure of the object to the counter.
		// For example, an empty field is 47 lenght because the empty value is not "" but "[{"type":"paragraph","children":[{"text":""}]}]"
		// rules: { maxLength: { value: maxLength, message: `Máximo ${maxLength} carácteres` } },
		rules: {
			validate: value => {
				if (value && transformEditorJsonToString(JSON.parse(value)).length < minLength) {
					addErrorMessage(props.label, `mínimo ${minLength} caracteres`);
					return false;
				}
				if (value && transformEditorJsonToString(JSON.parse(value)).length > maxLength) {
					addErrorMessage(props.label, `máximo ${maxLength} caracteres`);
					return false;
				}
				return true;
			},
		},
	});
	/* The received <Editor> value is a "stringified" JSON. Here we must do all the
	 * JSON.stringify & JSON.parse work, as SlateEditor need the object structure.
	 */
	let editorValue =
		typeof value === 'string'
			? value !== ''
				? JSON.parse(value)
				: [{ type: 'paragraph', children: [{ text: '' }] }]
			: value;

	const stringEditorValue = transformEditorJsonToString(editorValue);

	return (
		<>
			<Field mb={maxLength ? '' : 'mb-4'} showRequiredIcon={showRequiredIcon} {...props}>
				<ConditionalWrapper
					condition={children}
					wrapper={children => <div className='flex w-2/3'>{children}</div>}
				>
					<SlateEditor
						width={children ? 'w-full' : 'w-2/3'}
						value={editorValue}
						refreshContentNumber={refreshContentNumber}
						onChange={e => onChange(JSON.stringify(e))}
						inputRef={ref}
						editorHeigth={editorHeigth}
						missingTranslations={missingTranslations}
					/>
					{children}
				</ConditionalWrapper>
			</Field>
			{maxLength && (
				<div className='flex w-full max-w-screen-md '>
					<div className='w-1/3'></div>
					<p className='w-2/3 text-right text-gray-400 font-semibold italic'>
						<span
							className={
								stringEditorValue.length > maxLength || stringEditorValue.length < minLength
									? 'text-red-600'
									: ''
							}
						>
							{stringEditorValue.length}
						</span>
						{` / ${maxLength}`}
					</p>
				</div>
			)}
		</>
	);
};

export default Editor;
