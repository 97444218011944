import microsoftTranslator from './MicrosoftTranslator';
import transformEditorJsonToString from './transformEditorJsonToString';
import transformTranslatedEditorText from './transformTranslatedEditorText';

const translateCommercialForm = async (
	merchant,
	fromLocale,
	locale,
	config,
	setTranslatorModal,
	setValue,
	setRefreshCounterNumber,
	getLazyMerchant,
	setCommercialLogo,
	setFeaturedImage,
	setImages,
	addSuccessMessage,
	addErrorMessage
) => {
	let isSuccess = false;
	const { data } = await getLazyMerchant({
		variables: { id: merchant.id, forceLocale: true, locale: fromLocale },
	});

	if (!data?.Merchant) return isSuccess;
	const merchantToTranslate = data?.Merchant;

	// Define custom set methods to update state with translated text
	const currentImages = merchant.Images.map(image => ({ ...image }));
	const setImageGalleryTitle = (position, text) => {
		currentImages[position].title = text;
	};
	const setImageGalleryAlt = (position, text) => {
		currentImages[position].alt = text;
	};

	const setCommercialLogoAttribute = (name, text) =>
		setCommercialLogo(prev => ({
			...prev,
			[name]: text,
		}));

	const setFeaturedImageAttribute = (name, text) =>
		setFeaturedImage(prev => ({
			...prev,
			[name]: text,
		}));

	const setEditorValue = (name, text) => {
		const translationToSet = transformTranslatedEditorText(text);
		setValue(name, translationToSet);
	};

	// List of elements with text to translate and custom properties for each field
	// [{ text: text to translate,
	//  <name>: name of the field in the form | image position,
	//	set: custom method to update state
	// }, ...]
	const listOfTextToTranslate = [];

	// Nombre comercial
	if (!merchant.commercialName && merchantToTranslate.commercialName) {
		listOfTextToTranslate.push({
			text: merchantToTranslate.commercialName,
			name: 'commercialForm.commercialName',
			set: setValue,
		});
	}
	// Descripción
	// Transform "commercialRequiredDescription" formated estructure to a string and validate that the field contains text to translate
	if (merchantToTranslate.commercialRequiredDescription) {
		try {
			// Validate current description is empty to translate
			const currentDescription = transformEditorJsonToString(JSON.parse(merchant.commercialRequiredDescription));
			if (!currentDescription || currentDescription.length === 0) {
				const descriptionToTranslate = transformEditorJsonToString(
					JSON.parse(merchantToTranslate.commercialRequiredDescription),
					'\n'
				);
				if (descriptionToTranslate) {
					listOfTextToTranslate.push({
						text: descriptionToTranslate,
						name: 'commercialForm.commercialRequiredDescription',
						set: setEditorValue,
					});
				}
			}
		} catch {
			addErrorMessage('Error al transformar "Descripción"');
		}
	}
	// Información comercial (Descripción extendida, Título 1, Contenido 1, Título 2, Contenido 2, Título 3, Contenido 3)
	// Transform "instructions" formated estructure to a string and validate that the field contains text to translate
	if (merchantToTranslate.commercialContent) {
		try {
			const contentToTranslate = JSON.parse(merchantToTranslate.commercialContent);
			const currentContent = merchant.commercialContent;

			for (const [key] of Object.entries(contentToTranslate)) {
				if (key.includes('description') || key.includes('content')) {
					const descriptionToTranslate = transformEditorJsonToString(contentToTranslate[key], '\n');
					if (descriptionToTranslate) {
						const currentDescription = transformEditorJsonToString(JSON.parse(currentContent[key]));
						if (!currentDescription || currentDescription.length === 0) {
							listOfTextToTranslate.push({
								text: descriptionToTranslate,
								name: `commercialForm.commercialContent.${key}`,
								set: setEditorValue,
							});
						}
					}
				} else {
					const subtitleToTranslate = contentToTranslate[key];
					if (subtitleToTranslate && !currentContent[key]) {
						listOfTextToTranslate.push({
							text: subtitleToTranslate,
							name: `commercialForm.commercialContent.${key}`,
							set: setValue,
						});
					}
				}
			}
		} catch {
			addErrorMessage('Error al transformar "Información comercial"');
		}
	}
	// Imagen para Logo
	if (merchantToTranslate.CommercialLogo?.title && !merchant.CommercialLogo?.title) {
		listOfTextToTranslate.push({
			text: merchantToTranslate.CommercialLogo.title,
			set: setCommercialLogoAttribute,
			name: 'title',
		});
	}
	if (merchantToTranslate.CommercialLogo?.alt && !merchant.CommercialLogo?.alt) {
		listOfTextToTranslate.push({
			text: merchantToTranslate.CommercialLogo.alt,
			set: setCommercialLogoAttribute,
			name: 'alt',
		});
	}
	// Imagen para Banner
	if (merchantToTranslate.FeaturedImage?.title && !merchant.FeaturedImage?.title) {
		listOfTextToTranslate.push({
			text: merchantToTranslate.FeaturedImage.title,
			set: setFeaturedImageAttribute,
			name: 'title',
		});
	}
	if (merchantToTranslate.FeaturedImage?.alt && !merchant.FeaturedImage?.alt) {
		listOfTextToTranslate.push({
			text: merchantToTranslate.FeaturedImage.alt,
			set: setFeaturedImageAttribute,
			name: 'alt',
		});
	}
	// Galería de la empresa
	if (merchantToTranslate.Images?.length > 0) {
		const imagesToTraslate = [...merchantToTranslate.Images];
		const currentImages = [...merchant.Images];
		for (const [index, imageToTranslate] of imagesToTraslate.entries()) {
			const currentImage = currentImages[index];
			if (imageToTranslate.title && !currentImage.title) {
				listOfTextToTranslate.push({
					text: imageToTranslate.title,
					name: index,
					set: setImageGalleryTitle,
				});
			}
			if (imageToTranslate.alt && !currentImage.alt) {
				listOfTextToTranslate.push({
					text: imageToTranslate.alt,
					name: index,
					set: setImageGalleryAlt,
				});
			}
		}
	}
	if (listOfTextToTranslate.length > 0) {
		try {
			const translations = await microsoftTranslator(
				fromLocale,
				locale,
				listOfTextToTranslate.map(ele => ({ text: ele.text })),
				config,
				setTranslatorModal
			);
			if (translations) {
				for (const { name, set } of listOfTextToTranslate) {
					set(name, translations.shift().translations[0].text);
				}

				setImages(currentImages);
				setRefreshCounterNumber(v => v + 1); // Just increase the refreshCounterNumber
				addSuccessMessage('Traducción', 'campos traducidos correctamente');
				isSuccess = true;
			} else {
				addErrorMessage('Traducción', translations.message);
			}
		} catch (error) {
			setTranslatorModal(false);
			addErrorMessage('Traducción', error.message);
		}
	} else {
		setTranslatorModal(false);
		addErrorMessage('Traducción', 'no hay nada para traducir');
	}

	return isSuccess;
};

export default translateCommercialForm;
