import formatDate from 'lib/formatDate';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ContentTable from './ContentTable';
import LoadingView from 'components/LoadingView';
import printUserName from 'lib/printUserName';
import columnTitleElement from 'lib/columnTitleElement';
import TooltipMenu from 'components/TooltipMenu';
import Modal from 'components/Modal';
import { useCustomerDelete } from 'hooks/use-customers';
import { useToast } from 'components/Toast';
import translasteStatus from 'lib/translasteStatus';
import useToggle from 'hooks/use-toggle';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';

const TranslatorsList = ({
	loading,
	orderByDate,
	orderByFirstName,
	orderByEmail,
	statusList,
	translators,
	setTranslators,
}) => {
	const { setUserData, useImpersonateUser, logOut } = useAuth();
	const navigate = useNavigate();
	const { addErrorMessage, addSuccessMessage } = useToast();
	const [openModalHardDelete, setOpenModalHardDelete] = useToggle();
	const [customerDelete] = useCustomerDelete();
	const [impersonateUser] = useImpersonateUser({
		onCompleted: ({ ImpersonateUser }) => {
			if (isSuccessResponse(ImpersonateUser, ['User'], logOut, addErrorMessage, 'Impersonar traductor')) {
				setUserData(ImpersonateUser);
				addSuccessMessage('Traductor', `'${ImpersonateUser.firstName}' impersonado correctamente`);
				navigate('/dashboard');
			}
		},
	});

	const menuList = userId => {
		const options = [
			{
				title: 'Impersonar',
				onClick: () => {
					impersonateUser({ variables: { id: userId } });
				},
			},
			{
				title: 'Eliminar completamente de base de datos',
				onClick: () => {
					setOpenModalHardDelete(true);
				},
			},
		];

		return options;
	};

	return (
		<LoadingView loading={loading} whileLoading='Buscando traductores…'>
			<ContentTable
				columns={[
					columnTitleElement('Email', orderByEmail),
					columnTitleElement('Nombre', orderByFirstName),
					columnTitleElement('Fecha de alta', orderByDate),
					columnTitleElement('Estado', () => statusList),
				]}
				content={translators}
				render={user =>
					user && (
						<tr key={user.id} className='text-gray-700 border-t border-dashed border-coral-300'>
							<td className='px-4 py-2'>{user.email}</td>
							<td className='px-4 py-2'>{printUserName(user)}</td>
							<td className='px-4 py-2'>{formatDate(user.createdAt)}</td>
							<td id={`status_${user.email}`} className='px-4 py-2'>
								{translasteStatus(user.status)}
							</td>
							<td className='px-6'>
								<div className='h-16'>
									<TooltipMenu menuList={menuList(user.id)} menuClassName='w-40 -right-40' />
								</div>
								<Modal
									modalObject={{
										title: 'Confirmación',
										description: (
											<p className='text-lg leading-5 text-gray-500'>{`Al confirmar se eliminará por completo de la base de datos la cuenta asociada al email ${user?.email} y no se podrá anular.`}</p>
										),
										btnText: 'Confirmar',
										cancelBtnText: 'Cancelar',
									}}
									openModal={openModalHardDelete}
									setOpenModal={setOpenModalHardDelete}
									handleSubmit={() => {
										customerDelete({
											variables: {
												customerId: user.id,
												forceDelete: true,
											},
										}).then(res => {
											if (res.data.CustomerDelete.success) {
												addSuccessMessage(
													'Traductor',
													`${user.email} eliminado de base de datos correctamente`
												);
												const updatedTranslators = translators.filter(
													translator => translator.id !== user.id
												);
												setTranslators(prev => ({
													...prev,
													List: updatedTranslators,
												}));
											} else {
												addErrorMessage('Traductor', res.data.CustomerDelete.message);
											}
											setOpenModalHardDelete(false);
										});
									}}
								/>
							</td>
						</tr>
					)
				}
				empty={<p>No hay traductores</p>}
			/>
		</LoadingView>
	);
};

export default TranslatorsList;
