import Field from 'components/Field';
import { useUploadDocuments, useDeleteDocument } from 'hooks/use-documents';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useToast } from 'components/Toast';

import Button from './Button';
import isSuccessResponse from 'lib/isSuccessResponse';
import useAuth from 'hooks/use-auth';

export const DropDocumentArea = ({
	Documents = [],
	onChange,
	onDelete,
	multiple = false,
	children,
	disabled = false,
	whileIdleText = 'Pincha o Suelta tus documentos aquí',
	className,
	description,
	descriptionClassName = 'italic text-sm font-normal',
	setUploading = () => {},
	path,
	MerchantId,
	disabledMessage,
	specifications,
	showRequiredIcon = false,
	...props
}) => {
	const { addErrorMessage } = useToast();
	const { logOut } = useAuth();

	const [uploadDocuments, { loading: isUploading }] = useUploadDocuments({
		onCompleted: ({ UploadDocuments }) => {
			if (isSuccessResponse(UploadDocuments, ['Documents'], logOut, addErrorMessage, 'Documentos')) {
				onChange(UploadDocuments.Documents);
			}
		},
	});

	useEffect(() => {
		setUploading(isUploading);
	}, [isUploading]);

	const onDrop = useCallback(
		files => {
			if (files.length > 0) {
				uploadDocuments({ variables: { files, path, MerchantId } });
			}
		},
		[uploadDocuments, Documents.length]
	);

	return (
		<Field
			disabled={disabled}
			description={<p className={descriptionClassName}>{description}</p>}
			specifications={specifications}
			showRequiredIcon={showRequiredIcon}
			{...props}
		>
			<div className={`w-2/3 ${className || ''}`}>
				<div
					className={`border-4 border-dashed ${
						disabled ? 'border-gray-300' : 'border-coral-300'
					} dashed flex w-full flex-col items-center justify-center rounded-lg p-4 text-sm font-bold text-coral-300`}
				>
					{disabled ? (
						<span className='py-2 text-lg'>{disabledMessage}</span>
					) : (
						<DropFileArea
							disabled={disabled}
							onDrop={onDrop}
							multiple={multiple}
							isUploading={isUploading}
							noClick={Documents?.filter(i => !!i?.id)?.length > 0}
							whileIdleText={whileIdleText}
						>
							{Documents?.filter(i => !!i?.id)?.length > 0 && (
								<DocumentsGrid documents={Documents?.filter(i => !!i?.id)} onDelete={onDelete} />
							)}
						</DropFileArea>
					)}
				</div>
				{children}
			</div>
		</Field>
	);
};

export const DropFileArea = ({ disabled, children, onDrop, multiple, isUploading, noClick = false, whileIdleText }) => {
	const { addErrorMessage } = useToast();
	const { getRootProps, getInputProps, fileRejections, isDragActive, open } = useDropzone({
		onDrop,
		noClick,
		accept: 'application/pdf',
		multiple,
	});

	// Check for invalid images and display error
	useEffect(() => {
		for (const document of fileRejections) {
			switch (document.errors[0].code) {
				case 'too-many-files':
					addErrorMessage('Solo se puede subir un documento');
					return;
				case 'file-invalid-type':
					addErrorMessage('Formato de documento no soportado. Unicamente documento en .pdf');
					return;
				default:
					addErrorMessage(`${document.errors[0].message}`);
					return;
			}
		}
	}, [fileRejections, addErrorMessage]);

	const handleSelectDocumentButton = e => {
		e.preventDefault();
		open();
	};

	return (
		<>
			<div
				className=' focus:outline-none w-full text-center text-lg flex flex-col justify-center overflow-auto'
				{...getRootProps()}
			>
				<input disabled={disabled} {...getInputProps()} />
				{/* Always display documents even when dragging new one */}
				{children}
				{isUploading && <p className='py-2'>Subiendo archivos…</p>}
				{isDragActive && <p className='py-2'>Suelta tus documentos aquí</p>}
				{!isDragActive && !noClick && !isUploading && <p className='py-2'>{whileIdleText}</p>}
			</div>
			{/* Button for single document*/}
			{/* don't display when there's an document so user has to delete to upload new one*/}
			{!multiple && !noClick && (
				<Button
					disabled={disabled}
					onClick={handleSelectDocumentButton}
					className='mb-1 mt-4 w-full self-center'
				>
					Agregar documento
				</Button>
			)}
			{/* Button for documents*/}
			{multiple && (
				<Button
					disabled={disabled}
					onClick={handleSelectDocumentButton}
					className='mb-1 mt-4 w-full self-center'
				>
					{noClick ? 'Agregar documentos' : 'Seleccionar documentos'}
				</Button>
			)}
		</>
	);
};

export const DocumentsGrid = ({ documents, onDelete }) => (
	<div className={'flex flex-col gap-5 cursor'}>
		{documents.map((document, index) => (
			<DocumentElement document={document} key={`ie_${index}`} onDelete={onDelete} />
		))}
	</div>
);

export const DocumentElement = ({ document, onDelete }) => {
	const { addInfoMessage, addErrorMessage } = useToast();
	const [deleteDocument] = useDeleteDocument({
		onCompleted: ({ DeleteDocument }) => {
			if (DeleteDocument.success) {
				addInfoMessage('Documento', 'Borrado correctamente, recuerda guardar');
				onDelete(document.id);
			} else {
				addErrorMessage('Documento', `Error: ${DeleteDocument.message}`);
			}
		},
	});

	return (
		<div className='flex justify-between w-full'>
			<a
				className='flex overflow-hidden w-full'
				href={document.src}
				target='_blank'
				type='application/pdf'
				rel='noreferrer'
			>
				<img
					className='w-6'
					src='/images/file-pdf-solid.svg'
					alt='pdf document'
					title='Abrir pdf en nueva ventana'
				/>
				<div className='text-gray-700 pl-4 self-center'>{document.filename}</div>
			</a>
			<img
				className='ml-4 w-4 cursor-pointer'
				onClick={e => {
					e.preventDefault();
					if (!window.confirm('¿Confirma borrar el documento? luego no se puede recuperar')) return;
					onDelete && deleteDocument({ variables: { id: document.id } });
				}}
				title='Eliminar pdf'
				src='/images/trash-solid.svg'
				alt='pdf document'
			/>
		</div>
	);
};

export default DropDocumentArea;
