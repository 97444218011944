/**
 * Transform translated text to use in Editor component
 *
 * @param {Object} text text to transform
 * @returns {String} transformed text for Editor.
 */
const transformTranslatedEditorText = text => {
	let textToTransform = text.split('\n').map(value => ({
		type: 'paragraph',
		children: [{ text: value }],
	}));
	try {
		textToTransform = JSON.stringify(textToTransform);
	} catch {
		textToTransform = '[{"type":"paragraph","children":[{"text":"ERROR"}]}]';
	}
	return textToTransform;
};

export default transformTranslatedEditorText;
