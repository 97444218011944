import React, { useEffect, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

import useAuth from 'hooks/use-auth';

import Button from 'components/Button';
import Check from 'components/Check';
import Input from 'components/Input';
import useConf from 'hooks/use-conf';

const LoginForm = ({ className = 'text-lg w-full md:w-128 overflow-hidden mx-auto', displayHeader = true }) => {
	const location = useLocation();
	const { config } = useConf();
	const { isLoggedIn, loggedInStatus, setUserData, user, useLogin } = useAuth();
	const [login, { loading }] = useLogin({
		onCompleted: ({ SignIn }) => {
			// All Errors implement Fault
			if (SignIn.__typename.endsWith('Fault')) {
				setError(SignIn.message);
			} else if (SignIn.__typename === 'User') {
				setUserData(SignIn);
			}
		},
	});

	const [error, setError] = useState(null);
	const [loginFormData, setLoginFormData] = useState({
		email: '',
		password: '',
		rememberSession: false,
	});

	// Delete form email and password values if login successful
	useEffect(() => {
		if (isLoggedIn === loggedInStatus.yes) {
			setLoginFormData({ email: '', password: '' });
		}
	}, [isLoggedIn, loggedInStatus.yes]);

	const handleEmail = e => {
		setLoginFormData({ ...loginFormData, email: e.target.value });
	};

	const handleLogin = e => {
		e.preventDefault();
		if (loginFormData.email === '' || loginFormData.password === '') {
			setError('Se requiere email y contraseña');
			return null;
		}
		login({
			variables: {
				input: loginFormData,
			},
		});
	};

	const handlePassword = e => {
		setLoginFormData({ ...loginFormData, password: e.target.value });
	};

	if (isLoggedIn === loggedInStatus.yes) {
		if (location.state?.redirectFrom) {
			return <Navigate to={location.state.redirectFrom} replace />;
		}
		if (user?.type === 'merchant' && (user.status === 'revision' || user.status === 'pending')) {
			return <Navigate to={`/dashboard/asociados/edit/${user.MerchantId}`} replace />;
		}
		if (user?.type === 'translator') {
			return <Navigate to='/dashboard/asociados/list/all' replace />;
		}
		return <Navigate to='/dashboard' replace />;
	}

	if (loading) {
		return null;
	}

	return (
		<>
			<form onSubmit={handleLogin} className={className}>
				{displayHeader && (
					<div className='-space-y-7'>
						<img
							src={`${config.statics}/mp/logob.svg`}
							className='mx-auto block h-24 w-full overflow-hidden text-transparent'
							alt={`${config.marketplace_name}`}
						/>
						<h1 className='text-21 md:text-40 font-medium mb-0'>Acceso de productor/artesano</h1>
						<h2 className='text-19 md:text-27 font-normal mb-6'>Introduce tus datos</h2>
					</div>
				)}
				<div className='-space-y-7'>
					<Input
						type='email'
						autoComplete='email'
						name='email'
						id='email'
						className='w-full rounded-lg'
						containerclassname='mt-4 items-center'
						placeholder='email@dominio.com'
						value={loginFormData.email}
						onChange={handleEmail}
						required
					/>
					<Input
						type='password'
						autoComplete='current-password'
						name='password'
						id='password'
						className='w-full rounded-lg'
						containerclassname='mt-4 items-center'
						placeholder='Tu Contraseña'
						value={loginFormData.password}
						onChange={handlePassword}
						required
					/>
				</div>
				<Check
					checked={loginFormData.rememberSession}
					label='Recordar sesión'
					onChange={() => setLoginFormData(prev => ({ ...prev, rememberSession: !prev.rememberSession }))}
				/>
				<div className='flex flex-col font-light center mt-2'>
					<span className='center'>
						<span className='font-light mr-2'>¿No tienes cuenta?</span>
						<a
							rel='noreferrer'
							target='_blank'
							href={`${config.shop_url}/unirme`}
							className='font-semibold underline'
						>
							Únete
						</a>
					</span>
					<Link to='/restore-password'>¿Has olvidado tu constraseña?</Link>
				</div>
				<Button onClick={handleLogin} type='submit' disabled={loading} className='mt-4 block mx-auto uppercase'>
					{loading ? 'Enviando…' : 'Entrar al sistema'}
				</Button>
				{error && <p className='mt-4 border border-red-500 bg-gray-200 p-2 text-red-500'>{error}</p>}
			</form>
		</>
	);
};

export default LoginForm;
