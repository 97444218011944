import React, { useEffect } from 'react';

const EmptyModal = ({ openModal, children, setOpenModal = null }) => {
	useEffect(() => {
		const close = e => {
			if (e.keyCode === 27) {
				setOpenModal(false);
			}
		};
		if (setOpenModal) {
			window.addEventListener('keydown', close);
			return () => window.removeEventListener('keydown', close);
		}
	}, []);

	return (
		openModal && (
			<div className='z-50 fixed flex items-center justify-center inset-0'>
				<div className='absolute w-full h-full bg-black opacity-75'></div>
				{children}
			</div>
		)
	);
};

export default EmptyModal;
