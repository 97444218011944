import microsoftTranslator from './MicrosoftTranslator';
import transformEditorJsonToString from './transformEditorJsonToString';
import transformTranslatedEditorText from './transformTranslatedEditorText';

const translateProductCategoryForm = async (
	category,
	fromLocale,
	locale,
	config,
	setTranslatorModal,
	setValue,
	setRefreshCounterNumber,
	getLazyProductCategory,
	setImage,
	addSuccessMessage,
	addErrorMessage
) => {
	let isSuccess = false;
	const { data } = await getLazyProductCategory({
		variables: { id: category.id, forceLocale: true, locale: fromLocale },
	});

	if (!data?.ProductCategory) return isSuccess;
	const categoryToTranslate = data?.ProductCategory;

	// Define custom set methods to update state with translated text
	const setImageAttribute = (name, text) =>
		setImage(prev => ({
			...prev,
			[name]: text,
		}));

	const setEditorValue = (name, text) => {
		const translationToSet = transformTranslatedEditorText(text);
		setValue(name, translationToSet);
	};

	// List of elements with text to translate and custom properties for each field
	// [{ text: text to translate,
	//  <name>: name of the field in the form | image position,
	//	set: custom method to update state
	// }, ...]
	const listOfTextToTranslate = [];

	// Nombre de la categoría
	if (!category.name && categoryToTranslate.name) {
		listOfTextToTranslate.push({ text: categoryToTranslate.name, name: 'categoryForm.name', set: setValue });
	}
	// Información (Descripción breve, Subtítulo 1,...5, Descripción 1,...5)
	// Transform "content"
	if (categoryToTranslate.content) {
		try {
			const contentToTranslate = JSON.parse(categoryToTranslate.content);
			const currentContent = category.content;

			for (const [key] of Object.entries(contentToTranslate)) {
				if (key.includes('description')) {
					const descriptionToTranslate = transformEditorJsonToString(contentToTranslate[key], '\n');
					if (descriptionToTranslate) {
						const currentDescription = transformEditorJsonToString(JSON.parse(currentContent[key]));
						if (!currentDescription || currentDescription.length === 0) {
							listOfTextToTranslate.push({
								text: descriptionToTranslate,
								name: `categoryForm.content.${key}`,
								set: setEditorValue,
							});
						}
					}
				} else {
					const subtitleToTranslate = contentToTranslate[key];
					if (subtitleToTranslate && !currentContent[key]) {
						listOfTextToTranslate.push({
							text: subtitleToTranslate,
							name: `categoryForm.content.${key}`,
							set: setValue,
						});
					}
				}
			}
		} catch {
			addErrorMessage('Error al transformar descripciones y subtítulos');
		}
	}
	// Imagen
	if (categoryToTranslate.Image?.title && !category.Image?.title) {
		listOfTextToTranslate.push({
			text: categoryToTranslate.Image?.title,
			set: setImageAttribute,
			name: 'title',
		});
	}
	if (categoryToTranslate.Image?.alt && !category.Image?.alt) {
		listOfTextToTranslate.push({
			text: categoryToTranslate.Image?.alt,
			set: setImageAttribute,
			name: 'alt',
		});
	}

	if (listOfTextToTranslate.length > 0) {
		try {
			const translations = await microsoftTranslator(
				fromLocale,
				locale,
				listOfTextToTranslate.map(ele => ({ text: ele.text })),
				config,
				setTranslatorModal
			);
			if (translations) {
				for (const { name, set } of listOfTextToTranslate) {
					set(name, translations.shift().translations[0].text);
				}

				setRefreshCounterNumber(v => v + 1); // Just increase the refreshCounterNumber
				addSuccessMessage('Traducción', 'campos traducidos correctamente');
				isSuccess = true;
			} else {
				addErrorMessage('Traducción', translations.message);
			}
		} catch (error) {
			setTranslatorModal(false);
			addErrorMessage('Traducción', error.message);
		}
	} else {
		setTranslatorModal(false);
		addErrorMessage('Traducción', 'no hay nada para traducir');
	}

	return isSuccess;
};

export default translateProductCategoryForm;
