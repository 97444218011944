import React from 'react';
import ProductsList from 'components/ProductsList';

const Products = () => {
	const statusList = [
		{ label: 'Todos', scope: 'defaultScope' },
		{ label: 'Activo', scope: 'active' },
		{ label: 'Inactivo', scope: 'inactive' },
		{ label: 'Revisión', scope: 'revision' },
		{ label: 'Modificado', scope: 'modified' },
		{ label: 'Descatalogado', scope: 'deleted' },
	];

	const substatusList = [
		{ label: 'Todos', substatus: null },
		{ label: 'Correcto', substatus: 'correct' },
		{ label: 'Pendiente de correcciones', substatus: 'issue' },
		{ label: 'Faltan campos requeridos', substatus: 'requiredFields' },
		{ label: 'Faltan traducciones', substatus: 'untranslated' },
	];

	return <ProductsList statusList={statusList} substatusList={substatusList} />;
};

export default Products;
