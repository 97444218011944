import Button from 'components/Button';
import Input from 'components/Input';
import useAuth from 'hooks/use-auth';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useToast } from 'components/Toast';
import { SectionTitle } from './LayoutTitle';
import isSuccessResponse from 'lib/isSuccessResponse';

const PasswordManagement = ({ userData, className }) => {
	let location = useLocation();
	const { useChangePassword, logOut } = useAuth();
	const [changePassword, { loading }] = useChangePassword();

	const [passwordFormData, setPasswordFormData] = useState({
		id: userData?.id,
		password: '',
	});
	const [changePasswordResponse, setChangePasswordResponse] = useState(null);
	const [confirmPassword, setConfirmPassword] = useState('');
	// Toast alert
	const { addSuccessMessage, addErrorMessage } = useToast();

	useEffect(() => {
		// When change page reset state
		setChangePasswordResponse(null);
		setPasswordFormData({ id: userData?.id, password: '' });
	}, [location, userData]);

	useEffect(() => {
		if (
			changePasswordResponse &&
			isSuccessResponse(changePasswordResponse, ['User'], logOut, addErrorMessage, 'Contraseña')
		) {
			addSuccessMessage('Contraseña', 'guardada correctamente');
		}
	}, [changePasswordResponse, addSuccessMessage, addErrorMessage]);

	const handleChangePassword = e => {
		e.preventDefault();
		if (passwordFormData.password.length <= 0 || confirmPassword.length <= 0) {
			addErrorMessage('Password', 'Nueva contraseña requerida');
			return null;
		}
		if (passwordFormData.password !== confirmPassword) {
			addErrorMessage('Password', 'Contraseñas deben ser iguales');
			return null;
		}
		changePassword({
			variables: {
				id: passwordFormData.id,
				password: passwordFormData.password,
			},
		}).then(response => {
			setChangePasswordResponse(response.data.ChangePassword);
		});
		setPasswordFormData({ id: userData?.id, password: '' });
		setConfirmPassword('');
	};

	const handleConfirmPassword = e => {
		setConfirmPassword(e.target.value);
	};

	const handlePassword = e => {
		setPasswordFormData({ ...passwordFormData, password: e.target.value });
	};

	return (
		<div className={className}>
			<SectionTitle className='mb-4'>Cambio de contraseña</SectionTitle>
			<form onSubmit={handleChangePassword}>
				<Input
					id='password'
					label='Nueva contraseña'
					value={passwordFormData.password}
					onChange={handlePassword}
					placeholder='Nueva contraseña'
					type='password'
					htmlFor='password'
					autoComplete='new-password'
					required
				/>
				<Input
					id='confirmPassword'
					label='Confirmar contraseña'
					value={confirmPassword}
					onChange={handleConfirmPassword}
					placeholder='Confirmar contraseña'
					type='password'
					autoComplete='new-password'
					htmlFor='confirmPassword'
					required
				/>
				<div className='w-1/3'>
					<Button onClick={handleChangePassword} type='submit' disabled={loading}>
						Actualizar contraseña
					</Button>
				</div>
			</form>
		</div>
	);
};

export default PasswordManagement;
