import microsoftTranslator from './MicrosoftTranslator';
import translateLocale from 'lib/translateLocale';

const translateOtherFeaturesField = async (
	text,
	fromLocale,
	setTranslatorModal,
	setValue,
	name,
	addErrorMessage,
	locale,
	config
) => {
	if (text && text !== '[{"title":"","value":""}]' && text.includes('[{"title":')) {
		try {
			const rawOtherFeatures = JSON.parse(text);
			let otherFeaturesContent = '';
			for (let i = 0; i <= rawOtherFeatures.length - 1; i++) {
				otherFeaturesContent =
					otherFeaturesContent +
					`${rawOtherFeatures[i].title}\n${rawOtherFeatures[i].value}${
						i < rawOtherFeatures.length - 1 ? '\n' : ''
					}`;
			}
			if (otherFeaturesContent) {
				const translations = await microsoftTranslator(
					fromLocale,
					locale,
					[{ text: otherFeaturesContent }],
					config,
					setTranslatorModal
				);
				if (translations) {
					let otherFeatures = translations[0].translations[0].text.split('\n');
					const otherFeaturesFinal = [];
					while (otherFeatures.length > 0) {
						otherFeaturesFinal.push({ title: otherFeatures[0], value: otherFeatures[1] });
						otherFeatures = otherFeatures.slice(2);
					}
					setValue(name, otherFeaturesFinal);
				} else {
					addErrorMessage('Traducción', translations.message);
				}
			}
		} catch (error) {
			setTranslatorModal(false);
			addErrorMessage('Traducción', `Error al transformar "Otras características": ${error.message}`);
		}
	} else {
		setTranslatorModal(false);
		addErrorMessage('Traducción', `Campo "Otras características" sin texto en ${translateLocale(fromLocale)}`);
	}
};

export default translateOtherFeaturesField;
