const formatDate = (date, separator = '/') => {
	const year = date?.split('-')[0];
	const month = date?.split('-')[1];
	const day = date?.split('-')[2].slice(-0, 2);
	return day + separator + month + separator + year;
};

export const formatDateYYYYMMDD = (date, separator = '/') => {
	const year = date?.split('-')[0];
	const month = date?.split('-')[1];
	const day = date?.split('-')[2].slice(-0, 2);
	return year + separator + month + separator + day;
};

export default formatDate;
