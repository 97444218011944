import { gql, useLazyQuery, useMutation } from '@apollo/client';

export const INVOICE_INFO_FRAGMENT = /* GraphQL */ `
	fragment InvoiceInfo on Invoice {
		id
		number
		type
		pdf
	}
`;

const INVOICES_QUERY = gql`
	query Invoices($orderId: ID!, $limit: Int, $offset: Int) {
		Invoices(orderId: $orderId, limit: $limit, offset: $offset) {
			... on InvoiceList {
				count
				hasMore
				List {
					...InvoiceInfo
				}
			}
			... on DatabaseFault {
				message
			}
			... on AuthorizationFault {
				user
				message
			}
		}
	}
	${INVOICE_INFO_FRAGMENT}
`;

const ZIP_INVOICES_QUERY = gql`
	query ZipInvoices($startDate: Date, $endDate: Date) {
		ZipInvoices(startDate: $startDate, endDate: $endDate) {
			... on InvoicesUrl {
				url
				filename
			}
			... on DatabaseFault {
				message
			}
			... on AuthorizationFault {
				user
				message
			}
		}
	}
`;

const DELETE_ZIP_MUTATION = gql`
	mutation DeleteZip($filename: String!) {
		DeleteZip(filename: $filename) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
			}
			... on AuthorizationFault {
				user
				message
			}
			... on StorageFault {
				message
			}
		}
	}
`;

export const invoiceTypes = Object.freeze({
	SIMPLE: 'simple',
	SIMPLE_RECTIFIED: 'simpleRectified',
	COMPLETE: 'complete',
	COMPLETE_RECTIFIED: 'completeRectified',
});

export function getPrefixByInvoiceType(type) {
	switch (type) {
		case invoiceTypes.COMPLETE_RECTIFIED:
			return 'RC';
		case invoiceTypes.COMPLETE:
			return 'FC';
		case invoiceTypes.SIMPLE_RECTIFIED:
			return 'RS';
		case invoiceTypes.SIMPLE:
		default:
			return 'FS';
	}
}

export function getInvoiceTypeTranslation(type) {
	switch (type) {
		case invoiceTypes.COMPLETE_RECTIFIED:
		case invoiceTypes.COMPLETE:
			return 'Factura';
		case invoiceTypes.SIMPLE_RECTIFIED:
		case invoiceTypes.SIMPLE:
		default:
			return 'Ticket';
	}
}

export function formatNumber(number) {
	return String(number).padStart(8, '0');
}

export const useLazyInvoices = orderId =>
	useLazyQuery(INVOICES_QUERY, { variables: { orderId }, fetchPolicy: 'network-only' });
export const useZipInvoices = variables => useLazyQuery(ZIP_INVOICES_QUERY, { variables, fetchPolicy: 'network-only' });
export const useDeleteZip = props => useMutation(DELETE_ZIP_MUTATION, props);
