import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

export const MERCHANT_FRAGMENT_NO_USER = gql`
	fragment MerchantInfoNoUser on Merchant {
		id
		locale
		acceptConditions
		acceptConditionsAt
		administrativeAddress1
		administrativeAddress2
		administrativeBusinessName
		administrativeCity
		administrativeCountry
		administrativeDescription
		administrativeEmail
		administrativePhone
		administrativeProvince
		administrativeUrl
		administrativeVat
		administrativeZip
		commercialAddress1
		commercialAddress2
		commercialCity
		commercialContent
		commercialCountry
		CommercialLogo {
			id
			locale
			src
			title
			alt
		}
		commercialMaps
		commercialName
		commercialPhone
		commercialProvince
		commercialRequiredDescription
		commercialSchedule
		commercialVideo
		commercialZip
		commission
		createdAt
		entranceFee
		grants
		healthRegister
		Images {
			id
			locale
			src
			title
			alt
		}
		FeaturedImage {
			id
			locale
			src
			title
			alt
		}
		HealthRegistry {
			id
			filename
			src
		}
		logisticsAddress1
		logisticsAddress2
		logisticsCity
		logisticsCountry
		logisticsEmail
		logisticsObservations
		logisticsPhone
		logisticsProvince
		logisticsZip
		meetRequirements
		physicalStore
		ProductCategoryId
		ProductCount
		qualityCertificate
		QualityCertificates {
			id
			filename
			src
		}
		slug
		stripeAccountId
	}
`;

// In order to avoid cyclic imports
export const USERINFO_FRAGMENT = gql`
	fragment UserInfo on User {
		id
		acceptsMarketing
		email
		firstName
		lastName
		phone
		requiresOnboarding
		status
		type
		MerchantId
		Image {
			id
			locale
			src
			title
			alt
		}
		UserImageId
		lastLoginAt
	}
`;

export const MERCHANT_FRAGMENT = gql`
	fragment MerchantInfo on Merchant {
		...MerchantInfoNoUser
		User {
			...UserInfo
		}
		UserId
	}
	${USERINFO_FRAGMENT}
	${MERCHANT_FRAGMENT_NO_USER}
`;

export const MERCHANTS_FRAGMENT = gql`
	fragment MerchantsInfo on Merchant {
		id
		locale
		commercialName
		commission
		entranceFee
		meetRequirements
		createdAt
		User {
			id
			email
			status
		}
	}
`;

const MERCHANT_QUERY = gql`
	query Merchant($id: ID!, $forceLocale: Boolean, $locale: String!) {
		Merchant(id: $id, forceLocale: $forceLocale, locale: $locale) {
			... on Merchant {
				...MerchantInfo
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${MERCHANT_FRAGMENT}
`;

const MERCHANTS_QUERY = gql`
	query Merchants($search: String, $scope: String, $order_scope: String, $limit: Int, $offset: Int, $locale: String) {
		Merchants(
			search: $search
			scope: $scope
			order_scope: $order_scope
			limit: $limit
			offset: $offset
			locale: $locale
		) {
			... on MerchantList {
				count
				hasMore
				List {
					...MerchantsInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${MERCHANTS_FRAGMENT}
`;

const MERCHANTCREATE_MUTATION = gql`
	mutation MerchantCreate($input: MerchantCreateInput!) {
		MerchantCreate(input: $input) {
			... on Merchant {
				...MerchantInfo
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on MailFault {
				message
				emailRecipient
			}
			... on AuthorizationFault {
				message
			}
		}
	}
	${MERCHANT_FRAGMENT}
`;

const MERCHANTUPDATE_MUTATION = gql`
	mutation MerchantUpdate($id: ID!, $input: MerchantUpdateInput!, $locale: String!, $stripe: Boolean) {
		MerchantUpdate(id: $id, input: $input, locale: $locale, stripe: $stripe) {
			... on MerchantUpdate {
				Merchant {
					...MerchantInfo
				}
				Url
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on MailFault {
				message
				emailRecipient
			}
			... on AuthorizationFault {
				message
			}
		}
	}
	${MERCHANT_FRAGMENT}
`;

const MERCHANTDELETE_MUTATION = gql`
	mutation MerchantDelete($id: ID!) {
		MerchantDelete(id: $id) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
	${MERCHANT_FRAGMENT}
`;

const STRIPESTATUS_MUTATION = gql`
	mutation StripeStatus($MerchantId: ID) {
		StripeStatus(MerchantId: $MerchantId) {
			... on StripeStatus {
				message
				isActive
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on MailFault {
				message
				emailRecipient
			}
		}
	}
`;

const STRIPE_LOGIN_LINK_QUERY = gql`
	query StripeLoginLink {
		StripeLoginLink {
			... on StripeLoginLink {
				url
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
`;

export const useCreateMerchant = props => useMutation(MERCHANTCREATE_MUTATION, props);
export const useUpdateMerchant = props => useMutation(MERCHANTUPDATE_MUTATION, { ...props, fetchPolicy: 'no-cache' });
export const useDeleteMerchant = props => useMutation(MERCHANTDELETE_MUTATION, props);

export const useMerchant = variables => useQuery(MERCHANT_QUERY, { variables, fetchPolicy: 'network-only' });
export const useLazyMerchant = variables =>
	useLazyQuery(MERCHANT_QUERY, { variables, fetchPolicy: 'cache-and-network' });

export const useMerchants = variables => useQuery(MERCHANTS_QUERY, { variables, fetchPolicy: 'cache-and-network' });
export const useLazyMerchants = variables =>
	useLazyQuery(MERCHANTS_QUERY, { variables, fetchPolicy: 'cache-and-network' });

export const useStripeStatus = props => useMutation(STRIPESTATUS_MUTATION, { ...props, fetchPolicy: 'no-cache' });
export const useStripeLoginLink = props => useLazyQuery(STRIPE_LOGIN_LINK_QUERY, { ...props, fetchPolicy: 'no-cache' });
