import React from 'react';
import useAuth from 'hooks/use-auth';
import useConf from 'hooks/use-conf';

const LanguageTabs = ({ disabled = false, dirty = false, ...props }) => {
	const { config } = useConf();
	const { locale, setLocale } = useAuth();

	let languagesTabs = config.locales?.map(l => ({ value: l, active: l === locale }));

	// Note: We return here a function that returns a function that does the work.
	const handleLanguage = languageTab => () => {
		if (!disabled) {
			if (dirty && !window.confirm('Hay cambios, si cambias de idioma los vas a perder ¿quieres continuar?')) {
				return;
			}
			// Set all languagesTab.active to false
			for (const v of Object.keys(languagesTabs)) {
				languagesTabs[v].active = false;
			}
			languageTab.active = true;
			setLocale(languageTab.value);
		}
	};

	return (
		<div className='flex justify-end sticky-top bg-gray-200 z-30 border-b border-gray-300' {...props}>
			{languagesTabs?.map((language, i) => (
				<Language
					key={i}
					id={language.value}
					locale={language.value}
					disabled={disabled}
					onClick={handleLanguage(language)}
					active={language.active}
				/>
			))}
		</div>
	);
};

const Language = ({ locale, active = false, disabled, onClick }) => (
	<div
		onClick={onClick}
		className={`${active ? 'bg-gray-500 text-white' : 'underline'} ${
			disabled && 'opacity-50 cursor-not-allowed'
		} w-12 text-center rounded-t-lg p-2 uppercase cursor-pointer`}
	>
		{locale}
	</div>
);

export default LanguageTabs;
