import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import { useToast } from 'components/Toast';
import { useCreateProduct } from 'hooks/use-products';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/use-auth';
import useConf from 'hooks/use-conf';
import ProductForm from './ProductForm';
import isSuccessResponse from 'lib/isSuccessResponse';

const ProductNew = () => {
	const navigate = useNavigate();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { config } = useConf();
	const { user, locale, setLocale, getLazyNotificationCount, logOut } = useAuth();

	useEffect(() => {
		// To create new product has to be default locale (Could not be called directly, React error)
		setLocale(config.default_locale);
	}, [config.default_locale]);

	const [productNewResponse, setProductNewResponse] = useState(null);

	// Status list
	const statusList = [
		{ value: 'active', name: 'Activo' },
		{ value: 'inactive', name: 'Inactivo' },
		{ value: 'revision', name: 'Revisión' },
	];

	const substatusList = [
		{ value: 'correct', name: 'Correcto' },
		{ value: 'issue', name: 'Pendiente de correcciones' },
		{ value: 'requiredFields', name: 'Faltan campos requeridos' },
		{ value: 'untranslated', name: 'Faltan traducciones' },
	];

	// productNewResponse update States
	useEffect(() => {
		if (
			productNewResponse &&
			isSuccessResponse(productNewResponse, ['Product'], logOut, addErrorMessage, 'Crear producto')
		) {
			addSuccessMessage('Producto', `${productNewResponse.title} creado correctamente`);
			if (user?.type === 'admin') {
				getLazyNotificationCount();
			}
			navigate(`/dashboard/catalogo/productos/edit/${productNewResponse.id}`);
		}
	}, [productNewResponse, addSuccessMessage, addErrorMessage, getLazyNotificationCount, navigate, user.type]);

	const [createProduct] = useCreateProduct({
		onCompleted: ({ ProductCreate }) => {
			setProductNewResponse(ProductCreate);
		},
	});

	return (
		<>
			<LanguageTabs disabled={true} />
			<Card>
				<LayoutHeader>Nuevo Producto</LayoutHeader>
				<p className='text-lg italic md:mb-4'>
					Los campos marcados con un asterisco (<span className='text-coral-500 font-semibold'>*</span>) son
					obligatorios
				</p>
				<ProductForm
					onSubmit={async input => {
						await createProduct({ variables: { input, locale } });
					}}
					statusList={statusList}
					substatusList={substatusList}
					newProduct
				/>
			</Card>
		</>
	);
};

export default ProductNew;
