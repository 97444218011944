import React, { useContext, createContext, useEffect, useCallback, useState } from 'react';
import CloseButton from './CloseButton';

/**
 * @param {String} position top-right, top-left, bottom-right, bottom-left
 */
const ToastMessages = ({ toastList, setToastList, position, autoDelete = false, dismissTime = 8000 }) => {
	const deleteToast = useCallback(id => setToastList(prev => prev.filter(e => e.id !== id)), [setToastList]);

	switch (position) {
		case 'top-right':
			position = 'inset-y-0 right-0 animate-right';
			break;
		case 'top-left':
			position = 'inset-y-0 left-0 animate-left';
			break;
		case 'bottom-right':
			position = 'bottom-0 right-0 animate-right';
			break;
		case 'bottom-left':
			position = 'bottom-0 left-0 animate-left';
			break;
		default:
			position = 'inset-y-0 right-0 animate-right';
			break;
	}

	useEffect(() => {
		if (autoDelete) {
			const interval = setInterval(() => {
				if (toastList.length > 0) {
					deleteToast(toastList[0].id);
				}
			}, dismissTime);

			// The useEffect return value is a function called to do the cleanup:
			// It will be executed when we unmount the component or before a new
			// render happens.
			return () => clearInterval(interval);
		}
	}, [toastList, autoDelete, dismissTime, deleteToast]);

	return (
		<>
			<div className={`box-border text-sm fixed m-2 ${position} h-0 z-50`}>
				{toastList.map((toast, i) => (
					<div
						aria-label={`toast-${toast.type}`}
						key={i}
						className={`bg-white bg-no-repeat mx-0 mt-0 mb-4 overflow-hidden p-8 pointer-events-auto relative text-black hover:opacity-100 h-auto py-5 px-5 ${position} group`}
						style={{
							backgroundColor: toast.backgroundColor,
							borderStyle: toast.withBorder ? 'solid' : 'none',
							borderWidth: '2px',
							borderColor: '#a8a8a8',
						}}
					>
						<CloseButton onClick={() => deleteToast(toast.id)} />
						<span className='flex'>
							<div className='float-left mr-4'>
								{toast.type === 'success' && (
									<img className='h-8 w-8' src='/images/toast-icons/check.svg' alt='' />
								)}
								{toast.type === 'error' && (
									<img className='h-8 w-8' src='/images/toast-icons/error.svg' alt='' />
								)}
								{toast.type === 'info' && (
									<img className='h-8 w-8' src='/images/toast-icons/info.svg' alt='' />
								)}
							</div>
							<div>
								<p className='w-72 font-semibold h-4 text-lg mt-0 text-left mb-4'>{toast.title}</p>
								<p className='w-72 my-0 text-base mr-0 -ml-px text-left leading-none'>
									{toast.description}
								</p>
							</div>
						</span>
					</div>
				))}
			</div>
		</>
	);
};

const ToastContext = createContext();

// Provider component that wraps the Toast object ...
// ... available to any child component that wants to display a success or error message
export default function Toast({ children }) {
	const [toastList, setToastList] = useState([]);

	const addSuccessMessage = useCallback(
		(title, description) => {
			// Add success message
			setToastList(prev => [
				...prev,
				{
					id: prev.length > 0 ? prev?.at(-1).id + 1 : 1,
					title,
					description,
					type: 'success',
					backgroundColor: '#61bfad',
				},
			]);
		},
		[setToastList]
	);

	const addErrorMessage = useCallback(
		(title, description) => {
			// Add success message
			setToastList(prev => [
				...prev,
				{
					id: prev.length > 0 ? prev?.at(-1).id + 1 : 1,
					title,
					description,
					type: 'error',
					backgroundColor: '#ff8b8b',
				},
			]);
		},
		[setToastList]
	);

	const addInfoMessage = useCallback(
		(title, description) => {
			// Add success message
			setToastList(prev => [
				...prev,
				{
					id: prev.length > 0 ? prev?.at(-1).id + 1 : 1,
					title,
					description,
					type: 'info',
					backgroundColor: '#c2c2c2',
					withBorder: true,
				},
			]);
		},
		[setToastList]
	);

	return (
		<ToastContext.Provider value={{ addSuccessMessage, addErrorMessage, addInfoMessage }}>
			<ToastMessages toastList={toastList} setToastList={setToastList} position='top-right' autoDelete='true' />
			{children}
		</ToastContext.Provider>
	);
}

export const useToast = () => useContext(ToastContext);
