import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

const SPECIAL_FEATURE_INFO_FRAGMENT = gql`
	fragment SpecialFeatureInfo on SpecialFeature {
		id
		name
		description
	}
`;

const SPECIAL_FEATURES_QUERY = gql`
	query SpecialFeatures {
		SpecialFeatures {
			... on SpecialFeature {
				...SpecialFeatureInfo
			}
		}
	}
	${SPECIAL_FEATURE_INFO_FRAGMENT}
`;

const SPECIAL_FEATURE_CREATE_MUTATION = gql`
	mutation SpecialFeatureCreateMutation($name: String!, $description: String) {
		SpecialFeatureCreate(name: $name, description: $description) {
			... on SpecialFeature {
				...SpecialFeatureInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${SPECIAL_FEATURE_INFO_FRAGMENT}
`;

const SPECIAL_FEATURE_UPDATE_MUTATION = gql`
	mutation SpecialFeatureUpdateMutation($id: ID!, $name: String!, $description: String) {
		SpecialFeatureUpdate(id: $id, name: $name, description: $description) {
			... on SpecialFeature {
				...SpecialFeatureInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${SPECIAL_FEATURE_INFO_FRAGMENT}
`;

const SPECIAL_FEATURE_DELETE_MUTATION = gql`
	mutation SpecialFeatureDeleteMutation($id: ID!) {
		SpecialFeatureDelete(id: $id) {
			... on Success {
				success
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
`;

export const useSpecialFeatures = props =>
	useQuery(SPECIAL_FEATURES_QUERY, { ...props, fetchPolicy: 'cache-and-network' });
export const useLazySpecialFeatures = props =>
	useLazyQuery(SPECIAL_FEATURES_QUERY, {
		...props,
		fetchPolicy: 'cache-and-network',
	});
export const useCreateSpecialFeature = props => useMutation(SPECIAL_FEATURE_CREATE_MUTATION, props);
export const useUpdateSpecialFeature = props => useMutation(SPECIAL_FEATURE_UPDATE_MUTATION, props);
export const useDeleteSpecialFeature = props => useMutation(SPECIAL_FEATURE_DELETE_MUTATION, props);
