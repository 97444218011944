/**
 * Validate a string time for 24-hour format (e.g. 19:30)
 * It can be an empty string or a time to validate
 *
 * @param {String} value Time to validate
 * @returns {Boolean} Returns true empty string or correct format
 */
const isValid24HourFormat = value => {
	if (!value) return true;
	const regex = /^(?:[01]?\d|2[0-3]):[0-5]\d$/;
	return regex.test(value);
};
export default isValid24HourFormat;
