import Button from 'components/Button';
import InputForm from 'components/InputForm';
import TextareaForm from 'components/TextareaForm';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const FaqForm = ({ newFaq = false, faq, setDirty = () => {}, onSubmit }) => {
	const [isFormFilled, setIsFormFilled] = useState(newFaq);

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { isDirty },
	} = useForm({
		defaultValues: {
			FaqForm: {
				question: '',
				answer: '',
			},
		},
	});

	// Load Edit data
	useEffect(() => {
		if (faq) {
			setValue('FaqForm', { question: faq.question, answer: faq.answer });
			setDirty(false);
			setIsFormFilled(true);
		}
	}, [faq, setValue, setDirty]);

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (isDirty) {
			setDirty(isDirty);
		}
	}, [setDirty, isDirty]);

	return (
		isFormFilled && (
			<form onSubmit={handleSubmit(onSubmit)}>
				<InputForm
					label='Pregunta'
					width='w-2/3'
					control={control}
					register={register}
					name='FaqForm.question'
				/>
				<TextareaForm maxLength='2000' label='Respuesta' control={control} name='FaqForm.answer' />
				<Button className='mt-4' type='submit'>
					{newFaq ? 'Crear FAQ' : 'Actualizar FAQ'}
				</Button>
			</form>
		)
	);
};

export default FaqForm;
