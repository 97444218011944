import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import { SectionHeader } from 'components/LayoutHeader';
import useAuth from 'hooks/use-auth';
import { useLazyMerchant } from 'hooks/use-merchants';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useConf from 'hooks/use-conf';

import MerchantForm from './MerchantForm';
import CommercialForm from './CommercialForm';
import LoadingView from 'components/LoadingView';

const MerchantEdit = () => {
	const { merchantId } = useParams();
	const { config } = useConf();
	const { user, locale } = useAuth();

	const [dirty, setDirty] = useState(false);

	const [merchantData, setMerchantData] = useState(null);
	const [loading, setLoading] = useState(true);

	const [getLazyMerchant] = useLazyMerchant();

	// Excecute once at page load
	useEffect(() => {
		// query Product fields with specific locale
		getLazyMerchant({ variables: { id: merchantId, forceLocale: true, locale } }).then(({ data }) => {
			setMerchantData(data);
			setLoading(false);
		});
		setDirty(false);
	}, [locale, merchantId]);

	const handleCopyAnotherLanguage = () => {
		// query Product fields with any translation available
		getLazyMerchant({ variables: { id: merchantId, forceLocale: false, locale } }).then(({ data }) => {
			setMerchantData(data);
		});
	};

	const alertStatus = status => {
		if (status === 'revision') {
			return (
				<Card className='border border-coral-300 mb-10 text-xl'>
					<p>
						Su cuenta se encuentra en estado de
						<span className='font-semibold capitalize'> revisión</span> en espera de ser activada por el
						Administrador de "{config.marketplace_name}".
					</p>
					<p>
						Puede actualizar sus datos (Cuenta, Administrativa y Comercial) pero deberá esperar a que su
						cuenta esté activa para tener acceso al resto de las opciones.
					</p>
					<p>
						Los productos sólo se pueden crear si eres un Asociado Activo. Para ello es necesario que
						completes correctamente las fichas Administrativa y Comercial.
					</p>
				</Card>
			);
		}

		if (status === 'pending') {
			return (
				<Card className='border border-coral-300 mb-10 text-xl'>
					<p>
						Su cuenta se encuentra en estado de
						<span className='font-semibold capitalize'> pendiente</span>.
					</p>
					<p>
						Complete su registro rellenando las Fichas: 1. Datos de Perfil, 2. Ficha Administrativa, 3.
						Ficha Comercial. Una vez revisadas, su cuenta será Activada por el Administrador de "
						{config.marketplace_name}". (Consulte nuestras Guías de ayuda Paso a Paso en el icono superior
						❓).
					</p>
				</Card>
			);
		}
	};

	useEffect(() => {
		window.addEventListener('beforeunload', checkFormIsDirty);

		return () => {
			window.removeEventListener('beforeunload', checkFormIsDirty);
		};
	}, [dirty]);

	const checkFormIsDirty = e => {
		if (dirty) {
			e.preventDefault();
			if (e) {
				e.returnValue = ''; // Legacy method for cross browser support
			}
			return ''; // Legacy method for cross browser support
		}
	};

	return (
		<LoadingView whileLoading='Cargando el asociado' loading={loading}>
			<LanguageTabs dirty={dirty} />
			<Card>
				<SectionHeader className='uppercase mb-8 md:text-4xl'>
					{merchantData?.Merchant?.commercialName || 'Asociado'}
				</SectionHeader>
				{user && user.status && alertStatus(user.status)}
				{user.type === 'translator' ? (
					<CommercialForm
						adminLayout={user.type === 'admin'}
						merchantData={merchantData?.Merchant}
						dirty={dirty}
						setDirty={setDirty}
						withHeader={false}
						handleCopyAnotherLanguage={handleCopyAnotherLanguage}
						getLazyMerchant={getLazyMerchant}
					/>
				) : (
					<MerchantForm
						data={merchantData}
						handleCopyAnotherLanguage={handleCopyAnotherLanguage}
						adminLayout={user.type === 'admin'}
						dirty={dirty}
						setDirty={setDirty}
						getLazyMerchant={getLazyMerchant}
					/>
				)}
			</Card>
		</LoadingView>
	);
};

export default MerchantEdit;
