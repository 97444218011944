const transformEditorJsonToString = (json, separator = ' ') => {
	try {
		return json
			?.map(block => {
				return block.children
					.map(child => {
						return child.text;
					})
					.join('');
			})
			.join(separator);
	} catch {
		return '';
	}
};

export default transformEditorJsonToString;
