import React from 'react';

import Button from './Button';

const Modal = ({
	children,
	modalObject,
	openModal,
	setOpenModal,
	handleSubmit,
	onCancel = () => setOpenModal(false),
	withIcon = false,
	fullScreen = false,
	disabled = false,
}) => {
	return (
		<>
			{openModal && (
				<div className='z-40 fixed bottom-0 inset-x-0 px-4 py-4 sm:inset-0 sm:flex sm:items-center sm:justify-center'>
					<div className='overlay inset-0 transition-opacity ease-out duration-300 absolute bg-gray-500 opacity-75' />
					<div
						className={`flex flex-col p-6 bg-white rounded-lg overflow-auto max-h-full shadow-xl transform transition-all w-full ${
							fullScreen ? 'h-full' : 'sm:max-w-lg'
						}`}
						role='dialog'
						aria-modal='true'
						aria-labelledby='modal-headline'
					>
						<div className='flex flex-col h-full w-full bg-white'>
							{withIcon && (
								<div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-coral-100 sm:mx-0 sm:h-10 sm:w-10'>
									<svg
										className='h-6 w-6 text-coral-600'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
										/>
									</svg>
								</div>
							)}
							<div className='flex flex-col items-center justify-center sm:text-left w-full h-full'>
								<h3 className='text-lg leading-6 font-medium text-gray-900 mr-auto' id='modal-headline'>
									{modalObject.title}
								</h3>
								{children}
								{modalObject.description && (
									<div className='mt-6 mb-6'>
										<div className='text-lg leading-5 text-gray-500'>{modalObject.description}</div>
									</div>
								)}
							</div>
						</div>
						{(modalObject.btnText || modalObject.cancelBtnText) && (
							<div className='sm:flex sm:flex-row-reverse mt-6'>
								{modalObject.btnText && (
									<span className='flex w-full rounded-md shadow-sm sm:ml-4 sm:w-auto'>
										<Button
											className='w-full px-4'
											onClick={() => handleSubmit()}
											disabled={disabled}
										>
											{modalObject.btnText}
										</Button>
									</span>
								)}
								{modalObject.cancelBtnText && (
									<span className='flex w-full rounded-md shadow-sm sm:w-auto'>
										<Button
											onClick={() => onCancel()}
											bg={modalObject.btnText ? 'bg-gray-300' : 'bg-coral-500'}
											className={`w-full px-4 ${
												modalObject.btnText ? 'text-gray-700' : ''
											} hover:text-white`}
										>
											{modalObject.cancelBtnText}
										</Button>
									</span>
								)}
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Modal;
