import React from 'react';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import Card from 'components/Card';
import LayoutHeader from 'components/LayoutHeader';

const CRM = () => {
	const zohoList = [
		{
			image: '/images/zoho-icons/campaigns.svg',
			name: 'Campaigns',
			description: 'Campañas de email marketing',
			link: 'https://one.zoho.eu/p/20086020236/app/81466000000004013/sso',
		},
		{
			image: '/images/zoho-icons/marketingautomation.svg',
			name: 'Marketing Automation',
			description: 'Automatización del marketing',
			link: 'https://one.zoho.eu/p/20086020236/app/81466000000004101/sso',
		},
		{
			image: '/images/zoho-icons/crm-blue.svg',
			name: 'CRM',
			description: 'CRM',
			link: 'https://one.zoho.eu/p/20086020236/app/81466000000002047/sso',
		},
		{
			image: '/images/zoho-icons/desk.svg',
			name: 'Desk',
			description: 'Atención al cliente',
			link: 'https://one.zoho.eu/p/20086020236/app/81466000000002103/sso',
		},
		{
			image: '/images/zoho-icons/120319_0723_22BESTAccou22.webp',
			name: 'Books',
			description: 'Contabilidad',
			link: 'https://one.zoho.eu/p/20086020236/app/81466000000002099/sso',
		},
		{
			image: '/images/zoho-icons/social.svg',
			name: 'Social',
			description: 'RRSS',
			link: 'https://one.zoho.eu/p/20086020236/app/81466000000004017/sso',
		},
		{
			image: '/images/zoho-icons/salesiq.svg',
			name: 'Sales IQ',
			description: 'Chat en vivo',
			link: 'https://one.zoho.eu/p/20086020236/app/81466000000004001/sso',
		},
		{
			image: '/images/zoho-icons/sign.svg',
			name: 'Sign',
			description: 'Firma sin papeles',
			link: 'https://one.zoho.eu/p/20086020236/app/81466000000007053/sso',
		},
		{
			image: '/images/zoho-icons/pagesense.svg',
			name: 'Page Sense',
			description: 'Mapas de calor',
			link: 'https://one.zoho.eu/p/20086020236/app/81466000000004083/sso',
		},
		{
			image: '/images/zoho-icons/analytics.svg',
			name: 'Analíticas',
			description: 'Analíticas',
			link: 'https://one.zoho.eu/p/20086020236/app/81466000000004005/sso',
		},
	];

	return (
		<AuthorizationBoundary for={['admin']}>
			<Card>
				<LayoutHeader>Herramientas CRM</LayoutHeader>

				<div className='mt-20 w-full grid grid-cols-5 grid-rows-2 gap-y-8'>
					{zohoList.map((app, i) => (
						<a
							key={i}
							href={app.link}
							target='_blank'
							rel='noreferrer'
							className='w-full cursor-pointer flex flex-col items-center'
						>
							<img src={app.image} alt={app.name} key={i} className='w-100px aspect-square text-center' />
							<p className='text-center text-lg font-bold'>{app.name}</p>
							<p className='text-center'>{app.description}</p>
						</a>
					))}
				</div>
			</Card>
		</AuthorizationBoundary>
	);
};

export default CRM;
