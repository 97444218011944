/* eslint-disable unicorn/prefer-spread */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Card from 'components/Card';
import Check from 'components/Check';
import ContentTable from 'components/ContentTable';
import DownloadCSVButton from 'components/DownloadCSVButton';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import Pagination from 'components/Pagination';
import SearchBox from 'components/SearchBox';
import TooltipMenu from 'components/TooltipMenu';
import columnTitleElement from 'lib/columnTitleElement';
import SelectMerchantInput from 'components/SelectMerchantInput';
import formatDate from 'lib/formatDate';
import translasteStatus from 'lib/translasteStatus';
import useAuth from 'hooks/use-auth';
import {
	useProductsUpdateStatus,
	useLazyProducts,
	useUpdateProduct,
	useDuplicateProduct,
	useDeleteProduct,
	useProductInHome,
} from 'hooks/use-products';
import { useToast } from 'components/Toast';
import { useNavigate } from 'react-router-dom';
import DropdownTree from 'components/DropdownTree';
import { categoriesSimpleTree, useProductCategories } from 'hooks/use-product-categories';
import AuthorizationBoundary from './AuthorizationBoundary';
import useConf from 'hooks/use-conf';
import isSuccessResponse from 'lib/isSuccessResponse';
import useToggle from 'hooks/use-toggle';
import FeaturedProducts from './FeaturedProducts';
import Modal from './Modal';

const CreateProductButton = () => (
	<Button as={Link} to='/dashboard/catalogo/productos/new'>
		Nuevo Producto
	</Button>
);

const paginationLimit = 50;

const ProductsList = ({ statusList, substatusList, pageScope = 'all' }) => {
	const { config } = useConf();
	const navigate = useNavigate();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { getLazyNotificationCount, user, logOut } = useAuth();
	const [products, setProducts] = useState(null);
	const [scopes, setScopes] = useState([pageScope]);
	const [orderScope, setOrderScope] = useState('updated_desc');
	const [promoted, setPromoted] = useState(false);
	const [isInHome, setIsInHome] = useState(false);
	const [merchantHighlighted, setMerchantHighlighted] = useState(false);
	const [merchantId, setMerchantId] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedSubstatus, setSelectedSubstatus] = useState(null);
	const [productSearch, setProductSearch] = useState('');
	const [offset, setOffset] = useState(0);
	const [productToDelete, setProductToDelete] = useState(null);
	const [input, setInput] = useState({ globalCheck: false, items: [] });
	const [openModalHardDelete, setOpenModalHardDelete] = useToggle();

	const [getLazyProducts, { data, loading }] = useLazyProducts({
		search: productSearch,
		scopes,
		substatus: selectedSubstatus,
		order_scope: orderScope,
		isInHome: isInHome,
		promote: promoted,
		CategoryId: selectedCategory,
		MerchantId: merchantId,
		limit: paginationLimit,
		offset,
		locale: config.default_locale,
	});

	const [productsUpdateStatus] = useProductsUpdateStatus({
		onCompleted: ({ ProductsUpdateStatus }) => {
			if (isSuccessResponse(ProductsUpdateStatus, ['Success'], logOut, addErrorMessage, 'Productos')) {
				setOffset(0);
				getLazyProducts();
				addSuccessMessage('Productos', 'Descatalogados correctamente');
				if (user.type === 'admin') {
					getLazyNotificationCount();
				}
				setInput(prev => ({ ...prev, items: [] }));
			}
		},
	});

	const [productInHome] = useProductInHome();

	const [updateProduct] = useUpdateProduct({
		onCompleted: ({ ProductUpdate }) => {
			if (isSuccessResponse(ProductUpdate, ['Product'], logOut, addErrorMessage, 'Producto')) {
				setOffset(0);
				getLazyProducts();
				if (user.type === 'admin') {
					getLazyNotificationCount();
				}
			}
		},
	});

	const [duplicateProduct] = useDuplicateProduct({
		onCompleted: ({ ProductDuplicate }) => {
			if (isSuccessResponse(ProductDuplicate, ['Product'], logOut, addErrorMessage, 'Producto')) {
				addSuccessMessage('Productos', 'Duplicado correctamente');
				navigate(`/dashboard/catalogo/productos/edit/${ProductDuplicate.id}`);
			}
		},
	});

	const [deleteProduct] = useDeleteProduct();

	// Main Categories
	const { data: categoryData } = useProductCategories({
		variables: { locale: config.default_locale },
	});

	// Excecute once at page load
	useEffect(() => {
		getLazyProducts();
	}, []);

	// Excecute after first load and on search
	useEffect(() => {
		if (data && isSuccessResponse(data.Products, ['ProductList'], logOut, addErrorMessage, 'Productos')) {
			setProducts(data?.Products);
			setInput({ globalCheck: false, items: data?.Products.List.map(() => false) || [] });
		}
	}, [data, addErrorMessage]);

	const CreateFeaturedProducts = () => (
		<FeaturedProducts merchantId={user.MerchantId} getLazyProducts={getLazyProducts} />
	);

	const handleSearch = input => {
		setProductSearch(input);
		setOffset(0);
	};

	const handleClearSearch = () => {
		setProductSearch('');
		setOffset(0);
	};

	const handlePromotedProduct = e => {
		e.preventDefault();
		setPromoted(prev => !prev);
	};

	const handleIsInHomeProduct = e => {
		e.preventDefault();
		if (isInHome) {
			setScopes(scopes.filter(scope => scope !== 'inHome_asc'));
		} else {
			setScopes(prev => prev.concat('inHome_asc'));
		}
		setIsInHome(prev => !prev);
	};

	const handleMerchantHighlightedProduct = e => {
		e.preventDefault();
		if (merchantHighlighted) {
			setScopes(scopes.filter(scope => scope !== 'merchantHighlighted'));
		} else {
			setScopes(prev => prev.concat('merchantHighlighted'));
		}
		setMerchantHighlighted(prev => !prev);
	};

	const handleSelectCategoryId = categoryId => setSelectedCategory(categoryId);

	const handleClearCategory = () => {
		setSelectedCategory(null);
	};

	const handleSelectMerchant = merchantId => setMerchantId(merchantId.target.value);

	const filterStatusList = () => {
		if (!statusList) return null;

		const statusScopes = new Set(statusList.map(status => status.scope));
		return statusList.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				const otherScopes = scopes.filter(s => !statusScopes.has(s));
				setScopes([...otherScopes, scope]);
				setOffset(0);
			},
		}));
	};

	const filterSubstatusList = () => {
		return substatusList?.map(({ label, substatus }) => ({
			title: label,
			onClick: () => {
				setSelectedSubstatus(substatus);
				setOffset(0);
			},
		}));
	};

	const orderByDate = () => {
		const labelsAndScopes = [
			{ label: 'Creado ASC', scope: 'created_asc' },
			{ label: 'Creado DESC', scope: 'created_desc' },
			{ label: 'Modificado ASC', scope: 'updated_asc' },
			{ label: 'Modificado DESC', scope: 'updated_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
			},
		}));
	};

	const orderByMerchant = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'merchant_asc' },
			{ label: 'Descendente', scope: 'merchant_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
			},
		}));
	};

	const orderByTitle = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'title_asc' },
			{ label: 'Descendente', scope: 'title_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
			},
		}));
	};

	const globalMenuList = [
		{
			title: 'Descatalogar productos',
			onClick: () => {
				if (input.items.some(isCheckedItem => isCheckedItem)) {
					productsUpdateStatus({
						variables: {
							productsId: products?.List.filter((_, index) => input.items[index]).map(
								product => product.id
							),
							input: { status: 'deleted', promote: false, inHome: 0 },
						},
					});
				} else {
					addErrorMessage('Productos', 'No hay productos seleccionados');
				}
			},
		},
	];

	const getProductMenuList = ({ id, status, promote, isInHome, title }) => {
		const options = [
			{ title: 'Editar', href: `/dashboard/catalogo/productos/edit/${id}` },
			{
				title: 'Duplicar',
				onClick: () => {
					duplicateProduct({
						variables: {
							productId: id,
						},
					});
				},
			},
			{
				title: 'Descatalogar',
				onClick: () => {
					updateProduct({
						variables: {
							id,
							input: { status: 'deleted', promote: false, inHome: 0 },
						},
					}).then(({ data }) => {
						if (data.ProductUpdate.id) {
							addSuccessMessage('Producto', 'Descatalogado correctamente');
						}
					});
				},
			},
		];

		// Depends on product status, activeOption or inactiveOption are added as second option (in index 1 of options array)
		const activeOption = () => {
			if (status === 'inactive' || status === 'deleted') {
				return {
					title: 'Activar',
					onClick: () => {
						updateProduct({
							variables: {
								id,
								// When user type 'admin' activates a product, update to 'active'
								// When user type 'merchant' activates a product, update to 'modified'
								input: {
									status: user.type === 'admin' ? 'active' : 'revision',
									promote: false,
									inHome: 0,
								},
							},
						}).then(({ data }) => {
							if (data.ProductUpdate.id) {
								addSuccessMessage('Producto', 'Activado correctamente');
							}
						});
					},
				};
			}
			return null;
		};
		if (activeOption()) options.splice(1, 0, activeOption());

		const inactiveOption = () => {
			if (status === 'active' || status === 'revision' || status === 'modified') {
				return {
					title: 'Desactivar',
					onClick: () => {
						updateProduct({
							variables: {
								id,
								input: { status: 'inactive', promote: false, inHome: 0 },
							},
						}).then(({ data }) => {
							if (data.ProductUpdate.id) {
								addSuccessMessage('Producto', 'Desactivado correctamente');
							}
						});
					},
				};
			}
			return null;
		};
		if (inactiveOption()) options.splice(1, 0, inactiveOption());

		// Only 'admin' can promote or de-promote products with status 'active' or 'modified'
		if (user.type === 'admin' && (status === 'active' || status === 'modified')) {
			options.push({
				title: promote ? 'Despromocionar producto' : 'Promocionar producto',
				onClick: () => {
					// updateProduct({
					// 	variables: {
					// 		id,
					// 		input: { promote: !promote },
					// 	},
					// }).then(({ data }) => {
					// 	if (data.ProductUpdate.id) {
					// 		addSuccessMessage(
					// 			'Producto',
					// 			`${
					// 				data.ProductUpdate.promote
					// 					? 'Promovido correctamente'
					// 					: 'Despromovido correctamente'
					// 			}`
					// 		);
					// 	}
					// });
					addErrorMessage('Producto', 'Promocionar producto no implementado actualmente');
				},
			});
		}

		// Only 'admin' can set products in home or unset them with status 'active' or 'modified'
		if (user.type === 'admin' && (status === 'active' || status === 'modified')) {
			options.push({
				title: isInHome ? 'Quitar producto de portada' : 'Añadir producto a portada',
				onClick: () => {
					productInHome({
						variables: {
							id,
							isInHome: !isInHome,
							locale: config.default_locale,
						},
					}).then(({ data }) => {
						if (isSuccessResponse(data.ProductInHome, ['Product'], logOut, addErrorMessage, 'Producto')) {
							addSuccessMessage(
								'Producto',
								`${
									data.ProductInHome.inHome != 0
										? 'Añadido a portada correctamente'
										: 'Quitado de portada correctamente'
								}`
							);
						}
					});
				},
			});
		}

		// 'merchant' can send email to request promote or de-promote products with status 'active' or 'modified'
		if (user.type === 'merchant' && (status === 'active' || status === 'modified')) {
			options.push({
				title: promote ? 'Despromocionar producto' : 'Promocionar producto',
				// email: `mailto:${configure.app.notifications_email}?subject=Solicitud ${
				// 	promote ? 'Despromocionar producto' : 'Promocionar producto'
				// }`,
				onClick: () => {
					addErrorMessage('Producto', 'Promocionar producto no implementado actualmente');
				},
			});
		}

		// Only 'admin' can promote or de-promote products with status 'active' or 'modified'
		if (user.type === 'admin') {
			options.push({
				title: 'Eliminar completamente de base de datos',
				onClick: () => {
					setProductToDelete({ id, title });
					setOpenModalHardDelete(true);
				},
			});
		}

		return options;
	};

	return (
		<Card className='mb-20'>
			<AuthorizationBoundary for={['admin']}>
				<div className='flex'>
					<Button
						className='mb-4 font-medium text-lg'
						as={Link}
						to='/dashboard/catalogo/productos/special-features'
					>
						Editar características especiales
					</Button>
					<Button
						className='mb-4 font-medium text-lg ml-4'
						as={Link}
						to='/dashboard/catalogo/productos/orden-destacados'
					>
						Ordenar productos en portada
					</Button>
				</div>
			</AuthorizationBoundary>
			<AuthorizationBoundary for={['admin', 'merchant']}>
				<DownloadCSVButton
					queryProps={{
						search: productSearch,
						scopes,
						order_scope: orderScope,
						promote: promoted,
						inHome: isInHome,
						CategoryId: selectedCategory,
					}}
				/>
			</AuthorizationBoundary>

			<AuthorizationBoundary for={['admin', 'merchant']}>
				{pageScope === 'all' ? (
					<LayoutHeader
						actions={
							user.type === 'merchant'
								? [CreateFeaturedProducts, CreateProductButton]
								: [CreateProductButton]
						}
					>
						Productos
					</LayoutHeader>
				) : null}
			</AuthorizationBoundary>
			<span className='flex w-full'>
				<SearchBox
					placeholder='Busca un Producto'
					search={handleSearch}
					loading={loading}
					clearSearch={handleClearSearch}
				/>
				<Pagination
					offset={offset}
					setOffset={setOffset}
					count={products?.count}
					hasMore={products?.hasMore}
					limit={paginationLimit}
				/>
			</span>
			<span className='flex'>
				<DropdownTree
					noneSelectedLabel='Filtrar categoría'
					treeData={categoriesSimpleTree(null, categoryData?.ProductCategories?.List)}
					initialValue=''
					onChange={handleSelectCategoryId}
					width='w-1/3'
					className='h-10 py-0 font-light'
					borderStyle='border-black'
					onClear={handleClearCategory}
				/>
				<div className='flex w-full flex-col self-end lg:flex-row'>
					<Check
						checked={promoted}
						onChange={handlePromotedProduct}
						className='ml-2 w-48 checked:bg-blue-600'
						label='Producto en promoción'
					/>
					<Check
						checked={isInHome}
						onChange={handleIsInHomeProduct}
						className='ml-2 w-48 checked:bg-blue-600'
						label={
							<div className='flex items-center'>
								Producto en portada
								<img src='/images/home.svg' alt='house' className=' w-4 h-4 ml-2' />
							</div>
						}
					/>
					<Check
						checked={merchantHighlighted}
						onChange={handleMerchantHighlightedProduct}
						className='ml-2 w-48 checked:bg-blue-600'
						label={
							<div className='flex items-center'>
								Producto destacado
								<img src='/images/star.svg' alt='house' className=' w-4 h-4 ml-2' />
							</div>
						}
					/>
				</div>
			</span>
			<AuthorizationBoundary for={['admin']}>
				<div className='w-full mt-6'>
					<SelectMerchantInput
						emptyValue={{ id: '', commercialName: 'Todos los asociados' }}
						onChange={handleSelectMerchant}
						width='w-1/3'
						label='Selecciona un asociado'
					/>
				</div>
			</AuthorizationBoundary>
			<LoadingView loading={loading} whileLoading='Buscando productos...'>
				<ContentTable
					key={22}
					columns={[
						['admin', 'merchant'].includes(user.type) ? (
							<Check
								checked={input.globalCheck}
								onChange={() => {
									// Toogle all the products
									setInput({
										globalCheck: !input.globalCheck,
										items: products.List.map(() => !input.globalCheck),
									});
								}}
								className='mt-4 checked:bg-blue-600'
							/>
						) : null,
						columnTitleElement('Cre/Modif.', orderByDate),
						user.type === 'admin' ? columnTitleElement('Asociado', orderByMerchant) : null,
						columnTitleElement('Producto', orderByTitle),
						columnTitleElement('Categoria'),
						columnTitleElement('Precio'),
						columnTitleElement('Estado', statusList && filterStatusList),
						columnTitleElement('Subestado', substatusList && filterSubstatusList),
						['admin', 'merchant'].includes(user.type) ? (
							<div className='right pr-6 font-bold'>
								<TooltipMenu menuList={globalMenuList} mt='mt-0' ml='ml-5' />
							</div>
						) : null,
					].filter(element => element !== null)}
					content={products?.List}
					render={(item, index) => (
						<tr
							key={index}
							className='border-t border-dashed border-coral-300 text-gray-700'
							aria-label='product-row'
						>
							<AuthorizationBoundary for={['admin', 'merchant']}>
								<td>
									<Check
										checked={input.items[index] || input.globalCheck}
										onChange={() => {
											// Toogle item on list
											const newItems = [...input.items];
											newItems[index] = !newItems[index];
											setInput({ ...input, items: newItems });
										}}
										className='checked:bg-blue-600'
									/>
								</td>
							</AuthorizationBoundary>
							<td className='px-4 py-2'>
								<p className='text-center'>{formatDate(item.createdAt)}</p>
								<p className='text-center'>{formatDate(item.updatedAt)}</p>
							</td>
							{user.type === 'admin' ? (
								<td className='px-4 py-2'>{item.Merchant?.commercialName}</td>
							) : null}
							<td className='px-4 py-4 flex justify-between items-center' aria-label='product-name'>
								<Link className='flex-1' to={`/dashboard/catalogo/productos/edit/${item.id}`}>
									{item.title}
								</Link>
								<div className='ml-2'>
									{item.inHome !== 0 && (
										<img src='/images/home.svg' alt='house' className=' w-4 h-4' />
									)}
									{item.merchantHighlight !== 0 && (
										<img src='/images/star.svg' alt='star' className='w-4 h-4' />
									)}
								</div>
							</td>
							<td className='px-4 text-left'>{item.Categories?.map(c => c.name).join(' > ')}</td>
							<td className='px-2 pr-8 text-right'>
								{item.Variants?.map(c => c.price + '€').join(', ')}
							</td>
							<td className='px-4 py-2'>{translasteStatus(item.status)}</td>
							<td className='px-4 py-2'>{translasteStatus(item.substatus)}</td>
							<AuthorizationBoundary for={['admin', 'merchant']}>
								<td className='px-6'>
									<div className='h-16' aria-label='dots-tooltip-menu'>
										<TooltipMenu
											menuList={getProductMenuList({
												id: item.id,
												status: item.status,
												promote: item.promote,
												isInHome: item.inHome != 0 ? true : false,
												title: item.title,
											})}
										/>
									</div>
								</td>
							</AuthorizationBoundary>
						</tr>
					)}
					empty={
						<p className='my-5 flex h-24 w-full items-center justify-center rounded-md border border-gray-400 text-gray-700 shadow-md'>
							No hay Productos
						</p>
					}
				/>
				{productToDelete?.id ? (
					<Modal
						modalObject={{
							title: 'Confirmación',
							description: (
								<>
									Al confirmar se eliminará por completo de la base de datos el producto{' '}
									<b>{productToDelete.title}</b> y no se podrá anular.
								</>
							),
							btnText: 'Confirmar',
							cancelBtnText: 'Cancelar',
						}}
						openModal={openModalHardDelete}
						setOpenModal={setOpenModalHardDelete}
						handleSubmit={async () => {
							deleteProduct({
								variables: {
									id: productToDelete.id,
								},
							}).then(res => {
								if (res.data.ProductDelete.success) {
									addSuccessMessage(
										'Producto',
										`${productToDelete.title} eliminado de base de datos correctamente`
									);
									const updatedProducts = products.List.filter(
										product => product.id !== productToDelete.id
									);
									setProducts(prev => ({
										...prev,
										List: updatedProducts,
									}));
								} else {
									addErrorMessage('Producto', res.data.ProductDelete.message);
								}
								setOpenModalHardDelete(false);
							});
						}}
					/>
				) : null}
			</LoadingView>
		</Card>
	);
};

export default ProductsList;
