import { useController } from 'react-hook-form';
import Specifications from './Specifications';

const CheckForm = ({
	name,
	onClick = () => {},
	control,
	label,
	className = '',
	disabled,
	specifications,
	noToogle = false,
}) => {
	const {
		field: { onChange, ...field },
	} = useController({ name, control });

	// Prevent React Warning about value being null
	if (field.value === null || field.value === undefined) {
		field.value = '';
	}

	return (
		<span className={`flex items-center ${className} relative`}>
			<input
				{...field}
				onClick={onClick}
				onChange={noToogle ? () => {} : onChange}
				className='h-5 w-5 cursor-pointer'
				type='checkbox'
				checked={field.value}
				disabled={disabled}
				readOnly
			/>
			<label htmlFor={name} className='ml-2'>
				{label}
			</label>
			<Specifications specifications={specifications} />
		</span>
	);
};

export default CheckForm;
