import ConditionalWrapper from 'components/ConditionalWrapper';
import Field from 'components/Field';
import React from 'react';
import { useController } from 'react-hook-form';

const TextareaForm = ({
	id,
	children,
	maxLength = '500',
	width = 'w-2/3',
	height = 'h-32',
	name,
	control,
	showRequiredIcon = false,
	simple,
	className,
	missingTranslations = false,
	...props
}) => {
	const { field } = useController({ name, control });

	// Prevent React Warning about value being null
	if (field.value === null || field.value === undefined) {
		field.value = '';
	}

	const isUntranslatedField = () => missingTranslations && !field.value;

	return (
		<>
			<Field align='items-start' mb={maxLength ? '' : 'mb-4'} showRequiredIcon={showRequiredIcon} {...props}>
				<ConditionalWrapper
					condition={children}
					wrapper={children => <div className={`flex ${width}`}>{children}</div>}
				>
					<textarea
						id={id}
						{...field}
						maxLength={maxLength}
						{...props}
						className={`border mt-1 p-4 text-lg rounded-md border-coral-300 outline-none focus:outline-none ${height} ${
							children ? 'w-full' : 'w-2/3'
						} ${
							props?.disabled
								? 'bg-gray-300 text-gray-500'
								: isUntranslatedField()
								? 'bg-coral-100'
								: 'bg-white'
						} ${className || ''}`}
					></textarea>
					{children}
				</ConditionalWrapper>
			</Field>
			{maxLength && (
				<div className={simple ? 'flex w-full' : 'flex w-full max-w-screen-md'}>
					{!simple && <div className='w-1/3'></div>}
					<p className='w-2/3 text-right text-gray-400 font-semibold italic'>
						<span className={field.value.length > maxLength ? 'text-red-600' : ''}>
							{field.value.length}
						</span>
						{` / ${maxLength}`}
					</p>
				</div>
			)}
		</>
	);
};

export default TextareaForm;
