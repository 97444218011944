import React from 'react';

export const LayoutTitle = ({ children, actions }) => (
	<div className='flex items-center md:mb-4 justify-between'>
		<span className='flex items-center'>
			<h2 className='md:text-xl md:font-semibold uppercase text-red-500 flex-1'>{children}</h2>
		</span>
		{actions && actions.map(action => action())}
	</div>
);

export const SectionTitle = ({ className, children }) => (
	<h2 className={`md:text-xl md:font-semibold ${className}`}>{children}</h2>
);

export default LayoutTitle;
