const semiParseJSON = (content, contentOfTypeEditor) => {
	// First Parse the whole JSON if needed
	let objRes = typeof content === 'string' && content !== '' ? JSON.parse(content) : content;

	// Stringify the specified keys
	for (var [key, value] of Object.entries(objRes)) {
		if (contentOfTypeEditor.has(key) && typeof value !== 'string') objRes[key] = JSON.stringify(value);
	}

	return objRes;
};

export default semiParseJSON;
