const saveAllImages = async (images, setImageAttributes, locale) => {
	const filteredImages = images.filter(image => !!image?.id);

	if (filteredImages.length > 0) {
		for (const image of filteredImages) {
			await setImageAttributes({
				variables: {
					imageId: image.id,
					attributes: { title: image.title, alt: image.alt },
					locale,
				},
			});
		}
	}
};

export default saveAllImages;
