import Field from 'components/Field';
import React from 'react';

const Select = ({ options, placeholder, width = 'w-48', disabled = false, ...props }) => {
	return (
		<div>
			<Field disabled={disabled} {...props}>
				<select
					{...props}
					className={`border border-coral-300 rounded-md mt-1 p-2 outline-none ${width}  ${
						disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
					} ${props.className || ''}`}
					placeholder={placeholder}
					disabled={disabled}
				>
					{placeholder && <option value=''>{placeholder}</option>}
					{options.map((option, index) => (
						<option value={returnValue(option)} key={option.id || index}>
							{option.name || option.commercialName}
						</option>
					))}
				</select>
			</Field>
		</div>
	);
};

const returnValue = option => {
	if (typeof option === 'object') {
		if (Object.prototype.hasOwnProperty.call(option, 'value')) return option.value;
		if (Object.prototype.hasOwnProperty.call(option, 'id')) return option.id;
	}
	return option;
};

export default Select;
