/* eslint-disable no-console */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

const IMAGE_INFO_FRAGMENT = gql`
	fragment MediaInfo on Media {
		id
		locale
		filename
		src
		title
		alt
	}
`;

const MEDIA_QUERY = gql`
	query Media($id: ID!, $forceLocale: Boolean, $locale: String) {
		Media(id: $id, forceLocale: $forceLocale, locale: $locale) {
			id
			locale
			src
			filename
			title
			alt
		}
	}
`;

const UPLOAD_IMAGES_MUTATION = gql`
	mutation UploadMediaFiles(
		$files: [Upload!]!
		$path: String
		$MerchantId: ID
		$ProductId: ID
		$galleryType: String
	) {
		UploadMediaFiles(
			files: $files
			path: $path
			MerchantId: $MerchantId
			ProductId: $ProductId
			galleryType: $galleryType
		) {
			... on MediaFiles {
				MediaFiles {
					...MediaInfo
				}
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
			... on StorageFault {
				message
				filename
			}
		}
	}
	${IMAGE_INFO_FRAGMENT}
`;

const DELETE_IMAGE_MUTATION = gql`
	mutation DeleteMediaFile($id: ID!) {
		DeleteMediaFile(id: $id) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
			... on StorageFault {
				message
				filename
			}
		}
	}
`;

const SET_IMAGE_ATTRIBUTES_MUTATION = gql`
	mutation SetImageAttributes($imageId: ID!, $attributes: MediaAttributes, $locale: String) {
		SetImageAttributes(imageId: $imageId, attributes: $attributes, locale: $locale) {
			... on Media {
				...MediaInfo
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
			... on StorageFault {
				message
				filename
			}
		}
	}
	${IMAGE_INFO_FRAGMENT}
`;

const MERCHANT_IMAGE_POSITION_MUTATION = gql`
	mutation MoveMerchantImagePosition($ImageId: ID!, $action: Int, $locale: String) {
		MoveMerchantImagePosition(ImageId: $ImageId, action: $action, locale: $locale) {
			... on MediaFiles {
				MediaFiles {
					...MediaInfo
				}
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
	${IMAGE_INFO_FRAGMENT}
`;

const PRODUCT_IMAGE_POSITION_MUTATION = gql`
	mutation MoveProductImagePosition($ImageId: ID!, $action: Int, $locale: String) {
		MoveProductImagePosition(ImageId: $ImageId, action: $action, locale: $locale) {
			... on MediaFiles {
				MediaFiles {
					...MediaInfo
				}
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
	${IMAGE_INFO_FRAGMENT}
`;

export const useMedia = variables => useQuery(MEDIA_QUERY, { variables, skip: !variables.id });
export const useLazyMedia = variables => useLazyQuery(MEDIA_QUERY, { variables, fetchPolicy: 'cache-and-network' });
export const useMultipleFileUpload = props => useMutation(UPLOAD_IMAGES_MUTATION, props);
export default useMultipleFileUpload;
export const useDeleteMediaFile = props => useMutation(DELETE_IMAGE_MUTATION, props);
export const useSetImageAttributes = props =>
	useMutation(SET_IMAGE_ATTRIBUTES_MUTATION, { ...props, fetchPolicy: 'no-cache' });
export const useMoveMerchantImagePosition = props => useMutation(MERCHANT_IMAGE_POSITION_MUTATION, props);
export const useMoveProductImagePosition = props => useMutation(PRODUCT_IMAGE_POSITION_MUTATION, props);
