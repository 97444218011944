import React, { useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import useConf from 'hooks/use-conf';

import useAuth from 'hooks/use-auth';

const Sidebar = () => {
	const [subMenu, setSubmenu] = useState(null);
	const { user, notificationCounter } = useAuth();
	const { type, status } = user;
	const { config } = useConf();

	let items = getItemsByUserType(type, notificationCounter);

	return (
		<div className='md:flex md:items-start'>
			<div className='sticky top-0 w-full md:h-[100vh] bg-gray-600 md:pt-5 overflow-auto z-10'>
				<ul className='sticky top-0 flex md:flex-col text-white w-full'>
					{items.map((item, index) => {
						if (user && type === 'merchant' && (status === 'revision' || status === 'pending')) {
							return <SidebarMainItem key={index} user={user} title={item.title} img={item.img} />;
						}
						return (
							<SidebarMainItem
								key={index}
								user={user}
								{...item}
								onClick={() => setSubmenu(index)}
								active={index === subMenu}
							/>
						);
					})}
					<Link
						className='text-white left-0 px-10 py-2 text-center md:mt-4 self-center'
						to={'/dashboard/versions'}
					>
						v.{`${config.app_version}`}
					</Link>
				</ul>
			</div>
			<SibarMainItemSubmenu
				className='flex md:flex-col md:w-48 md:py-3 md:px-5 text-gray-600 text-lg md:sticky md:top-0'
				menu={items}
				currentSubmenu={subMenu}
			/>
		</div>
	);
};

const SidebarMainItem = ({ title, href, active, img, className, onClick, user }) =>
	href ? (
		<Link
			id={`sidebar_${title}`}
			to={href}
			className={`font-medium flex flex-col justify-end md:justify-center items-center w-full p-3 md:my-1 text-center focus:outline-none ${
				href ? 'transition-all duration-100 hover:bg-gray-500' : ''
			} ${className || ''} ${active ? 'bg-gray-500' : ''}`}
			onClick={onClick}
		>
			{img && <img className='w-10 md:mt-1 mb-2' src={img} alt={title} />}
			<span className='font-light leading-3 text-xl'>{title}</span>
		</Link>
	) : (
		<button
			id={`sidebar_${title}`}
			className={`font-medium flex flex-col items-center w-full p-3 my-1 text-center focus:outline-none ${
				href ? 'transition-all duration-100 hover:bg-gray-500' : ''
			} ${className || ''} ${active && 'bg-gray-500'}`}
			onClick={onClick}
			disabled={user.type === 'merchant' && user.status === 'pending' ? true : false}
		>
			{img && <img className='w-10 mt-1 mb-2' src={img} alt={title} />}
			<span className='flex-1 leading-3 font-light text-xl'>{title}</span>
		</button>
	);

const SibarMainItemSubmenu = ({ menu, currentSubmenu, className }) => {
	const [selected, setSelected] = useState(null);
	const handleSelect = index => () => setSelected(index);

	return (
		menu[currentSubmenu]?.children?.length > 0 && (
			<div className='w-full h-full bg-gray-300 px-4 md:px-0'>
				<ul className={className}>
					{menu[currentSubmenu]?.children?.map((item, index) => (
						<li className='mt-2 md:mt-0 mb-2 md:mb-4 flex justify-between' key={index}>
							<span
								className={`font-semibold mr-2 ${selected === index ? 'text-black' : ''}`}
								onClick={handleSelect(index)}
							>
								{item.href ? <Link to={item.href}>{item.title}</Link> : item.title}
							</span>
							{item.counter && <span className='text-coral-600'>{item.counter}</span>}
							{item.children && (
								<ul>
									{item.children.map(item => (
										<SidebarLinkItem item={item} />
									))}
								</ul>
							)}
						</li>
					))}
				</ul>
			</div>
		)
	);
};

const SidebarLinkItem = ({ item }) => {
	const active =
		useMatch({
			path: item.href,
			end: true,
			exact: true,
		}) !== null;

	return (
		<li className={`text-gray-400 border-l-4 py-1 pl-2 font-semibold -mb-1 ${active ? 'border-coral-400' : ''}`}>
			<Link to={item.href}>{item.title}</Link>
		</li>
	);
};

export const Counter = ({ active, children }) => (
	// {count && <Counter active={active}>{count}</Counter>}
	<span
		className={`rounded-full text-white ml-auto py-1 px-2 text-xs transition-all duration-200 border-coral-700 ${
			!active && 'border bg-coral-600 shadow-md'
		}`}
	>
		{children}
	</span>
);

const adminItems = counter => [
	{
		title: 'Usuarios',
		img: '/images/user-icons/ico_asociados.svg',
		href: '/dashboard/asociados/list/all',
		children: [
			{
				title: 'Asociados',
				href: '/dashboard/asociados/list/all',
			},
			{
				title: 'Clientes',
				href: '/dashboard/clientes',
			},
			{
				title: 'Traductores',
				href: '/dashboard/traductores',
			},
		],
	},
	{
		title: 'Productos',
		img: '/images/user-icons/ico-productos.svg',
		href: '/dashboard/catalogo/productos',
	},
	{
		title: 'Pedidos',
		img: '/images/user-icons/ico-pedidos.svg',
		href: '/dashboard/pedidos',
		// children: [
		// 	{
		// 		title: 'Pedidos',
		// 		className: 'subtitle',
		// 		children: [
		// 			{
		// 				title: 'Recibidos',
		// 				href: '/dashboard/pedidos/recibidos',
		// 			},
		// 			{
		// 				title: 'Procesados',
		// 				href: '/dashboard/pedidos/procesados',
		// 			},
		// 			{
		// 				title: 'Enviados',
		// 				href: '/dashboard/pedidos/enviados',
		// 			},
		// 		],
		// 	},
		// 	{
		// 		title: 'Asociados',
		// 		className: 'subtitle',
		// 		children: [
		// 			{
		// 				title: 'Todos los Asociados',
		// 				href: '/dashboard/asociados/list/all',
		// 			},
		// 			{
		// 				title: 'Pendientes de Aprobación',
		// 				href: '/dashboard/asociados/list/pending',
		// 			},
		// 			{
		// 				title: 'Nuevo Asociado',
		// 				href: '/dashboard/asociados/new',
		// 			},
		// 		],
		// 	},
		// 	// {
		// 	// 	title: 'Informes',
		// 	// 	className: 'subtitle',
		// 	// 	children: [
		// 	// 		{
		// 	// 			title: 'Perfil de Cliente',
		// 	// 			href: '/dashboard/informes/perfil-cliente',
		// 	// 		},
		// 	// 		{
		// 	// 			title: 'Zonas de Venta',
		// 	// 			href: '/dashboard/informes/zonas-venta',
		// 	// 		},
		// 	// 		{
		// 	// 			title: 'Ventas realizadas',
		// 	// 			href: '/dashboard/informes/ventas-realizadas',
		// 	// 		},
		// 	// 		{
		// 	// 			title: 'Ingresos Recibidos',
		// 	// 			href: '/dashboard/informes/ingresos-recibidos',
		// 	// 		},
		// 	// 		{
		// 	// 			title: 'Ingresos Pendientes',
		// 	// 			href: '/dashboard/informes/ingresos-pendientes',
		// 	// 		},
		// 	// 	],
		// 	// },
		// 	// {
		// 	// 	title: 'Facturación',
		// 	// 	className: 'subtitle',
		// 	// 	children: [
		// 	// 		{
		// 	// 			title: 'Inversión en Publicidad',
		// 	// 			href: '/dashboard/facturacion/publicidad',
		// 	// 		},
		// 	// 		{
		// 	// 			title: 'Facturas',
		// 	// 			href: '/dashboard/facturacion/facturas',
		// 	// 		},
		// 	// 	],
		// 	// },
		// ],
	},
	{
		title: 'Devoluciones',
		img: '/images/user-icons/ico-devoluciones.svg',
		href: '/dashboard/devoluciones',
	},
	{
		title: 'Facturación',
		img: '/images/user-icons/ico-facturacion.svg',
		href: '/dashboard/facturacion',
		// children: [
		// 	{
		// 		title: 'Facturación',
		// 		className: 'subtitle',
		// 		children: [
		// 			{
		// 				title: 'Facturas pedidos clientes',
		// 				href: '/dashboard/facturacion/clientes',
		// 			},
		// 			{
		// 				title: 'Facturas Asociados',
		// 				href: '/dashboard/facturacion/asociados',
		// 			},
		// 		],
		// 	},
		// ],
	},
	{
		title: 'Revisión',
		img: '/images/user-icons/ico_revision.svg',
		href: '/dashboard/revision/productos',
		children: [
			{
				title: 'Productos',
				href: '/dashboard/revision/productos',
				counter: counter.productsCount,
			},
			{
				title: 'Asociados',
				href: '/dashboard/revision/asociados',
				counter: counter.merchantsCount,
			},
			{
				title: 'Eventos',
				href: '/dashboard/revision/eventos',
			},
		],
	},
	{
		title: 'Estadísticas',
		img: '/images/user-icons/ico_estadisticas.svg',
		href: '/dashboard/estadisticas',
	},
	{
		title: 'Personalizar tienda',
		img: '/images/user-icons/ico-ajustes.svg',
		href: '/dashboard/personalizar-tienda',
	},
	{
		title: 'CRM',
		img: '/images/zoho-icons/crm-white.svg',
		href: '/dashboard/crm',
	},
	// {
	// 	title: 'Marketing',
	// 	img: '/images/user-icons/ico-marketing.svg',
	// 	href: '/dashboard/catalogo/marketing',
	// 	// children: [
	// 	// 	{
	// 	// 		title: 'Newsletter',
	// 	// 	},
	// 	// 	{
	// 	// 		title: 'CRM Externo',
	// 	// 	},
	// 	// 	{
	// 	// 		title: 'Promociones',
	// 	// 		children: [
	// 	// 			{
	// 	// 				title: 'Promocionar nuevo Producto',
	// 	// 			},
	// 	// 			{
	// 	// 				title: 'Creación de Vale',
	// 	// 			},
	// 	// 			{
	// 	// 				title: 'Listado de productos promocionados',
	// 	// 			},
	// 	// 		],
	// 	// 	},
	// 	// ],
	// },
	// { title: 'Productos', img: '/images/user-icons/ico-productos.svg', href: '/dashboard/catalogo/productos' },
	// { title: 'Experiencias', img: '/images/user-icons/ico-experiencias.svg', href: '/dashboard/experiencias' },
	// { title: 'Promociones', img: '/images/user-icons/ico-promociones.svg', href: '/dashboard/promociones' },
	// {
	// 	title: 'Ajustes',
	// 	img: '/images/user-icons/ico-ajustes.svg',
	// 	// href: '/dashboard/ajustes',
	// 	children: [
	// 		{
	// 			title: 'Mi Cuenta',
	// 			children: [
	// 				{
	// 					title: 'Mi cuenta',
	// 					href: `/dashboard/account`,
	// 				},
	// 			],
	// 		},
	// 		{ title: 'FAQ', href: '/dashboard/faq' },
	// 		{
	// 			title: 'Categorías',
	// 			children: [
	// 				{ title: 'Listado Categorías', href: '/dashboard/catalogo/categorias' },
	// 				{ title: 'Nueva Categoría', href: '/dashboard/catalogo/categorias/new' },
	// 			],
	// 		},
	// 	],
	// },
];

const merchantItems = (/*{ user } */) => [
	{
		title: 'Pedidos',
		img: '/images/user-icons/ico-pedidos.svg',
		href: '/dashboard/pedidos',
	},
	{
		title: 'Devoluciones',
		img: '/images/user-icons/ico-devoluciones.svg',
		href: '/dashboard/devoluciones',
	},
	{
		title: 'Productos',
		img: '/images/user-icons/ico-productos.svg',
		href: '/dashboard/catalogo/productos',
	},
	{
		title: 'Facturación',
		img: '/images/user-icons/ico-facturacion.svg',
		href: '/dashboard/facturacion',
		// children: [
		// 	{
		// 		title: 'Facturación',
		// 		className: 'subtitle',
		// 		children: [
		// 			{
		// 				title: 'Facturas pedidos clientes',
		// 				href: '/dashboard/facturacion/clientes',
		// 			},
		// 			{
		// 				title: 'Facturas Asociados',
		// 				href: '/dashboard/facturacion/asociados',
		// 			},
		// 		],
		// 	},
		// ],
	},
	{
		title: 'Estadísticas',
		img: '/images/user-icons/ico_estadisticas.svg',
		href: '/dashboard/estadisticas',
	},
	// {
	// 	title: 'Administración',
	// 	img: '/images/user-icons/ico-administracion.svg',
	// 	children: [
	// 		{
	// 			title: 'Pedidos',
	// 			className: 'subtitle',
	// 			children: [
	// 				{
	// 					title: 'Recibidos',
	// 					href: '/dashboard/pedidos/recibidos',
	// 				},
	// 				{
	// 					title: 'Procesados',
	// 					href: '/dashboard/pedidos/procesados',
	// 				},
	// 				{
	// 					title: 'Enviados',
	// 					href: '/dashboard/pedidos/enviados',
	// 				},
	// 			],
	// 		},
	// 		{
	// 			title: 'Informes',
	// 			className: 'subtitle',
	// 			children: [
	// 				{
	// 					title: 'Perfil de Cliente',
	// 					href: '/dashboard/informes/perfil-cliente',
	// 				},
	// 				{
	// 					title: 'Zonas de Venta',
	// 					href: '/dashboard/informes/zonas-venta',
	// 				},
	// 				{
	// 					title: 'Ventas realizadas',
	// 					href: '/dashboard/informes/ventas-realizadas',
	// 				},
	// 				{
	// 					title: 'Ingresos Recibidos',
	// 					href: '/dashboard/informes/ingresos-recibidos',
	// 				},
	// 				{
	// 					title: 'Ingresos Pendientes',
	// 					href: '/dashboard/informes/ingresos-pendientes',
	// 				},
	// 			],
	// 		},
	// 		{
	// 			title: 'Facturación',
	// 			className: 'subtitle',
	// 			children: [
	// 				{
	// 					title: 'Inversión en Publicidad',
	// 					href: '/dashboard/facturacion/publicidad',
	// 				},
	// 				{
	// 					title: 'Facturas',
	// 					href: '/dashboard/facturacion/facturas',
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	// {
	// 	title: 'Promociones',
	// 	img: '/images/user-icons/ico-experiencias.svg',
	// 	href: '/dashboard/catalogo/promociones',
	// 	// children: [
	// 	// 	{
	// 	// 		title: 'Promocionar nuevo producto',
	// 	// 	},
	// 	// 	{
	// 	// 		title: 'Creación de Vale',
	// 	// 	},
	// 	// 	{
	// 	// 		title: 'Listado de productos promocionados',
	// 	// 	},
	// 	// ],
	// },
	// { title: 'Experiencias', img: '/images/user-icons/ico-experiencias.svg', href: '/dashboard/experiencias' },
	// { title: 'Promociones', img: '/images/user-icons/ico-promociones.svg', href: '/dashboard/promociones' },
	// {
	// 	title: 'Ajustes',
	// 	img: '/images/user-icons/ico-ajustes.svg',
	// 	children: [
	// 		{
	// 			title: 'Mi Cuenta',
	// 			href: '/dashboard/account',
	// 			children: [
	// 				{
	// 					title: 'Ficha administrativa',
	// 					href: `/dashboard/edit/administrative`,
	// 				},
	// 				{
	// 					title: 'Ficha comercial',
	// 					href: `/dashboard/edit/commercial`,
	// 				},
	// 				{
	// 					title: 'Mi cuenta',
	// 					href: `/dashboard/account`,
	// 				},
	// 			],
	// 		},
	// 	],
	// },
];

const translatorItems = () => [
	{
		title: 'Asociados',
		img: '/images/user-icons/ico_asociados.svg',
		href: '/dashboard/asociados/list/all',
	},
	{
		title: 'Productos',
		img: '/images/user-icons/ico-productos.svg',
		href: '/dashboard/catalogo/productos',
	},
];

const getItemsByUserType = (type, notificationCounter) => {
	switch (type) {
		case 'admin':
			return adminItems(notificationCounter);
		case 'merchant':
			return merchantItems();
		case 'translator':
			return translatorItems();
		default:
			return [];
	}
};

export default Sidebar;
