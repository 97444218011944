import Apollo from 'components/Apollo';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fontsource/barlow-condensed/latin.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'tw-elements';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { AuthProvider } from 'hooks/use-auth';
import { useConf, ConfProvider } from 'hooks/use-conf';
import Toast from 'components/Toast';

const DynamicHead = () => {
	const { config } = useConf();

	return (
		<HelmetProvider>
			<Helmet>
				<link rel='icon' href={`${config.statics}/mp/favicon.svg`} />
				<meta name='description' content={`${config.marketplace_name}`} />
				<link rel='apple-touch-icon' href={`${config.statics}/mp/logo192.png`} />
				{/* manifest.json provides metadata used when your web app is installed on a
					user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/ */}
				<link rel='manifest' href={`${config.statics}/mp/manifest.json`} />
				<title>{config.marketplace_name} - Panel de Administración</title>
			</Helmet>
		</HelmetProvider>
	);
};

ReactDOM.render(
	<React.StrictMode>
		<Apollo>
			<ConfProvider>
				<DynamicHead />
				<AuthProvider>
					<Toast>
						<App />
					</Toast>
				</AuthProvider>
			</ConfProvider>
		</Apollo>
	</React.StrictMode>,
	document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
