import './DropdownTree.css';

import { createPopper } from '@popperjs/core';
import React, { createRef, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import TreeMenu from 'react-simple-tree-menu';
import { useToast } from 'components/Toast';

const getObjectWithKey = (nodes, key) => {
	// Search key in the current level (array received)
	const found = nodes.filter(node => node.key === key);

	if (found.length > 0) {
		// Return the first found node in the array
		return found[0];
	}

	// Now search the children
	for (const node of nodes) {
		if (Object.prototype.hasOwnProperty.call(node, 'nodes')) {
			let result = getObjectWithKey(node.nodes, key);
			if (result) {
				return result;
			}
		}
	}
	// Not found
	return null;
};

const getKeyLabel = (nodes, key) => {
	// Search the key in the whole node tree
	const node = getObjectWithKey(nodes, key);

	return node?.label;
};

const getPath = (nodes, key) => {
	// Search the key in the whole node tree
	const node = getObjectWithKey(nodes, key);
	return node ? node.path : '';
};

const DropdownTreeForm = ({
	treeData,
	initialValue,
	noneSelectedLabel,
	disabled,
	onChange,
	selectCategoryParent = false,
	width = 'w-2/3',
	name,
	control,
	productCategory,
	...props
}) => {
	const { addErrorMessage } = useToast();
	// dropdown props
	const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
	const [value, setValue] = useState(noneSelectedLabel);
	const [activePath, setActivePath] = useState('0');

	const { field } = useController({ name, control, rules: { required: props.required ?? false } });

	if (noneSelectedLabel && selectCategoryParent) {
		const noneElement = {
			key: 0,
			label: noneSelectedLabel,
		};
		treeData = [noneElement, ...treeData];
	}

	useEffect(() => {
		if (initialValue && Array.isArray(treeData) && treeData.length > 1 && value === noneSelectedLabel) {
			setValue(getKeyLabel(treeData, initialValue));
			setActivePath(getPath(treeData, initialValue));
		}
	}, [initialValue, treeData, value, noneSelectedLabel]);

	const btnDropdownRef = createRef();
	const popoverDropdownRef = createRef();
	const treeRef = createRef();

	const openDropdownPopover = () => {
		createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
			placement: 'bottom-start',
		});
		setDropdownPopoverShow(true);
	};

	const handleSelectElement = selectedNode => {
		if (selectCategoryParent) {
			// Check path of the selectedNode is not a children
			// Parent category can not be set to anything in the same category children nor itself
			if (productCategory?.id && new RegExp(`\\b${productCategory?.id}\\b`).test(selectedNode.path)) {
				addErrorMessage('Categoría', 'No puede asignar a su propia categoría o sub-categoría');
				return;
			}
			setValue(selectedNode.label);
			onChange(selectedNode.key.split('/').pop());
			setDropdownPopoverShow(false);
		} else {
			if (!selectedNode.hasNodes) {
				setValue(selectedNode.label);
				onChange(selectedNode.key.split('/').pop());
				setDropdownPopoverShow(false);
			} else {
				const ops = selectedNode.openNodes;
				// Clicked element with children: toggle open/close
				treeRef.current.resetOpenNodes(
					ops.includes(selectedNode.path)
						? ops.filter(op => op !== selectedNode.path) // Remove element from array
						: [...ops, selectedNode.path] // Add element to array
				);
			}
		}
		setActivePath(selectedNode.path);
	};

	// When we lose focus we just close the DropdownTreeForm
	// Close when click outside the DropdownTreeForm
	const handleOutsideClick = e => {
		if (!e.currentTarget.contains(e.relatedTarget)) {
			setDropdownPopoverShow(false);
		}
	};

	const handleDropDownClick = () => (dropdownPopoverShow ? setDropdownPopoverShow(false) : openDropdownPopover());
	const pathsToOpen = activePath?.split('/').map((key, idx, arr) => arr.slice(0, idx + 1).join('/'));
	return (
		<div onBlur={handleOutsideClick} className={width}>
			<div className='relative inline-flex align-middle w-full'>
				<button
					className={`${
						disabled && 'bg-gray-300 text-gray-500'
					} focus:outline-none border border-coral-300 rounded-md mt-1 p-2 outline-none w-full false py-2 px-4 text-lg text-left`}
					disabled={disabled}
					type='button'
					ref={btnDropdownRef}
					onClick={handleDropDownClick}
				>
					{value}
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className='absolute top-0 right-0 mr-1 mt-4 h-4 w-4'
						fill='none'
						viewBox='0 0 24 24'
						stroke='currentColor'
					>
						<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
					</svg>
				</button>
				<div
					ref={popoverDropdownRef}
					className={
						(dropdownPopoverShow ? 'block ' : 'hidden ') +
						'w-full max-h-20 bg-white text-base z-30 float-left py-2 list-none text-left rounded shadow-lg mt-1'
					}
				>
					<TreeMenu
						{...field}
						disabled={disabled}
						activeKey={activePath}
						initialOpenNodes={pathsToOpen}
						cacheSearch
						data={treeData}
						debounceTime={125}
						disableKeyboard={false}
						hasSearch
						onClickItem={handleSelectElement}
						resetOpenNodesOnDataUpdate={true}
						ref={treeRef}
					/>
				</div>
			</div>
		</div>
	);
};

export default DropdownTreeForm;
