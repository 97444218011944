import React, { useEffect, useState } from 'react';
import ContentTable from 'components/ContentTable';
import LoadingView from 'components/LoadingView';
import translasteStatus from 'lib/translasteStatus';

import { Link, useParams, useNavigate } from 'react-router-dom';

import useAuth from 'hooks/use-auth';
import { useLazyMerchants } from 'hooks/use-merchants';

import Button from 'components/Button';
import Card from 'components/Card';
import LayoutHeader from 'components/LayoutHeader';
import Pagination from 'components/Pagination';
import SearchBox from 'components/SearchBox';
import { useToast } from 'components/Toast';
import TooltipMenu from 'components/TooltipMenu';
import columnTitleElement from 'lib/columnTitleElement';
import Currency from './Currency';
import AuthorizationBoundary from './AuthorizationBoundary';
import useConf from 'hooks/use-conf';
import isSuccessResponse from 'lib/isSuccessResponse';

const paginationLimit = 50;

const MerchantsList = ({ statusList, pageScope }) => {
	const { config } = useConf();
	const { addErrorMessage, addSuccessMessage } = useToast();
	const { status: paramScope } = useParams();
	const { setUserData, useUpdateUser, useImpersonateUser, user, logOut } = useAuth();
	const [scope, setScope] = useState(pageScope || paramScope);
	const [orderScope, setOrderScope] = useState('commercialName_asc');
	const [merchants, setMerchants] = useState(null);
	const [merchantSearch, setMerchantSearch] = useState('');
	const [offset, setOffset] = useState(0);
	const navigate = useNavigate();

	const [getLazyMerchants, { data, loading }] = useLazyMerchants({
		search: merchantSearch,
		scope,
		order_scope: orderScope,
		limit: paginationLimit,
		offset,
		locale: config.default_locale,
	});
	const [updateUser] = useUpdateUser({
		onCompleted: ({ ProfileUpdate }) => {
			if (ProfileUpdate.id) {
				setOffset(0);
				getLazyMerchants();
			}
		},
	});

	const [impersonateUser] = useImpersonateUser({
		onCompleted: ({ ImpersonateUser }) => {
			if (isSuccessResponse(ImpersonateUser, ['User'], logOut, addErrorMessage, 'Impersonar asociado')) {
				setUserData(ImpersonateUser);
				addSuccessMessage('Asociado', `'${ImpersonateUser.firstName}' impersonado correctamente`);
				navigate('/dashboard');
			}
		},
	});

	// Excecute once at page load
	useEffect(() => {
		getLazyMerchants();
	}, [getLazyMerchants]);

	// Excecute after first load and on search
	useEffect(() => {
		if (data && isSuccessResponse(data.Merchants, ['MerchantList'], logOut, addErrorMessage, 'Asociados')) {
			setMerchants(data.Merchants);
		}
	}, [data, addErrorMessage]);

	const handleSearch = input => {
		setMerchantSearch(input);
		setOffset(0);
		getLazyMerchants();
	};

	const handleClearSearch = () => {
		setMerchantSearch('');
		setOffset(0);
		getLazyMerchants();
	};

	const merchantMenuList = ({ merchantId, userId, userStatus }) => {
		const list = [
			{ title: 'Editar', href: `/dashboard/asociados/edit/${merchantId}` },
			{
				title: 'Impersonar',
				onClick: () => {
					impersonateUser({ variables: { id: userId } });
				},
			},
		];

		if (userStatus !== 'active') {
			list.push({
				title: 'Activar',
				onClick: () => {
					updateUser({
						variables: {
							id: userId,
							input: { status: 'active' },
						},
					}).then(({ data }) => {
						if (data?.ProfileUpdate?.id) {
							addSuccessMessage('Asociado', 'Activado correctamente');
						}
					});
				},
			});
		}

		if (userStatus !== 'inactive') {
			list.push({
				title: 'Desactivar',
				onClick: () => {
					updateUser({
						variables: {
							id: userId,
							input: { status: 'inactive' },
						},
					}).then(({ data }) => {
						if (data?.ProfileUpdate?.id) {
							addSuccessMessage('Asociado', 'Desactivado correctamente');
						}
					});
				},
			});
		}
		return list;
	};

	const orderByDate = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'created_asc' },
			{ label: 'Descendente', scope: 'created_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyMerchants();
			},
		}));
	};

	const orderByCommercialName = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'commercialName_asc' },
			{ label: 'Descendente', scope: 'commercialName_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyMerchants();
			},
		}));
	};

	const filterByStatus = () => {
		return statusList?.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setScope(scope);
				setOffset(0);
				getLazyMerchants();
			},
		}));
	};

	const showCommission = merchant => {
		if (merchant.entranceFee > 0) {
			return merchant.meetRequirements ? (
				<p className='text-green-400 flex'>
					<Currency>{merchant.entranceFee}</Currency>
					<span className='ml-2'>(pagado)</span>
				</p>
			) : (
				<p className='text flex'>
					<Currency>{merchant.entranceFee}</Currency>
					<span className='ml-2'>(pendiente)</span>
				</p>
			);
		}

		return <p className='font-normal'>Sin cuota</p>;
	};

	const columnTitles =
		user?.type === 'admin'
			? [
					columnTitleElement('Fecha alta', orderByDate),
					columnTitleElement('Asociado', orderByCommercialName),
					columnTitleElement('Cuota Adhesión '),
					columnTitleElement('Comisión (%)'),
					columnTitleElement('Estado', statusList && filterByStatus),
			  ]
			: [
					columnTitleElement('Fecha alta', orderByDate),
					columnTitleElement('Asociado', orderByCommercialName),
					columnTitleElement('Estado', statusList && filterByStatus),
			  ];

	return (
		<Card className='mb-20'>
			<AuthorizationBoundary for={['admin', 'translator']}>
				<LayoutHeader actions={user.type === 'admin' ? [CreateMerchantButton] : null}>
					Listado de Asociados
				</LayoutHeader>
				<span className='flex w-full justify-between'>
					<SearchBox
						placeholder='Busca un Asociado'
						search={handleSearch}
						loading={loading}
						clearSearch={handleClearSearch}
					/>
					<Pagination
						offset={offset}
						setOffset={setOffset}
						count={merchants?.count}
						hasMore={merchants?.hasMore}
						limit={paginationLimit}
					/>
				</span>
				<LoadingView loading={loading} whileLoading='Buscando asociados…' className='text-gray-600'>
					<ContentTable
						key={21}
						columns={columnTitles}
						content={merchants?.List}
						render={(i, index) =>
							i && (
								<tr
									className='text-gray-700 border-t border-dashed border-coral-300'
									key={index}
									data-id={`merchant-${i.id}`}
								>
									<td className='px-4 py-2'>{new Date(i.createdAt).toLocaleDateString()}</td>
									<td className='px-4 py-2'>
										<Link to={`/dashboard/asociados/edit/${i.id}`}>{i.commercialName}</Link>
									</td>
									{user.type == 'admin' ? (
										<td className='px-4 py-2 font-semibold'>{showCommission(i)}</td>
									) : null}
									{user.type == 'admin' ? <td className='px-4 py-2'>{i.commission}</td> : null}
									<td id={`status_${i.User?.email}`} className='px-4 py-2'>
										{translasteStatus(i.User?.status)}
									</td>
									{user.type == 'admin' ? (
										<td className='px-6'>
											<div className='h-16' aria-label='dots-tooltip-menu'>
												<TooltipMenu
													menuList={merchantMenuList({
														merchantId: i.id,
														userId: i.User?.id,
														userStatus: i.User?.status,
													})}
												/>
											</div>
										</td>
									) : null}
								</tr>
							)
						}
						empty={<p className='text-center text-gray-600'>No hay Asociados que mostrar</p>}
					/>
				</LoadingView>
			</AuthorizationBoundary>
		</Card>
	);
};

const CreateMerchantButton = () => (
	<Button as={Link} to='/dashboard/asociados/new'>
		Nuevo Asociado
	</Button>
);

export default MerchantsList;
