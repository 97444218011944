import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import { useToast } from 'components/Toast';
import { useLazyProduct, useUpdateProduct } from 'hooks/use-products';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ProductForm from './ProductForm';
import useAuth from 'hooks/use-auth';
import EventList from 'components/EventList';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import useConf from 'hooks/use-conf';
import isSuccessResponse from 'lib/isSuccessResponse';
import { useRevalidateProduct } from 'lib/revalidateProduct';

const ProductEdit = () => {
	const { config } = useConf();
	const { user, locale, getLazyNotificationCount, logOut } = useAuth();

	const { productId } = useParams();
	const [getLazyProduct] = useLazyProduct();
	const [dirty, setDirty] = useState(false);
	const [forceDirty, setForceDirty] = useState(false);
	const [loading, setLoading] = useState(true);
	const [productData, setProductData] = useState(null);
	const eventsRef = useRef(null);
	const handleEventsFocus = () => {
		eventsRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
	};
	const revalidateProduct = useRevalidateProduct();
	// Toast alert
	const { addErrorMessage } = useToast();

	// Excecute once at page load
	useEffect(() => {
		// query Product fields with specific locale
		getLazyProduct({
			variables: { id: productId, forceLocale: true, locale },
			onCompleted: ({ Product }) => {
				if (isSuccessResponse(Product, ['Product'], logOut, addErrorMessage, 'Editar producto')) {
					setLoading(false);
					setProductData(Product);
				}
			},
		});
		setDirty(false);
		setForceDirty(false);
	}, [locale, productId]);

	const [updateProduct] = useUpdateProduct({
		onCompleted: ({ ProductUpdate }) => {
			if (isSuccessResponse(ProductUpdate, ['Product'], logOut, addErrorMessage, 'Editar producto')) {
				if (user?.type === 'admin') {
					getLazyNotificationCount();
				}
				setDirty(false);
				// If all correct revalidate (refresh) the product in the shop
				revalidateProduct(config, ProductUpdate, addErrorMessage);
			}
		},
	});

	const handleCopyAnotherLanguage = () => {
		setLoading(true);
		setDirty(true);
		setForceDirty(true);
		// query Product fields with any translation available
		getLazyProduct({
			variables: { id: productId, forceLocale: false, locale },
			onCompleted: ({ Product }) => {
				if (isSuccessResponse(Product, ['Product'], logOut, addErrorMessage, 'Editar producto')) {
					setLoading(false);
					setProductData(Product);
				}
			},
		});
	};

	useEffect(() => {
		window.addEventListener('beforeunload', checkFormIsDirty);

		return () => {
			window.removeEventListener('beforeunload', checkFormIsDirty);
		};
	}, [dirty]);

	const checkFormIsDirty = e => {
		if (dirty) {
			e.preventDefault();
			if (e) {
				e.returnValue = ''; // Legacy method for cross browser support
			}
			return ''; // Legacy method for cross browser support
		}
	};

	return (
		<>
			<LanguageTabs dirty={dirty} />
			<Card>
				<LayoutHeader>Editar Producto</LayoutHeader>
				<p className='text-lg italic md:mb-4'>
					Los campos marcados con un asterisco (<span className='text-coral-500 font-semibold'>*</span>) son
					obligatorios
				</p>
				<LoadingView whileLoading='Cargando el producto' loading={loading}>
					<ProductForm
						product={productData}
						onSubmit={async (input, onCompleted) =>
							await updateProduct({ variables: { id: productId, input, locale }, onCompleted })
						}
						setDirty={setDirty}
						statusList={statusList}
						substatusList={substatusList}
						handleEventsFocus={handleEventsFocus}
						handleCopyAnotherLanguage={handleCopyAnotherLanguage}
						dirty={dirty}
						forceDirty={forceDirty}
						getLazyProduct={getLazyProduct}
					/>
				</LoadingView>
			</Card>
			<AuthorizationBoundary for={['admin', 'merchant']}>
				{productData?.Events ? (
					<Card className='my-4 accordion'>
						<EventList
							events={productData.Events?.filter(event => event.message !== null)}
							eventsOnly
							focusRef={eventsRef}
						/>
					</Card>
				) : null}
			</AuthorizationBoundary>
		</>
	);
};

// Status list
const statusList = [
	{ value: 'active', name: 'Activo' },
	{ value: 'inactive', name: 'Inactivo' },
	{ value: 'revision', name: 'Revisión' },
	{ value: 'modified', name: 'Modificado' },
	{ value: 'deleted', name: 'Descatalogado' },
];

const substatusList = [
	{ value: 'correct', name: 'Correcto' },
	{ value: 'issue', name: 'Pendiente de correcciones' },
	{ value: 'requiredFields', name: 'Faltan campos requeridos' },
	{ value: 'untranslated', name: 'Faltan traducciones' },
];

export default ProductEdit;
