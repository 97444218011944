import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from 'components/SortableItem';
import Modal from 'components/Modal';
import Button from 'components/Button';
import ComboBox from 'components/ComboBox';
import CloseButton from 'components/CloseButton';
import { useSortMerchantProductsHighlight } from 'hooks/use-products';
import useAuth from 'hooks/use-auth';
import { useToast } from 'components/Toast';
import isSuccessResponse from 'lib/isSuccessResponse';
import LoadingView from './LoadingView';
import { useLazyProducts } from 'hooks/use-products';
import webhook from 'lib/webhook.js';
import useConf from 'hooks/use-conf';

const FeaturedProducts = ({ merchantId, getLazyProducts }) => {
	const [openModal, setOpenModal] = useState(false);
	const [products, setProducts] = useState([]);
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
	const [highlightedProducts, setHighlightedProducts] = useState([]);
	const [otherProducts, setOtherProducts] = useState([]);
	const [SortMerchantProductsHighlight] = useSortMerchantProductsHighlight();
	const { logOut } = useAuth();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [getProducts] = useLazyProducts({
		scope: 'active',
		MerchantId: merchantId,
	});
	const { config } = useConf();

	useEffect(() => {
		if (products.length > 0) {
			const filteredProducts = products.filter(product => product.merchantHighlight !== 0);
			const orderedProducts = filteredProducts.sort((a, b) => a.merchantHighlight - b.merchantHighlight);
			setHighlightedProducts(orderedProducts);

			// Set other products to be all products that are not highlighted and normalize the name to be used in the select component
			setOtherProducts(
				products
					.filter(product => product.merchantHighlight === 0)
					.map(product => ({
						...product,
						name: product.title,
					}))
			);
		}
	}, [products]);

	const handleSelectChange = event => {
		const selectedOption = otherProducts?.find(product => product.id === event.target.value);
		if (selectedOption) {
			if (highlightedProducts.length < 4) {
				setHighlightedProducts(prev => [...prev, selectedOption]);
				setOtherProducts(prev => prev.filter(product => product.id !== selectedOption.id));
			} else {
				alert('You can only have 4 featured products.');
			}
		}
	};

	const handleDragEnd = event => {
		const { active, over } = event;

		if (active && over) {
			setHighlightedProducts(prev => {
				const oldIndex = prev.findIndex(product => product.id === active.id);
				const newIndex = prev.findIndex(product => product.id === over.id);

				if (oldIndex !== -1 && newIndex !== -1) {
					const newProducts = [...prev];
					const [removed] = newProducts.splice(oldIndex, 1);
					newProducts.splice(newIndex, 0, removed);

					return newProducts;
				}

				return prev;
			});
		}
	};

	const handleDelete = (event, product) => {
		event.stopPropagation();
		setHighlightedProducts(prev => prev.filter(p => p.id !== product.id));
		setOtherProducts(prev => [...prev, { ...product, name: product.title }]);
	};

	const handleSubmit = () => {
		const updatedMerchantHighlightedProducts = highlightedProducts.map((product, index) => {
			return { id: product.id, merchantHighlight: index + 1 };
		});

		SortMerchantProductsHighlight({
			variables: { products: updatedMerchantHighlightedProducts, merchantId: merchantId },
		}).then(({ data }) => {
			if (
				isSuccessResponse(
					data?.SortMerchantProductsHighlight,
					['SortMerchantProductsHighlight'],
					logOut,
					addErrorMessage,
					'Error al ordenar productos destacados'
				)
			) {
				addSuccessMessage('Éxito', 'Los productos destacados se han ordenado correctamente');
				webhook(
					config,
					config.locales.map(locale => `/${locale}/asociados/${data.SortMerchantProductsHighlight.slug}`)
				)
					.then(() => {
						addSuccessMessage('Ficha Comercial', 'Asociado actualizado correctamente');
					})
					.catch(error => {
						addErrorMessage(
							'Ficha Comercial',
							`Asociado actualizado correctamente, pero no se pudo revalidar en la tienda: "${error.message}"`
						);
					});
				setOpenModal(false);
				getLazyProducts();
			}
		});
	};

	return (
		<>
			<Button
				onClick={() => {
					getProducts().then(({ data }) => {
						setProducts(data.Products.List);
					});
					setOpenModal(true);
				}}
			>
				Ordenar productos destacados
			</Button>
			<Modal
				modalObject={{
					title: '',
					description: '',
					btnText: 'Confirmar',
					cancelBtnText: 'Cancelar',
				}}
				openModal={openModal}
				setOpenModal={setOpenModal}
				handleSubmit={handleSubmit}
			>
				<LoadingView loading={!products} whileLoading='Cargando productos destacados...'>
					<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
						<div className='flex flex-col justify-center w-full h-full gap-4 overflow-hidden'>
							<div className='flex flex-col w-full gap-2'>
								<h3 className='text-lg font-semibold'>Productos Destacados</h3>
								<p className=' text-gray-500'>
									Define hasta 4 productos que se mostrarán primero en tus secciones
								</p>

								<SortableContext
									items={highlightedProducts.map(product => product.id)}
									strategy={rectSortingStrategy}
								>
									<div className='flex w-full gap-2'>
										{highlightedProducts.map((product, index) => (
											<SortableItem key={product.id} id={product.id}>
												<div className='relative flex flex-col w-full h-32 rounded-md border shadow-md text-sm px-2 py-2 group'>
													<div className='absolute top-0 left-0 w-5 h-5 text-center border-r border-b bg-white m-1'>
														{index + 1}
													</div>
													<img
														className='w-full h-full object-cover'
														src={product.DefaultImage.src}
														alt={product.title}
													/>
													<CloseButton
														onClick={event => handleDelete(event, product)}
														className='bg-coral-500 opacity-0 group-hover:opacity-100'
													/>
												</div>
												<div className='text-center  break-words w-full h-12'>
													{product.title}
												</div>
											</SortableItem>
										))}
									</div>
								</SortableContext>
							</div>
							<div className='flex w-full gap-2'>
								<div className='w-full h-[60%]'>
									<h3 className='text-lg font-semibold mb-2'>Añadir Productos Destacados</h3>
									<ComboBox
										options={otherProducts}
										placeholder={'Selecciona un producto'}
										onChange={handleSelectChange}
										width={'w-1/2'}
									/>
								</div>
							</div>
						</div>
					</DndContext>
				</LoadingView>
			</Modal>
		</>
	);
};

export default FeaturedProducts;
