import Button from 'components/Button';
import CheckForm from 'components/CheckForm';
import DropImageArea from 'components/DropImageArea';
import InputForm from 'components/InputForm';
import LayoutHeader from 'components/LayoutHeader';
import PasswordManagement from 'components/PasswordManagement';
import Score from 'components/Score';
import SelectForm from 'components/SelectForm';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useConf from 'hooks/use-conf';
import isSuccessResponse from 'lib/isSuccessResponse';

const AccountForm = ({
	adminLayout,
	className,
	userData,
	merchantSlug,
	ownUser,
	setDirty = () => {},
	withHeader = true,
}) => {
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { config } = useConf();
	const { setUserData, useUpdateUser, getLazyNotificationCount, locale, user, logOut } = useAuth();
	const disableNonTranslatableFields = locale !== config.default_locale || user.type === 'translator';
	const [updateUser, { loading }] = useUpdateUser({
		onCompleted: ({ ProfileUpdate }) => {
			if (isSuccessResponse(ProfileUpdate, ['Success'], logOut, addErrorMessage, 'Asociado')) {
				addSuccessMessage('Asociado', 'actualizado correctamente');
				// when admin change merchant status from 'revision'
				const accountFormStatus = getValues('accountForm.status');

				if (
					!ownUser &&
					((userData.status === 'revision' && accountFormStatus !== 'revision') ||
						(userData.status !== 'revision' && accountFormStatus === 'revision'))
				) {
					getLazyNotificationCount();
				}
				// when user is modifying own User data, store user result in useAuth to be used in the app
				if (ownUser) {
					setUserData(ProfileUpdate);
				}
			}
		},
	});
	const [userImage, setUserImage] = useState(userData ? { ...userData?.Image } : {});
	const [score, setScore] = useState(0);
	const [imagesUploading, setImagesUploading] = useState(false);

	const {
		handleSubmit,
		control,
		register,
		setValue,
		getValues,
		watch,
		reset,
		formState: { dirtyFields },
	} = useForm({
		defaultValues: {
			accountForm: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				acceptsMarketing: false,
				UserImageId: null,
				status: 'inactive',
			},
		},
	});

	useEffect(() => {
		if (userData) {
			reset({
				accountForm: {
					firstName: userData.firstName || '',
					lastName: userData.lastName || '',
					email: userData.email || '',
					phone: userData.phone || '',
					acceptsMarketing: userData.acceptsMarketing || false,
					UserImageId: userData.UserImageId || null,
					status: userData.status || 'inactive',
				},
			});
			// Creates a copy of each image element so it can be modified inside the DropfileImage
			setUserImage({ ...userData?.Image });
			setDirty(false);
		}
	}, [userData, setValue, setDirty]);

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (Object.keys(dirtyFields).length > 0) {
			setDirty(true);
		} else {
			setDirty(false);
		}
	}, [Object.keys(dirtyFields).length]);

	const conditionsLink = (
		<p>
			{`Acepto recibir información comercial de ${config.marketplace_name}. Consulta la `}
			<a className='text-red-500' href={`${config.statics}/legal/es/Politica_de_Privacidad.pdf`}>
				Política de Privacidad.
			</a>
		</p>
	);

	// Using calculateFormScore in combination with watch "score" state
	function calculateFormScore(userFormData) {
		if (userFormData) {
			const userFormDataFiltered = Object.entries(userFormData).filter(
				e => e[0] !== 'acceptsMarketing' && e[0] !== 'status'
			);
			let total = userFormDataFiltered.length;
			let count = 0;

			for (var [, value] of userFormDataFiltered) {
				if (value) {
					count++;
				}
			}
			setScore((100 * count) / total);
		} else {
			// When no data 0%
			setScore(0);
		}
	}

	watch(({ accountForm }) => {
		calculateFormScore(accountForm);
	});

	const handleOnChangeUserImage = useCallback(
		([media]) => {
			setValue('accountForm.UserImageId', media.id);
			setUserImage({ ...media });
		},
		[setUserImage, setValue]
	);

	const onSubmit = ({ accountForm }) => {
		updateUser({
			variables: {
				id: userData?.id,
				input: accountForm,
			},
		});
	};

	return (
		<div className={className}>
			<Score data={score} name='de perfil' content='' />
			{withHeader && <LayoutHeader>{adminLayout ? 'Datos de la Cuenta' : 'Datos de Perfil'}</LayoutHeader>}
			<form onSubmit={handleSubmit(onSubmit)}>
				<br />
				{adminLayout && (
					<SelectForm
						name='accountForm.status'
						control={control}
						register={register}
						options={[
							{ value: 'active', name: 'Activo' },
							{ value: 'inactive', name: 'Inactivo' },
							{ value: 'pending', name: 'Pendiente' },
							{ value: 'revision', name: 'Revisión' },
						]}
						label='Estado'
						disabled={disableNonTranslatableFields || loading}
					/>
				)}
				<InputForm
					name='accountForm.firstName'
					control={control}
					register={register}
					disabled={disableNonTranslatableFields}
					label='Nombre'
					placeholder='Nombre'
				/>
				<InputForm
					name='accountForm.lastName'
					control={control}
					register={register}
					disabled={disableNonTranslatableFields}
					label='Apellidos'
					placeholder='Appelidos'
				/>
				<InputForm
					name='accountForm.email'
					control={control}
					register={register}
					disabled={disableNonTranslatableFields}
					label='Email'
					placeholder='Email'
					readOnly={!adminLayout}
				/>
				<InputForm
					name='accountForm.phone'
					control={control}
					register={register}
					disabled={disableNonTranslatableFields}
					label='Teléfono'
					placeholder='+34555555555'
					type='tel'
					pattern='^\+?[0-9]*'
					maxLength='15'
					minLength='9'
				/>
				<DropImageArea
					path={adminLayout ? merchantSlug : ''}
					label='Imagen de perfil'
					withAlts={false}
					whileIdleText='Selecciona imagen de perfil del Panel de Administración'
					description=''
					Images={userImage ? [userImage] : []}
					onChange={handleOnChangeUserImage}
					onDelete={() => {
						setValue('accountForm.UserImageId', null);
						setUserImage(null);
					}}
					setUploading={setImagesUploading}
					specifications={
						<ul className='list-disc pl-4'>
							<li>Formato 1/1 (cuadrado)</li>
							<li>Tamaño máximo 500kb</li>
						</ul>
					}
					previewStyle='aspect-square object-cover'
					boxStyle='w-1/3'
				/>
				<CheckForm
					name='accountForm.acceptsMarketing'
					control={control}
					label={conditionsLink}
					disabled={disableNonTranslatableFields}
				/>
				<Button className='mt-5' type='submit' disabled={loading || imagesUploading}>
					{`Actualizar ${adminLayout ? 'datos' : 'mis datos'}`}
				</Button>
			</form>
			<PasswordManagement userData={userData} className='border-t border-dashed border-coral-300 mt-10 pt-10' />
		</div>
	);
};

export default AccountForm;
