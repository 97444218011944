import { useState } from 'react';

const SidebarWithFloatingBox = ({ children }) => {
	const [isHovered, setIsHovered] = useState(false);
	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const handleMouseMove = event => {
		setCursorPosition({ x: event.clientX, y: event.clientY });
	};

	return (
		<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove}>
			{children}
			{isHovered && (
				<div
					className='fixed bg-white text-black text-base p-3 rounded z-50 max-w-[15.5rem] border border-red-500 shadow-lg whitespace-normal'
					style={{
						top: cursorPosition.y + 15,
						left: cursorPosition.x + 15,
					}}
				>
					Necesitas ser un Asociado Activo. Para ello es necesario que completes correctamente las fichas
					Administrativa y Comercial.
				</div>
			)}
		</div>
	);
};

export default SidebarWithFloatingBox;
