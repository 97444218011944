import React, { useEffect, useState, useRef } from 'react';
import { SectionHeader } from 'components/LayoutHeader';
import ContentTable from './ContentTable';
import formatDate from 'lib/formatDate';
import formatTime from 'lib/formatTime';
import SearchBox from './SearchBox';
import Pagination from './Pagination';
import { useLazyEvents } from 'hooks/use-events';
import AuthorizationBoundary from './AuthorizationBoundary';
import SelectMerchantInput from './SelectMerchantInput';
import { useToast } from 'components/Toast';
import LayoutHeader from 'components/LayoutHeader';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';

const EventList = ({ events, paginationLimit = 50, eventsOnly = false, focusRef = null }) => {
	const { user, logOut } = useAuth();
	const { addErrorMessage } = useToast();
	const [offset, setOffset] = useState(0);
	const [eventSearch, setEventSearch] = useState('');
	const [merchantId, setMerchantId] = useState('');
	const [eventData, setEventData] = useState(events || []);

	const [getLazyEvents, { loading }] = useLazyEvents({
		variables: {
			search: eventSearch,
			MerchantId: merchantId,
			limit: paginationLimit,
			offset,
		},
		onCompleted: ({ Events }) => {
			if (isSuccessResponse(Events, ['EventList'], logOut, addErrorMessage, 'Actualizar categoría')) {
				setEventData(Events);
			}
		},
	});

	useEffect(() => {
		if (!events) {
			getLazyEvents();
		}
	}, []);

	const handleSearch = input => {
		setEventSearch(input);
		setOffset(0);
		getLazyEvents();
	};

	const handleClearSearch = () => {
		setEventSearch('');
		setOffset(0);
		getLazyEvents();
	};
	const handleSelectMerchant = e => setMerchantId(e.target.value);

	const displayDetails = event => {
		if (user.type === 'admin' && event) {
			if (event.User?.Merchant) {
				return (
					<td className='px-4 text-left cursor-pointer'>
						<Link to={`/dashboard/asociados/edit/${event.User?.Merchant.id}`}>{event.details}</Link>
					</td>
				);
			}
			if (event.Order && event.Order.status !== 'draft') {
				return (
					<td className='px-4 text-left cursor-pointer'>
						<Link to={`/dashboard/pedido/${event?.Order?.id}`}>{event.details}</Link>
					</td>
				);
			}
			return <td className='px-4 py-2 w-1/2'>{event.details}</td>;
		}
	};

	const eventsListRef = useRef(null);
	const handleEventListFocus = () => {
		setTimeout(() => {
			eventsListRef.current.scrollIntoView({ behavior: 'smooth' });
		}, 300);
	};

	return (
		<>
			{!eventsOnly && (
				<>
					<div ref={focusRef} tabIndex='-1'>
						<SectionHeader>Eventos e Incidencias</SectionHeader>
					</div>
					<span className='flex w-full justify-between'>
						<SearchBox
							placeholder='Busca un Asociado'
							search={handleSearch}
							loading={loading}
							clearSearch={handleClearSearch}
						/>
						<Pagination
							offset={offset}
							setOffset={setOffset}
							count={eventData?.count}
							hasMore={eventData?.hasMore}
							limit={paginationLimit}
						/>
					</span>
					<AuthorizationBoundary for={['admin']}>
						{/* En SelectMerchantInput se hace un use-merchant que muestra los merchants y permite cambiar el MerchantId */}
						<SelectMerchantInput
							emptyValue={{ id: '', commercialName: 'Todos los asociados' }}
							onChange={handleSelectMerchant}
							width='w-1/3'
							label='Selecciona un asociado'
						/>
					</AuthorizationBoundary>
					<ContentTable
						columns={user.type === 'admin' ? ['Fecha', 'Detalle', 'Mensaje'] : ['Fecha', 'Mensaje']}
						content={eventData.List || events}
						render={(event, index) =>
							event && (
								<tr key={index} className='text-gray-700 border-t border-dashed border-coral-300'>
									<td className='px-4 py-2 w-32'>{`${formatDate(event.createdAt)}, ${formatTime(
										event.createdAt
									)}`}</td>
									{displayDetails(event)}
									<td className='px-4 py-2 flex-1'>{event.message}</td>
								</tr>
							)
						}
						empty={<p>No hay eventos</p>}
					/>
				</>
			)}
			{eventsOnly && (
				<div ref={focusRef} tabIndex='-1' className='accordion-item'>
					<h2 className='accordion-header mb-0'>
						<button
							className='accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-coral-500 text-left bg-white border-0 rounded-none transition focus:outline-none'
							type='button'
							data-bs-toggle='collapse'
							data-bs-target='#collapseOne'
							aria-expanded='false'
							aria-controls='collapseOne'
							onClick={handleEventListFocus}
						>
							<LayoutHeader>Eventos e Incidencias</LayoutHeader>
						</button>
					</h2>
					<div
						id='collapseOne'
						className='accordion-collapse collapse'
						aria-labelledby='accountForm'
						data-bs-parent='#accordionAccountForm'
						ref={eventsListRef}
					>
						<ContentTable
							columns={user.type === 'admin' ? ['Fecha', 'Detalle', 'Mensaje'] : ['Fecha', 'Mensaje']}
							content={eventData.List || events}
							render={(event, index) =>
								event && (
									<tr key={index} className='text-gray-700 border-t border-dashed border-coral-300'>
										<td className='px-4 py-2 w-32'>{`${formatDate(event.createdAt)}, ${formatTime(
											event.createdAt
										)}`}</td>
										{displayDetails(event)}
										<td className='px-4 py-2 flex-1'>{event.message}</td>
									</tr>
								)
							}
							empty={<p>No hay eventos</p>}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default EventList;
