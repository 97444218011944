import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

const MERCHANTPAYMENT_INFO_FRAGMENT = gql`
	fragment MerchantPaymentInfo on MerchantPayment {
		id
		status
		grossTotal
		netTotal
		transferredTotal
		orderCount
		createdAt
		endDate
		Merchant {
			id
			locale
			commercialName
		}
		MerchantPaymentTotals {
			maxTransfer
		}
	}
`;

const MERCHANTPAYMENTDETAIL_INFO_FRAGMENT = gql`
	fragment MerchantPaymentDetailInfo on MerchantPayment {
		id
		status
		grossTotal
		netTotal
		transferredTotal
		orderCount
		pdf
		createdAt
		startDate
		endDate
		Merchant {
			id
			commercialName
			commission
			locale
		}
		MerchantPaymentTotals {
			grossTotalDiscounts
			totalTax
			grossTotalPrice
			maxTransfer
		}
	}
`;

const MERCHANTPAYMENTORDER_INFO_FRAGMENT = gql`
	fragment MerchantPaymentOrderInfo on MerchantPaymentOrder {
		id
		updatedAt
		grossTotalDiscounts
		totalTax
		grossTotalPrice
	}
`;

const MERCHANTPAYMENT_QUERY = gql`
	query MerchantPayment($MerchantId: ID, $MerchantPaymentId: ID, $locale: String) {
		MerchantPayment(MerchantId: $MerchantId, MerchantPaymentId: $MerchantPaymentId, locale: $locale) {
			... on MerchantPayment {
				...MerchantPaymentDetailInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${MERCHANTPAYMENTDETAIL_INFO_FRAGMENT}
`;

const MERCHANTPAYMENTS_QUERY = gql`
	query MerchantPayments(
		$scope: String
		$order_scope: String
		$startDate: Date
		$endDate: Date
		$MerchantId: ID
		$limit: Int
		$offset: Int
		$locale: String
	) {
		MerchantPayments(
			scope: $scope
			order_scope: $order_scope
			startDate: $startDate
			endDate: $endDate
			MerchantId: $MerchantId
			limit: $limit
			offset: $offset
			locale: $locale
		) {
			... on MerchantPaymentList {
				count
				hasMore
				List {
					...MerchantPaymentInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${MERCHANTPAYMENT_INFO_FRAGMENT}
`;

const MERCHANTPAYMENTSORDERS_QUERY = gql`
	query MerchantPaymentOrders($MerchantPaymentId: ID, $limit: Int, $offset: Int) {
		MerchantPaymentOrders(MerchantPaymentId: $MerchantPaymentId, limit: $limit, offset: $offset) {
			... on MerchantPaymentOrderList {
				count
				hasMore
				List {
					...MerchantPaymentOrderInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${MERCHANTPAYMENTORDER_INFO_FRAGMENT}
`;

const TRANSFER_MERCHANTPAYMENT_MUTATION = gql`
	mutation TransferMerchantPayment($MerchantPaymentId: ID, $quantity: Float, $locale: String) {
		TransferMerchantPayment(MerchantPaymentId: $MerchantPaymentId, quantity: $quantity, locale: $locale) {
			... on Success {
				success
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
			... on MailFault {
				message
				emailRecipient
			}
			... on StripeFault {
				message
				stripeAccountId
			}
		}
	}
`;

export const useMerchantPayment = props => useQuery(MERCHANTPAYMENT_QUERY, props);
export const useLazyMerchantPayment = props => useLazyQuery(MERCHANTPAYMENT_QUERY, props);

export const useMerchantPayments = props => useQuery(MERCHANTPAYMENTS_QUERY, props);
export const useLazyMerchantPayments = props => useLazyQuery(MERCHANTPAYMENTS_QUERY, props);
export const useMerchantPaymentOrders = props => useQuery(MERCHANTPAYMENTSORDERS_QUERY, props);

export const useTransferMerchantPayment = props => useMutation(TRANSFER_MERCHANTPAYMENT_MUTATION, props);
