import React, { useState, useEffect } from 'react';

import useAuth from 'hooks/use-auth';
import { useLazyMerchant } from 'hooks/use-merchants';

import AdministrativeForm from 'pages/Merchants/AdministrativeForm';
import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';

const MyAdministrativeForm = () => {
	const { user, locale } = useAuth();
	const [dirty, setDirty] = useState(false);

	const [getLazyMerchant, { data: merchantData }] = useLazyMerchant();

	// Excecute once at page load
	useEffect(() => {
		// query Product fields with specific locale
		getLazyMerchant({ variables: { id: user.MerchantId, forceLocale: true, locale } });
	}, [locale, user.MerchantId]);

	return (
		<>
			<LanguageTabs dirty={dirty} />
			<Card>
				{merchantData ? (
					<AdministrativeForm
						merchantData={merchantData.Merchant}
						userData={user}
						className='shadow-none'
						adminLayout={false}
						setDirty={setDirty}
					/>
				) : null}
			</Card>
		</>
	);
};

export default MyAdministrativeForm;
