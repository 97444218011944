/* eslint-disable no-console */
import { gql, useMutation, useQuery } from '@apollo/client';

const DOCUMENT_INFO_FRAGMENT = gql`
	fragment DocumentInfo on Document {
		id
		filename
		src
	}
`;

const DOCUMENT_QUERY = gql`
	query Document($id: ID!, $locale: String) {
		Document(id: $id, locale: $locale) {
			id
			filename
			src
		}
	}
`;

const UPLOAD_DOCUMENTS_MUTATION = gql`
	mutation UploadDocuments($files: [Upload!]!, $path: String, $MerchantId: ID) {
		UploadDocuments(files: $files, path: $path, MerchantId: $MerchantId) {
			... on Documents {
				Documents {
					...DocumentInfo
				}
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
			... on StorageFault {
				message
				filename
			}
		}
	}
	${DOCUMENT_INFO_FRAGMENT}
`;

const UPLOAD_MERCHANT_CONTRACT_MUTATION = gql`
	mutation UploadMerchantContract($document: String!, $MerchantId: ID) {
		UploadMerchantContract(document: $document, MerchantId: $MerchantId) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
			... on StorageFault {
				message
				filename
			}
		}
	}
`;

const DELETE_DOCUMENT_MUTATION = gql`
	mutation DeleteDocument($id: ID!) {
		DeleteDocument(id: $id) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
			... on StorageFault {
				message
				filename
			}
		}
	}
`;

export const useDocument = (id, locale) => useQuery(DOCUMENT_QUERY, { variables: { id, locale }, skip: !id });
export const useDeleteDocument = props => useMutation(DELETE_DOCUMENT_MUTATION, props);
export const useUploadDocuments = props => useMutation(UPLOAD_DOCUMENTS_MUTATION, props);
export const useUploadMerchantContract = props => useMutation(UPLOAD_MERCHANT_CONTRACT_MUTATION, props);
export default useUploadDocuments;
