import AuthorizationBoundary from 'components/AuthorizationBoundary';
import DownloadInvoices from 'components/DownloadInvoices';
import OrderList from 'components/OrderList';
import React from 'react';

const Orders = () => {
	return (
		<>
			<AuthorizationBoundary for={['admin']}>
				<DownloadInvoices />
			</AuthorizationBoundary>
			<OrderList pageScope='all' title='Pedidos' />
		</>
	);
};

export default Orders;
