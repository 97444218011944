import AuthorizationBoundary from 'components/AuthorizationBoundary';
import EventList from 'components/EventList';
import Card from 'components/Card';
import Currency from 'components/Currency';
import LayoutHeader, { SectionHeader } from 'components/LayoutHeader';
import { useLazyOrder, useUpdateOrderNotes } from 'hooks/use-orders';
import formatDate from 'lib/formatDate';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import translasteStatus from 'lib/translasteStatus';
import printUserName from 'lib/printUserName';
import MerchantShippingDetails from './MerchantShippingDetails';
import Message from 'components/Message';
import { formatNumber, getInvoiceTypeTranslation, getPrefixByInvoiceType, useLazyInvoices } from 'hooks/use-invoices';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import isSuccessResponse from 'lib/isSuccessResponse';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';

const handleOpenInvoice = invoice => {
	if (invoice.pdf) {
		// open pdf in a new window
		const pdfWindow = window.open('');
		pdfWindow.document.write(
			'<iframe width="100%" height="100%" src="data:application/pdf;base64, ' +
				encodeURI(invoice.pdf) +
				'"></iframe>'
		);
	}
};

const sortMerchantShippings = delivery => {
	return delivery.MerchantShippings && delivery.MerchantShippings.length > 1
		? [...delivery.MerchantShippings].sort((a, b) => {
				if (a.status === 'new' && ['prepared', 'canceled', 'shipped'].includes(b.status)) {
					return -1;
				}

				if (['prepared', 'canceled', 'shipped'].includes(a.status) && b.status === 'new') {
					return 1;
				}

				return 0;
		  })
		: delivery.MerchantShippings;
};

const OrderDetail = () => {
	const { orderId } = useParams();
	const [shippingNotes, setShippingNotes] = useState('');
	const [getLazyOrder, { data }] = useLazyOrder({
		orderId,
	});
	const [getLazyInvoices, { data: invoicesData }] = useLazyInvoices(orderId);
	const [totalAmount, setTotalAmount] = useState(0);
	const [isFormFilled, setIsFormFilled] = useState(false);
	const { addErrorMessage, addSuccessMessage } = useToast();
	const { logOut, user } = useAuth();
	const canEditNotes = user.type === 'admin' && ['new', 'processing', 'prepared'].includes(data?.Order.status);

	// Excecute once at page load
	useEffect(() => {
		getLazyOrder();
		getLazyInvoices();
	}, [getLazyOrder, getLazyInvoices]);

	useEffect(() => {
		if (data) {
			setTotalAmount(
				data.Order?.Deliveries?.map(delivery =>
					delivery.MerchantShippings?.map(ms =>
						ms.OrderItems?.reduce(
							(oiSum, { grossDiscountedPrice, grossPrice, sentQuantity, boughtQuantity }) =>
								oiSum +
								(grossDiscountedPrice || grossPrice) *
									(sentQuantity === null ? boughtQuantity : sentQuantity),
							0
						)
					).reduce((msSum, item) => item + msSum, 0)
				).reduce((delSum, item) => item + delSum, 0)
			);
			setShippingNotes(data.Order?.shippingNotes);
			setIsFormFilled(true);
		}
	}, [data]);

	const [updateNotes] = useUpdateOrderNotes({
		onCompleted: ({ UpdateNotes }) => {
			if (isSuccessResponse(UpdateNotes, ['Success'], logOut, addErrorMessage, 'Notas')) {
				addSuccessMessage('Notas', 'actualizada correctamente');
			}
		},
	});

	const handleUpdateNotes = () => {
		updateNotes({
			variables: {
				orderId,
				shippingNotes,
			},
		});
	};

	return (
		isFormFilled && (
			<>
				<Card className='mb-4'>
					<LayoutHeader>Detalles del Pedido {orderId}</LayoutHeader>
					{(data?.Order?.status === 'new' || data?.Order?.status === 'processing') && (
						<Message error className='w-full sm:w-72'>
							Recuerda que tienes 24h para preparar el pedido
						</Message>
					)}
					<div className='mt-5'>
						<SectionHeader className='mt-5'>General</SectionHeader>
						<div className='flex flex-col w-1/5 mt-5'>
							<div className='flex content-between gap-4'>
								<p className='text-coral-500 flex-1'>Fecha de creación</p>
								<p>{formatDate(data?.Order?.orderDate)}</p>
							</div>

							<div className='flex content-between gap-4'>
								<p className='text-coral-500 flex-1'>Estado</p>
								<span className='border border-coral-500 text-coral-500 font-medium text-center py-1 px-2'>
									{translasteStatus(data?.Order?.status)}
								</span>
							</div>

							<div className='flex content-between gap-4'>
								<p className='text-coral-500 flex-1'>Accepta Sustitución</p>
								<p>{data?.Order?.authorizeProductSubstitution ? 'Si' : 'No'}</p>
							</div>
							<div className='flex content-between gap-4'>
								<p className='text-coral-500 flex-1'>Usuario</p>
								<p>{printUserName(data?.Order?.User)}</p>
							</div>
						</div>
						<div className='flex content-between gap-4'>
							<div className='grid grid-cols-2 grid-gap-4 w-full mt-5'>
								<div>
									<SectionHeader>Envío</SectionHeader>
									<div>
										<p className='mt-5'>{data?.Order?.shippingName}</p>
										<p>{data?.Order?.shippingAddress1}</p>
										<p>{data?.Order?.shippingAddress2}</p>
										<p>
											{data?.Order?.shippingZip} {data?.Order?.shippingProvince}
										</p>
										<p>{data?.Order?.shippingCountry}</p>
										<p>{data?.Order?.billNotes}</p>
									</div>
								</div>
							</div>
							<div className='grid grid-gap-4 w-full mt-5'>
								<div>
									<SectionHeader>Notas</SectionHeader>
									<div>
										<Textarea
											className='w-full'
											placeholder={data?.Order?.shippingNotes}
											borderstyle='border border-gray-700'
											value={shippingNotes}
											onChange={e => setShippingNotes(e.target.value)}
											disabled={!canEditNotes}
										/>
										<AuthorizationBoundary for={['admin']}>
											<Button
												className='w-40'
												onClick={handleUpdateNotes}
												disabled={!canEditNotes}
											>
												Actualizar Notas
											</Button>
										</AuthorizationBoundary>
									</div>
								</div>
							</div>
						</div>
						<div className='grid grid-gap-4 grid-cols-2 mt-5'></div>
					</div>

					<div className='flex'>
						<span className='flex-1 uppercase font-semibold'>Gastos de envío</span>
						{data?.Order?.grossClientShippingPrice ? (
							<Currency className='flex-1 font-bold'>{data?.Order?.grossClientShippingPrice}</Currency>
						) : (
							<p className='flex-1 font-bold'>Envio gratuito</p>
						)}
					</div>

					<AuthorizationBoundary for={['admin']}>
						<div className='flex'>
							<span className='flex-1 uppercase font-semibold'>Importe Total</span>
							<Currency className='flex-1 font-bold'>
								{totalAmount + data?.Order?.grossClientShippingPrice}
							</Currency>
						</div>
					</AuthorizationBoundary>
					{invoicesData?.Invoices?.count > 0 && (
						<AuthorizationBoundary for={['admin']}>
							<div className='mt-4'>
								<span className='flex-1 uppercase font-semibold'>Tickets o facturas disponibles</span>
								<ul>
									{invoicesData.Invoices?.List?.map(invoice => (
										<li className='flex list-none mb-2' key={invoice.id}>
											<p
												className='border-b border-dashed border-coral-300 hover:text-coral-500 hover:font-semibold tranisiton-color duration-100 cursor-pointer'
												onClick={() => handleOpenInvoice(invoice)}
											>
												{`${getInvoiceTypeTranslation(invoice.type)} ${getPrefixByInvoiceType(
													invoice.type
												)}-${formatNumber(invoice.number)}`}
											</p>
										</li>
									))}
								</ul>
							</div>
						</AuthorizationBoundary>
					)}
				</Card>
				{data.Order?.Deliveries?.map((delivery, index) => (
					<Card key={index} className='mb-4'>
						<div className='flex content-between gap-6 w-1/5 items-center mb-4'>
							<SectionHeader>
								Envío {index + 1}/{data.Order.Deliveries.length}
							</SectionHeader>
							<span className='border border-coral-500 text-coral-500 font-medium text-center py-1 px-2'>
								{translasteStatus(delivery.status)}
							</span>
						</div>
						{sortMerchantShippings(delivery).map(merchantShipping => (
							<MerchantShippingDetails
								key={delivery.MerchantShippings.indexOf(merchantShipping)}
								merchantShippingIndex={delivery.MerchantShippings.indexOf(merchantShipping)}
								orderId={orderId}
								orderData={data.Order}
								delivery={delivery}
								merchantShipping={merchantShipping}
								setTotalAmount={setTotalAmount}
								getLazyOrder={getLazyOrder}
								shippingNotes={data.Order.shippingNotes}
							/>
						))}
					</Card>
				))}

				<Card className='mb-4'>
					{data ? <EventList events={data.Order?.Events?.filter(event => event.message !== null)} /> : null}
				</Card>
			</>
		)
	);
};
export default OrderDetail;
