/**
 * Translate status attribute from DB to spanish.
 *
 * @param {String} status raw status attribute from DB.
 * @returns {String} translated status to spanish.
 */
const translasteStatus = status => {
	switch (status) {
		case 'accepted':
			return 'Aceptado';
		case 'active':
			return 'Activo';
		case 'canceled':
			return 'Anulado';
		case 'completed':
			return 'Completado';
		case 'created':
			return 'Creado';
		case 'deleted':
			return 'Descatalogado';
		case 'draft':
			return 'Carrito';
		case 'inactive':
			return 'Inactivo';
		case 'incident':
			return 'Incidencia';
		case 'modified':
			return 'Modificado';
		case 'new':
			return 'Creado';
		case 'notified':
			return 'Notificado';
		case 'paid':
			return 'Pagado';
		case 'parcial-delivery':
			return 'Envío parcial';
		case 'processing':
			return 'Procesando';
		case 'pending':
			return 'Pendiente';
		case 'prepared':
			return 'Preparado';
		case 'registered':
			return 'Registrado';
		case 'returned':
			return 'Devuelto';
		case 'revision':
			return 'En revisión';
		case 'correct':
			return 'Correcto';
		case 'issue':
			return 'Pendiente de correcciones';
		case 'requiredFields':
			return 'Faltan campos requeridos';
		case 'untranslated':
			return 'Faltan traducciones';
		case 'shipped':
			return 'En reparto';
		default:
			return 'Desconocido';
	}
};

export default translasteStatus;
