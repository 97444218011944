/**
 * Validate a response data and handle errors, returns true if success
 *
 * @param {Object} data Data with typename to validate errors
 * @param {Object} successTypes List with correct typename to validate
 * @param {Object} logOut Method logOut from use-auth
 * @param {Object} addErrorMessage Method from Toast
 * @param {String} title Title for Toast
 * @returns {String} Returns true if success
 */
const isSuccessResponse = (data, successTypes, logOut, addErrorMessage, title) => {
	if (!data) {
		addErrorMessage('Error', 'Refresca la página e intenta de nuevo más tarde');
		return false;
	}
	if (successTypes.includes(data.__typename)) {
		return true;
	}
	let isSuccess = true;
	if (data.__typename === 'AuthorizationFault') {
		logOut();
		isSuccess = false;
	} else if (data.__typename.endsWith('Fault')) {
		isSuccess = false;
	}
	if (!isSuccess) {
		addErrorMessage(title, data.message);
	}
	return isSuccess;
};

export default isSuccessResponse;
