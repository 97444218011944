import Specifications from './Specifications';

const Field = ({
	id,
	label,
	disabled = false,
	width = 'w-full',
	align = 'items-center',
	maxW = '',
	labelclass = 'w-1/5',
	mb = 'mb-4',
	containerClassName = '',
	className = 'flex relative',
	children,
	required,
	showRequiredIcon = false,
	specifications,
	descriptionClassName = 'italic text-sm font-normal',
	...props
}) => (
	<div className={`${className} ${align} ${width} ${maxW} ${mb} ${containerClassName}`}>
		{(label || props.placeholder) && (
			<label
				htmlFor={id}
				className={`${!label && 'sr-only'} font-semibold ${
					disabled ? 'text-gray-500' : 'text-gray-700'
				} text-lg ${labelclass} `}
			>
				<div className='flex w-full'>
					<div className={`align-center ${specifications ? 'mt-2' : ''}`}>{label || props.placeholder}</div>
					{required || showRequiredIcon ? <span className='text-coral-500'> *</span> : null}
					<Specifications specifications={specifications} />
				</div>
				<span className={descriptionClassName}>{props.description}</span>
			</label>
		)}
		{children}
	</div>
);

export default Field;
