import microsoftTranslator from './MicrosoftTranslator';

const translateVariantsTitleField = async (
	variants,
	fromLocale,
	setTranslatorModal,
	setValue,
	addErrorMessage,
	locale,
	config
) => {
	try {
		const listOfTextToTranslate = [];
		if (variants.length > 0) {
			for (const variant of variants) {
				if (variant.title) {
					listOfTextToTranslate.push({ text: variant.title });
				}
			}
		}
		if (listOfTextToTranslate.length > 0) {
			const translations = await microsoftTranslator(
				fromLocale,
				locale,
				listOfTextToTranslate,
				config,
				setTranslatorModal
			);
			if (translations) {
				for (const [index, variant] of variants.entries()) {
					if (variant.title) {
						setValue(`productForm.Variants.${index}.title`, translations.shift().translations[0].text);
					}
				}
			} else {
				addErrorMessage('Traducción', translations.message);
			}
		} else {
			setTranslatorModal(false);
			addErrorMessage('Traducción', 'no hay nada para traducir');
		}
	} catch (error) {
		setTranslatorModal(false);
		addErrorMessage('Traducción', error.message);
	}
};

export default translateVariantsTitleField;
