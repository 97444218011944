import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import AuthorizationBoundary from 'components/AuthorizationBoundary';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import FaqForm from './FaqForm';

import { useFaq, useUpdateFaq } from 'hooks/use-faq';
import useAuth from 'hooks/use-auth';
import Card from 'components/Card';
import { useToast } from 'components/Toast';
import isSuccessResponse from 'lib/isSuccessResponse';

const FAQEdit = () => {
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [dirty, setDirty] = useState(false);
	const { faqId: id } = useParams();
	const { locale, logOut } = useAuth();
	const { data, loading } = useFaq(id, locale);
	const [updateFaq] = useUpdateFaq({
		onCompleted: ({ UpdateFAQ }) => {
			if (isSuccessResponse(UpdateFAQ, ['FAQ'], logOut, addErrorMessage, 'FAQ')) {
				addSuccessMessage('FAQ', 'Actualizada correctamente');
			}
		},
	});

	return (
		<AuthorizationBoundary for={['admin']}>
			<LoadingView loading={loading}>
				<LanguageTabs dirty={dirty} />
				<Card>
					<LayoutHeader>Editar FAQ</LayoutHeader>
					<FaqForm
						faq={data?.FAQ}
						onSubmit={({ FaqForm }) => updateFaq({ variables: { id, input: FaqForm, locale } })}
						setDirty={setDirty}
					/>
				</Card>
			</LoadingView>
		</AuthorizationBoundary>
	);
};

export default FAQEdit;
