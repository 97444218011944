import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import useAuth from 'hooks/use-auth';

import Button from 'components/Button';
import Input from 'components/Input';

import useConf from 'hooks/use-conf';

const RestorePasswordForm = ({ className = 'text-lg w-full md:w-128 overflow-hidden mx-auto' }) => {
	const { config } = useConf();
	const { isLoggedIn, loggedInStatus, user, useRestorePassword } = useAuth();
	const [restorePassword, { loading }] = useRestorePassword({
		onCompleted: ({ RestorePassword }) => {
			// All Errors implement Fault
			if (RestorePassword.__typename.endsWith('Fault')) {
				setError(RestorePassword.message);
			} else if (RestorePassword.__typename === 'Success') {
				setSuccess(`Se ha enviado un correo con una nueva contraseña a: ${email}`);
			}
		},
	});

	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [email, setEmail] = useState('');

	// Delete form email and password values if login successful
	useEffect(() => {
		if (isLoggedIn === loggedInStatus.yes) {
			setEmail('');
		}
	}, [isLoggedIn, loggedInStatus.yes]);

	const handleEmail = e => {
		setEmail(e.target.value);
	};

	const handleRestore = e => {
		e.preventDefault();
		if (email === '') {
			setError('Se requiere email');
			return null;
		}
		restorePassword({
			variables: {
				email,
			},
		});
	};

	if (isLoggedIn === loggedInStatus.yes) {
		return user && user.type === 'merchant' && (user.status === 'revision' || user.status === 'pending') ? (
			<Navigate to={`/dashboard/asociados/edit/${user.MerchantId}`} replace />
		) : (
			<Navigate to='/dashboard' replace />
		);
	}

	if (loading) {
		return null;
	}

	return (
		<form onSubmit={handleRestore} className={className}>
			<img
				src={`${config.statics}/mp/logob.svg`}
				className='mx-auto block h-24 w-full overflow-hidden text-transparent'
				alt={`${config.marketplace_name}`}
			/>
			<div className='w-full pb-4'>
				<div className='text-start mb-4'>
					<p>Introduce el correo electrónico asociado a tu cuenta</p>
					<p>Te enviaremos una nueva contraseña</p>
				</div>
				<Input
					type='email'
					autoComplete='email'
					name='email'
					id='email'
					className='w-full rounded-lg'
					containerclassname='mt-4 items-center'
					placeholder='email@dominio.com'
					value={email}
					onChange={handleEmail}
					required
				/>
			</div>
			<div className='w-full pb-4 font-light'>
				<Link to='/login' className='tranisiton-color duration-100'>
					Iniciar sesión
				</Link>
			</div>
			<Button type='submit' disabled={loading} className='mt-4 block mx-auto uppercase'>
				{loading ? 'Enviando…' : 'Enviar'}
			</Button>
			{error && <p className='mt-4 border border-red-500 bg-gray-200 p-2 text-red-500'>{error}</p>}
			{success && <p className='mt-4 border border-green-700 bg-gray-200 p-2 text-green-700'>{success}</p>}
		</form>
	);
};

export default RestorePasswordForm;
