import React, { useState } from 'react';
import InputForm from 'components/InputForm';
import Check from 'components/Check';
import SelectForm from 'components/SelectForm';

const trClassName = 'flex border-dashed border-coral-400';

const NutritionalInformation = ({
	isNutritionalInformation,
	setIsNutritionalInformation,
	disabled,
	control,
	register,
	getValues,
	watch,
}) => (
	<>
		<Check
			className='font-semibold text-gray-700 text-lg'
			label='Información nutricional'
			checked={isNutritionalInformation}
			onChange={() => {
				setIsNutritionalInformation(prev => !prev);
			}}
			disabled={disabled}
		/>
		{isNutritionalInformation ? (
			<table className='text-base leading-7 break-words w-2/3 md:w-1/2 lg:w-1/3 text-left'>
				<tbody>
					<tr className={`border-b-2 ${trClassName}`}>
						<td className='w-1/2 whitespace-normal font-normal'> Valor energético </td>
						<CaloriesInput
							name='productForm.nutritionalInformation.energy'
							control={control}
							register={register}
							disabled={disabled}
							getValues={getValues}
							watch={watch}
						/>
					</tr>
					<tr className={`border-b-2 ${trClassName}`}>
						<td className='w-1/2 whitespace-normal font-normal'> Porción </td>
						<td className='ml-auto'>
							100
							<SelectForm
								simpleStyle
								name='productForm.nutritionalInformation.nutritionalMeasure'
								control={control}
								register={register}
								options={[
									{ value: 'g', name: 'g' },
									{ value: 'ml', name: 'ml' },
								]}
								disabled={disabled}
								className='min-w-0 w-14 ml-2 pl-2 border-2 border-gray-300 rounded leading-tight focus:outline-none focus:border-gray-800'
							/>
						</td>
					</tr>
					<tr className={`border-b-2 ${trClassName}`}>
						<td className='w-1/2 whitespace-normal font-normal'> Grasas </td>
						<TdInput
							name='productForm.nutritionalInformation.fatTotal'
							control={control}
							register={register}
							disabled={disabled}
						/>
					</tr>
					<tr>
						<td className='whitespace-normal font-normal'>de las cuales:</td>
					</tr>
					<tr className={`border-b ${trClassName}`}>
						<td className='pl-2 w-1/2 whitespace-normal font-normal'>
							<span className='mr-2'>&#8226;</span>Saturadas
						</td>
						<TdInput
							name='productForm.nutritionalInformation.fatSaturated'
							control={control}
							register={register}
							disabled={disabled}
						/>
					</tr>
					<tr className={`border-b ${trClassName}`}>
						<td className='pl-2 w-1/2 whitespace-normal font-normal'>
							<span className='mr-2'>&#8226;</span>Monoinsaturadas
						</td>
						<TdInput
							name='productForm.nutritionalInformation.fatMono'
							control={control}
							register={register}
							disabled={disabled}
						/>
					</tr>
					<tr className={`border-b-2 ${trClassName}`}>
						<td className='pl-2 w-1/2 whitespace-normal font-normal'>
							<span className='mr-2'>&#8226;</span>Poliinsaturadas
						</td>
						<TdInput
							name='productForm.nutritionalInformation.fatPoli'
							control={control}
							register={register}
							disabled={disabled}
						/>
					</tr>
					<tr className={`border-b-2 ${trClassName}`}>
						<td className='w-1/2 whitespace-normal font-normal'>Carbohidratos</td>
						<TdInput
							name='productForm.nutritionalInformation.carbohydrates'
							control={control}
							register={register}
							disabled={disabled}
						/>
					</tr>
					<tr>
						<td className='whitespace-normal font-normal'>de las cuales:</td>
					</tr>
					<tr className={`border-b-2 ${trClassName}`}>
						<td className='pl-2 w-1/2 whitespace-normal font-normal'>
							<span className='mr-2'>&#8226;</span>Azúcar
						</td>
						<TdInput
							name='productForm.nutritionalInformation.sugars'
							control={control}
							register={register}
							disabled={disabled}
						/>
					</tr>
					<tr className={`border-b-2 ${trClassName}`}>
						<td className='w-1/2 whitespace-normal font-normal'> Fibra </td>
						<TdInput
							name='productForm.nutritionalInformation.fiber'
							control={control}
							register={register}
							disabled={disabled}
						/>
					</tr>
					<tr className={`border-b-2 ${trClassName}`}>
						<td className='w-1/2 whitespace-normal font-normal'>Proteína</td>
						<TdInput
							name='productForm.nutritionalInformation.protein'
							control={control}
							register={register}
							disabled={disabled}
						/>
					</tr>
					<tr className={`border-b-2 ${trClassName}`}>
						<td className='w-1/2 whitespace-normal font-normal'> Sal </td>
						<TdInput
							name='productForm.nutritionalInformation.sodium'
							control={control}
							register={register}
							disabled={disabled}
						/>
					</tr>
				</tbody>
			</table>
		) : null}
	</>
);

const TdInput = ({ name, control, register, disabled }) => (
	<td className='ml-auto'>
		<span className='flex'>
			<InputForm
				simpleStyle
				type='number'
				step='0.01'
				name={name}
				control={control}
				register={register}
				disabled={disabled}
				className='text-center min-w-0 w-20 mr-2 pl-2 appearance-none border-2 border-gray-300 rounded leading-tight focus:outline-none focus:border-gray-800'
			/>
			g
		</span>
	</td>
);

const CaloriesInput = ({ name, control, register, disabled, getValues, watch }) => {
	const [kjouls, setKjouls] = useState(Number.parseInt(getValues(name) * 4.184));
	watch((_, { name }) => {
		if (name === 'productForm.nutritionalInformation.energy') {
			setKjouls(Number.parseInt(getValues(name) * 4.184));
		}
	});

	return (
		<td className='pl-4'>
			<span className='flex'>
				<InputForm
					simpleStyle
					type='number'
					name={name}
					control={control}
					register={register}
					disabled={disabled}
					className='min-w-0 w-20 mr-2 pl-2 appearance-none border-2 border-gray-300 rounded leading-tight focus:outline-none focus:border-gray-800'
				/>
				Kcal / {kjouls} KJ
			</span>
		</td>
	);
};

export default NutritionalInformation;
