import isValidSchedule from 'lib/schedule/isValidSchedule';
import { emptySchedule } from 'lib/schedule/emptySchedule';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import Field from './Field';
import { useToast } from './Toast';
import { dayList } from 'lib/schedule/dayList';
import isValid24HourFormat from 'lib/schedule/isValid24HourFormat';
import Check from './Check';

const Schedule = ({ disabled, name, control, ...props }) => {
	const { addErrorMessage } = useToast();
	const {
		field: { ref, value, onChange },
	} = useController({
		name,
		control,
		rules: {
			validate: value => {
				return isValidSchedule(JSON.parse(value), addErrorMessage);
			},
		},
	});

	let weekSchedule = Array.isArray(value) ? value : value && JSON.parse(value);
	if (!weekSchedule) {
		// When no schedule use a default one
		weekSchedule = [...emptySchedule];
	}

	const elements = weekSchedule.map((daySchedule, dayScheduleIdx) => (
		<DayInput
			onChange={e => {
				// Modify the day data in the JSON Array (we know the day using dayScheduleIdx)
				weekSchedule[dayScheduleIdx] = e;
				// Convert the whole weekSchedule back to a JSON string and send it back to the parent
				onChange(JSON.stringify(weekSchedule));
			}}
			key={dayScheduleIdx}
			label={dayList[dayScheduleIdx]}
			dayData={daySchedule}
			disabled={disabled}
			props={props}
		/>
	));

	return (
		<>
			<Field
				label={<p className='font-semibold text-gray-700 text-xl w-1/3'>Horario</p>}
				specifications={
					<ul className='list-disc pl-4'>
						<li>Formato 24-horas</li>
						<li>
							<div className='flex flex-col'>
								<p>
									Si es horario continuo rellenar los dos primeros campos y dejar los dos últimos
									vacíos.
								</p>
								<div className='flex items-center'>
									Ejemplo: <MockHour time='9:00' /> - <MockHour time='17:00' /> y de <MockHour /> -{' '}
									<MockHour />
								</div>
							</div>
						</li>
						<li>
							<div className='flex flex-col'>
								<p>Si es horario partido rellenar los cuatro campos</p>
								<div className='flex items-center'>
									Ejemplo: <MockHour time='9:00' /> - <MockHour time='14:00' /> y de{' '}
									<MockHour time='15:00' /> - <MockHour time='20:00' />
								</div>
							</div>
						</li>
						<li>No permite tener un día sin horas, para ello se marca como "Cerrado"</li>
					</ul>
				}
			/>
			<div className='ml-8' ref={ref} tabIndex='-1'>
				{elements}
			</div>
		</>
	);
};

const DayInput = ({ id, label, dayData = {}, onChange, disabled, props }) => {
	const isDisabled = disabled || dayData.closed;

	return (
		<div className='mb-2 py-2'>
			<Field
				id={id}
				className={`${!label && 'sr-only'} font-semibold text-gray-700 text-lg text-left`}
				labelclass='w-20 mb-4'
				containerclassname='flex items-center text-red-300'
				label={label || props.placeholder}
				mb=''
				maxW=''
				width=''
			>
				<div className='flex flex-col md:flex-row'>
					<div className='flex flex-col'>
						<div className='flex items-start'>
							<HourInput
								{...{
									isDisabled,
									dayData,
									onChange,
									id,
									className: 'mr-4',
									value: 'morningStart',
									props,
								}}
							/>
							<div className='mt-2'>-</div>
							<HourInput
								{...{
									isDisabled,
									dayData,
									onChange,
									id,
									value: 'morningEnd',
									props,
								}}
							/>
							<div className='pt-2'>y de</div>
						</div>
						<div className='w-full h-6'></div>
					</div>
					<div className='flex flex-col'>
						<div className='flex items-start'>
							<HourInput
								{...{
									isDisabled,
									dayData,
									onChange,
									id,
									value: 'afternoonStart',
									props,
								}}
							/>
							<div className='mt-2'>-</div>
							<HourInput
								{...{
									isDisabled,
									dayData,
									onChange,
									id,
									value: 'afternoonEnd',
									props,
								}}
							/>
							<Check
								checked={dayData.closed}
								label='Cerrado'
								onChange={() => onChange({ ...dayData, closed: !dayData.closed })}
								disabled={disabled}
							/>
						</div>
						<p className='flex w-full text-gray-400 italic pl-4'>
							*Rellenar solo en caso de jornada partida.
						</p>
					</div>
				</div>
			</Field>
		</div>
	);
};

const HourInput = ({ isDisabled, dayData, id, onChange, className = 'mx-4', value, ...props }) => {
	const [error, setError] = useState('');
	return (
		<div className={`flex flex-col ${className || ''}`}>
			<div
				className={`flex flex-col items-center w-100px border border-coral-400 bg-white rounded-md ${
					isDisabled || dayData.closed ? 'bg-gray-300 text-gray-500' : 'bg-white'
				}`}
			>
				<input
					type='text'
					autoComplete='none'
					maxLength={5}
					data-1p-ignore
					className={`peer w-full py-2 px-4 mx-auto text-center ${
						isDisabled ? 'bg-gray-300 cursor-not-allowed' : 'cursor-pointer'
					} rounded border-0 bg-transparent outline-none`}
					id={id}
					disabled={isDisabled}
					value={dayData[value]}
					onChange={e => {
						if (error) {
							setError('');
						}
						onChange({ ...dayData, [value]: e.target.value });
					}}
					onBlur={e => {
						if (isValid24HourFormat(e.target.value)) {
							if (e.target.value.charAt(0) === '0') {
								onChange({ ...dayData, [value]: e.target.value.slice(1) });
							}
							setError('');
						} else {
							setError('formato incorrecto');
						}
					}}
					{...props}
				/>
			</div>
			{error && <p className='text text-center'>{error}</p>}
		</div>
	);
};

const MockHour = ({ time, className = 'mx-2' }) => (
	<div className={`flex flex-col ${className || ''}`}>
		<div className='flex flex-col items-center w-60px border border-coral-400 bg-white rounded-md'>
			<div className='w-full py-1 px-1 mx-auto text-center rounded border-0 bg-transparent outline-none'>
				{time ? time : <div className='w-60px h-[27px]' />}
			</div>
		</div>
	</div>
);

export default Schedule;
