/**
 * Display name of user.
 *
 * @param {Object} user User object.
 * @returns {String} display name available in the account (first name, last name or email).
 */
const printUserName = user => {
	if (user?.firstName) {
		let printName = user?.firstName;
		if (user?.lastName) {
			printName = `${printName} ${user?.lastName}`;
		}
		return printName;
	}
	return user?.email;
};

export default printUserName;
