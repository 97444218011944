import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import { useToast } from 'components/Toast';
import { useSetImageAttributes } from 'hooks/use-media';
import { useLazyProductCategory, useProductCategoryUpdateMutation } from 'hooks/use-product-categories';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useConf from 'hooks/use-conf';
import CategoryForm from './CategoryForm';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';
import useRevalidateCategory from 'lib/revalidateCategory';
import Button from 'components/Button';

const CategoryEdit = () => {
	// Hooks and imports
	const { config } = useConf();
	const { locale, logOut } = useAuth();
	const { addErrorMessage } = useToast();
	const { categoryId } = useParams();
	const revalidateCategory = useRevalidateCategory();
	const [getLazyProductCategory] = useLazyProductCategory();
	const [setImageAttributes] = useSetImageAttributes();
	const navigate = useNavigate();

	// States
	const [dirty, setDirty] = useState(false);
	const [loading, setLoading] = useState(true);
	const [productCategoryData, setProductCategoryData] = useState(null);

	// Excecute once at page load
	useEffect(() => {
		// query ProductCategory fields with specific locale
		getLazyProductCategory({
			variables: { id: categoryId, forceLocale: true, locale },
			onCompleted: ({ ProductCategory }) => {
				if (
					isSuccessResponse(ProductCategory, ['ProductCategory'], logOut, addErrorMessage, 'Editar categoría')
				) {
					setLoading(false);
					setProductCategoryData(ProductCategory);
				}
			},
		});
	}, [locale, categoryId]);

	const [editCategory, { loading: isSubmitting }] = useProductCategoryUpdateMutation({
		onCompleted: ({ ProductCategoryUpdate }) => {
			if (
				isSuccessResponse(
					ProductCategoryUpdate,
					['ProductCategory'],
					logOut,
					addErrorMessage,
					'Editar categoría'
				)
			) {
				revalidateCategory(config, ProductCategoryUpdate, true);
				setDirty(false);
			}
		},
	});

	const handleSubmit = values => {
		let { Image, ...params } = values;
		editCategory({ variables: { id: categoryId, locale, input: { ...params } } });

		if (Image) {
			setImageAttributes({
				variables: { imageId: Image.id, attributes: { title: Image.title, alt: Image.alt } },
			});
		}
	};

	return (
		<>
			<LanguageTabs dirty={dirty} />
			<Card>
				<div className='flex'>
					<Button
						onClick={() => {
							if (
								dirty &&
								!window.confirm('Hay cambios, si sales los vas a perder ¿quieres continuar?')
							) {
								return;
							} else {
								setDirty(false);
								navigate('/dashboard/catalogo/categorias');
							}
						}}
						className='flex mb-4'
					>
						<img className='w-6 h-6 mr-2' alt='back' src='/images/circle-chevron-left-solid.svg' />
						Volver a Categorías
					</Button>
				</div>
				<LayoutHeader>Editar Categoría - {productCategoryData?.name}</LayoutHeader>
				<LoadingView whileLoading='Buscando categoría' loading={loading}>
					<CategoryForm
						productCategory={productCategoryData}
						isSubmitting={isSubmitting}
						onSubmit={handleSubmit}
						setDirty={setDirty}
						dirty={dirty}
						getLazyProductCategory={getLazyProductCategory}
					/>
				</LoadingView>
			</Card>
		</>
	);
};

export default CategoryEdit;
