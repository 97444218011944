import Card from 'components/Card';
import Currency from 'components/Currency';
import LayoutHeader, { SectionHeader } from 'components/LayoutHeader';
import formatDate from 'lib/formatDate';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMerchantPayment, useTransferMerchantPayment, useMerchantPaymentOrders } from 'hooks/use-merchant-payments';
import useAuth from 'hooks/use-auth';
import columnTitleElement from 'lib/columnTitleElement';
import ContentTable from 'components/ContentTable';
import Pagination from 'components/Pagination';
import translasteStatus from 'lib/translasteStatus';
import { useToast } from 'components/Toast';
import Button from 'components/Button';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import TransferModal from 'components/TransferModal';
import isSuccessResponse from 'lib/isSuccessResponse';

const MerchantPaymentDetail = () => {
	const { addErrorMessage, addSuccessMessage } = useToast();
	const { user, locale, logOut } = useAuth();
	const { merchantpaymentId } = useParams();
	const [offset, setOffset] = useState(0);
	const [limit] = useState(50);
	const [openModalTransfer, setOpenModalTransfer] = useState(false);
	const [paymentValue, setPaymentValue] = useState(0);
	const [merchantPaymentResponse, setMerchantPaymentResponse] = useState(null);
	const [ordersResponse, setOrdersResponse] = useState(null);

	useMerchantPayment({
		variables: {
			MerchantId: user?.MerchantId || null,
			MerchantPaymentId: merchantpaymentId,
			locale,
		},
		onCompleted: ({ MerchantPayment }) => {
			if (isSuccessResponse(MerchantPayment, ['MerchantPayment'], logOut, addErrorMessage, 'Facturación')) {
				setMerchantPaymentResponse(MerchantPayment);
			}
		},
	});

	useMerchantPaymentOrders({
		variables: {
			MerchantPaymentId: merchantpaymentId,
			limit,
			offset,
		},
		onCompleted: ({ MerchantPaymentOrders }) => {
			if (
				isSuccessResponse(MerchantPaymentOrders, ['MerchantPaymentOrders'], logOut, addErrorMessage, 'Pedidos')
			) {
				setOrdersResponse(MerchantPaymentOrders);
			}
		},
	});

	const [transferMerchantPayment] = useTransferMerchantPayment({
		variables: {
			MerchantPaymentId: merchantpaymentId,
			quantity: Number.parseFloat(paymentValue),
			locale,
		},
		onCompleted: ({ TransferMerchantPayment }) => {
			if (isSuccessResponse(TransferMerchantPayment, ['Success'], logOut, addErrorMessage, 'Transferencia')) {
				setMerchantPaymentResponse(prev => ({
					...prev,
					status: 'paid',
					transferredTotal: Number.parseFloat(paymentValue),
				}));
				addSuccessMessage('Transferencia', 'Realizada correctamente');
			}
		},
	});

	const openPdf = async () => {
		if (merchantPaymentResponse.pdf) {
			const pdfWindow = window.open('');
			pdfWindow.document.write(
				'<iframe width="100%" height="100%" src="data:application/pdf;base64, ' +
					encodeURI(merchantPaymentResponse.pdf) +
					'"></iframe>'
			);
		}
	};

	return (
		<>
			<Card className='mb-4'>
				<LayoutHeader>Resumen a Facturar de {merchantPaymentResponse?.Merchant?.commercialName}</LayoutHeader>
				<div className='mt-5'>
					<div className='flex flex-col w-1/5 mt-5'>
						<div className='flex content-between gap-4'>
							<p className='text-coral-500 flex-1'>Fecha inicial</p>
							<p>
								{merchantPaymentResponse?.startDate
									? formatDate(merchantPaymentResponse?.startDate)
									: 'Inicio'}
							</p>
						</div>
						<div className='flex content-between gap-4'>
							<p className='text-coral-500 flex-1'>Fecha final</p>
							<p>{formatDate(merchantPaymentResponse?.endDate)}</p>
						</div>

						<div className='flex content-between gap-4'>
							<p className='text-coral-500 flex-1'>Estado</p>
							<span
								className={`border font-medium text-center py-1 px-2 ${
									merchantPaymentResponse?.status === 'paid'
										? 'border-green-400 text-green-400'
										: 'border-coral-500 text-coral-500'
								}`}
							>
								{translasteStatus(merchantPaymentResponse?.status)}
							</span>
						</div>

						<div className='flex content-between gap-4'>
							<p className='text-coral-500 flex-1'>Número de Pedidos</p>
							<p>{merchantPaymentResponse?.orderCount || 0}</p>
						</div>
						<div className='flex content-between gap-4'>
							<p className='text-coral-500 flex-1'>Total Impuestos</p>
							<Currency>{merchantPaymentResponse?.MerchantPaymentTotals?.totalTax || 0}</Currency>
						</div>
						<div className='flex content-between gap-4'>
							<p className='text-coral-500 flex-1'>Total Descuentos</p>
							<Currency>
								{merchantPaymentResponse?.MerchantPaymentTotals?.grossTotalDiscounts || 0}
							</Currency>
						</div>
						<div className='flex content-between gap-4'>
							<p className='text-coral-500 flex-1'>Total de Ventas</p>
							<Currency>{merchantPaymentResponse?.MerchantPaymentTotals?.grossTotalPrice || 0}</Currency>
						</div>
						{merchantPaymentResponse?.status === 'paid' && (
							<div className='flex content-between gap-4'>
								<p className='text-coral-500 flex-1'>Total Transferido</p>
								<Currency>{merchantPaymentResponse?.transferredTotal || 0}</Currency>
							</div>
						)}
					</div>
					<div className='grid grid-gap-4 grid-cols-2 mt-5'></div>
				</div>
				<Button onClick={() => openPdf()}>Ver resumen de facturación</Button>
				{merchantPaymentResponse?.status === 'created' && (
					<AuthorizationBoundary for={['admin']}>
						<Button
							className='mt-6'
							onClick={() => {
								setPaymentValue(Number.parseFloat(merchantPaymentResponse.grossTotal));
								setOpenModalTransfer(true);
							}}
						>
							Transferir a {merchantPaymentResponse.Merchant?.commercialName}
						</Button>
					</AuthorizationBoundary>
				)}
			</Card>
			<Card className='mb-20'>
				<SectionHeader className='mt-5'>Detalle Pedidos</SectionHeader>
				<Pagination
					offset={offset}
					setOffset={setOffset}
					count={ordersResponse?.count}
					hasMore={ordersResponse?.hasMore}
					limit={limit}
				/>
				<ContentTable
					columns={[
						columnTitleElement('Nº Pedido'),
						columnTitleElement('Fecha'),
						columnTitleElement('Total Descuentos'),
						columnTitleElement('Total IVA'),
						columnTitleElement('Total Compra'),
					]}
					content={ordersResponse?.List}
					render={(order, index) =>
						order && (
							<tr key={index} className='text-gray-700 border-t border-dashed border-coral-300'>
								<td className='px-4 py-2'>
									<Link
										className='border-b border-dashed border-coral-300 hover:text-coral-500 tranisiton-color duration-100'
										to={`/dashboard/pedido/${order.id}`}
									>
										{order.id}
									</Link>
								</td>
								<td className='px-4 py-2'>{formatDate(order.updatedAt)}</td>
								<td className='px-4 py-2'>
									<Currency>{order.grossTotalDiscounts}</Currency>
								</td>
								<td className='px-4 py-2'>
									<Currency>{order.totalTax}</Currency>
								</td>
								<td className='px-4 py-2'>
									<Currency>{order.grossTotalPrice}</Currency>
								</td>
							</tr>
						)
					}
					empty={<p>No hay pedidos</p>}
				/>
			</Card>
			<TransferModal
				merchantPayment={merchantPaymentResponse}
				paymentValue={paymentValue}
				setPaymentValue={setPaymentValue}
				transferMerchantPayment={transferMerchantPayment}
				openModalTransfer={openModalTransfer}
				setOpenModalTransfer={setOpenModalTransfer}
			/>
		</>
	);
};
export default MerchantPaymentDetail;
