import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import useAuth from 'hooks/use-auth';
import useConf from 'hooks/use-conf';
import TooltipMenu from './TooltipMenu';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import printUserName from 'lib/printUserName';

const Header = props => {
	const { config } = useConf();

	return (
		<div className='bg-black text-white p-4 flex items-center'>
			<p className='flex-1'>
				<Link to='/' className='flex'>
					<img
						src={`${config.statics}/mp/logow.svg`}
						alt={`${config.marketplace_name}`}
						className='h-10 mr-2'
					/>
				</Link>
			</p>
			<AuthorizationBoundary for={['merchant']}>
				<MerchantActiveReminderMessage />
			</AuthorizationBoundary>
			<Menu {...props} />
		</div>
	);
};

export default Header;

const adminTooltipMenuList = [
	{ title: 'Datos de perfil', href: '/dashboard/account' },
	{ title: 'Categorías', href: '/dashboard/catalogo/categorias' },
	{ title: 'FAQ', href: '/dashboard/faq' },
	{ title: 'Log', href: '/dashboard/revision/eventos' },
	{ title: 'Historial de versiones', href: '/dashboard/versions' },
];

const merchantTooltipMenuList = [
	{ title: 'Datos de perfil', href: '/dashboard/account' },
	{ title: 'Ficha administrativa', href: '/dashboard/edit/administrative' },
	{ title: 'Ficha comercial', href: '/dashboard/edit/commercial' },
	{ title: 'Historial de versiones', href: '/dashboard/versions' },
];

const translatorTooltipMenuList = [
	{ title: 'Datos de perfil', href: '/dashboard/account' },
	{ title: 'Historial de versiones', href: '/dashboard/versions' },
];

const menuListByUserType = {
	admin: adminTooltipMenuList,
	merchant: merchantTooltipMenuList,
	translator: translatorTooltipMenuList,
};

const Menu = () => {
	const { user, notificationCounter } = useAuth();
	const { config } = useConf();

	return (
		<>
			<div className='border-r border-gray-800 mr-4 pr-4 flex items-center'>
				<div className='border border-gray-300 rounded-full h-10 w-10 overflow-hidden mr-4'>
					<img
						className='object-cover h-full w-full'
						src={user?.Image?.src || `${config.statics}/mp/favicon.svg`}
						alt='Imagen de perfil'
					/>
				</div>
				<div>
					<p>{printUserName(user)}</p>
					<p className='flex items-center text-xs'>
						<UserPanelIcon className='mr-0' />
						<span>
							Panel{' '}
							{user?.type === 'admin'
								? 'Administrador'
								: user?.type === 'translator'
								? 'Traductor'
								: 'Asociado'}
						</span>
					</p>
				</div>
				{user?.status === 'pending' || user?.status === 'revision' ? null : (
					<TooltipMenu menuList={menuListByUserType[user.type]} ml='ml-8' mt='mt-10' chevron />
				)}
			</div>
			<AuthorizationBoundary for={['admin']}>
				<NotificationButton
					className='border-r border-gray-800 mr-2 pr-4 flex items-center h-10'
					notificationCounter={notificationCounter}
				/>
			</AuthorizationBoundary>
			{/* <MailButton className='border-r border-gray-800 mr-2 pr-4 w-12 h-8' /> */}
			<QuestionButton className='ml-2 cursor-pointer' />
			<ExitButton className='ml-2 cursor-pointer' />
		</>
	);
};

const UserPanelIcon = ({ className }) => (
	<svg height='16' viewBox='0 0 21 21' width='16' xmlns='http://www.w3.org/2000/svg' className={className}>
		<g
			fill='none'
			fillRule='evenodd'
			stroke='#fff'
			strokeLinecap='round'
			strokeLinejoin='round'
			transform='matrix(0 1 -1 0 17.5 3.5)'
		>
			<path
				d='m9.828 1.672v5.656h-5.656'
				transform='matrix(.70710678 -.70710678 -.70710678 -.70710678 5.232244 12.631756)'
			/>
			<path d='m7 .5v11' />
			<path d='m0 14.5h14' />
		</g>
	</svg>
);

const ExitButton = ({ className }) => {
	const { logOut } = useAuth();

	return (
		<button
			className='flex p-4 cursor-pointer focus:outline-none'
			onClick={() => {
				logOut();
			}}
		>
			<svg height='28' viewBox='0 0 21 21' width='28' xmlns='http://www.w3.org/2000/svg' className={className}>
				<g
					fill='none'
					fillRule='evenodd'
					stroke='#fff'
					strokeLinecap='round'
					strokeLinejoin='round'
					transform='translate(3 3)'
				>
					<path
						d='m12.717 5.379-.068 4.175-4.175.067'
						transform='matrix(.70710678 .70710678 .70710678 -.70710678 -2.199921 5.311079)'
					/>
					<path d='m9 3v9' transform='matrix(0 1 -1 0 16.5 -1.5)' />
					<path
						d='m-1.74806976 2.74806976.0022166 7.50105294c.00091744 1.1043385.89625231 1.9994088 2.00059101 1.9999999h9.99438475c1.1045695 0 2-.8954305 2-2v-7.50105284'
						transform='matrix(0 1 -1 0 12.749 2.249)'
					/>
				</g>
			</svg>
		</button>
	);
};

const QuestionButton = ({ className }) => (
	<Link to='/dashboard/help'>
		<svg height='28' viewBox='0 0 21 21' width='28' xmlns='http://www.w3.org/2000/svg' className={className}>
			<g fill='none' fillRule='evenodd' transform='translate(2 2)'>
				<circle cx='8.5' cy='8.5' r='8' stroke='#fff' strokeLinecap='round' strokeLinejoin='round' />
				<path
					d='m8.5 9.5v-1l1.41421356-1.41421356c.37507274-.37507276.58578644-.88378059.58578644-1.41421356v-.17157288c0-.61286606-.3462631-1.17313156-.89442719-1.4472136l-.21114562-.1055728c-.56305498-.2815275-1.2257994-.2815275-1.78885438 0l-.10557281.0527864c-.61286606.30643303-1 .9328289-1 1.61803399v.88196601'
					stroke='#fff'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<circle cx='8.5' cy='12.5' fill='#fff' r='1' />
			</g>
		</svg>
	</Link>
);

const NotificationButton = ({ className, notificationCounter }) => (
	<div className={className}>
		{(notificationCounter?.merchantsCount || notificationCounter?.productsCount) && (
			<span className='mr-1'>{notificationCounter?.merchantsCount + notificationCounter?.productsCount}</span>
		)}
		<svg height='28' width='28' viewBox='0 0 113 123' version='1.1' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M43.976,31.269c8.134,-1.67 16.268,-1.584 24.403,0.005c1.692,0.331 3.335,-0.775 3.666,-2.467c0.331,-1.693 -0.775,-3.336 -2.468,-3.667c-8.953,-1.749 -17.905,-1.832 -26.858,0.006c-1.689,0.347 -2.779,2 -2.432,3.69c0.346,1.689 2,2.78 3.689,2.433Z'
				fill='#fff'
			/>
			<path
				d='M27.83,84.71c18.285,0.75 36.57,0.758 54.854,0c1.724,-0.071 3.065,-1.528 2.993,-3.251c-0.071,-1.724 -1.528,-3.065 -3.251,-2.993c-18.113,0.75 -36.226,0.743 -54.339,-0c-1.724,-0.071 -3.18,1.271 -3.251,2.994c-0.07,1.723 1.271,3.18 2.994,3.25Z'
				fill='#fff'
			/>
			<path
				d='M50.045,10.35c-0.526,-0.986 -0.824,-2.112 -0.824,-3.307c-0,-3.887 3.156,-7.043 7.043,-7.043c3.887,0 7.043,3.156 7.043,7.043c-0,1.166 -0.284,2.266 -0.787,3.235c-0.001,0.04 -0.002,0.08 -0.004,0.119l0.03,-0.006l0.016,0.019c17.537,3.016 22.676,19.322 22.676,30.759c0,15.721 1.299,34.174 25,50c8.927,5.961 -10.268,10.417 -22.917,10.417l-62.5,-0c-12.648,-0 -30.761,-3.091 -22.916,-10.417c24.855,-23.212 25,-34.279 25,-50c-0,-11.513 6.697,-27.961 23.14,-30.819Zm6.245,5.545l-1.098,0c-16.16,0 -22.311,15.158 -22.311,25.274c-0,16.602 0.068,28.528 -24.788,52.37c0.731,0.256 1.514,0.497 2.231,0.655c4.404,0.976 9.853,1.416 14.497,1.416c0,-0 62.5,-0 62.5,-0c4.824,-0 10.698,-0.707 15.287,-1.936c0.19,-0.051 0.384,-0.108 0.58,-0.17c-22.511,-16.803 -23.926,-35.895 -23.926,-52.335c0,-9.951 -4.597,-24.739 -21.752,-25.259c-0.052,0.006 -0.105,0.012 -0.157,0.018l-1.089,-0l0.026,-0.033Z'
				fill='#fff'
			/>
			<path
				d='M72.047,106.461c0,8.711 -7.072,15.783 -15.783,15.783c-8.712,0 -15.784,-7.072 -15.784,-15.783l31.567,-0Z'
				fill='#fff'
			/>
		</svg>
	</div>
);

const MerchantActiveReminderMessage = () => {
	const { user } = useAuth();
	// If the user is not 'active' do not set timer and do not create state
	if (user.status !== 'active') return null;
	const [isMessageVisible, setIsMessageVisible] = useState(true);
	useEffect(() => {
		const timer = setTimeout(() => {
			setIsMessageVisible(false);
		}, 10_000);
		return () => clearTimeout(timer);
	}, []);

	return isMessageVisible && user.status === 'active' ? (
		<div className='border-4 border-green-600 p-2 rounded-md mr-2 animate-pulse ease-in-out'>
			Acceso a tu perfil y fichas de empresa -{'>'}
		</div>
	) : null;
};

// const MailButton = ({ className }) => {
// 	const { data } = useRevisionCount();
// 	return (
// 		<div>
// 			<svg height='28' viewBox='0 0 21 21' width='28' xmlns='http://www.w3.org/2000/svg' className={className}>
// 				<g
// 					fill='none'
// 					fillRule='evenodd'
// 					stroke='#fff'
// 					strokeLinecap='round'
// 					strokeLinejoin='round'
// 					transform='matrix(0 -1 1 0 2.5 16.5)'
// 				>
// 					<path d='m2 .5h8c1.1045695 0 2 .8954305 2 2v10c0 1.1045695-.8954305 2-2 2h-8c-1.1045695 0-2-.8954305-2-2v-10c0-1.1045695.8954305-2 2-2z' />
// 					<path d='m2 6 5 3 5-3' transform='matrix(0 1 -1 0 14.5 .5)' />
// 				</g>
// 			</svg>
// 			<Pill className='mr-2'>{data?.RevisionCount?.number}</Pill>
// 		</div>
// 	);
// };
