const days = 7;
export const emptySchedule = Array.from({ length: days }).fill({
	morningStart: '',
	morningEnd: '',
	afternoonStart: '',
	afternoonEnd: '',
	closed: false,
});

export const stringifiedEmptySchedule = JSON.stringify(emptySchedule);
