import React, { useEffect, useState } from 'react';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import Card from 'components/Card';
import LayoutHeader from 'components/LayoutHeader';
import CustomersList from 'components/CustomersList';
import SearchBox from 'components/SearchBox';
import { useToast } from 'components/Toast';
import { useLazyUsers } from 'hooks/use-customers';
import Pagination from 'components/Pagination';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';
const paginationLimit = 50;

const Customers = () => {
	const { logOut } = useAuth();
	const { addErrorMessage } = useToast();

	const [search, setSearch] = useState('');
	const [customers, setCustomers] = useState(null);
	const [offset, setOffset] = useState(0);
	const [orderScope, setOrderScope] = useState('created_asc');
	const [scope, setScope] = useState('all_customers');
	const [getLazyUsers, { data, loading }] = useLazyUsers({
		search,
		scope,
		order_scope: orderScope,
		limit: paginationLimit,
		offset,
	});

	// Excecute once at page load
	useEffect(() => {
		getLazyUsers();
	}, [getLazyUsers]);

	// Excecute after first load and on search
	useEffect(() => {
		if (data && isSuccessResponse(data.Users, ['UserList'], logOut, addErrorMessage, 'Usuarios')) {
			setCustomers(data?.Users);
		}
	}, [data, addErrorMessage]);

	const orderByDate = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'created_asc' },
			{ label: 'Descendente', scope: 'created_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyUsers();
			},
		}));
	};
	const orderByFirstName = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'firstName_asc' },
			{ label: 'Descendente', scope: 'firstName_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyUsers();
			},
		}));
	};

	const orderByEmail = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'email_asc' },
			{ label: 'Descendente', scope: 'email_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyUsers();
			},
		}));
	};
	const handleSearch = input => {
		setSearch(input);
		getLazyUsers();
	};

	const handleClearSearch = () => {
		setSearch('');
		getLazyUsers();
	};

	const filterByStatus = scope => {
		setScope(scope);
		setOffset(0);
		getLazyUsers();
	};

	const statusList = [
		{ title: 'Todos', onClick: () => filterByStatus('all_customers') },
		{ title: 'Registrados', onClick: () => filterByStatus('registered_customers') },
		{ title: 'Activos', onClick: () => filterByStatus('active_customers') },
	];

	return (
		<AuthorizationBoundary for={['admin']}>
			<Card>
				<LayoutHeader>Listado de clientes</LayoutHeader>
				<span className='flex w-full'>
					<SearchBox
						placeholder='Busca usuario'
						search={handleSearch}
						loading={loading}
						clearSearch={handleClearSearch}
					/>
					<Pagination
						offset={offset}
						setOffset={setOffset}
						count={customers?.count}
						hasMore={customers?.hasMore}
						limit={paginationLimit}
					/>
				</span>
				<CustomersList
					data={customers?.List}
					orderByDate={orderByDate}
					orderByEmail={orderByEmail}
					orderByFirstName={orderByFirstName}
					loading={loading}
					customers={customers}
					setCustomers={setCustomers}
					statusList={statusList}
				/>
			</Card>
		</AuthorizationBoundary>
	);
};

export default Customers;
