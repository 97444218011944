import formatDate from 'lib/formatDate';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ContentTable from './ContentTable';
import LoadingView from 'components/LoadingView';
import printUserName from 'lib/printUserName';
import columnTitleElement from 'lib/columnTitleElement';
import TooltipMenu from 'components/TooltipMenu';
import Modal from 'components/Modal';
import { useCustomerDelete } from 'hooks/use-customers';
import { useToast } from 'components/Toast';
import translasteStatus from 'lib/translasteStatus';
import useToggle from 'hooks/use-toggle';

const CustomersList = ({
	data,
	loading,
	orderByDate,
	orderByFirstName,
	orderByEmail,
	statusList,
	customers,
	setCustomers,
}) => {
	const { addErrorMessage, addSuccessMessage } = useToast();
	const [openModalSoftDelete, setOpenModalSoftDelete] = useToggle();
	const [openModalHardDelete, setOpenModalHardDelete] = useToggle();
	const [userToDelete, setUserToDelete] = useState(null);
	const [customerDelete] = useCustomerDelete();

	const customerMenuList = ({ id, email }) => {
		const options = [
			{
				title: 'Detalles',
				href: `/dashboard/clientes/${id}`,
			},
			{
				title: 'Eliminar y conservar datos',
				onClick: () => {
					setUserToDelete({ id, email });
					setOpenModalSoftDelete(true);
				},
			},
			{
				title: 'Eliminar completamente de base de datos',
				onClick: () => {
					setUserToDelete({ id, email });
					setOpenModalHardDelete(true);
				},
			},
		];

		return options;
	};

	return (
		<LoadingView loading={loading} whileLoading='Buscando clientes…'>
			<ContentTable
				columns={[
					columnTitleElement('Email', orderByEmail),
					columnTitleElement('Nombre', orderByFirstName),
					columnTitleElement('Fecha de alta', orderByDate),
					columnTitleElement('Estado', () => statusList),
				]}
				content={data}
				render={user =>
					user && (
						<tr key={user.id} className='text-gray-700 border-t border-dashed border-coral-300'>
							<td className='px-4 py-2'>
								<Link
									className='border-b border-dashed border-coral-300 hover:text-coral-500 tranisiton-color duration-100'
									to={`/dashboard/clientes/${user.id}`}
								>
									{user.email}
								</Link>
							</td>
							<td className='px-4 py-2'>
								<Link
									className='border-b border-dashed border-coral-300 hover:text-coral-500 tranisiton-color duration-100'
									to={`/dashboard/clientes/${user.id}`}
								>
									{printUserName(user)}
								</Link>
							</td>
							<td className='px-4 py-2'>{formatDate(user.createdAt)}</td>
							<td id={`status_${user.email}`} className='px-4 py-2'>
								{translasteStatus(user.status)}
							</td>
							<td className='px-6'>
								<div className='h-16'>
									<TooltipMenu
										menuList={customerMenuList({ id: user.id, email: user.email })}
										menuClassName='w-40 -right-40'
									/>
								</div>
							</td>
							{/* <td className='px-4 py-2'>próximamente</td> */}
						</tr>
					)
				}
				empty={<p>No hay usuarios</p>}
			/>
			{userToDelete?.id ? (
				<>
					<Modal
						modalObject={{
							title: 'Confirmación',
							description: (
								<p className='text-lg leading-5 text-gray-500'>{`Al confirmar se eliminará la cuenta asociada al email ${userToDelete.email} y no se podrá anular.`}</p>
							),
							btnText: 'Confirmar',
							cancelBtnText: 'Cancelar',
						}}
						openModal={openModalSoftDelete}
						setOpenModal={setOpenModalSoftDelete}
						handleSubmit={() => {
							customerDelete({
								variables: {
									customerId: userToDelete.id,
									forceDelete: false,
								},
							}).then(res => {
								if (res.data.CustomerDelete.success) {
									addSuccessMessage('Usuario', `${userToDelete.email} eliminado correctamente`);
									const updatedCustomers = customers.List.filter(
										customer => customer.id !== userToDelete.id
									);
									setCustomers(prev => ({
										...prev,
										List: updatedCustomers,
									}));
								} else {
									addErrorMessage('Usuario', res.data.CustomerDelete.message);
								}
								setOpenModalSoftDelete(false);
							});
						}}
					/>
					<Modal
						modalObject={{
							title: 'Confirmación',
							description: (
								<p className='text-lg leading-5 text-gray-500'>{`Al confirmar se eliminará por completo de la base de datos la cuenta asociada al email ${userToDelete.email} y no se podrá anular.`}</p>
							),
							btnText: 'Confirmar',
							cancelBtnText: 'Cancelar',
						}}
						openModal={openModalHardDelete}
						setOpenModal={setOpenModalHardDelete}
						handleSubmit={() => {
							customerDelete({
								variables: {
									customerId: userToDelete.id,
									forceDelete: true,
								},
							}).then(res => {
								if (res.data.CustomerDelete.success) {
									addSuccessMessage(
										'Usuario',
										`${userToDelete.email} eliminado de base de datos correctamente`
									);
									const updatedCustomers = customers.List.filter(
										customer => customer.id !== userToDelete.id
									);
									setCustomers(prev => ({
										...prev,
										List: updatedCustomers,
									}));
								} else {
									addErrorMessage('Usuario', res.data.CustomerDelete.message);
								}
								setOpenModalHardDelete(false);
							});
						}}
					/>
				</>
			) : null}
		</LoadingView>
	);
};

export default CustomersList;
