import React, { useState, useEffect } from 'react';
import { useToast } from './Toast';
import Button from './Button';
import useConf from 'hooks/use-conf';

const VersionChecker = ({ versionCheckInterval = 60 * 60 * 1000 }) => {
	const { config } = useConf();
	const { addErrorMessage } = useToast();
	const [showUpdateNotification, setShowUpdateNotification] = useState(false);

	const checkForUpdates = async () => {
		try {
			const versionFile = await fetch('/version');
			const latestVersion = await versionFile.text();

			const browserVersion = localStorage.getItem('app_version');
			if (browserVersion === null) {
				localStorage.setItem('app_version', latestVersion);
			} else if (browserVersion !== latestVersion) {
				setShowUpdateNotification(true);
				localStorage.setItem('app_version', latestVersion);
			}

			localStorage.setItem('last_version_check', Date.now());
		} catch (error) {
			addErrorMessage('Error al solicitar versión:', error);
		}
	};

	const shouldCheckForUpdates = () => {
		const lastVersionCheck = localStorage.getItem('last_version_check');

		if (!lastVersionCheck) {
			return true;
		}

		const timeSinceLastCheck = Date.now() - Number.parseInt(lastVersionCheck, 10);

		return timeSinceLastCheck >= versionCheckInterval;
	};

	useEffect(() => {
		const handleWindowFocus = () => {
			if (shouldCheckForUpdates()) {
				checkForUpdates();
			}
		};

		if (shouldCheckForUpdates()) {
			checkForUpdates();
		}

		window.addEventListener('focus', handleWindowFocus);

		return () => {
			window.removeEventListener('focus', handleWindowFocus);
		};
	}, []);

	return (
		showUpdateNotification && (
			<div className='fixed inset-0 flex items-center justify-center z-50 px-10 md:px-0'>
				<div className='fixed z-10 inset-0 bg-black opacity-75'></div>
				<div className='bg-white z-50 rounded-lg p-8 shadow-md md:w-1/2'>
					<div className='bg-black flex justify-center items-center py-4'>
						<img
							src={`${config.statics}/mp/logow.svg`}
							alt={`${config.marketplace_name}`}
							className='h-10'
						/>
					</div>
					<h2 className='font-bold mb-4 mt-4 text-xl md:text-2xl'>Nueva versión disponible</h2>
					<p className='mb-6 text-lg md:text-xl'>
						Una nueva versión de la aplicación está disponible. Por favor refresca la página o pulsa el
						botón "Refrescar" para utilizar las últimas actualizaciones
					</p>
					<Button
						onClick={() => {
							window.location.reload();
						}}
						className='text-white font-semibold py-2 px-6 rounded mx-auto text-xl md:text-2xl'
					>
						Refrescar
					</Button>
				</div>
			</div>
		)
	);
};

export default VersionChecker;
