import Field from 'components/Field';
import useDebounce from 'lib/useDebounce';
import React, { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useToast } from 'components/Toast';
import ConditionalWrapper from './ConditionalWrapper';

const InputForm = ({
	id,
	suffix,
	width = 'w-2/3',
	type = 'text',
	fieldWidth = 'w-full',
	fieldFloat = '',
	children,
	name,
	control,
	register = control.register,
	debounce = false,
	onSubmit = () => {},
	getValues = () => {},
	readOnly,
	showRequiredIcon = false,
	simpleStyle = false,
	className,
	regex,
	forcePositiveInteger = false,
	missingTranslations = false,
	...props
}) => {
	const { addErrorMessage } = useToast();
	let { field } = useController({
		name,
		control,
		rules: {
			required: props.required ?? false,
			validate: value => {
				if (regex && !regex.test(value)) {
					addErrorMessage(props.label, 'El formato no es válido');
					return false;
				}
				return true;
			},
		},
	});
	const [showPassword, setShowPassword] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const [bouncing, setBouncing] = useState(false);
	const [value, setValue] = useState(debounce ? getValues(name) : '');

	if (type === 'number') {
		if (forcePositiveInteger) {
			field = {
				...field,
				onKeyDown: e => {
					if ([',', '.', '+', '-', 'e', 'E'].includes(e.key)) {
						e.preventDefault();
					}
				},
			};
		}
		field = {
			...field,
			...register(name, {
				setValueAs: v => (v === '' || v === null || v === undefined ? '' : Number.parseFloat(v)),
			}),
		};
	}
	const { onChange, ...restField } = field;

	let debouncedInput = null;
	if (debounce) {
		debouncedInput = useDebounce(getValues(name));
	}

	useEffect(() => {
		setFirstRender(false);
		if (debounce && bouncing && !firstRender) {
			onSubmit();
			setBouncing(false);
		}
	}, [debouncedInput]);

	useEffect(() => {
		if (!firstRender && props.required && !value) {
			addErrorMessage(`${props.label}`, 'Campo requerido no puede estar vacío');
			setBouncing(false);
		}
		if (value === debouncedInput) {
			setBouncing(false);
		}
	}, [value?.length]);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	// Prevent React Warning about value being null
	if (restField.value === null || restField.value === undefined) {
		restField.value = '';
	}

	const isUntranslatedField = () => missingTranslations && !restField.value;

	if (type === 'password') {
		return (
			<Field width={fieldWidth} float={fieldFloat} showRequiredIcon={showRequiredIcon} {...props}>
				<div
					className={`flex items-center border border-coral-300  ${
						props?.disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
					} rounded-md py-2 px-4 ${width} ${className || ''}`}
				>
					<input
						{...restField}
						type={`${showPassword ? 'text' : 'password'}`}
						id={id}
						readOnly={readOnly}
						{...props}
						className={'flex-1 outline-none focus:outline-none'}
						onChange={e => {
							if (!readOnly) {
								onChange(e);
							}
						}}
					/>
					<span onClick={handleShowPassword}>
						<img
							className='w-5'
							alt='toggle password visibility'
							src={`${
								showPassword
									? '/images/form-icons/eye-slash-solid.svg'
									: '/images/form-icons/eye-solid.svg'
							}`}
						/>
					</span>
				</div>
				{children}
			</Field>
		);
	}

	if (simpleStyle) {
		return (
			<input
				{...restField}
				type={type}
				id={id}
				readOnly={readOnly}
				{...props}
				className={className}
				onChange={e => {
					if (!readOnly) {
						onChange(e);
					}
				}}
			/>
		);
	}

	return (
		<Field width={fieldWidth} float={fieldFloat} {...props}>
			<ConditionalWrapper condition={children} wrapper={children => <div className='flex w-2/3'>{children}</div>}>
				<div
					className={`flex items-center border  ${
						props?.disabled
							? 'bg-gray-300 text-gray-500'
							: isUntranslatedField()
							? 'bg-coral-100'
							: 'bg-white'
					} rounded-md py-2 px-4 ${children ? 'w-full' : width} ${
						readOnly ? 'border-gray-500 text-gray-600 bg-gray-300' : 'border-coral-300'
					} ${className || ''}`}
					onBlur={e => {
						if (debounce && bouncing) {
							setValue(e.target.value);
							onSubmit();
							setBouncing(false);
						}
					}}
				>
					<input
						{...restField}
						type={type}
						id={id}
						{...props}
						readOnly={readOnly}
						className={`flex-1 outline-none focus:outline-none ${
							readOnly ? 'border-gray-500 text-gray-600 bg-gray-300' : 'border-coral-300'
						} ${isUntranslatedField() ? 'bg-coral-100' : ''}`}
						onChange={e => {
							if (debounce) {
								setValue(e.target.value);
								setBouncing(true);
							}
							if (!readOnly) {
								onChange(e);
							}
						}}
						onWheel={e => {
							if (type === 'number') {
								e.target.blur();
							}
						}}
					/>
					{suffix}
					{bouncing && (
						<div>
							<div className='w-4 h-4 border-l-4 border-b-2 border-coral-500 border-solid rounded-full animate-spin'></div>
						</div>
					)}
				</div>
				{children}
			</ConditionalWrapper>
		</Field>
	);
};

export default InputForm;
