import React, { useEffect, useState } from 'react';
import Card from 'components/Card';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import LayoutHeader from 'components/LayoutHeader';
import CustomerAddress from './CustomerAddress';
import CustomerAccountDetails from './CustomerAccountDetails';
import { useCustomer } from 'hooks/use-customers';
import { useParams, useNavigate } from 'react-router-dom';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';
import OrderList from 'components/OrderList';

const CustomerDetails = () => {
	const { logOut } = useAuth();
	const navigate = useNavigate();
	const { customerId } = useParams();
	const { data } = useCustomer({ customerId });
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [customerData, setCustomerDataResponse] = useState(null);

	useEffect(() => {
		if (data && isSuccessResponse(data.Customer, ['User'], logOut, addErrorMessage, 'Cliente')) {
			setCustomerDataResponse(data?.Customer);
		} else if (data && !data.Customer) {
			addErrorMessage('Cliente', 'Error: No se encontró cliente');
			navigate('/dashboard/clientes');
		}
	}, [data, addSuccessMessage, addErrorMessage, navigate]);

	return (
		<AuthorizationBoundary for={['admin']}>
			<Card>
				{customerData && (
					<>
						<LayoutHeader>Perfil del cliente</LayoutHeader>
						<CustomerAccountDetails title='Datos de la cuenta' customer={customerData} />
						<CustomerAddress title='Dirección de envío' address={customerData?.ShippingAddress} />
						<CustomerAddress title='Dirección de facturación' address={customerData?.BillAddress} />
						<OrderList
							userId={customerId}
							pageScope='all'
							title={
								customerData.firstName
									? 'Pedidos de ' + customerData?.firstName + ' ' + customerData?.lastName
									: 'Pedidos de ' + customerData?.email
							}
						/>
					</>
				)}
			</Card>
		</AuthorizationBoundary>
	);
};

export default CustomerDetails;
