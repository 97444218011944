const Tooltip = ({ children, tooltip, containerClassName = 'items-center w-full mb-10', triangleClassName = '' }) =>
	tooltip && (
		<span
			className='relative flex items-center group'
			data-bs-toggle='popover'
			data-bs-placement='top'
			data-bs-content={tooltip}
		>
			{tooltip && (
				<div className={`absolute bottom-0 flex-col hidden group-hover:flex ${containerClassName}`}>
					<span
						className={
							'flex w-auto z-40 text-md text-left leading-none font-medium  border-coral-500 bg-gray-200 border-2 rounded-md p-2'
						}
					>
						{tooltip}
					</span>
					<div className={`w-3 h-3 -mt-2 mb-0 rotate-45 bg-coral-500 ${triangleClassName}`}></div>
				</div>
			)}
			{children}
		</span>
	);

export default Tooltip;
