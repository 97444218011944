import Card from 'components/Card';
import LayoutHeader, { SectionHeader } from 'components/LayoutHeader';
import { useLazyRefund, useUpdateRefund } from 'hooks/use-orders';
import formatDate from 'lib/formatDate';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import printUserName from 'lib/printUserName';
import ContentTable from 'components/ContentTable';
import columnTitleElement from 'lib/columnTitleElement';
import Check from 'components/Check';
import Button from 'components/Button';
import isSuccessResponse from 'lib/isSuccessResponse';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';

const StatusCard = ({ currentStatus, newStatus, items, setItems, columnTitle, handleUpdateRefunds, user }) => {
	return items?.length > 0 ? (
		<>
			<ContentTable
				columns={[
					columnTitleElement('Producto'),
					columnTitleElement('Variante'),
					columnTitleElement('Cantidad'),
					columnTitleElement(columnTitle),
				]}
				thClassName='w-1/4'
				content={items}
				render={item =>
					item && (
						<tr key={item?.id} className='border-t border-dashed border-coral-300 text-sm text-gray-700'>
							<RefundItem
								item={item}
								items={items}
								setItems={setItems}
								status={currentStatus}
								user={user}
							/>
						</tr>
					)
				}
				empty={null}
			/>
			{(currentStatus === 'picked' || (user?.type === 'admin' && currentStatus === 'requested')) && (
				<div className='flex gap-2 mt-6'>
					<Button onClick={() => handleSelectAll(items, setItems, newStatus)}>Seleccionar todo</Button>
					<Button onClick={() => handleSelectAll(items, setItems, currentStatus)}>Limpiar todo</Button>
					<Button onClick={() => handleUpdateRefunds(items, newStatus)}>Confirmar cambios</Button>
				</div>
			)}
		</>
	) : (
		<p>No hay artículos</p>
	);
};

const RefundItem = ({ item, items, setItems, status, user }) => {
	const handleClick = () => {
		if (status === 'requested') {
			setItems(
				items.map(i => {
					return i.id != item.id
						? i
						: {
								...i,
								refundStatus: `${i.refundStatus === 'requested' ? 'picked' : 'requested'}`,
						  };
				})
			);
		}
		if (status === 'picked') {
			setItems(
				items.map(i => {
					return i.id != item.id
						? i
						: {
								...i,
								refundStatus: `${i.refundStatus === 'picked' ? 'completed' : 'picked'}`,
						  };
				})
			);
		}
	};

	return (
		<>
			<td className='px-4 py-2'>{item?.Variant?.Product?.title}</td>
			<td className='px-4 py-2'>{item?.Variant?.title}</td>
			<td className='px-4 py-2'>{item?.refundRequestedQuantity}</td>
			<td className='px-4 py-2'>
				{(status === 'picked' || (user?.type === 'admin' && status === 'requested')) && (
					<Check
						checked={
							status === 'requested'
								? item?.refundStatus === 'picked'
								: item?.refundStatus === 'completed'
						}
						onChange={handleClick}
						className='ml-2 w-48 checked:bg-blue-600'
					/>
				)}
			</td>
		</>
	);
};

const handleSelectAll = (items, setItems, newStatus) => {
	setItems(
		items.map(item => {
			return {
				...item,
				refundStatus: newStatus,
			};
		})
	);
};

const RefundDetail = () => {
	const { refundId } = useParams();
	const { addErrorMessage, addSuccessMessage } = useToast();
	const { user, logOut } = useAuth();

	const [getLazyRefund, { data }] = useLazyRefund({
		orderId: refundId,
	});
	const [updateRefund] = useUpdateRefund({
		onCompleted: ({ UpdateRefund }) => {
			if (isSuccessResponse(UpdateRefund, ['Success'], logOut, addErrorMessage, 'Devolución')) {
				addSuccessMessage('Devolución', 'actualizada correctamente');
			}
		},
	});

	const [isFormFilled, setIsFormFilled] = useState(false);
	const [requested, setRequested] = useState([]);
	const [picked, setPicked] = useState([]);
	const [completed, setCompleted] = useState([]);

	useEffect(() => {
		getLazyRefund();
	}, [getLazyRefund]);

	useEffect(() => {
		if (data) {
			setIsFormFilled(true);
		}
	}, [data]);

	useEffect(() => {
		setRequested(data?.Order?.RefundItems.filter(item => item.refundStatus === 'requested'));
		setPicked(data?.Order?.RefundItems.filter(item => item.refundStatus === 'picked'));
		setCompleted(data?.Order?.RefundItems.filter(item => item.refundStatus === 'completed'));
	}, [data]);

	const handleUpdateRefunds = (items, newStatus) => {
		items = items
			.filter(item => item.refundStatus === newStatus)
			.map(item => {
				return {
					id: item.id,
					OrderId: data.Order.id,
					MerchantId: item.MerchantId,
				};
			});
		if (items.length > 0) {
			updateRefund({
				variables: {
					items,
					status: newStatus,
				},
			});
		} else {
			addErrorMessage('Devolución', 'Debes seleccionar al menos un artículo');
		}
	};

	return (
		isFormFilled && (
			<>
				<Card className='mb-4'>
					<LayoutHeader>Detalles de la devolución {refundId}</LayoutHeader>
					<div className='mt-5'>
						<SectionHeader className='mt-5'>General</SectionHeader>
						<div className='flex flex-col w-1/5 mt-5'>
							<div className='flex content-between gap-4'>
								<p className='text-coral-500 flex-1'>Fecha de creación</p>
								<p>{formatDate(data?.Order?.orderDate)}</p>
							</div>
							<div className='flex content-between gap-4'>
								<p className='text-coral-500 flex-1'>Usuario</p>
								<p>{printUserName(data?.Order?.User)}</p>
							</div>
						</div>

						<div className='grid grid-cols-2 grid-gap-4 w-full mt-5'>
							<div>
								<SectionHeader>Envío</SectionHeader>
								<div>
									<p className='mt-5'>{data?.Order?.shippingName}</p>
									<p>{data?.Order?.shippingAddress1}</p>
									<p>{data?.Order?.shippingAddress2}</p>
									<p>
										{data?.Order?.shippingZip} {data?.Order?.shippingProvince}
									</p>
									<p>{data?.Order?.shippingCountry}</p>
									<p>{data?.Order?.billNotes}</p>
								</div>
							</div>
						</div>
						<div className='grid grid-gap-4 grid-cols-2 mt-5'></div>
					</div>
				</Card>

				<Card className='mb-4'>
					<SectionHeader className='mb-6'>Solicitados</SectionHeader>
					<StatusCard
						currentStatus='requested'
						newStatus='picked'
						items={requested}
						setItems={setRequested}
						columnTitle={`${user?.type === 'admin' ? 'Recoger' : ''}`}
						handleUpdateRefunds={handleUpdateRefunds}
						user={user}
					/>
				</Card>
				<Card className='mb-4'>
					<SectionHeader className='mb-6'>Recogidos</SectionHeader>
					<StatusCard
						currentStatus='picked'
						newStatus='completed'
						items={picked}
						setItems={setPicked}
						columnTitle='Completar'
						handleUpdateRefunds={handleUpdateRefunds}
						user={user}
					/>
				</Card>
				<Card className='mb-4'>
					<SectionHeader className='mb-6'>Completados</SectionHeader>
					<StatusCard currentStatus='completed' items={completed} columnTitle='' />
				</Card>
			</>
		)
	);
};
export default RefundDetail;
