import { gql, useMutation } from '@apollo/client';

const SEND_EMAIL_TO_NOTIFICATIONS_MUTATION = gql`
	mutation SendEmailToNotifications($subject: String, $text: String) {
		SendEmailToNotifications(subject: $subject, text: $text) {
			... on Success {
				success
			}
			... on AuthorizationFault {
				user
				message
			}
			... on MailFault {
				message
				emailRecipient
			}
		}
	}
`;

export const useSendEmailToNotifications = props => useMutation(SEND_EMAIL_TO_NOTIFICATIONS_MUTATION, props);
