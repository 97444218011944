import React, { useEffect, useState } from 'react';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import Card from 'components/Card';
import LayoutHeader from 'components/LayoutHeader';
import TranslatorsList from 'components/TranslatorsList';
import { useToast } from 'components/Toast';
import { useLazyUsers } from 'hooks/use-customers';
import Pagination from 'components/Pagination';
import SearchBox from 'components/SearchBox';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';
const paginationLimit = 50;

const Translators = () => {
	const { logOut } = useAuth();
	const { addErrorMessage } = useToast();

	const [translators, setTranslators] = useState(null);
	const [offset, setOffset] = useState(0);
	const [orderScope, setOrderScope] = useState('created_asc');
	const [search, setSearch] = useState('');
	const [getLazyUsers, { data, loading }] = useLazyUsers({
		search,
		scope: 'all_translators',
		order_scope: orderScope,
		limit: paginationLimit,
		offset,
	});

	// Excecute once at page load
	useEffect(() => {
		getLazyUsers();
	}, [getLazyUsers]);

	// Excecute after first load and on search
	useEffect(() => {
		if (data && isSuccessResponse(data.Users, ['UserList'], logOut, addErrorMessage, 'Usuarios')) {
			setTranslators(data?.Users);
		}
	}, [data, addErrorMessage]);

	const orderByDate = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'created_asc' },
			{ label: 'Descendente', scope: 'created_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyUsers();
			},
		}));
	};
	const orderByFirstName = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'firstName_asc' },
			{ label: 'Descendente', scope: 'firstName_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyUsers();
			},
		}));
	};

	const orderByEmail = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'email_asc' },
			{ label: 'Descendente', scope: 'email_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyUsers();
			},
		}));
	};
	const handleSearch = input => {
		setSearch(input);
		getLazyUsers();
	};
	const handleClearSearch = () => {
		setSearch('');
		getLazyUsers();
	};

	return (
		<AuthorizationBoundary for={['admin']}>
			<Card>
				<LayoutHeader>Listado de traductores</LayoutHeader>
				<span className='flex w-full'>
					<SearchBox
						placeholder='Busca usuario'
						search={handleSearch}
						loading={loading}
						clearSearch={handleClearSearch}
					/>
					<Pagination
						offset={offset}
						setOffset={setOffset}
						count={translators?.count}
						hasMore={translators?.hasMore}
						limit={paginationLimit}
					/>
				</span>
				<TranslatorsList
					orderByDate={orderByDate}
					orderByEmail={orderByEmail}
					orderByFirstName={orderByFirstName}
					loading={loading}
					translators={translators?.List}
					setTranslators={setTranslators}
				/>
			</Card>
		</AuthorizationBoundary>
	);
};

export default Translators;
