import { useState } from 'react';
import Button from './Button';
import Input from './Input';
import LayoutHeader from './LayoutHeader';
import Card from './Card';
import { useZipInvoices, useDeleteZip } from 'hooks/use-invoices';
import { useToast } from './Toast';
import isSuccessResponse from 'lib/isSuccessResponse';
import useAuth from 'hooks/use-auth';

const DownloadInvoices = () => {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const { addErrorMessage } = useToast();
	const { logOut } = useAuth();
	const [getZipInvoices] = useZipInvoices();
	const [deleteZip] = useDeleteZip();

	const handleDownloadInvoices = () => {
		getZipInvoices({
			variables: {
				startDate,
				endDate,
			},
			onCompleted: ({ ZipInvoices }) => {
				if (isSuccessResponse(ZipInvoices, ['Success'], logOut, addErrorMessage, 'Facturas')) {
					window.open(ZipInvoices.url);
					deleteZip({
						variables: {
							filename: ZipInvoices.filename,
						},
					}).then(({ data }) => {
						isSuccessResponse(data.DeleteZip, ['Success'], logOut, addErrorMessage, 'Facturas');
					});
				}
			},
		});
	};

	const handleSelectStartDate = e => {
		if (e.target.value === '') {
			setStartDate(null);
		} else {
			if (e.target.value > endDate) {
				addErrorMessage('Fecha Inicial', 'No puede ser mayor que la fecha final');
			} else if (new Date(e.target.value) > new Date()) {
				addErrorMessage('Fecha Inicial', 'No puede ser mayor al día de hoy');
			} else {
				setStartDate(e.target.value);
			}
		}
	};

	const handleSelectEndDate = e => {
		if (e.target.value === '') {
			setEndDate(null);
		} else {
			if (e.target.value < startDate) {
				addErrorMessage('Fecha Final', 'No puede ser menor que la fecha inicial');
			} else if (new Date(e.target.value) > new Date()) {
				addErrorMessage('Fecha Final', 'No puede ser mayor al día de hoy');
			} else {
				setEndDate(e.target.value);
			}
		}
	};

	return (
		<Card className='mb-10'>
			<LayoutHeader>Descargar facturas</LayoutHeader>
			<div className='flex gap-16'>
				<Input
					label='Fecha inicial'
					containerClassName='basis-1/4'
					className='w-1/2'
					labelclass='w-1/3'
					onChange={handleSelectStartDate}
					type='date'
					value={startDate}
					mb='mb-0'
					width=''
				/>
				<Input
					label='Fecha final'
					containerClassName='basis-1/4'
					className='w-1/2'
					labelclass='w-1/3'
					onChange={handleSelectEndDate}
					type='date'
					value={endDate}
					mb='mb-0'
					width=''
				/>
				<div className='flex justify-end grow'>
					<Button onClick={handleDownloadInvoices}>Descargar facturas</Button>
				</div>
			</div>
		</Card>
	);
};

export default DownloadInvoices;
