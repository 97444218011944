import microsoftTranslator from './MicrosoftTranslator';
import transformEditorJsonToString from './transformEditorJsonToString';
import transformTranslatedEditorText from './transformTranslatedEditorText';

const translateEditorField = async (
	text,
	fromLocale,
	setTranslatorModal,
	setValue,
	name,
	addErrorMessage,
	locale,
	config,
	setRefreshCounterNumber
) => {
	try {
		const transformedText = transformEditorJsonToString(JSON.parse(text), '\n');
		if (transformedText) {
			const translations = await microsoftTranslator(
				fromLocale,
				locale,
				[{ text: transformedText }],
				config,
				setTranslatorModal
			);
			if (translations) {
				setValue(name, transformTranslatedEditorText(translations.shift().translations[0].text));
				setRefreshCounterNumber(v => v + 1); // Just increase the refreshCounterNumber
			} else {
				addErrorMessage('Traducción', translations.message);
			}
		}
	} catch (error) {
		setTranslatorModal(false);
		addErrorMessage('Traducción', `Error al transformar campo con formato: ${error.message}`);
	}
};

export default translateEditorField;
