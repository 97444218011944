import { gql, useLazyQuery } from '@apollo/client';

const EVENT_INFO_FRAGMENT = gql`
	fragment EventInfo on Event {
		id
		action
		category
		details
		message
		success
		createdAt
		User {
			id
			Merchant {
				id
				locale
			}
		}
		Order {
			id
			status
		}
	}
`;

const EVENTS_QUERY = gql`
	query Events($search: String, $MerchantId: ID, $limit: Int, $offset: Int) {
		Events(search: $search, MerchantId: $MerchantId, limit: $limit, offset: $offset) {
			... on EventList {
				count
				hasMore
				List {
					...EventInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${EVENT_INFO_FRAGMENT}
`;

export const useLazyEvents = props =>
	useLazyQuery(EVENTS_QUERY, {
		...props,
	});
