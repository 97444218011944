export const merchantCommision = Object.freeze({
	0: 0,
	12: 12,
	15: 15,
	18: 18,
});

export const merchantExemptYears = Object.freeze({
	2: 2,
	5: 5,
});
