import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import Button from 'components/Button';
import TranslatorButton from 'components/TranslatorButton';
import DropdownTreeForm from 'components/DropdownTreeForm';
import DropImageArea from 'components/DropImageArea';
import Editor from 'components/Editor';
import Field from 'components/Field';
import Input from 'components/Input';
import InputForm from 'components/InputForm';
import Score from 'components/Score';
import SelectForm from 'components/SelectForm';
import SelectMerchantInput from 'components/SelectMerchantInput';
import TextareaForm from 'components/TextareaForm';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';
import { categoriesSimpleTree, useLazyProductCategory, useProductCategories } from 'hooks/use-product-categories';
import translasteStatus from 'lib/translasteStatus';
import translateLocale from 'lib/translateLocale';
import formatDate from 'lib/formatDate';
import useConf from 'hooks/use-conf';
import slugify from 'lib/slugify';
import DropDocumentArea from 'components/DropDocumentArea';
import { useSpecialFeatures } from 'hooks/use-special-features';
import Check from 'components/Check';
import CheckForm from 'components/CheckForm';
import Modal from 'components/Modal';
import Textarea from 'components/Textarea';
import { useProductIncident, useProductInHome } from 'hooks/use-products';
import EmptyModal from 'components/EmptyModal';
import translateProductForm from 'lib/translateProductForm';
import translatePlainField from 'lib/translatePlainField';
import translateEditorField from 'lib/translateEditorField';
import translateOtherFeaturesField from 'lib/translateOtherFeaturesField';
import translateVariantsTitleField from 'lib/translateVariantsTitleField';
import isSuccessResponse from 'lib/isSuccessResponse';
import NutritionalInformation from './NutritionalInformation';
import { useSetImageAttributes } from 'hooks/use-media';
import saveAllImages from 'lib/saveAllImages';
import handleTranslatedImage from 'lib/handleTranslatedImage';
import { deliveryTypes } from 'lib/constants/productConstants';

const variantsFields = [
	{
		title: '',
		price: '',
		discountedPrice: '',
		sku: '',
		barcode: '',
		format: null,
		position: 0,
	},
];
const otherFeatureFields = [
	{
		title: '',
		value: '',
	},
];
const emptyNutritionalInformation = {
	energy: 0,
	nutritionalMeasure: 'g',
	fatTotal: 0,
	fatSaturated: 0,
	fatMono: 0,
	fatPoli: 0,
	carbohydrates: 0,
	sugars: 0,
	fiber: 0,
	protein: 0,
	sodium: 0,
};

const ProductForm = ({
	newProduct = false,
	product,
	onSubmit,
	setDirty = () => {},
	statusList,
	substatusList,
	handleEventsFocus = () => {},
	handleCopyAnotherLanguage = () => {},
	getLazyProduct = () => {},
	dirty = false,
	forceDirty = false,
}) => {
	const { config } = useConf();
	const { user, locale, logOut } = useAuth();
	const disableNonTranslatableFields = locale !== config.default_locale || user.type === 'translator';
	const { addSuccessMessage, addErrorMessage, addInfoMessage } = useToast();
	// Form States
	const [disabled, setDisabled] = useState(false);
	const [score, setScore] = useState(0);
	const [slug, setSlug] = useState('');
	const [openRevisionIssueModal, setOpenRevisionIssueModal] = useState(false);
	const [isRequiredDataSheet, setIsRequiredDataSheet] = useState(false);
	const [defaultImage, setDefaultImage] = useState(null);
	const [dataSheet, setDataSheet] = useState(null);
	const [Images, setImages] = useState([]);
	const [specialFeatures, setSpecialFeatures] = useState([]);
	const [isFileUploading, setIsFileUploading] = useState(false);
	const [revisionIssueText, setRevisionIssueText] = useState('');
	const [translatorModal, setTranslatorModal] = useState(false);
	const [fromLocale, setFromLocale] = useState('');
	const [isNutritionalInformation, setIsNutritionalInformation] = useState(false);

	// Counter to increase when loading new data from the API (used to flag the editors
	// to refresh the content)
	const [refreshCounterNumber, setRefreshCounterNumber] = useState(0);
	const otherProductFeatures =
		product && product.otherFeatures !== '' ? toArrayOfObjects(product.otherFeatures) : otherFeatureFields;

	const {
		unregister,
		register,
		handleSubmit,
		getValues,
		control,
		watch,
		setValue,
		reset,
		formState: { dirtyFields },
	} = useForm({
		defaultValues: {
			productForm: {
				MerchantId: newProduct && user.type === 'merchant' ? user.MerchantId : '',
				CategoryId: '',
				title: '',
				handle: '',
				subtitle: '',
				description: '[{"type":"paragraph","children":[{"text":""}]}]',
				instructions: '[{"type":"paragraph","children":[{"text":""}]}]',
				isDistilled: false,
				vat: 21,
				video: '',
				unit: 'u.',
				seoTitle: '',
				seoDescription: '',
				type: 'shippable',
				deliveryType: deliveryTypes.STANDARD,
				preparationTime: 0,
				deliveryObservations: '',
				brand: null,
				contactInstructions: '',
				status: 'revision',
				substatus: 'correct',
				DefaultImage: null,
				DataSheet: null,
				Images: [],
				otherFeatures: otherProductFeatures,
				nutritionalInformation: emptyNutritionalInformation,
				Variants: variantsFields,
				SpecialFeatures: [],
			},
		},
	});

	// Load Edit data
	useEffect(() => {
		if (product) {
			reset({
				productForm: {
					MerchantId: newProduct ? (user.type === 'merchant' ? user.MerchantId : '') : product.Merchant?.id,
					CategoryId: product.CategoryId || '',
					title: product.title || '',
					handle: product.handle || '',
					subtitle: product.subtitle || '',
					description:
						product.description && product.description !== ''
							? product.description
							: '[{"type":"paragraph","children":[{"text":""}]}]',
					instructions:
						product.instructions && product.instructions !== ''
							? product.instructions
							: '[{"type":"paragraph","children":[{"text":""}]}]',
					isDistilled: product.isDistilled || false,
					vat: product.vat ?? 21,
					video: product.video || '',
					unit: product.unit || 'u.',
					seoTitle: product.seoTitle || '',
					seoDescription: product.seoDescription || '',
					type: product.type || 'shippable',
					deliveryType: product.deliveryType || deliveryTypes.STANDARD,
					preparationTime: product.preparationTime || 0,
					deliveryObservations: product.deliveryObservations || '',
					brand: product.brand || null,
					contactInstructions: product.contactInstructions || '',
					status: product.status || 'revision',
					substatus: product.substatus || 'correct',
					DefaultImage: product.DefaultImage?.id || null,
					DataSheet: product.DataSheet?.id || null,
					Images: product.Images?.map(i => i?.id) || [],
					otherFeatures: otherProductFeatures,
					nutritionalInformation: product.nutritionalInformation
						? JSON.parse(product.nutritionalInformation)
						: emptyNutritionalInformation,
					Variants:
						product.Variants.length > 0
							? product.Variants?.map((item, index) => ({
									id: item.id,
									title: item.title || '',
									price: item.price ?? '',
									discountedPrice: item.discountedPrice ?? '',
									sku: item.sku || '',
									barcode: item.barcode || '',
									isAvailable: item.isAvailable,
									outOfSeasson: item.outOfSeasson ?? 0,
									limitedProduction: item.limitedProduction ?? 0,
									format: item.format || null,
									position: index,
							  }))
							: variantsFields,
					SpecialFeatures: product.SpecialFeatures?.map(i => ({ id: i?.id, value: true })) || [],
				},
			});
			// Creates a copy of each image element so it can be modified inside the DropfileImage
			setImages(product.Images.map(image => ({ ...image })));
			setDefaultImage({ ...product.DefaultImage });
			setDataSheet(product.DataSheet);
			setIsNutritionalInformation(!!product.nutritionalInformation);
			setSlug(product.handle || '');
			setRefreshCounterNumber(v => v + 1); // Just increase the refreshCounterNumber
		}
	}, [product, setImages, setDefaultImage, setDataSheet, setValue, setDirty]);

	// This is to validate if the dataSheet is required
	const [getLazyProductCategory] = useLazyProductCategory({
		variables: {
			id: getValues('productForm.CategoryId'),
		},
		onCompleted: ({ ProductCategory }) => {
			setIsRequiredDataSheet(ProductCategory.parentRequiresDataSheet || ProductCategory.requiresDataSheet);
		},
	});

	const [updateProductIncident] = useProductIncident({
		onCompleted: ({ ProductIncident }) => {
			if (isSuccessResponse(ProductIncident, ['Success'], logOut, addErrorMessage, 'Incidencia')) {
				setValue('productForm.substatus', 'issue');
				setOpenRevisionIssueModal(false);
				addSuccessMessage('Incidencia creada correctamente');
			}
		},
	});

	// Main Categories
	const { data, loading: catsLoading } = useProductCategories({
		variables: { locale: config.default_locale },
	});

	const { data: specialFeaturesData } = useSpecialFeatures();

	const handleDropdownTreeForm = pId => {
		setValue('productForm.CategoryId', pId);
	};

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (Object.keys(dirtyFields).length > 0) {
			setDirty(true);
		} else if (forceDirty) {
			setDirty(true);
		} else {
			setDirty(false);
		}
	}, [Object.keys(dirtyFields).length]);

	// Is used to delete a single image id from productForm.Images
	useEffect(() => {
		unregister('productForm.Images');
		setValue(
			'productForm.Images',
			Images.map(image => image.id)
		);
	}, [Images, setValue, unregister]);

	// Load Edit data
	useEffect(() => {
		if (specialFeaturesData) {
			setSpecialFeatures(
				specialFeaturesData.SpecialFeatures?.map(item => ({
					id: item?.id,
					name: item.name,
					description: item.description,
					value: product?.SpecialFeatures.some(specialFeature => specialFeature.id === item.id) || false,
				}))
			);
		}
	}, [product, specialFeaturesData, setSpecialFeatures]);

	useEffect(() => {
		if (!newProduct && getValues('productForm.CategoryId') !== null) {
			getLazyProductCategory();
		}
	}, []);

	const handleDefaultImage = useCallback(
		([newImage]) => {
			setDefaultImage(newImage);
			setValue('productForm.DefaultImage', newImage.id);
		},
		[setDefaultImage, setValue]
	);

	const handleGalleryImages = useCallback(
		_newData => {
			const prevImages = getValues('productForm.Images') || [];
			setValue('productForm.Images', [
				...prevImages,
				..._newData.map(image => image.id).filter(imageId => !prevImages.includes(imageId)),
			]);
			setImages(prev => [
				...prev,
				// Add images that are not already added
				..._newData.filter(image => !prev.some(prevImage => prevImage.id === image.id)),
			]);
		},
		[setImages, setValue, getValues]
	);

	const handleDataSheet = useCallback(
		([newDocument]) => {
			setDataSheet(newDocument);
			setValue('productForm.DataSheet', newDocument.id);
		},
		[setDataSheet, setValue]
	);

	// Using calculateFormScore in combination with watch "score" state
	const calculateProductScore = input => {
		if (input) {
			const formDataFiltered = [
				...Object.entries(input).filter(
					e => !['Images', 'MerchantId', 'Variants', 'otherFeatures', 'contactInstructions'].includes(e[0])
				),
				...Object.entries(input.Variants[0]).filter(
					e => !['title', 'isAvailable', 'outOfSeasson', 'limitedProduction'].includes(e[0])
				),
				...Object.entries(input.otherFeatures[0]),
			];
			let total = formDataFiltered.length;
			let count = 0;
			for (var [, value] of formDataFiltered) {
				if (value && value !== '[{"type":"paragraph","children":[{"text":""}]}]') {
					count++;
				}
			}
			setScore((100 * count) / total);
		} else {
			// When no data 0%
			setScore(0);
		}
	};

	watch(({ productForm }, { name }) => {
		calculateProductScore(productForm);
		if (
			(name === 'productForm.title' || name === 'productForm.status') &&
			getValues('productForm.status') !== 'active' &&
			getValues('productForm.status') !== 'modified'
		) {
			setValue('productForm.handle', slugify(productForm.title));
		}
		if (
			name === 'productForm.status' &&
			(getValues('productForm.status') === 'active' || getValues('productForm.status') === 'modified')
		) {
			setValue('productForm.handle', product?.handle);
		}
		if (name === 'productForm.handle') {
			setSlug(productForm.handle);
		}
		// fetch category data to set DataSheet required prop
		if (name === 'productForm.CategoryId' && productForm.CategoryId) {
			getLazyProductCategory();
		}
	});

	const isRequired = useCallback(() => {
		// Temporal fix to make fields not required
		// if (newProduct) {
		// 	return true;
		// }
		// if (user.type === 'admin') {
		// 	return false;
		// }
		return false;
	}, [newProduct, user]);

	const handleSpecialFeatures = specialFeature => {
		setSpecialFeatures(prev =>
			prev.map(prevSpecialFeature => {
				return prevSpecialFeature.id === specialFeature.id
					? { ...specialFeature, value: !specialFeature.value }
					: prevSpecialFeature;
			})
		);
	};

	const handleRevisionIssueModal = () => {
		setOpenRevisionIssueModal(value => !value);
	};

	const revisionIssueModalObject = {
		title: 'Notificar incidencia',
		description: (
			<span className='text-lg leading-5 text-gray-500'>{`Indicar incidencia y al confirmar se enviará un email a ${product?.Merchant?.commercialName}`}</span>
		),
		btnText: 'Confirmar',
		cancelBtnText: 'Cancelar',
	};

	const handleRevisionIssueText = e => {
		setRevisionIssueText(e.target.value);
	};

	const handleUpdateProductIncident = () => {
		updateProductIncident({ variables: { input: { productId: product?.id, text: revisionIssueText } } });
	};

	const handleTranslateField = async (fieldName, translateMethod, aditionalProp = () => {}) => {
		setTranslatorModal(true);
		getLazyProduct({
			variables: { id: product.id, forceLocale: true, locale: fromLocale },
		}).then(({ data }) => {
			if (data?.Product) {
				if (!data.Product[fieldName]) {
					addInfoMessage(
						'Traducción',
						`Campo seleccionado sin texto en el idioma ${translateLocale(fromLocale)}`
					);
					setTranslatorModal(false);
					return;
				}
				translateMethod(
					data.Product[fieldName],
					fromLocale,
					setTranslatorModal,
					setValue,
					`productForm.${fieldName}`,
					addErrorMessage,
					locale,
					config,
					aditionalProp
				);
			}
		});
	};

	// Unit list
	const unitList = [
		{ value: '', name: '' },
		{ value: 'u.', name: '(u.) unidad' },
		{ value: 'l.', name: '(l.) litros' },
		{ value: 'k.', name: '(k.) kilos' },
	];

	const [setImageAttributes] = useSetImageAttributes();
	const [isSuccessTranslated, setIsSuccessTranslated] = useState(false);
	useEffect(async () => {
		if (isSuccessTranslated) {
			await saveAllImages([defaultImage, ...Images], setImageAttributes, locale);
		}
	}, [isSuccessTranslated]);

	useEffect(() => {
		if (getValues('productForm.deliveryType') === deliveryTypes.DEFERRED) {
			if (getValues('productForm.preparationTime') < 2) {
				setValue('productForm.preparationTime', 2);
			}
		} else if (getValues('productForm.deliveryType') === deliveryTypes.STANDARD) {
			setValue('productForm.preparationTime', 0);
		}
	}, [watch('productForm.deliveryType'), watch('productForm.preparationTime')]);

	return (
		<div>
			<AuthorizationBoundary for={['admin', 'merchant']}>
				<Score
					data={score}
					name='de producto'
					content='Si tu ficha supera el 75% de cumplimentación, tiene muchas más probabilidades de generar ventas'
				/>
			</AuthorizationBoundary>
			<form
				onSubmit={handleSubmit(({ productForm }) => {
					if (!disabled) {
						let status = getValues('productForm.status');
						let substatus = getValues('productForm.substatus');

						// Check that product contains defaultImage to continue
						// if (user.type !== 'admin' && !getValues('productForm.DefaultImage')) {
						// 	addErrorMessage('Producto', 'El producto debe contener "Imagen destacada"');
						// 	return;
						// }

						// Check that product contains DataSheet if category requiresDataSheet to continue
						// if (user.type !== 'admin' && isRequiredDataSheet) {
						// 	addErrorMessage('Producto', 'El producto debe contener "Ficha técnica"');
						// 	return;
						// }

						let { Variants, description, instructions, CategoryId, ...restProductForm } = productForm;

						if (CategoryId === '') {
							CategoryId = null;
						}

						Variants = Variants.map(variant => ({
							...variant,
							format: variant.format === '' ? null : variant.format,
							price: variant.price === '' ? null : variant.price,
							discountedPrice: variant.discountedPrice === '' ? null : variant.discountedPrice,
						}));
						const selectedSpecialFeatures = [];
						for (const specialFeature of specialFeatures) {
							if (specialFeature.value) {
								selectedSpecialFeatures.push(specialFeature.id);
							}
						}

						const otherFeatures = JSON.stringify(restProductForm.otherFeatures);
						// Only parse JSON if nutritionalInformation is selected, otherwise set value to null
						let nutritionalInformation = null;
						if (isNutritionalInformation) {
							nutritionalInformation = JSON.stringify(restProductForm.nutritionalInformation);
						}

						setDisabled(true);
						onSubmit(
							{
								...restProductForm,
								CategoryId,
								Variants,
								SpecialFeatures: selectedSpecialFeatures,
								nutritionalInformation,
								// When formated text is empty, send empty string to delete Translation row in db
								description:
									description.replace(/\s/g, '') === '[{"type":"paragraph","children":[{"text":""}]}]'
										? ''
										: description,
								instructions:
									instructions.replace(/\s/g, '') ===
									'[{"type":"paragraph","children":[{"text":""}]}]'
										? ''
										: instructions,
								otherFeatures:
									otherFeatures.replace(/\s/g, '') === '[{"title":"","value":""}]'
										? ''
										: otherFeatures,
								status,
								substatus,
							},
							async ({ ProductUpdate }) => {
								if (
									!newProduct &&
									isSuccessResponse(
										ProductUpdate,
										['Product'],
										logOut,
										addErrorMessage,
										'Editar producto'
									)
								) {
									await saveAllImages([defaultImage, ...Images], setImageAttributes, locale);
									setDirty(false);
								}
							}
						);
						setDisabled(false);
					}
				})}
				encType='multipart/form-data'
				className={disabled ? 'opacity-50' : ''}
			>
				<div className='mt-4 max-w-screen-lg'>
					{!newProduct ? (
						<>
							<AuthorizationBoundary for={['admin']}>
								<Button className='h-12' type='button' onClick={handleRevisionIssueModal}>
									Notificar incidencia
								</Button>
							</AuthorizationBoundary>
							<div className='flex items-center w-full'>
								{getValues('productForm.substatus') === 'issue' && (
									<Button className='h-12' onClick={handleEventsFocus} type='button'>
										Consultar incidencia
									</Button>
								)}
								{getValues('productForm.substatus') === 'untranslated' && (
									<Button
										className='h-12'
										disabled={dirty}
										onClick={handleCopyAnotherLanguage}
										type='button'
										tooltip={
											<ul className='list-disc pl-4'>
												<li>
													Copiará lo escrito en la ficha en Catalán, Español, Inglés o alemán.
												</li>
												<li>Solo se copiará en los campos vacíos (donde no haya texto).</li>
												<li>Los textos solo se copian, se deben traducir por el Asociado.</li>
											</ul>
										}
									>
										Copiar datos de otro idioma
									</Button>
								)}
								<div className='flex flex-col ml-auto'>
									<p className='text-center mb-1 text-lg font-medium'>Traducir todo</p>
									<TranslatorButton
										onClick={async () => {
											setIsSuccessTranslated(false);
											const isSuccess = await translateProductForm(
												{
													id: product.id,
													...getValues('productForm'),
													Images,
													DefaultImage: defaultImage,
												},
												fromLocale,
												locale,
												config,
												setTranslatorModal,
												setValue,
												setRefreshCounterNumber,
												getLazyProduct,
												setDefaultImage,
												setImages,
												addSuccessMessage,
												addErrorMessage
											);
											if (isSuccess) {
												setIsSuccessTranslated(isSuccess);
											}
										}}
										fromLocale={fromLocale}
										setFromLocale={setFromLocale}
										tooltip={
											<ul className='list-disc pl-4'>
												{!fromLocale ? (
													<li>Elegir el idioma original del que traducir.</li>
												) : (
													<li>Traducir de {translateLocale(fromLocale)}.</li>
												)}
												<li>No traducirá campos que ya tengan texto en esta ficha.</li>
											</ul>
										}
										title='translate-all'
									/>
								</div>
							</div>
						</>
					) : null}
				</div>
				{!newProduct && (
					<AuthorizationBoundary for={['merchant']}>
						<div className='flex items-start'>
							<Input
								className='border-none w-2/3'
								labelclass='w-1/5'
								name='productForm.status'
								value={translasteStatus(getValues('productForm.status'))}
								label='Estado'
								readOnly
							/>
						</div>
						<div className='flex w-full justify-start items-start'>
							<Input
								className='border-none w-1/5'
								labelclass='w-1/5'
								name='productForm.substatus'
								value={translasteStatus(getValues('productForm.substatus'))}
								label='Subestado'
								readOnly
							/>
						</div>
					</AuthorizationBoundary>
				)}

				<AuthorizationBoundary for={['admin']}>
					<SelectForm
						name='productForm.status'
						control={control}
						register={register}
						width='w-1/3 mr-4'
						label='Estado'
						disabled={disableNonTranslatableFields}
						options={statusList}
						type='text'
					/>
				</AuthorizationBoundary>

				<AuthorizationBoundary for={['admin']}>
					<SelectForm
						name='productForm.substatus'
						control={control}
						register={register}
						width='w-1/3 mr-4'
						label='Subestado'
						disabled={disableNonTranslatableFields}
						options={substatusList}
						type='text'
					/>
				</AuthorizationBoundary>

				{!newProduct && (
					<div className='flex mb-8'>
						<Input
							className='border-none w-2/3'
							labelclass='w-1/2'
							value={product && formatDate(product.createdAt)}
							label='Fecha de creación'
							readOnly
						/>
						<Input
							className='border-none w-2/3'
							labelclass='w-1/2'
							value={product && formatDate(product.updatedAt)}
							label='Fecha última modificación'
							readOnly
						/>
					</div>
				)}
				<SelectForm
					name='productForm.type'
					control={control}
					register={register}
					width='w-1/3'
					label='Tipo de producto'
					disabled={disableNonTranslatableFields}
					options={[
						{ value: 'shippable', name: 'Estándar' },
						{ value: 'experience', name: 'Experiencia - Visita' },
					]}
					specifications={
						<ul className='list-disc pl-4'>
							<li>
								<b>Estándar:</b> Producto físico que requiere ser enviado
							</li>
							<li>
								<b>Experiencia - Visita:</b> No requiere envío por logística
							</li>
						</ul>
					}
				/>
				<SelectForm
					name='productForm.deliveryType'
					control={control}
					register={register}
					width='w-1/3'
					label='Tipo de entrega'
					disabled={disableNonTranslatableFields}
					options={[
						{ value: deliveryTypes.STANDARD, name: 'Entrega inmediata' },
						{ value: deliveryTypes.DEFERRED, name: 'Entrega diferida' },
						{ value: deliveryTypes.BY_MERCHANT, name: 'Entrega directa' },
					]}
					specifications={
						<ul className='list-disc pl-4'>
							<li>
								<b>Entrega inmediata:</b> preparado antes de 24 h para entregar por Producto de Aquí.
							</li>
							<li>
								<b>Entrega diferida:</b> preparado en un periodo igual o superior a dos días, con portes
								pagados por el productor y entregado por Producto de Aquí.
							</li>
							<li>
								<b>Entrega directa:</b> gestionado y enviado por el asociado.
							</li>
						</ul>
					}
				/>
				<InputForm
					name='productForm.preparationTime'
					control={control}
					register={register}
					type='number'
					label='Días de preparación'
					width='w-1/3'
					min={getValues('productForm.deliveryType') === deliveryTypes.DEFERRED ? 2 : 0}
					disabled={getValues('productForm').deliveryType === deliveryTypes.STANDARD}
					forcePositiveInteger
					specifications={
						<ul className='list-disc pl-4'>
							<li>Tiempo estimado de preparación en días laborables</li>
						</ul>
					}
				/>
				<TextareaForm
					name='productForm.deliveryObservations'
					control={control}
					label='Observaciones sobre la entrega'
					className='mt-1 border border-coral-300 p-4 h-28'
					maxLength='120'
					description='* Máximo 120 caracteres'
					disabled={getValues('productForm').deliveryType === deliveryTypes.STANDARD}
					missingTranslations={getValues('productForm').substatus === 'untranslated'}
					specifications={
						<ul className='list-disc pl-4'>
							<li>Empezar en mayúsculas</li>
							<li>No todo en mayúsculas</li>
							<li>Ortografía correcta</li>
						</ul>
					}
				>
					{!newProduct ? (
						<TranslatorButton
							onClick={async () => handleTranslateField('deliveryObservations', translatePlainField)}
							fromLocale={fromLocale}
							setFromLocale={setFromLocale}
						/>
					) : null}
				</TextareaForm>
				<AuthorizationBoundary for={['admin']}>
					{/* En SelectMerchantInput se hace un use-merchant que muestra los merchants y permite cambiar el MerchantId */}
					<SelectMerchantInput
						name='productForm.MerchantId'
						control={control}
						register={register}
						disabled={disableNonTranslatableFields}
						width='w-1/3'
						label='Selecciona un asociado'
						required={isRequired()}
					/>
				</AuthorizationBoundary>
				<InputForm
					name='productForm.title'
					control={control}
					label='Nombre del producto'
					className='border border-coral-300 py-2 px-4'
					placeholder='Nombre del producto'
					maxLength='60'
					height='h-12'
					description='* Máximo 60 caracteres'
					//required={isRequired()}
					required
					missingTranslations={getValues('productForm').substatus === 'untranslated'}
					specifications={
						<ul className='list-disc pl-4'>
							<li>Empezar en mayúsculas</li>
							<li>No todo en mayúsculas</li>
							<li>Ortografía correcta</li>
						</ul>
					}
				>
					{!newProduct ? (
						<TranslatorButton
							onClick={async () => handleTranslateField('title', translatePlainField)}
							fromLocale={fromLocale}
							setFromLocale={setFromLocale}
						/>
					) : null}
				</InputForm>

				<AuthorizationBoundary for={['admin']}>
					<InputForm
						name='productForm.handle'
						control={control}
						register={register}
						label='Url del producto'
						className='flex-row border border-coral-300 py-2 px-4'
						placeholder='Url del producto'
						required={isRequired()}
						missingTranslations={getValues('productForm').substatus === 'untranslated'}
					>
						<p className='ml-4 flex-1 text-sm italic text-gray-600'>{`${config.shop_url}/${locale}/${slug}`}</p>
					</InputForm>
				</AuthorizationBoundary>

				<AuthorizationBoundary for={['merchant']}>
					<Field label='Url del producto'>
						<p className='ml-4 flex-1 text-sm italic text-gray-600'>{`${
							config.shop_url
						}/${locale}/${getValues('productForm.handle')}`}</p>
					</Field>
				</AuthorizationBoundary>

				<TextareaForm
					name='productForm.subtitle'
					control={control}
					label='Subtítulo'
					className='mt-1 border border-coral-300 p-4 h-28'
					required={isRequired()}
					showRequiredIcon
					maxLength='120'
					description='* Máximo 120 caracteres'
					missingTranslations={getValues('productForm').substatus === 'untranslated'}
					specifications={
						<ul className='list-disc pl-4'>
							<li>Empezar en mayúsculas</li>
							<li>No todo en mayúsculas</li>
							<li>Ortografía correcta</li>
						</ul>
					}
				>
					{!newProduct ? (
						<TranslatorButton
							onClick={async () => handleTranslateField('subtitle', translatePlainField)}
							fromLocale={fromLocale}
							setFromLocale={setFromLocale}
						/>
					) : null}
				</TextareaForm>
				<AuthorizationBoundary for={['admin', 'merchant']}>
					<div className='mb-4 flex w-full items-center'>
						<span
							className={`${
								disableNonTranslatableFields ? 'text-gray-500' : 'text-gray-700'
							} w-1/5 text-lg font-semibold`}
						>
							Categoría <span className='text-coral-600'>*</span>
						</span>
						<DropdownTreeForm
							name='productForm.CategoryId'
							control={control}
							initialValue={getValues('productForm.CategoryId')}
							disabled={disableNonTranslatableFields}
							noneSelectedLabel='Seleccione una categoría'
							treeData={categoriesSimpleTree(null, data?.ProductCategories?.List)}
							onChange={handleDropdownTreeForm}
							width='w-1/3'
							required={isRequired()}
							showRequiredIcon
						/>
					</div>
				</AuthorizationBoundary>
				<AuthorizationBoundary for={['admin', 'merchant']}>
					{specialFeatures ? (
						<>
							<p className='font-semibold text-gray-700 text-lg w-1/3'>Características del producto</p>
							<div className='w-full mb-8'>
								<div className='flex flex-col w-2/3'>
									{specialFeatures.map(specialFeature => (
										<div className='flex' key={specialFeature.id}>
											<Check
												checked={specialFeature.value}
												onChange={() => handleSpecialFeatures(specialFeature)}
												className='ml-2 w-1/3 checked:bg-blue-600'
												label={specialFeature.name}
												disabled={disableNonTranslatableFields}
											/>
											<p className='ml-2 text-sm italic text-gray-600'>
												{specialFeature.description}
											</p>
										</div>
									))}
								</div>
							</div>
						</>
					) : null}
				</AuthorizationBoundary>

				<Editor
					name='productForm.description'
					control={control}
					label='Información del producto'
					specifications={
						<ul className='list-disc pl-4'>
							<li>Estructurar contenido en párrafos</li>
							<li>No hacer uso excesivo de negrita</li>
						</ul>
					}
					required={isRequired()}
					refreshContentNumber={refreshCounterNumber}
					showRequiredIcon
					missingTranslations={getValues('productForm').substatus === 'untranslated'}
				>
					{!newProduct ? (
						<TranslatorButton
							onClick={async () =>
								handleTranslateField('description', translateEditorField, setRefreshCounterNumber)
							}
							fromLocale={fromLocale}
							setFromLocale={setFromLocale}
						/>
					) : null}
				</Editor>
				<div className='mt-6 flex w-full max-w-screen-md '>
					<div className='w-1/3'></div>
					<p className='text-sm italic text-gray-600'>
						* Esta característica, no se reflejará en el grado de cumplimentación.
					</p>
				</div>
				<Field label='Otras características' description='ej: ingredientes, alérgenos, color, material, ...'>
					<div className='flex flex-wrap lg:flex-nowrap w-2/3'>
						<OtherFeaturesArray
							control={control}
							register={register}
							fields={otherFeatureFields}
							unregister={unregister}
							setValue={setValue}
							getValues={getValues}
							missingTranslations={getValues('productForm').substatus === 'untranslated'}
						/>
						{!newProduct ? (
							<TranslatorButton
								onClick={async () => handleTranslateField('otherFeatures', translateOtherFeaturesField)}
								fromLocale={fromLocale}
								setFromLocale={setFromLocale}
							/>
						) : null}
					</div>
				</Field>
				<Editor
					containerClassName='mt-6'
					name='productForm.instructions'
					control={control}
					label='Información útil'
					description='ej.: modo de conservación, uso, recetas, ...'
					missingTranslations={getValues('productForm').substatus === 'untranslated'}
					specifications={
						<ul className='list-disc pl-4'>
							<li>Estructurar contenido en párrafos</li>
							<li>No hacer uso excesivo de negrita</li>
						</ul>
					}
					refreshContentNumber={refreshCounterNumber}
				>
					{!newProduct ? (
						<TranslatorButton
							onClick={async () =>
								handleTranslateField('instructions', translateEditorField, setRefreshCounterNumber)
							}
							fromLocale={fromLocale}
							setFromLocale={setFromLocale}
						/>
					) : null}
				</Editor>
				<NutritionalInformation
					isNutritionalInformation={isNutritionalInformation}
					setIsNutritionalInformation={setIsNutritionalInformation}
					disabled={disableNonTranslatableFields}
					control={control}
					register={register}
					getValues={getValues}
					watch={watch}
				/>
				<hr className='my-8 border-0' />
				<Field label='Precio y variantes' description='Clica el "+" para añadir variantes' />
				<SelectForm
					name='productForm.unit'
					control={control}
					register={register}
					width='w-1/5 mr-4'
					label='Unidad de medida'
					disabled={disableNonTranslatableFields}
					options={unitList}
					type='text'
				/>
				<VariantsArray
					newProduct={newProduct}
					control={control}
					fields={variantsFields}
					unregister={unregister}
					register={register}
					setValue={setValue}
					getValues={getValues}
					watch={watch}
					isRequired={isRequired}
					showRequiredIcon
					isAdmin={user.type === 'admin'}
					disableNonTranslatableFields={disableNonTranslatableFields}
					setTranslatorModal={setTranslatorModal}
					getLazyProduct={getLazyProduct}
					productId={product?.id}
					fromLocale={fromLocale}
					setFromLocale={setFromLocale}
					addErrorMessage={addErrorMessage}
					locale={locale}
					config={config}
				/>

				<SelectForm
					name='productForm.vat'
					type='number'
					control={control}
					register={register}
					disabled={disableNonTranslatableFields}
					width='w-1/3'
					label='Tipo de IVA'
					required={isRequired()}
					showRequiredIcon
					options={[
						{ value: 21, name: '21%' },
						{ value: 10, name: '10%' },
						{ value: 5, name: '5%' },
						{ value: 4, name: '4%' },
						{ value: 2, name: '2%' },
						{ value: 0, name: '0%' },
					]}
				/>
				<AuthorizationBoundary for={['admin', 'merchant']}>
					<CheckForm
						name='productForm.isDistilled'
						label='Aplica impuesto especial de alcoholes'
						control={control}
						disabled={disableNonTranslatableFields}
						specifications={
							<ul className='list-disc pl-4'>
								<li>
									En este artículo es de aplicación el impuesto especial de alcoholes, solo aplicable
									a productos destilados
								</li>
							</ul>
						}
					/>
				</AuthorizationBoundary>
				<hr className='my-8 border-0' />

				<DropImageArea
					disabled={!getValues('productForm.MerchantId')}
					disabledMessage='Debes seleccionar un asociado para subir imagen'
					MerchantId={getValues('productForm.MerchantId')}
					label='Imagen destacada'
					Images={defaultImage ? [defaultImage] : []}
					onChange={handleDefaultImage}
					whileIdleText='Pincha o Suelta tus Imágenes aquí'
					onDelete={() => {
						setValue('productForm.DefaultImage', null);
						setDefaultImage(null);
					}}
					onTranslateImage={translatedImage => handleTranslatedImage(setDefaultImage, translatedImage)}
					setUploading={setIsFileUploading}
					required={isRequired()}
					showRequiredIcon
					specifications={
						<ul className='list-disc pl-4'>
							<li>Producto centrado y con fondo blanco puro rgb(255 255 255)</li>
							<li>Formato 1/1 (cuadrado)</li>
							<li>Resolución mínima recomendable 620x620px</li>
							<li>Tamaño máximo 500kb</li>
						</ul>
					}
					previewStyle='aspect-square object-contain'
					boxStyle='w-1/3'
					setTranslatorModal={setTranslatorModal}
					fromLocale={fromLocale}
					setFromLocale={setFromLocale}
				/>

				<DropImageArea
					disabled={!getValues('productForm.MerchantId')}
					disabledMessage='Debes seleccionar un asociado para subir imagenes'
					MerchantId={getValues('productForm.MerchantId')}
					ProductId={product?.id}
					galleryType='product'
					label='Galería de imágenes'
					Images={Images}
					setImages={setImages}
					dirty={dirty}
					setDirty={setDirty}
					onChange={handleGalleryImages}
					whileIdleText='Pincha o Suelta tus Imágenes aquí'
					onDelete={id => setImages(Images => [...Images.filter(i => i?.id !== id)])}
					onTranslateImage={(translatedImage, index) =>
						handleTranslatedImage(setImages, translatedImage, index)
					}
					setUploading={setIsFileUploading}
					multiple
					description='Agregar breve descripción para ayudar el SEO. * Para que su producto transmita confianza al cliente, entre las fotos se deben incluir fotos de las etiquetas con primer plano de todas las leyendas legales (Registro Sanitario, composición, calorías, origen…).'
					required={isRequired()}
					showRequiredIcon
					specifications={
						<ul className='list-disc pl-4'>
							<li>Formato 1/1 (cuadrado)</li>
							<li>Resolución mínima recomendable 620x620px</li>
							<li>Tamaño máximo 500kb</li>
						</ul>
					}
					previewStyle='aspect-square object-contain'
					boxStyle='w-1/3'
					setTranslatorModal={setTranslatorModal}
					fromLocale={fromLocale}
					setFromLocale={setFromLocale}
				>
					{/* <Button className='my-3'>Añadir imagenes</Button> */}
				</DropImageArea>
				<AuthorizationBoundary for={['admin', 'merchant']}>
					<DropDocumentArea
						disabled={!getValues('productForm.MerchantId')}
						disabledMessage='Debes seleccionar un asociado para subir documento'
						MerchantId={getValues('productForm.MerchantId')}
						label='Ficha técnica'
						Documents={[dataSheet]}
						onChange={handleDataSheet}
						whileIdleText='Pincha o Suelta tu documento aquí'
						description='Documento en formato .pdf'
						onDelete={() => {
							setValue('productForm.DataSheet', null);
							setDataSheet(null);
						}}
						setUploading={setIsFileUploading}
						// required={user.type === 'admin' ? false : isRequiredDataSheet}
						showRequiredIcon={isRequiredDataSheet}
					/>

					<InputForm
						name='productForm.video'
						control={control}
						register={register}
						label='Enlace a Youtube'
						description='Introducir el URL completo'
						className='border border-coral-300 py-2 px-4 font-semibold'
						placeholder='https://...'
						missingTranslations={getValues('productForm').substatus === 'untranslated'}
					/>
				</AuthorizationBoundary>
				{/* Option for 'merchant' to send email to request promote or de-promote product */}
				{/* <MerchantPromoteProduct config={config} product={product} addErrorMessage={addErrorMessage} /> */}
				{/* Option for 'admin' to promote or de-promote product */}
				{/* <AdminPromoteProduct product={product} onSubmit={onSubmit} addErrorMessage={addErrorMessage} /> */}
				{/* Option for 'admin' to set or unset in home product */}
				<AdminProductInHome product={product} onSubmit={onSubmit} addErrorMessage={addErrorMessage} />

				<hr className='my-16 border-0' />
				<p className='my-8 text-lg'>
					El SEO es necesario para ayudar a los motores de búsqueda a entender sobre qué trata cada página,
					mejorar el ranking en buscadores y que más personas encuentren tu sitio.
				</p>

				<AuthorizationBoundary for={['admin', 'translator']}>
					<InputForm
						name='productForm.seoTitle'
						control={control}
						register={register}
						label='Título SEO'
						className='border border-coral-300 py-2 px-4 font-semibold'
						placeholder='Título SEO'
						maxLength='80'
						description={<p className='ml-4 text-sm italic text-gray-600'>* Máximo 80 caracteres.</p>}
					>
						{!newProduct ? (
							<TranslatorButton
								onClick={async () => handleTranslateField('seoTitle', translatePlainField)}
								fromLocale={fromLocale}
								setFromLocale={setFromLocale}
							/>
						) : null}
					</InputForm>

					<TextareaForm
						name='productForm.seoDescription'
						control={control}
						label='Descripción SEO'
						className='border border-coral-300 py-2 px-4 font-normal'
						placeholder='Descripción SEO'
						maxLength='160'
						description={<p className='ml-4 text-sm italic text-gray-600'>* Máximo 160 caracteres.</p>}
					>
						{!newProduct ? (
							<TranslatorButton
								onClick={async () => handleTranslateField('seoDescription', translatePlainField)}
								fromLocale={fromLocale}
								setFromLocale={setFromLocale}
							/>
						) : null}
					</TextareaForm>
				</AuthorizationBoundary>

				<InputForm
					name='productForm.brand'
					control={control}
					register={register}
					label='Marca SEO'
					className='border border-coral-300 py-2 px-4 font-normal'
					placeholder='Marca del producto para SEO.'
					maxLength='80'
					description={<p className='ml-4 text-sm italic text-gray-600'>* Máximo 80 caracteres.</p>}
					disabled={disableNonTranslatableFields}
				>
					<span className='pl-4'>Marca del producto (campo no traducible)</span>
				</InputForm>
				<div className='flex gap-10'>
					<Button
						className='mt-4'
						disabled={disabled || catsLoading || isFileUploading}
						type='submit'
						tooltip={newProduct ? null : `Asegúrate que los textos están en ${translateLocale(locale)}`}
					>
						{newProduct ? 'Crear producto' : 'Actualizar producto'}
					</Button>
					{product?.id && (
						<Button
							disabled={!product.handle}
							tooltip={
								!product.handle
									? `Actualizar el producto para poder previsualizarlo (falta el título en el idioma ${translateLocale(
											locale
									  )})`
									: null
							}
							as={product.handle ? 'a' : 'button'}
							bg='border border-coral-500 text-coral-500 hover:none'
							hover='bg-gray-200'
							className='mt-4'
							target='_blank'
							href={`${config.shop_url}/${locale}/${getValues('productForm.handle')}`}
						>
							PREVISUALIZAR PRODUCTO EN TIENDA
						</Button>
					)}
				</div>
			</form>
			<Modal
				modalObject={revisionIssueModalObject}
				openModal={openRevisionIssueModal}
				setOpenModal={setOpenRevisionIssueModal}
				handleSubmit={handleUpdateProductIncident}
			>
				<Textarea
					value={revisionIssueText}
					className='mt-10'
					onChange={handleRevisionIssueText}
					width='w-full'
				/>
			</Modal>
			<EmptyModal openModal={translatorModal}>
				<div className='relative flex w-[270px] h-[270px] rounded-full items-center justify-center z-40 overflow-hidden'>
					<div className='absolute flex w-[270px] aspect-square animate-spin-slow bg-gradient-to-r from-black to-coral-500' />
					<div className='flex items-center justify-center bg-coral-500 z-50 w-[220px] h-[220px] rounded-full'>
						<p className='text-5xl font-medium'>Traduciendo</p>
					</div>
				</div>
			</EmptyModal>
		</div>
	);
};

// const MerchantPromoteProduct = ({ config, product, addErrorMessage }) => {
// 	const handlePromoteProduct = e => {
// 		e.preventDefault();
// 		if (product) {
// 			// onSubmit({ promote: !product.promote });
// 			addErrorMessage('Producto', 'Promocionar producto no implementado actualmente');
// 		}
// 	};
// 	// const emailBody = () =>
// 	// 	`Estimado/a,%0D%0A%0D%0ASolicito%20${
// 	// 		product?.promote ? 'quitar%20promoción%20de' : 'promocionar'
// 	// 	}%20el%20producto%20${product.title.replace(
// 	// 		' ',
// 	// 		'%20'
// 	// 	)}%0D%0A%0D%0AAtentamente,%0D%0A${product.Merchant?.commercialName.replace(' ', '%20')}`;

// 	return (
// 		<AuthorizationBoundary for={['merchant']}>
// 			<div className='grid h-full grid-flow-col p-4 pr-8 shadow'>
// 				<div className='col-start p-4'>
// 					<p className='text-2xl uppercase'>{`¿Quieres ${
// 						product?.promote ? 'quitar promoción de' : 'promocionar'
// 					} este producto?`}</p>
// 					{product ? (
// 						product.promote ? (
// 							<>
// 								<p>
// 									{`Solicita quitar promoción de este producto enviando un email a ${config.notifications_email} o clicando en "QUITAR PROMOCIÓN DE PRODUCTO".`}
// 								</p>
// 								<p>
// 									No olvides incluir en el email el nombre del producto y la razón por la cual desea
// 									dar de baja la promoción.
// 								</p>
// 							</>
// 						) : (
// 							<>
// 								<p>
// 									{`Solicita la promoción de este producto enviando un email a ${config.notifications_email} o clicando en "PROMOCIONAR PRODUCTO".`}
// 								</p>
// 								<p>
// 									No olvides incluir en el email el nombre del producto y el período solicitado de
// 									vigencia.
// 								</p>
// 							</>
// 						)
// 					) : (
// 						<p>Luego de crear el producto podrás solicitar su promoción de la página de inicio.</p>
// 					)}
// 				</div>
// 				<div className='flex items-center justify-end'>
// 					<Button
// 						onClick={handlePromoteProduct}
// 						disabled={product ? false : true}
// 						className='float-right mt-4 ml-4 uppercase'
// 					>
// 						{product ? (
// 							<>
// 								{product?.promote ? 'quitar promoción de producto' : 'promocionar producto'}
// 								{/* <a
// 								href={`mailto:${config.notifications_email}?subject=Solicitud%20${
// 									product.promote ? 'quitar%20promoción%20de%20producto' : 'promocionar%20producto'
// 								}&body=${emailBody()}`}
// 							>
// 								{product?.promote ? 'quitar promoción de producto' : 'promocionar producto'}
// 							</a> */}
// 							</>
// 						) : (
// 							'promocionar producto'
// 						)}
// 					</Button>
// 				</div>
// 			</div>
// 		</AuthorizationBoundary>
// 	);
// };

// const AdminPromoteProduct = ({ product, addErrorMessage }) => {
// 	const handlePromoteProduct = e => {
// 		e.preventDefault();
// 		if (product) {
// 			// onSubmit({ promote: !product.promote });
// 			addErrorMessage('Producto', 'Promocionar producto no implementado actualmente');
// 		}
// 	};
// 	return (
// 		<AuthorizationBoundary for={['admin']}>
// 			<div className='grid h-full grid-flow-col p-4 pr-8 shadow'>
// 				<div className='col-start p-4'>
// 					<p className='text-2xl uppercase'>
// 						{`¿Quieres ${product?.promote ? 'quitar promoción de' : 'promocionar'} este producto?`}
// 					</p>
// 					{product ? (
// 						<p>
// 							{product?.promote
// 								? 'Al "QUITAR PROMOCIÓN DE PRODUCTO" desaparecerá del "Banner" en la página principal.'
// 								: 'Al "PROMOCIONAR PRODUCTO" aparecerá en el "Banner" en la página principal.'}
// 						</p>
// 					) : (
// 						<p>Luego de crear el producto podrás promocionarlo.</p>
// 					)}
// 				</div>
// 				<div className='flex items-center justify-end'>
// 					<Button
// 						onClick={handlePromoteProduct}
// 						disabled={product ? false : true}
// 						className='float-right mt-4 ml-4 uppercase'
// 					>
// 						{product
// 							? product?.promote
// 								? 'quitar promoción de producto'
// 								: 'promocionar producto'
// 							: 'promocionar producto'}
// 					</Button>
// 				</div>
// 			</div>
// 		</AuthorizationBoundary>
// 	);
// };

const AdminProductInHome = ({ product }) => {
	const [productInHome] = useProductInHome();
	const { addErrorMessage, addSuccessMessage } = useToast();
	const { logOut } = useAuth();
	const handleProductInHome = e => {
		e.preventDefault();
		if (product) {
			productInHome({
				variables: {
					id: product.id,
					isInHome: !product.inHome,
				},
			}).then(({ data }) => {
				if (
					isSuccessResponse(data?.ProductInHome, ['Product'], logOut, addErrorMessage, 'Producto en portada')
				) {
					addSuccessMessage(
						'Producto',
						`${data.ProductInHome.title} ` +
							(data.ProductInHome.inHome > 0 ? 'añadido a portada' : 'eliminado de portada')
					);
				}
			});
		}
	};

	return (
		<AuthorizationBoundary for={['admin']}>
			<div className='grid h-full grid-flow-col p-4 pr-8 shadow'>
				<div className='col-start p-4'>
					<p className='text-2xl uppercase'>
						{`¿Quieres ${product?.inHome ? 'Quitar de portada' : 'Añadir a portada'} este producto?`}
					</p>
					{product ? (
						<p>
							{product?.inHome
								? 'Al pulsar "ELIMINAR PRODUCTO DE PORTADA" desaparecerá de la sección de "Productos Destacados" en la página principal.'
								: 'Al pulsar "AÑADIR PRODUCTO A PORTADA" aparecerá en la sección de "Productos Destacados" en la página principal.'}
						</p>
					) : (
						<p>Luego de crear el producto podrás añadirlo a la portada.</p>
					)}
				</div>
				<div className='flex items-center justify-end'>
					<Button
						onClick={handleProductInHome}
						disabled={product ? false : true}
						className='float-right mt-4 ml-4'
					>
						{product
							? product?.inHome
								? 'ELIMINAR PRODUCTO DE PORTADA'
								: 'AÑADIR PRODUCTO A PORTADA'
							: 'AÑADIR PRODUCTO A PORTADA'}
					</Button>
				</div>
			</div>
		</AuthorizationBoundary>
	);
};

// Variants
const VariantsArray = ({
	newProduct,
	control,
	getValues,
	setValue,
	unregister,
	fields,
	disableNonTranslatableFields,
	register,
	isRequired,
	isAdmin,
	showRequiredIcon,
	setTranslatorModal,
	getLazyProduct,
	productId,
	fromLocale,
	setFromLocale,
	addErrorMessage,
	locale,
	config,
}) => {
	const variants = getValues('productForm.Variants') || [];
	return (
		<div className='flex flex-wrap lg:flex-nowrap w-full max-w-screen-lg'>
			<div className='flex flex-col w-full'>
				{variants?.map((_, i) => (
					<div key={i} className='flex gap-10'>
						<VariantField
							control={control}
							register={register}
							variants={variants}
							setValue={setValue}
							unregister={unregister}
							index={i}
							fields={fields}
							isRequired={isRequired}
							showRequiredIcon={showRequiredIcon}
							isAdmin={isAdmin}
							disableNonTranslatableFields={disableNonTranslatableFields}
							getValues={getValues}
						/>
					</div>
				))}
			</div>
			{!newProduct ? (
				<TranslatorButton
					className='w-1/3'
					onClick={async () => {
						setTranslatorModal(true);
						getLazyProduct({
							variables: { id: productId, forceLocale: true, locale: fromLocale },
						}).then(({ data }) => {
							if (data) {
								translateVariantsTitleField(
									data.Product?.Variants,
									fromLocale,
									setTranslatorModal,
									setValue,
									addErrorMessage,
									locale,
									config
								);
							}
						});
					}}
					fromLocale={fromLocale}
					setFromLocale={setFromLocale}
				/>
			) : null}
		</div>
	);
};

const OrderVariantsArrows = ({ variants, index, setValue, unregister }) => {
	const handleChangePosition = (action, index) => {
		const selectedVariantWithNewPosition = { ...variants[index], position: variants[index].position + action };
		const replaceVariantWithNewPosition = { ...variants[index + action], position: index };
		const newVariants = [...variants];
		newVariants[index + action] = selectedVariantWithNewPosition;
		newVariants[index] = replaceVariantWithNewPosition;
		setValue(
			'productForm.Variants',
			newVariants?.map(item => ({
				id: item.id,
				title: item.title || '',
				price: item.price ?? '',
				discountedPrice: item.discountedPrice ?? '',
				sku: item.sku || '',
				barcode: item.barcode || '',
				isAvailable: item.isAvailable,
				outOfSeasson: item.outOfSeasson ?? 0,
				limitedProduction: item.limitedProduction ?? 0,
				format: item.format || null,
				position: item.position || 0,
			}))
		);
		unregister('productForm.Variants', { keepValue: true });
	};

	return (
		variants.length > 1 && (
			<AuthorizationBoundary for={['admin', 'merchant']}>
				<div className='flex flex-col'>
					{index > 0 && (
						<img
							onClick={() => handleChangePosition(-1, index)}
							className='cursor-pointer w-10 h-10'
							title='Subir variante'
							alt='flecha hacia arriba'
							src='/images/square-caret-up-solid.svg'
						/>
					)}
					{index < variants.length - 1 && (
						<img
							onClick={() => handleChangePosition(1, index)}
							className='cursor-pointer w-10 h-10'
							title='Bajar variante'
							alt='flecha hacia abajo'
							src='/images/square-caret-down-solid.svg'
						/>
					)}
				</div>
			</AuthorizationBoundary>
		)
	);
};

// Single fields
const VariantField = ({
	variants,
	control,
	index,
	unregister,
	register,
	setValue,
	fields,
	isRequired,
	disableNonTranslatableFields,
	isAdmin,
	showRequiredIcon,
	getValues,
}) => {
	return (
		<Field>
			<div
				className={`flex w-full flex-col ${
					variants.length - 1 !== index ? 'border-b border-dashed border-coral-600' : ''
				}`}
			>
				<div className='flex gap-2 pr-4'>
					<div>
						<OrderVariantsArrows
							variants={variants}
							index={index}
							setValue={setValue}
							unregister={unregister}
						/>
					</div>
					<div className='grow'>
						<div className='flex justify-between gap-4 w-full'>
							<div className={`flex ${variants.length > 1 ? 'gap-3' : 'gap-5'}`}>
								{variants.length > 1 ? (
									<div className='flex flex-col'>
										<p className='text-lg italic text-gray-600'>
											<span className='text-coral-600'>*</span> Nombre de variación ej: XL
										</p>
										<InputForm
											register={register}
											control={control}
											name={`productForm.Variants.${index}.title`}
											type='text'
											placeholder='Nombre'
											width='1'
											required={isAdmin ? false : variants.length > 1}
											showRequiredIcon={showRequiredIcon}
										/>
									</div>
								) : null}
								<div className='flex flex-col'>
									<p className='text-lg italic text-gray-600'>
										<span className='text-coral-600'>*</span> Precio (IVA incluido)
									</p>
									<InputForm
										name={`productForm.Variants.${index}.price`}
										control={control}
										register={register}
										min='0.00'
										width='1'
										placeholder='Precio (IVA incluido)'
										suffix='€'
										required={isRequired()}
										showRequiredIcon
										type='number'
										step='0.01'
										disabled={disableNonTranslatableFields}
									/>
								</div>
								<div className='flex flex-col'>
									<p className='text-lg italic text-gray-600'>Precio con descuento</p>
									<InputForm
										name={`productForm.Variants.${index}.discountedPrice`}
										control={control}
										register={register}
										width='1'
										placeholder='Precio (IVA incluido)'
										suffix='€'
										type='number'
										step='0.01'
										disabled={disableNonTranslatableFields}
									/>
								</div>
							</div>
							<AuthorizationBoundary for={['admin', 'merchant']}>
								<div className='flex gap-4 '>
									{variants.length !== 1 && (
										<RemoveFields
											key={`rf_${index}`}
											setValue={setValue}
											variants={variants}
											unregister={unregister}
											index={index}
										/>
									)}
									{variants.length - 1 === index && (
										<AddFields
											key={`af_${index}`}
											setValue={setValue}
											variants={variants}
											unregister={unregister}
											index={index}
											fields={fields}
										/>
									)}
								</div>
							</AuthorizationBoundary>
						</div>
						<div className='flex justify-between w-full'>
							<div className='flex flex-col'>
								<p className='text-lg italic text-gray-600'>Formato</p>
								<InputForm
									register={register}
									control={control}
									name={`productForm.Variants.${index}.format`}
									type='number'
									placeholder='Ej: 0.7'
									step='0.01'
									width='1'
									disabled={disableNonTranslatableFields}
									suffix={getValues('productForm.unit')}
								/>
							</div>
							<div className='flex flex-col'>
								<p className='text-lg italic text-gray-600'>SKU</p>
								<InputForm
									register={register}
									control={control}
									name={`productForm.Variants.${index}.sku`}
									type='text'
									placeholder='SKU (opcional)'
									width='1'
									disabled={disableNonTranslatableFields}
								/>
							</div>
							<div className='flex flex-col'>
								<p className='text-lg italic text-gray-600'>EAN</p>
								<InputForm
									register={register}
									control={control}
									name={`productForm.Variants.${index}.barcode`}
									type='text'
									placeholder='EAN (opcional)'
									width='1'
									disabled={disableNonTranslatableFields}
								/>
							</div>
							<div className='flex flex-col'>
								<p className='text-lg italic text-gray-600'>Stock</p>
								<SelectForm
									name={`productForm.Variants.${index}.isAvailable`}
									control={control}
									disabled={disableNonTranslatableFields}
									options={[
										{ value: true, name: 'Disponible' },
										{ value: false, name: 'No Disponible' },
									]}
									type='boolean'
									width='1'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Field>
	);
};

const AddFields = ({ index, setValue, variants, unregister, fields }) => {
	const handleAddVariant = e => {
		e.preventDefault();
		if (variants[index].price) {
			fields[0].position = index + 1;
			setValue('productForm.Variants', [...variants, ...fields]);
			unregister('productForm.Variants', { keepValue: true });
		}
	};

	return (
		<button
			aria-label={`addPriceButton_${index}`}
			onClick={handleAddVariant}
			className='flex h-8 w-8 items-center justify-center self-center rounded-full bg-coral-300 font-bold'
		>
			+
		</button>
	);
};

const RemoveFields = ({ index, setValue, variants, unregister }) => {
	const handleRemoveVariant = e => {
		e.preventDefault();
		setValue(
			'productForm.Variants',
			variants.filter((_, i) => i !== index)
		);
		unregister('productForm.Variants', { keepValue: true });
	};

	return (
		<button
			aria-label={`removePriceButton_${index}`}
			onClick={handleRemoveVariant}
			className='flex h-8 w-8 items-center justify-center self-center rounded-full bg-coral-300 font-bold'
		>
			-
		</button>
	);
};

// Other Features
const OtherFeaturesArray = ({ control, getValues, setValue, unregister, fields, register }) => {
	const otherFeatures = getValues('productForm.otherFeatures') || [
		{
			title: '',
			value: '',
		},
	];
	return (
		<div className='flex flex-col w-full'>
			{otherFeatures?.map((value, i) => (
				<div key={i} className='flex gap-4'>
					<FeatureField control={control} register={register} index={i} getValues={getValues} />
					{otherFeatures.length !== 1 && (
						<RemoveFeatureFields
							setValue={setValue}
							otherFeatures={otherFeatures}
							unregister={unregister}
							index={i}
						/>
					)}
					{otherFeatures.length - 1 === i && (
						<AddFeatureFields
							setValue={setValue}
							otherFeatures={otherFeatures}
							unregister={unregister}
							index={i}
							fields={fields}
						/>
					)}
				</div>
			))}
		</div>
	);
};

// Single fields
const FeatureField = ({ control, index, register, getValues }) => {
	return (
		<div className='flex gap-10'>
			<span className='flex flex-col'>
				{!index && <p className='text-sm italic text-gray-600'>Nombre de caracteristica</p>}
				<InputForm
					register={register}
					control={control}
					name={`productForm.otherFeatures.${index}.title`}
					type='text'
					placeholder='Nombre'
					width='1'
					missingTranslations={getValues('productForm').substatus === 'untranslated'}
				/>
			</span>

			<span className='flex flex-col'>
				{!index && <p className='text-sm italic text-gray-600'>Valor de caracteristica</p>}
				<InputForm
					register={register}
					control={control}
					name={`productForm.otherFeatures.${index}.value`}
					type='text'
					placeholder='Valor'
					width='1'
					missingTranslations={getValues('productForm').substatus === 'untranslated'}
				/>
			</span>
		</div>
	);
};

const AddFeatureFields = ({ index, setValue, otherFeatures, unregister, fields }) => {
	const handleAddFeature = e => {
		e.preventDefault();
		if (otherFeatures[index].title && otherFeatures[index].value !== null) {
			setValue('productForm.otherFeatures', [...otherFeatures, ...fields]);
			unregister('productForm.otherFeatures', { keepValue: true });
		}
	};

	return (
		<button
			id={`addFeatureButton_${index}`}
			aria-label={`addFeatureButton_${index}`}
			onClick={handleAddFeature}
			className='flex h-8 w-8 items-center justify-center self-center rounded-full bg-coral-300 font-bold'
		>
			+
		</button>
	);
};

const RemoveFeatureFields = ({ index, setValue, otherFeatures, unregister }) => {
	const handleRemoveFeature = e => {
		e.preventDefault();
		setValue(
			'productForm.otherFeatures',
			otherFeatures.filter((_, i) => i !== index)
		);
		unregister('productForm.otherFeatures', { keepValue: true });
	};

	return (
		<button
			id={`removeFeatureButton_${index}`}
			aria-label={`removeFeatureButton_${index}`}
			onClick={handleRemoveFeature}
			className='flex h-8 w-8 items-center justify-center self-center rounded-full bg-coral-300 font-bold'
		>
			-
		</button>
	);
};

const toArrayOfObjects = v => {
	if (!Array.isArray(v)) {
		return JSON.parse(v);
	}
	return v;
};

export default ProductForm;
