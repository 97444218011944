/**
 * Component that will receive those 3 arguments and return a different output based on the condition
 *
 * @param {Boolean} condition condition to render the wrapping element
 * @param {Object} wrapper wrapper component
 * @param {Object} children children
 */

// https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

export default ConditionalWrapper;
