import React, { useEffect, useState } from 'react';

import Card from 'components/Card';
import { useForm } from 'react-hook-form';
import {
	useCreateSpecialFeature,
	useDeleteSpecialFeature,
	useLazySpecialFeatures,
	useUpdateSpecialFeature,
} from 'hooks/use-special-features';
import LayoutHeader from 'components/LayoutHeader';
import InputForm from 'components/InputForm';
import TextareaForm from 'components/TextareaForm';
import LayoutTitle from 'components/LayoutTitle';
import Button, { DeleteButton } from 'components/Button';
import { useToast } from 'components/Toast';
import Modal from 'components/Modal';
import useToggle from 'hooks/use-toggle';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';

const SpecialFeatures = () => {
	const [specialFeatures, setSpecialFeatures] = useState([]);

	const [getLazySpecialFeatures] = useLazySpecialFeatures({
		onCompleted: ({ SpecialFeatures }) => {
			setSpecialFeatures(SpecialFeatures.map(specialFeature => ({ ...specialFeature })));
		},
	});

	useEffect(() => {
		getLazySpecialFeatures();
	}, []);

	return (
		<Card>
			<LayoutHeader>Características especiales</LayoutHeader>
			<LayoutTitle>Nueva característica</LayoutTitle>
			<SpecialFeatureForm newSpecialFeature getLazySpecialFeatures={getLazySpecialFeatures} />
			<LayoutTitle>Lista de características</LayoutTitle>
			{specialFeatures.map(({ name, description, id }) => (
				<SpecialFeatureForm
					name={name}
					description={description}
					id={id}
					key={id}
					getLazySpecialFeatures={getLazySpecialFeatures}
				/>
			))}
		</Card>
	);
};

const SpecialFeatureForm = ({ id, name, description, getLazySpecialFeatures, newSpecialFeature }) => {
	const { logOut } = useAuth();
	const { addErrorMessage, addSuccessMessage } = useToast();
	const [openModal, setOpenModal] = useToggle();
	const [createSpecialFeature, { loading: loadingCreate }] = useCreateSpecialFeature({
		onCompleted: ({ SpecialFeatureCreate }) => {
			if (
				isSuccessResponse(
					SpecialFeatureCreate,
					['SpecialFeature'],
					logOut,
					addErrorMessage,
					'Característica especial'
				)
			) {
				addSuccessMessage('Característica especial', 'creada correctamente');
				getLazySpecialFeatures();
				setValue('specialFeature', { id: '', name: '', description: '' });
			}
		},
	});

	const [updateSpecialFeature, { loading: loadingUpdate }] = useUpdateSpecialFeature({
		onCompleted: ({ SpecialFeatureUpdate }) => {
			if (
				isSuccessResponse(
					SpecialFeatureUpdate,
					['SpecialFeature'],
					logOut,
					addErrorMessage,
					'Característica especial'
				)
			) {
				addSuccessMessage('Característica especial', 'actualizada correctamente');
				getLazySpecialFeatures();
			}
		},
	});

	const [deleteSpecialFeature, { loading: loadingDelete }] = useDeleteSpecialFeature({
		onCompleted: ({ SpecialFeatureDelete }) => {
			if (
				isSuccessResponse(SpecialFeatureDelete, ['Success'], logOut, addErrorMessage, 'Característica especial')
			) {
				addSuccessMessage('Característica especial', 'eliminada correctamente');
				getLazySpecialFeatures();
			}
		},
	});

	const { handleSubmit, control, setValue, getValues, register } = useForm({
		defaultValues: {
			specialFeature: {
				id: newSpecialFeature ? '' : id,
				name: newSpecialFeature ? '' : name,
				description: newSpecialFeature ? '' : description,
			},
		},
	});

	const onSubmit = ({ specialFeature }) => {
		if (newSpecialFeature) {
			createSpecialFeature({
				variables: { name: specialFeature.name, description: specialFeature.description },
			});
		} else {
			updateSpecialFeature({
				variables: specialFeature,
			});
		}
	};

	return (
		<div className='flex'>
			<form className={`w-full mb-8 ${newSpecialFeature ? '' : 'border-b'}`} onSubmit={handleSubmit(onSubmit)}>
				<InputForm
					fieldWidth=''
					width=''
					className='ml-4'
					name='specialFeature.name'
					control={control}
					register={register}
					label='Nombre'
					placeholder='Nombre'
					required={newSpecialFeature}
					description={!newSpecialFeature ? <p>{`id: ${getValues('specialFeature.id')}`}</p> : ''}
				>
					<Button className='ml-4' type='submit' disabled={loadingUpdate || loadingCreate}>
						{newSpecialFeature ? 'Crear' : 'Guardar'}
					</Button>
					{!newSpecialFeature && (
						<DeleteButton
							className='ml-4'
							type='button'
							onClick={() => setOpenModal(true)}
							disabled={loadingDelete}
						>
							Eliminar
						</DeleteButton>
					)}
				</InputForm>

				<TextareaForm
					height='h-20'
					className='ml-4 border border-coral-300 py-2 px-4 font-normal'
					name='specialFeature.description'
					control={control}
					register={register}
					label='Descripción'
					placeholder='Descripción'
					required={newSpecialFeature}
				/>
			</form>
			<Modal
				modalObject={{
					title: 'Confirmación',
					description: (
						<span className='text-lg leading-5 text-gray-500'>
							Al confirmar se eliminará y no se podrá anular.
						</span>
					),
					btnText: 'Confirmar',
					cancelBtnText: 'Cancelar',
				}}
				openModal={openModal}
				setOpenModal={setOpenModal}
				handleSubmit={() => {
					deleteSpecialFeature({
						variables: { id: getValues('specialFeature.id') },
					});
				}}
			/>
		</div>
	);
};

export default SpecialFeatures;
