import Button from 'components/Button';
import Card from 'components/Card';
import InputForm from 'components/InputForm';
import Input from 'components/Input';
import LayoutHeader from 'components/LayoutHeader';
import { useCreateMerchant } from 'hooks/use-merchants';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useToast } from 'components/Toast';
import LayoutTitle from 'components/LayoutTitle';
import generateRandomPassword from 'lib/generateRandomPassword';
import isSuccessResponse from 'lib/isSuccessResponse';
import useAuth from 'hooks/use-auth';

const MerchantNew = () => {
	const { logOut } = useAuth();
	let location = useLocation();
	const [create, { loading }] = useCreateMerchant();

	const [createMerchantResponse, setCreateMerchantResponse] = useState(null);
	const { addSuccessMessage, addErrorMessage } = useToast();

	const { handleSubmit, reset, control, register } = useForm({
		defaultValues: {
			merchantForm: {
				// Account data
				firstName: '',
				lastName: '',
				email: '',
				password: generateRandomPassword(),
				phone: '',

				// Conditions
				commission: 0,
				entranceFee: 0,

				// Administrative data
				commercialName: '',
				administrativeAddress1: '',
				administrativeAddress2: '',
				administrativeBusinessName: '',
				administrativeCity: '',
				administrativeCountry: 'ES',
				administrativeProvince: '',
				administrativeVat: '',
				administrativeZip: '',
			},
		},
	});

	useEffect(() => {
		reset();
	}, [location, reset]);

	useEffect(() => {
		if (
			createMerchantResponse &&
			isSuccessResponse(createMerchantResponse, ['Merchant'], logOut, addErrorMessage, 'Crear asociado')
		) {
			reset();
			addSuccessMessage('Crear asociado', 'creado correctamente');
		}
	}, [createMerchantResponse, addSuccessMessage, addErrorMessage, reset]);

	return (
		<>
			<Card>
				<LayoutHeader>Crear Asociado</LayoutHeader>
				<p className='mb-4'>
					Con los siguientes datos se creará un Asociado y se enviará un Email con su usuario y contraseña
					notificando su Alta en el sistema.
				</p>
				<p className='mb-4'>Todos los campos son requeridos. (El email estará asociado al usuario)</p>
				<form
					onSubmit={handleSubmit(data => {
						create({
							variables: {
								input: data.merchantForm,
							},
						}).then(res => {
							setCreateMerchantResponse(res.data.MerchantCreate);
						});
					})}
				>
					<LayoutTitle>Datos de la cuenta</LayoutTitle>

					<InputForm
						name='merchantForm.firstName'
						control={control}
						register={register}
						id='firstName'
						label='Nombre Contacto'
						placeholder='Nombre de Persona Contacto'
						required
					/>
					<InputForm
						name='merchantForm.lastName'
						control={control}
						register={register}
						id='lastName'
						label='Apellido Contacto'
						placeholder='Nombre de Persona Contacto'
					/>
					<InputForm
						name='merchantForm.phone'
						control={control}
						register={register}
						label='Teléfono'
						placeholder='Teléfono'
						type='tel'
						pattern='^\+?[0-9]*'
						maxLength='15'
						minLength='9'
						required
					/>
					<InputForm
						name='merchantForm.email'
						control={control}
						register={register}
						id='email'
						type='email'
						label='Email'
						placeholder='Email'
						required
					/>
					<InputForm
						name='merchantForm.password'
						control={control}
						register={register}
						id='newpassword'
						label='Contraseña Temporal'
						placeholder='Contraseña Temporal'
						autoComplete='off'
						required
					/>
					<LayoutTitle>Condiciones del Proveedor</LayoutTitle>
					<InputForm
						name='merchantForm.commission'
						type='number'
						control={control}
						register={register}
						id='commission'
						label='Comisión'
						suffix='%'
						description={
							<p className='text-sm font-normal pr-4'>
								% sobre pvp que se descontará del ingreso del asociado
							</p>
						}
						min='0'
						max='100'
					/>
					<InputForm
						name='merchantForm.entranceFee'
						control={control}
						register={register}
						type='number'
						id='entranceFee'
						label='Cuota a pagar'
						placeholder='Cuota en €'
						suffix='€'
						min='0'
					/>
					<LayoutTitle>Datos del Proveedor</LayoutTitle>

					<InputForm
						name='merchantForm.commercialName'
						control={control}
						register={register}
						id='commercialName'
						label='Nombre Comercial'
						placeholder='Nombre Comercial'
						required
					/>
					<InputForm
						name='merchantForm.administrativeBusinessName'
						control={control}
						register={register}
						id='administrativeBusinessName'
						label='Razón social'
						placeholder='Razón social'
						required
					/>
					<InputForm
						name='merchantForm.administrativeVat'
						control={control}
						register={register}
						label='CIF'
						placeholder='CIF'
						required
					/>
					<InputForm
						name='merchantForm.administrativeAddress1'
						control={control}
						register={register}
						id='administrativeAddress1'
						label='Dirección'
						placeholder='Calle/Avenida/Paseo, Número'
						required
					/>
					<InputForm
						name='merchantForm.administrativeAddress2'
						control={control}
						register={register}
						id='administrativeAddress2'
						label='Más datos de la dirección'
						placeholder='Escalera/Piso/Letra/Empresa/etc.'
					/>
					<InputForm
						name='merchantForm.administrativeCity'
						control={control}
						register={register}
						label='Localidad'
						placeholder='Localidad'
						required
					/>
					<InputForm
						name='merchantForm.administrativeProvince'
						control={control}
						register={register}
						label='Provincia'
						placeholder='Provincia'
						required
					/>
					<InputForm
						name='merchantForm.administrativeZip'
						control={control}
						register={register}
						label='Código Postal'
						placeholder='Código Postal'
						pattern='[0-9]*'
						maxLength='5'
						minLength='5'
						required
					/>
					<Input className='border-none' value='ES' label='País' readOnly />

					<Button disabled={loading}>Crear</Button>
				</form>
			</Card>
		</>
	);
};

export default MerchantNew;
