import { dayList } from './dayList';
import isValid24HourFormat from './isValid24HourFormat';

const isValidSchedule = (schedule, addErrorMessage) => {
	for (let day in schedule) {
		if (Object.prototype.hasOwnProperty.call(schedule, day)) {
			const { afternoonEnd, afternoonStart, closed, morningEnd, morningStart } = schedule[day];
			// Show error when day is not closed and all hours are empty
			if (!closed && afternoonEnd === '' && afternoonStart === '' && morningEnd === '' && morningStart === '') {
				addErrorMessage(
					'Horario',
					<span>
						indicar horas el día <b>{dayList[day]}</b> o marcar como cerrado
					</span>
				);
				return false;
			}
			// Show error when day is not closed and hours range are not filled correctly
			if (
				!closed &&
				((morningEnd !== '' && morningStart === '') ||
					(morningEnd === '' && morningStart !== '') ||
					(afternoonEnd !== '' && afternoonStart === '') ||
					(afternoonEnd === '' && afternoonStart !== ''))
			) {
				addErrorMessage(
					'Horario',
					<span>
						falta completar horario el día <b>{dayList[day]}</b>
					</span>
				);
				return false;
			}

			if (
				!closed &&
				(!isValid24HourFormat(afternoonEnd) ||
					!isValid24HourFormat(afternoonStart) ||
					!isValid24HourFormat(morningEnd) ||
					!isValid24HourFormat(morningStart))
			) {
				addErrorMessage(
					'Horario',
					<span>
						formato de hora inválido el día <b>{dayList[day]}</b>
					</span>
				);
				return false;
			}
		}
	}
	return true;
};

export default isValidSchedule;
