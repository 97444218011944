import useAuth from 'hooks/use-auth';
import useConf from 'hooks/use-conf';
import translateLocale from 'lib/translateLocale';
import Button from './Button';
import Tooltip from './Tooltip';

const TranslatorButton = ({ fromLocale, setFromLocale, onClick, className = '', tooltip, ...props }) => {
	const { config } = useConf();
	const { locale } = useAuth();

	return (
		<div className={`flex items-start ${className}`}>
			<Tooltip
				tooltip={
					tooltip
						? tooltip
						: !fromLocale
						? 'Elegir el idioma original del que traducir.'
						: `Traducir de ${translateLocale(fromLocale)}.`
				}
			>
				<Button
					type='button'
					disabled={!fromLocale}
					className='ml-2 h-37px'
					padding=''
					onClick={e => {
						e.preventDefault();
						onClick();
					}}
					bg='bg-transparent'
					{...props}
				>
					<svg width='62' height='37' viewBox='0 0 62 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M 0,6.16667 C 0,2.76536 2.76536,0 6.16667,0 h 18.50003 4.625 7.395732 L 61.996417,0.00563017 C 61.989895,0.10833203 61.996073,37 61.996073,37 l -31.154981,0.01954 h -1.5416 L 24.6667,37 H 6.16667 C 2.76536,37 0,34.2346 0,30.8333 Z m 30.8333,0 V 30.8333 H 55.5 V 6.16667 Z M 17.1799,10.782 c -0.3083,-0.6937 -1.002,-1.14658 -1.7632,-1.14658 -0.7612,0 -1.455,0.45288 -1.7633,1.14658 L 7.48672,24.657 c -0.43359,0.9732 0.00963,2.1102 0.98281,2.5438 0.97318,0.4336 2.11017,-0.0097 2.54377,-0.9828 l 0.8575,-1.9368 h 7.0917 l 0.8576,1.9368 c 0.4335,0.9731 1.5705,1.4067 2.5437,0.9828 0.9732,-0.424 1.4068,-1.5706 0.9828,-2.5438 z m -1.7632,5.5211 1.8307,4.124 h -3.6615 z m 27.75,-6.66768 c 1.0599,0 1.9271,0.86718 1.9271,1.92708 v 0.3854 h 4.2395 1.5417 c 1.0599,0 1.9271,0.8672 1.9271,1.9271 0,1.0599 -0.8672,1.9271 -1.9271,1.9271 h -0.1927 l -0.1542,0.4336 c -0.8575,2.351 -2.1583,4.4901 -3.8156,6.3015 0.0867,0.0579 0.1734,0.106 0.2602,0.1542 l 1.8211,1.0888 c 0.9153,0.5492 1.2044,1.7344 0.6648,2.6401 -0.5396,0.9057 -1.7344,1.2044 -2.6401,0.6649 l -1.8211,-1.0888 c -0.4336,-0.2602 -0.8479,-0.53 -1.2622,-0.8191 -1.0214,0.7227 -2.1102,1.349 -3.2761,1.8693 l -0.3469,0.1542 c -0.9731,0.4336 -2.1101,-0.0097 -2.5437,-0.9828 -0.4336,-0.9732 0.0096,-2.1102 0.9828,-2.5438 l 0.3469,-0.1541 c 0.6166,-0.2795 1.214,-0.5878 1.7825,-0.9443 l -1.1755,-1.1755 c -0.7516,-0.7516 -0.7516,-1.9753 0,-2.7269 0.7516,-0.7515 1.9753,-0.7515 2.7268,0 l 1.4068,1.4068 0.0482,0.0482 c 1.1948,-1.2623 2.168,-2.7268 2.8713,-4.336 h -3.3916 -6.9375 c -1.0599,0 -1.9271,-0.8671 -1.9271,-1.927 0,-1.0599 0.8672,-1.9271 1.9271,-1.9271 h 5.0104 v -0.3854 c 0,-1.0599 0.8672,-1.92712 1.9271,-1.92712 z'
							fill='#FF515B'
						/>
					</svg>
				</Button>
				<select
					className='border-t-2 border-r-2 border-b-2 border-coral-500 rounded-r-md h-37px'
					value={fromLocale}
					onChange={e => setFromLocale(e.target.value)}
				>
					<option value=''>Seleccionar</option>
					{config.locales
						.filter(configLocale => configLocale !== locale)
						.map(locale => (
							<option key={locale} value={locale}>
								{translateLocale(locale)}
							</option>
						))}
				</select>
			</Tooltip>
		</div>
	);
};

export default TranslatorButton;
