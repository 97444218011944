import LayoutHeader from 'components/LayoutHeader';
import React, { useState, useRef } from 'react';

import AccountForm from './AccountForm';
import AdministrativeForm from './AdministrativeForm';
import CommercialForm from './CommercialForm';

const MerchantForm = ({
	adminLayout,
	data,
	handleCopyAnotherLanguage = () => {},
	dirty,
	setDirty,
	getLazyMerchant = () => {},
}) => {
	const [meetRequirements, setMeetRequirements] = useState(data?.Merchant.meetRequirements);

	const administrativeFormRef = useRef(null);
	const handleAdministrativeFormFocus = () => {
		setTimeout(() => {
			administrativeFormRef.current.scrollIntoView({ behavior: 'smooth' });
		}, 300);
	};
	const commercialFormRef = useRef(null);
	const handleCommercialFormFocus = () => {
		setTimeout(() => {
			commercialFormRef.current.scrollIntoView({ behavior: 'smooth' });
		}, 300);
	};

	return (
		<div className='accordion' id='accordionAccountForm'>
			{/* Account Form */}
			<div className='accordion-item bg-white border border-coral-500'>
				<h2 className='accordion-header mb-0' id='accountForm'>
					<button
						className='accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-coral-500 text-left bg-white border-0 rounded-none transition focus:outline-none'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#collapseOne'
						aria-expanded='false'
						aria-controls='collapseOne'
					>
						<LayoutHeader>{adminLayout ? 'Datos de la Cuenta' : 'Datos de Perfil'}</LayoutHeader>
					</button>
				</h2>
				<div
					id='collapseOne'
					className='accordion-collapse collapse'
					aria-labelledby='accountForm'
					data-bs-parent='#accordionAccountForm'
				>
					<div className='accordion-body py-4 px-5'>
						<AccountForm
							adminLayout={adminLayout}
							className='shadow-none'
							userData={data?.Merchant.User}
							merchantSlug={data?.Merchant?.slug}
							setDirty={setDirty}
							withHeader={false}
						/>
					</div>
				</div>
			</div>
			{/* Administrative Form */}
			<div className='accordion-item bg-white border border-coral-500' ref={administrativeFormRef} tabIndex='-1'>
				<h2 className='accordion-header mb-0' id='administrativeForm'>
					<button
						className='accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-coral-500 text-left bg-white border-0 rounded-none transition focus:outline-none'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#collapseTwo'
						aria-expanded='false'
						aria-controls='collapseTwo'
						onClick={handleAdministrativeFormFocus}
					>
						<span className='flex flex-col'>
							<LayoutHeader>Ficha Administrativa</LayoutHeader>
							<p className='text-gray-700'>Datos administrativos y de facturación de la empresa.</p>
						</span>
					</button>
				</h2>
				<div
					id='collapseTwo'
					className='accordion-collapse collapse'
					aria-labelledby='administrativeForm'
					data-bs-parent='#accordionAccountForm'
				>
					<div className='accordion-body py-4 px-5'>
						<AdministrativeForm
							adminLayout={adminLayout}
							merchantData={data?.Merchant}
							userData={data?.Merchant.User}
							setDirty={setDirty}
							setMeetRequirements={setMeetRequirements}
							withHeader={false}
						/>
					</div>
				</div>
			</div>
			{/* Commercial Form */}
			<div
				className={`accordion-item bg-white border ${
					adminLayout || meetRequirements ? 'border-coral-500' : 'border-gray-500'
				}`}
				ref={commercialFormRef}
				tabIndex='-1'
			>
				<h2 className='accordion-header mb-0' id='commercialForm'>
					<button
						className={`accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base ${
							adminLayout || meetRequirements ? 'text-coral-500' : 'text-gray-500'
						} text-left bg-white border-0 rounded-none transition focus:outline-none`}
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#collapseThree'
						aria-expanded='false'
						aria-controls='collapseThree'
						onClick={handleCommercialFormFocus}
					>
						<span className='flex flex-col'>
							<LayoutHeader>Ficha Comercial</LayoutHeader>
							<p className='text-gray-700'>Datos que aparecen en la parte pública de la web.</p>
						</span>
					</button>
				</h2>
				<div
					id='collapseThree'
					className='accordion-collapse collapse'
					aria-labelledby='commercialForm'
					data-bs-parent='#accordionAccountForm'
				>
					<div className='accordion-body py-4 px-5'>
						<p className='text-gray-700 mb-8'>
							No escribas todo en mayúsculas (solo al inicio de frase), párrafos cortos y revisa la
							ortografía.
						</p>
						{adminLayout || meetRequirements ? (
							<CommercialForm
								adminLayout={adminLayout}
								merchantData={data?.Merchant}
								dirty={dirty}
								setDirty={setDirty}
								withHeader={false}
								handleCopyAnotherLanguage={handleCopyAnotherLanguage}
								getLazyMerchant={getLazyMerchant}
							/>
						) : (
							<div className='accordion-body py-4 px-5'>
								Disponible luego de confirmar el importe de la cuota de adhesión
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MerchantForm;
