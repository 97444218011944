/**
 * Translate locale to complete word in spanish.
 *
 * @param {String} locale two character locale
 * @returns {String} translated locale to complete word.
 */
const translateLocale = locale => {
	switch (locale) {
		case 'es':
			return 'Español';
		case 'ca':
			return 'Catalán';
		case 'en':
			return 'Inglés';
		case 'de':
			return 'Alemán';
		default:
			return '';
	}
};

export default translateLocale;
