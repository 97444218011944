import VersionChecker from 'components/VersionChecker';
import React from 'react';

const LoginLayout = ({ children }) => {
	return (
		<div className='main flex flex-col min-h-screen mx-auto max-w-full overflow-x-hidden md:overflow-x-clip'>
			{children}
			<VersionChecker />
		</div>
	);
};

export default LoginLayout;
