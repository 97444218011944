import Field from 'components/Field';
import React, { useState } from 'react';

const Input = ({
	className,
	id,
	suffix,
	type = 'text',
	fieldFloat = '',
	placeholderstyle = 'font-light placeholder-gray-400',
	value,
	invalid,
	children,
	containerClassName,
	...props
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	if (type === 'password') {
		return (
			<Field float={fieldFloat} {...props}>
				<div
					className={`flex items-center align-center bg-white p-2  ${
						className || 'w-2/3'
					} border border-gray-700`}
				>
					<input
						value={value || ''}
						type={`${showPassword ? 'text' : 'password'}`}
						id={id}
						{...props}
						className={`w-full outline-none focus:outline-none placeholder-gray-400 ${
							placeholderstyle || ''
						}`}
					/>
					<span className='w-20px aspect-square relative' onClick={handleShowPassword}>
						<img
							className='w-5'
							alt='toggle password visibility'
							src={`${
								showPassword
									? '/images/form-icons/eye-slash-solid.svg'
									: '/images/form-icons/eye-solid.svg'
							}`}
						/>
					</span>
				</div>
				{children}
			</Field>
		);
	}

	return (
		<Field float={fieldFloat} containerClassName={containerClassName} {...props}>
			<div
				className={`flex items-center align-center bg-white p-2 border ${className || 'w-2/3'} ${
					invalid ? 'border-coral-500 text-coral-500' : 'border-gray-700'
				}`}
			>
				<input
					value={value ?? ''}
					type={type}
					id={id}
					{...props}
					className={`w-full outline-none focus:outline-none ${placeholderstyle || ''}`}
				/>
				{suffix}
			</div>
			{children}
		</Field>
	);
};

export default Input;
