import { gql, useLazyQuery } from '@apollo/client';

const STATISTICS_QUERY = gql`
	query Statistics($graphYear: Int, $MerchantId: ID) {
		Statistics(graphYear: $graphYear, MerchantId: $MerchantId) {
			... on StatisticsValues {
				totals {
					grossTotalDiscounts
					numberOfOrders
					totalSales
					totalTax
				}
				salesList {
					month
					orderAmountPerMonth
					orderCountPerMonth
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
`;

export const useLazyStatistics = variables =>
	useLazyQuery(STATISTICS_QUERY, { variables, fetchPolicy: 'cache-and-network' });
