import React, { useEffect, useState } from 'react';

import Card from 'components/Card';
import LayoutHeader from 'components/LayoutHeader';
import Button from 'components/Button';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';
import { useLazySortProducts, useProductInHome, useSortProductInHome } from 'hooks/use-products';
import useConf from 'hooks/use-conf';
import columnTitleElement from 'lib/columnTitleElement';
import TooltipMenu from 'components/TooltipMenu';
import LoadingView from 'components/LoadingView';
import ContentTable from 'components/ContentTable';
import { Link, useNavigate } from 'react-router-dom';
import webhook from 'lib/webhook';

const SortProductsInHome = () => {
	const { config } = useConf();
	const navigate = useNavigate();
	const { logOut } = useAuth();
	const { addSuccessMessage, addErrorMessage } = useToast();

	const [products, setProducts] = useState([]);

	const [getLazySortProducts, { data, loading }] = useLazySortProducts({
		isInHome: true,
		order_scope: 'inHome_asc',
		limit: 0,
		offset: 0,
		locale: config.default_locale,
	});
	const [productInHome] = useProductInHome();
	const [sortProductInHome] = useSortProductInHome();

	useEffect(() => {
		getLazySortProducts();
	}, []);

	// Excecute after first load
	useEffect(() => {
		if (data && isSuccessResponse(data.Products, ['ProductList'], logOut, addErrorMessage, 'Productos')) {
			setProducts(data?.Products?.List);
		}
	}, [data, addErrorMessage]);

	return (
		<Card>
			<div className='flex justify-between mb-4'>
				<Button
					onClick={() => {
						navigate(-1);
					}}
					className='flex'
				>
					<img className='w-6 h-6 mr-2' alt='back' src='/images/circle-chevron-left-solid.svg' />
					Volver
				</Button>
				<Button
					className='h-[40px] ml-4'
					onClick={() =>
						webhook(
							config,
							config.locales.map(locale => `/${locale}`)
						)
							.then(() => {
								addSuccessMessage('Página revalidada', 'Página "home" actualizada en la tienda');
							})
							.catch(error => {
								addErrorMessage('Revalidar tienda', error.message);
							})
					}
					type='button'
					tooltip='Regenerar en la tienda la página "home"'
				>
					Regenerar página "home"
				</Button>
			</div>
			<LayoutHeader>Ordenar productos en portada</LayoutHeader>
			<LoadingView loading={loading} whileLoading='Buscando productos...'>
				<ContentTable
					key={22}
					columns={[
						columnTitleElement('Orden'),
						columnTitleElement('Imagen'),
						columnTitleElement('Título'),
						columnTitleElement('Categoría'),
						columnTitleElement('Opciones'),
					].filter(element => element !== null)}
					content={products}
					render={(item, index) => (
						<tr
							key={index}
							className='border-t border-dashed border-coral-300 text-gray-700'
							aria-label='product-row'
						>
							<td className='px-4 text-left'>
								<OrderArrows
									products={products}
									index={index}
									sortProductInHome={sortProductInHome}
									locale={config.default_locale}
									getLazySortProducts={getLazySortProducts}
									logOut={logOut}
									addErrorMessage={addErrorMessage}
									isSuccessResponse={isSuccessResponse}
								/>
							</td>
							<td className='px-4 text-left'>
								<img className='w-14 h-14' src={item.DefaultImage?.src} alt={item.DefaultImage?.alt} />
							</td>
							<td className='px-4 text-left' aria-label='product-name'>
								<Link to={`/dashboard/catalogo/productos/edit/${item.id}`}>{item.title}</Link>
							</td>
							<td className='px-4 text-left'>{item?.Categories?.map(c => c.name).join(' > ')}</td>
							<td className='px-6'>
								<div className='h-16' aria-label='dots-tooltip-menu'>
									<TooltipMenu
										menuList={getProductMenuList({
											id: item.id,
											status: item.status,
											promote: item.promote,
											isInHome: item.isInHome,
											title: item.title,
											productInHome,
											logOut,
											addErrorMessage,
											addSuccessMessage,
											locale: config.default_locale,
										})}
									/>
								</div>
							</td>
						</tr>
					)}
					empty={
						<p className='my-5 flex h-24 w-full items-center justify-center rounded-md border border-gray-400 text-gray-700 shadow-md'>
							No hay Productos
						</p>
					}
				/>
			</LoadingView>
		</Card>
	);
};

const OrderArrows = ({
	products,
	index,
	sortProductInHome,
	locale,
	getLazySortProducts,
	logOut,
	addErrorMessage,
	isSuccessResponse,
}) => {
	const handleChangePosition = (action, index) => {
		sortProductInHome({
			variables: {
				id: products[index].id,
				position: action,
				locale,
			},
			onCompleted: ({ SortProductInHome }) => {
				if (
					isSuccessResponse(
						SortProductInHome,
						['Success'],
						logOut,
						addErrorMessage,
						'Ordenar producto en portada'
					)
				) {
					getLazySortProducts();
				}
			},
		});
	};

	return (
		products.length > 1 && (
			<div className='flex'>
				<img
					onClick={() => index < products.length - 1 && handleChangePosition(1, index)}
					className={` w-6 h-6 ${
						index < products.length - 1 ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed'
					}`}
					title='Bajar posición'
					alt='flecha hacia abajo'
					src='/images/square-caret-down-solid.svg'
				/>
				<span className='w-6 text-center'>{products[index].inHome}</span>
				<img
					onClick={() => index > 0 && handleChangePosition(-1, index)}
					className={` w-6 h-6 ${index > 0 ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed'}`}
					title='Subir posición'
					alt='flecha hacia arriba'
					src='/images/square-caret-up-solid.svg'
				/>
			</div>
		)
	);
};

const getProductMenuList = ({ id, isInHome, productInHome, logOut, addErrorMessage, addSuccessMessage, locale }) => {
	const options = [
		{
			title: 'Eliminar de portada',
			onClick: () => {
				productInHome({
					variables: {
						id,
						isInHome: !isInHome,
						locale,
					},
				}).then(({ data }) => {
					if (isSuccessResponse(data.ProductInHome, ['Product'], logOut, addErrorMessage, 'Producto')) {
						addSuccessMessage('Producto', 'Eliminado de portada correctamente');
					}
				});
			},
		},
	];

	return options;
};

export default SortProductsInHome;
