import React, { useState, useEffect, useContext, createContext } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Loading from 'components/Loading';

const MARKETPLACE_CONFIG = gql`
	query Marketplace {
		Marketplace {
			id
			contactEmail
			defaultLocale
			locales
			marketplaceCommission
			marketplaceName
			notificationsEmail
			shopUrl
			statics
			stripePublishableKey
			urlDomain
			translatorKey
			translatorEndpoint
			translatorLocation
			FiscalData {
				name
				cif
				address
				zip
				city
				province
				registration_info
				iban
				swift
			}
		}
	}
`;

const ConfContext = createContext();

// Provider component that wraps your app and makes config object ...
// ... available to any child component that calls useConf().
export function ConfProvider({ children }) {
	const conf = useConfFunctions();
	return conf.isConfigLoaded ? (
		<ConfContext.Provider value={conf}>{children}</ConfContext.Provider>
	) : (
		<Loading text='Configurando...' />
	);
}

export const useConf = () => useContext(ConfContext);

const toArr = str => (str ? str.toString().split(',') : null);

const useConfFunctions = () => {
	// As the initial config set any environment variables set
	const [config, _setConfig] = useState({
		default_locale: process.env.REACT_APP_DEFAULT_LOCALE,
		locales: toArr(process.env.REACT_APP_LOCALES),
		marketplace_commission: process.env.REACT_APP_MARKETPLACE_COMMISSION,
		marketplace_name: process.env.REACT_APP_MARKETPLACE_NAME,
		notifications_email: process.env.REACT_APP_NOTIFICATIONS_EMAIL,
		shop_url: process.env.REACT_APP_SHOP_URL,
		statics: process.env.REACT_APP_STATICS,
		stripe_publishable_key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
		url_domain: process.env.REACT_APP_URL_DOMAIN,
		// Local configuration only (env vars)
		app_version: process.env.REACT_APP_VERSION,
		node_env: process.env.NODE_ENV,
		translatorKey: process.env.REACT_APP_TRANSLATOR_KEY,
		translatorEndpoint: process.env.REACT_APP_TRANSLATOR_ENDPOINT,
		translatorLocation: process.env.REACT_APP_TRANSLATOR_LOCATION,
	});

	const [isConfigLoaded, _setIsConfigLoaded] = useState(false);
	const [maintenance, setMaintenance] = useState(false);

	const { data } = useQuery(MARKETPLACE_CONFIG, { fetchPolicy: 'cache-first' });

	const checkMaintenance = () => {
		fetch(process.env.REACT_APP_EDGE_CONFIG).then(async response => {
			const dataMaintenance = await response.json();
			setMaintenance(dataMaintenance.items.isInMaintenanceMode);
		});
	};

	// Any non defined value is updated with the API returned data
	useEffect(() => {
		if (data) {
			const c = data.Marketplace;
			_setConfig({
				default_locale: config.default_locale || c.defaultLocale || 'ca',
				contact_email: c.contactEmail || '',
				fiscal_data: c.FiscalData || {
					name: '',
					cif: '',
					address: '',
					zip: '',
					city: '',
					province: '',
					registration_info: '',
					iban: '',
					swift: '',
				},
				locales: config.locales || c.locales || [],
				marketplace_commission: config.marketplace_commission ?? c.marketplaceCommission ?? 1,
				marketplace_name: config.marketplace_name || c.marketplaceName || '',
				notifications_email: config.notifications_email || c.notificationsEmail || '',
				shop_url: config.shop_url || c.shopUrl || '',
				statics: config.statics || c.statics || '',
				stripe_publishable_key: config.stripe_publishable_key || c.stripePublishableKey || '',
				url_domain: config.url_domain || c.urlDomain || '',
				// Local configuration only (env vars)
				app_version: config.app_version,
				node_env: config.node_env || 'development',
				translatorKey: config.microsoft_translator_key || c.translatorKey || '',
				translatorEndpoint: config.microsoft_translator_endpoint || c.translatorEndpoint || '',
				translatorLocation: config.microsoft_translator_location || c.translatorLocation || '',
			});
			_setIsConfigLoaded(true);
		}
	}, [data]);

	useEffect(() => {
		// Check if page is in maintenance mode when is loaded and repeat every 15 minutes
		checkMaintenance();
		setInterval(checkMaintenance, 15 * 60 * 1000);
	});

	return {
		config,
		isConfigLoaded,
		maintenance,
	};
};

export default useConf;
